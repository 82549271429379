import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Toolbar } from "@mui/material";
import { goodTime } from "../components/util";
import InviteDialog from "./invite-dialog";
import OrgUserMenu from "./orgUserMenu";
import * as api from '../api/api'
import Footer from '../components/footer';
import CloseIcon from '@mui/icons-material/Close';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import PageLoading from "../components/pageloading";
import PageContainer from "../components/PageContainer";

const SuccessModal = ({ isOpen, onClose, Status }) => {
  console.log('status is', Status)
  let h4Msg = ''
  let pMsg = ''
  switch (Status) {
    case 'ok':
        break;
    case 'fetchfailed':
        h4Msg = "Fetch Failed!"
        pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
        break;
    case 'nouser':
        h4Msg = "Authentication Failed!"
        pMsg = "User is not part of the Organization. Please contact your Administrator"
        break;
    case 'unauth':
        h4Msg = "Authentication Failed!"
        pMsg = "User is not authorized to perform this operation"
        validUser = 0
        break;
    case 'error':
        h4Msg = "Failed!"
        pMsg = "Failed to update the Org name"
        validUser = 0
        break;
    default:
        h4Msg = "Unknown Error"
        pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
        break;
  }
  if (Status !== 'ok') {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose} >
          <h4 className="popup-title">{h4Msg}</h4>
          <p className="success-message">{pMsg}</p>
          <div className="button-container">
            <button onClick={onClose}>Close</button>
          </div>
      </Popup>
    );
  }
};

let validUser = -1

var UserA = {};
var OrgA = {};

export const OrgProfile = () => {
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState(false);
  const [submitMsg, setSubmitMsg] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const [org, setOrg] = useState({
    org_id: "",
    name: "",
    member: [],
    owner: "",
    alertmember: [],
    domains: [],
  });

  const { getAccessTokenSilently } = useAuth0();

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  useEffect(() => {
    const fn = async () => {
      const token = await getAccessTokenSilently();
      await api.GetUser(token, (data) => {
        if (data.status === 'error') {
          if (data.errorCode === 1007) {
            validUser = 1
            setSubmitMsg("nouser");
            setSuccessModalOpen(true);
          } else {
            validUser = 1
            setSubmitMsg("fetchfailed");
            setSuccessModalOpen(true);
          }
        } else {
          setSubmitMsg("ok");
          setSuccessModalOpen(true);
          validUser = 0
          UserA = data
        }
      })
      api.GetOrg(token, (data) => {
        setOrg(data)
        OrgA = data
      })
    }
    fn()
  }, [edit, state]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const tname = target.name;

    setOrg(prevState => ({
      ...prevState,
      [tname]: value
    }));
  }

  const submitHandler = async (event) => {
    event.preventDefault();

    const fn = async () => {
      const token = await getAccessTokenSilently();
      const data = await api.PostOrg(token, { name: org.name });
      if (data.hasOwnProperty('errorCode') && data.errorCode === 1009) {
        validUser = 1
        setSubmitMsg("unauth");
        setSuccessModalOpen(true);
      } else if (data.status === 'error') {
        validUser = 1
        setSubmitMsg("error");
        setSuccessModalOpen(true);
      } else {
        //validUser = 0
        setEdit(false)
      }
    }
    fn()
  }

  if (validUser === 0) {
    return (
      <PageContainer title="Organization" description="this is the organization page">
        <Box component="form"
          onSubmit={submitHandler}
          noValidate
          autoComplete="off">
          <Toolbar>
            <Typography
            variant="h4"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center", color: "#007bff" }}
            >
            Organization
            </Typography>
            <br/><br/><br/><br/>
        </Toolbar>
          <Table aria-label="Org Name">
            <TableBody>
              <TableRow>
                <TableCell align="right">Org ID:</TableCell>
                <TableCell align="left">
                  <Typography variant="h6" component="div" >{org.org_id}</Typography>
                </TableCell>
                <TableCell align="right">Name:</TableCell>
                <TableCell align="left">
                  <Typography variant="h6" component="div" >{org.name}</Typography>
                </TableCell>
                {/* Do not allow customers to change the organization name. CS Team should be able to change
                up on the request from the customers */}
                {/* <TableCell align="left" >
                  {edit
                    ? <TextField
                      required
                      name="name"
                      value={org.name}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      sx={{ width: "100%" }}
                    />
                    : <Typography variant="h6" component="div" >{org.name}</Typography>
                  }
                </TableCell>
                <TableCell>
                  {edit
                    ? <div><Button onClick={() => setEdit(false)} variant="outlined" sx={{ margin: 1 }}>Back</Button>
                      <Button type="submit" variant="contained">Save</Button></div>
                    : <div><Button onClick={() => setEdit(true)} variant="outlined" sx={{ margin: 1 }}>Edit</Button></div>
                  }
                </TableCell> */}
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ margin: 5 }} />
        <Box>
          {(UserA.role === 'superadmin') && (
            <Toolbar>
            <InviteDialog setState={setState} user={UserA} org={OrgA}/>
            </Toolbar>
          )}
          <Toolbar>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>Users</Typography>
            {(UserA.role === 'admin' || UserA.role === 'pooladmin' || UserA.role === 'superadmin') &&
            <InviteDialog setState={setState} user={UserA} org={null}/>
            }
          </Toolbar>
          <Table aria-label="Users">
            <TableHead>
              <TableRow>
                <TableCell >Name</TableCell>
                <TableCell >Email [ <span style={{ color: 'green' }}>&#x2714;</span><span style={{ fontWeight: 'normal' }}> will get Alerts</span>]</TableCell>
                <TableCell >Role</TableCell>
                <TableCell >Invite Status</TableCell>
                <TableCell >Invited</TableCell>
                <TableCell >Joined</TableCell>
                <TableCell >Domains</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                (org.user) && (org.user.length) > 0 ? org.user.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell >
                      {item.name}
                    </TableCell>
                    <TableCell >
                    <span>
                      {item.email}
                      {org.alertmember !== null && org.alertmember !== undefined && org.alertmember.includes(item.email) ? (
                        <span style={{ color: 'green' }}> &#x2714;</span>
                      ):null}
                    </span>
                  </TableCell>
                    <TableCell >
                      {item.role}
                    </TableCell>
                    <TableCell >
                      {item.invite}
                    </TableCell>
                    <TableCell >
                      {goodTime(item.invitedAt)}
                    </TableCell>
                    <TableCell >
                      {goodTime(item.createdAt)}
                    </TableCell>
                    <TableCell >
                      <OrgUserMenu user={UserA} item={item} setState={setState} />
                    </TableCell>
                  </TableRow>
                )) : <TableRow />}
            </TableBody>
          </Table>
        </Box>
        <Footer sub={true}/>
        </PageContainer>
    );
  } else if (validUser !== -1 && validUser !== 0){
    return(
      <PageContainer title="Organization" description="this is the organization page">
      <div>
      <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg} />
      </div>
      </PageContainer>
    )
  } else {
    return <PageContainer title="Organization" description="this is the organization page"><PageLoading /></PageContainer>
  }
};

export default OrgProfile;