import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth0-provider-with-history";
import "./index.css";
import AppContextProvider from "./AppContext/AppContextProvider";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Router>
    <Auth0ProviderWithHistory>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </Auth0ProviderWithHistory>
  </Router>
);
