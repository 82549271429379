const OldMenuitems = [
  {
    title: 'Home',
    href: '/',
  },
  {
    title: 'Profile',
    href: '/profile',
    collapse: true,
    children: [
      {
        title: '> User Profile',
        href: '/profile/user',
      },
      {
        title: '> Organization',
        href: '/profile/org',
      },
    ],
  },
  {
    title: 'Config',
    href: '/config',
    collapse: true,
    children: [
      {
        title: '> DVFS',
        href: '/config/dvfs',
      },
      {
        title: '> ZTP',
        href: '/config/ztp',
      },
      {
        title: '> Pool',
        href: '/config/miner',
      },
      // {
      //   title: '> Stats',
      //   href: '/config/stats',
      // },
      {
        title: '> Review',
        href: '/config/review',
      },
    ],
  },
  // {
  //   title: 'Api Keys',
  //   href: '/apikey',
  // },
  {
    title: 'Jobs',
    href: '/job',
  },
  {
    title: 'Asset Management',
    href: '/device',
    collapse: true,
    children: [
      {
        title: '> Miner List',
        href: '/device/devicelist',
      },
      {
        title: '> Miner Group',
        href: '/device/devicegroup',
      },
    ],
  },
  {
    title: 'Miner Operations',
    href: '/socket',
    collapse: true,
    children: [
      {
        title: '> Post Command',
        href: '/socket/send',
      },
      {
        title: '> Commands Status',
        href: '/socket/recieve',
      },
      // {
      //   title: '> Scaling',
      //   href: '/socket/dvfs',
      // },
      // {
      //   title: '> Mock',
      //   href: '/socket/mockdvfs',
      // },
      {
        title: '> Auto Tuning',
        href: '/socket/tuning',
      },
    ],
  },

];

const NewMenuitems = [
  {
    title: 'Home',
    href: '/',
  },
  {
    title: 'Miner',
    href: '/minerstatus',
  },
  // {
  //   title: 'Miner Group',
  //   href: '/minergroup',
  //   collapse: true,
  //   children: [
  //     {
  //       title: 'Manage Groups',
  //       href: '/minergroup/groupmgmt',
  //     },
  //     {
  //       title: 'Operations',
  //       href: '/minergroup/group-operations',
  //     },
  //   ],
  // },
  {
    title: 'Configuration',
    href: '/config',
    collapse: true,
    children: [
      {
        title: 'Mining Pool',
        href: '/config/miner',
      },
      {
        title: 'Price',
        href: '/config/price',
      },
      {
        title: 'Demand',
        href: '/config/demand',
      },
      {
        title: 'View',
        href: '/minergroup/groupinfo',
      },
    ],
  },
  {
    title: 'Jobs',
    href: '/job',
  },
  {
    title: 'Power Management',
    href: '/power-management',
  },
  {
    title: 'Profile',
    href: '/profile',
    collapse: true,
    children: [
      {
        title: 'User Profile',
        href: '/profile/user',
      },
      {
        title: 'Organization',
        href: '/profile/org',
      },
      {
        title: 'API Keys',
        href: '/profile/apikey',
      },
    ],
  },
  // {
  //   title: 'API Keys',
  //   href: '/apikey',
  // },
  {
    title: 'FluxGPT™',
    href: '/fluxgpt',
  },
  {
    title: 'TechSupport',
    href: '/techsupport',
  },
];

export { OldMenuitems, NewMenuitems };
