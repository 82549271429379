import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Stack, Box, Typography } from '@mui/material';
import { env } from '../env';
import { JobRefreshInterval } from '../components/refresh-interval';
import { displayTS, JobDialog } from './job-dialog'
import { darken, lighten } from '@mui/material/styles';
import Footer from '../components/footer';
import Loading from "../components/loading";
import PageLoading from "../components/pageloading";
import CloseIcon from '@mui/icons-material/Close';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import "../App.css";
import PageContainer from "../components/PageContainer";

const defaultRefresh = 15

const renderJSONPretty = (params) => {
  if (params.value) {
      let text = JSON.stringify(params.value, null, 4)
      let textvalue = ''
      let critical = false
      if (params.field === "commands") {
        let cmdvalue = params.value
        if (cmdvalue.length === 1) {
          let cmd = cmdvalue[0]
          if (cmd.command === 'fvmode' || cmd.command === 'mode') {
            textvalue = cmd.tune
            if (cmd.command === 'fvmode' && cmd.sleep !== undefined && cmd.sleep !== null) {
              textvalue = 'Standby ' + cmd.sleep.toUpperCase()
            }
          } else if (cmd.command === 'powercurtail') {
            if (cmd.hasOwnProperty('operation')) {
              if (cmd.operation === 'add') {
                textvalue = 'AddCurtailSchedule'
              } else if (cmd.operation === 'delete') {
                textvalue = 'DeleteCurtailSchedule'
              } else {
                textvalue = cmd.command
              }
            } else {
              textvalue = cmd.command
            }
          } else {
            textvalue = cmd.command
          }
        }
      }
      return (
          <JobDialog name={params.field} text={text} code={params.value} textvalue={textvalue} critical={critical}/>
      );
  }
  return ""
}

const SuccessModal = ({ isOpen, onClose, Status }) => {
  // console.log('status is', Status)
  let h4Msg = ''
  let pMsg = ''
  switch (Status) {
    case 'ok':
      break;
    case 'fetchfailed':
      h4Msg = "Fetch Failed!"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
    case 'nouser':
        h4Msg = "Authentication Failed!"
        pMsg = "User is not part of the Organization. Please contact your Administrator"
      break;
    default:
      h4Msg = "Unknown Error"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
  }
  if (Status !== 'ok' && Status !== 'something') {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose} >
          <h4 className="popup-title">{h4Msg}</h4>
          <p className="success-message">{pMsg}</p>
          <div className="button-container">
            <button onClick={onClose}>Close</button>
          </div>
      </Popup>
    );
  }
};

let validUser = -1

const initialColVisibility = {
  jobid: true,
  status: true,
  email: true,
  dgname: true,
  org_id: false,
  updatedAt: true,
  createdAt: false,
  commands: true,
};

export const Job = () => {
  const [count, setCount] = React.useState(100);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [timegap, setTimegap] = React.useState(defaultRefresh);
  const [lastRefresh, setLastRefresh] = React.useState("");
  const [submitMsg, setSubmitMsg] = React.useState('');
  const [successModalOpen, setSuccessModalOpen] = React.useState(false);
  const [colWidths, setColWidths] = React.useState({
      jobid     : 300,
      status    : 100,
      email     : 300,
      dgname    : 120,
      org_id    : 120,
      updatedAt : 180,
      createdAt : 120,
      commands  : 200,
  })

  const [colVisibilityModel, setColVisibilityModel] = React.useState(() => {
    const storedColVisibility = localStorage.getItem('JobsColVisibility');
    return storedColVisibility ? JSON.parse(storedColVisibility) : initialColVisibility;
  });

  const [sortModel, setSortModel] = React.useState([]);

  const [filterModel, setFilterModel] = React.useState(() => {
    const savedFilterModel = localStorage.getItem('JobsfilterModel');
    return savedFilterModel ? JSON.parse(savedFilterModel) : { items: [] };
  });

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    localStorage.setItem('JobsfilterModel', JSON.stringify(newFilterModel));
  };

  React.useEffect(() => {
    const storedColWidths = localStorage.getItem('JobsColWidths');
    if (storedColWidths) {
      setColWidths(JSON.parse(storedColWidths));
    }
  }, []);

  React.useEffect(() => {
    const storedColVisibility = localStorage.getItem('JobsColVisibility');
    if (storedColVisibility) {
      setColVisibilityModel(JSON.parse(storedColVisibility));
    }
  }, []);


  React.useEffect(() => {
    // console.log('colVisibilityModel', colVisibilityModel)
    localStorage.setItem('JobsColVisibility', JSON.stringify(colVisibilityModel));
  }, [colVisibilityModel]);


  React.useEffect(() => {
    const storedSortModel = localStorage.getItem('JobsSortModel');
    if (storedSortModel) {
      setSortModel(JSON.parse(storedSortModel));
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem('JobsColWidths', JSON.stringify(colWidths));
  }, [colWidths]);

  const handleColumnVisibilityChange = (newModel) => {
    setColVisibilityModel(newModel);
  };

  const handleSortModelChange = (newSortModel) => {
    // console.log('newSortModel', newSortModel)
    setSortModel(newSortModel);
    localStorage.setItem('JobsSortModel', JSON.stringify(newSortModel));
  };

  function handleColumnWidthChange(width) {
    if (width.colDef.field==='jobid') {
      setColWidths({...colWidths, jobid: width.colDef.width})
    } else if (width.colDef.field==='status') {
      setColWidths({...colWidths, status: width.colDef.width})
    } else if (width.colDef.field==='email') {
      setColWidths({...colWidths, email: width.colDef.width})
    } else if (width.colDef.field==='dgname') {
      setColWidths({...colWidths, dgname: width.colDef.width})
    } else if (width.colDef.field==='org_id') {
      setColWidths({...colWidths, org_id: width.colDef.width})
    } else if (width.colDef.field==='updatedAt') {
      setColWidths({...colWidths, updatedAt: width.colDef.width})
    } else if (width.colDef.field==='createdAt') {
      setColWidths({...colWidths, createdAt: width.colDef.width})
    } else if (width.colDef.field==='commands') {
      setColWidths({...colWidths, commands: width.colDef.width})
    }
  }

  let columns = [
    { field: 'jobid', headerName: 'Job ID', width: colWidths.jobid,  maxWidth: 400, hide:!colVisibilityModel.jobid, sortable: true, renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ), },
    { field: 'status', headerName: 'Status', width: colWidths.status, maxWidth: 400, hide:!colVisibilityModel.status, sortable: true, renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ), },
    { field: 'email', headerName: 'User', width: colWidths.email,  maxWidth: 400, hide:!colVisibilityModel.email, sortable: true, renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ), },
    { field: 'dgname', headerName: 'Group', width: colWidths.dgname,  maxWidth: 400, hide:!colVisibilityModel.dgname, sortable: true, renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ), },
    { field: 'org_id', headerName: 'Org', width: colWidths.org_id,  maxWidth: 400, hide:!colVisibilityModel.org_id, sortable: true, renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ), },
    { field: 'updatedAt', headerName: 'Updated', width: colWidths.updatedAt, maxWidth: 400, hide:!colVisibilityModel.updatedAt, sortable: true, renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ), },
    { field: 'createdAt', headerName: 'Created', width: colWidths.createdAt,  maxWidth: 400, hide:!colVisibilityModel.createdAt, sortable: true, renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ), },
    { field: 'commands', headerName: 'Commands', width: colWidths.commands, maxWidth: 400, hide:!colVisibilityModel.commands, cellClassName: 'no-padding-cell', renderCell: renderJSONPretty, sortable: false, filterable: false },
  ];

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const handleInterval = (val) => {
      setTimegap(val)
  }

  const { getAccessTokenSilently } = useAuth0();

  const [paginationModel, setPaginationModel] = React.useState({page: 0, pageSize: 50})

  const PostJobs = async (page) => {
    let r = {
        count: 0,
        docs: []
    }

    try {
        const token = await getAccessTokenSilently();

        const response = await fetch(
            env.APIPath.jobs,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    'pagination': {
                        limit: paginationModel.pageSize,
                        skip: paginationModel.pageSize * page
                    }
                })
            }
        );
        const data = await response.json();
        // console.log('Response from API', data)
        if (data.status === "ok") {
          validUser = 0
          setSubmitMsg("ok");
          setSuccessModalOpen(true);
          if (data.count > 0) {
            data.docs.forEach((el) => {
                const doc = {
                    jobid: el.jobid ?? '',
                }
                if (el.status) {
                    const lowerCaseString = el.status.toLowerCase();
                    const capitalizedString = lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
                    doc.status = capitalizedString
                }
                if (el.email) {
                    doc.email = el.email
                }
                if (el.dgname) {
                    if (el.dgname === 'Teraflux Group') {
                      doc.dgname = 'Default Group'
                    } else {
                      doc.dgname = el.dgname
                    }
                }
                if (el.org_id) {
                  doc.org_id = el.org_id
                }
                if (el.createdAt) {
                    const { diffTime, timeStr } = displayTS(el.createdAt)
                    doc.createdAt = timeStr
                }
                if (el.updatedAt) {
                  const { diffTime, timeStr } = displayTS(el.updatedAt)
                  doc.updatedAt = timeStr
                }
                if (el.commands) {
                  doc.commands = el.commands
                }
                r.docs.push(doc)
            });
          }
          r.count = data.count
        } else {
            validUser = 1
            if (data.errorCode === 1007) {
              setSubmitMsg("nouser");
              setSuccessModalOpen(true);
            } else {
              setSubmitMsg("fetchfailed");
              setSuccessModalOpen(true);
            }
            console.log('response from API is a failure', data)
        }
    } catch (error) {
        validUser = 1
        setSubmitMsg("something");
        setSuccessModalOpen(true);
        console.log('caught error', error.message);
    }
    return r
};

React.useEffect(() => {
  let active = true;

  const fn = async () => {
      setLoading(true);
      const { docs: newRows, count: count1 } = await PostJobs(paginationModel.page);

      if (!active) {
          return;
      }

      setRows(newRows);
      setLoading(false);
      setCount(count1);

      const nowDate = new Date();
      setLastRefresh(nowDate.toLocaleString());

      setTimeout(() => {
          fn()
      }, timegap * 1000);
  }
  fn()

  return () => {
      active = false;
  }
}, [paginationModel, timegap]);


const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

if (validUser === 0) {
    return (
      <PageContainer title="Jobs" description="this is the jobs page">
      <Box sx={{
          '& .rowGreen': {
              bgcolor: (theme) =>
                  getBackgroundColor(theme.palette.info.main, theme.palette.mode),
          },
      }}>
            <Typography
            variant="h4"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center", color: "#007bff" }}
            >
            Job Details
            </Typography>
            <br />
          <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 4, ml:2 }}>
          <Typography sx={{mr:3, mt:-2}}>Refresh Interval</Typography>
            <JobRefreshInterval name={`Last Refresh: ${lastRefresh}`} value={timegap} callback={handleInterval}/>
          </Stack>
          <Box
          sx={{
            fontSize: '12px',
            fontStyle: 'italic',
            textAlign: 'left',
            color: 'gray',
            mr: 1,
          }}>
          <IconButton color="primary" size="small">
          <InfoOutlinedIcon fontSize="small" />
          </IconButton>
          <Typography variant="caption" sx={{ fontStyle: 'italic', fontSize: 12 }}>
            Jobs older than 30 days are automatically deleted
          </Typography>
        </Box>
        <br />
          <DataGrid
              autoHeight
              getRowId={(row) => row.jobid}
              rows={rows}
              columns={columns}
              getRowHeight={() => 'auto'}
              getEstimatedRowHeight={() => 50}
              pagination
              pageSizeOptions={[25, 50]}
              rowCount={count}
              paginationModel={paginationModel}
              onPaginationModelChange={(newModel) => {
                if (!loading) {
                  setPaginationModel(newModel);
                }
              }}
              paginationMode="server"
              onRowSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
              }}
              rowSelectionModel={selectionModel}
              loading={loading}
              disableRowSelectionOnClick
              sx={{mr:5, ml:2, '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '6px' },
    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '9px' },
    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },}}
              onColumnWidthChange={handleColumnWidthChange}
              onColumnResize={(params) => handleColumnWidthChange(params)}
              columnVisibilityModel={colVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => handleColumnVisibilityChange(newModel)}
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              filterModel={filterModel}
              onFilterModelChange={handleFilterModelChange}
          />
      <Footer />
      </Box >
      </PageContainer>
    );
  } else if (validUser !== -1 && validUser !== 0) {
    return(
      <PageContainer title="Jobs" description="this is the jobs page">
      <div>
      <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg}/>
      </div>
      </PageContainer>
      );
  } else {
    return (
      <PageContainer title="Jobs" description="this is the jobs page">
      <PageLoading />
      </PageContainer>
    );
  }
}

export default withAuthenticationRequired(Job, {
    onRedirecting: () => <Loading />,
});