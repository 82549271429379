import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { env } from "../env.js";
import PaperWrap from "../components/paper-wrap";
import Loading from "../components/loading";
import { TabPanel, a11yProps } from "../components/tab";
import * as cfg from "./cfg";
import * as api from "../api/api";
import Footer from "../components/footer";
import MinerConfig from "./miner-config";
import PoolConfig from "./pool-config";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "react-modal";
import Popup from "reactjs-popup";
import Button from "@mui/material/Button";
import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PageLoading from "../components/pageloading";

const SuccessModal = ({ isOpen, onClose }) => {
  return (
    <Popup className="popup1-content" open={isOpen} onClose={onClose}>
      <div>
        <h4 className="popup-title">Success Message</h4>
        <p className="success-message">Saved Mining Pool Config Successfully</p>
      </div>
      <div className="button-container">
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
  );
};

const FailModal = ({ isOpen, onClose }) => {
  return (
    <Popup className="popup1-content" open={isOpen} onClose={onClose}>
      <h4 className="popup-title">Warning Message</h4>
      <p className="success-message">Failed to save the Mining Pool Config</p>
      <div className="button-container">
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
  );
};

const FailModalUnAuth = ({ isOpen, onClose }) => {
  return (
    <Popup className="popup1-content" open={isOpen} onClose={onClose}>
      <h4 className="popup-title">Warning Message</h4>
      <p className="success-message">
        User is not authorized to perform this operation
      </p>
      <div className="button-container">
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
  );
};

const CONFIG_APPLY_GROUPS = "configApplyGroups";
const CONFIG_SELECT_ALL = "configSelectAll";

const OTPProtectedPage = (props) => {
  // console.log('OTPProtectedPage props:', props)
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const updatedGroupList = props.GroupList.filter(
    (group) => group !== props.minerGroup
  );
  // const [applyGroups, setApplyGroups] = useState(JSON.parse(localStorage.getItem(CONFIG_APPLY_GROUPS)) || []);
  // const storedSelectAll = localStorage.getItem(CONFIG_SELECT_ALL);
  // const initialSelectAll = storedSelectAll === 'true';
  // const [selectAll, setSelectAll] = useState(initialSelectAll || false);

  const [applyGroups, setApplyGroups] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  //console.log('OTPProtectedPage minerGroup:', props.minerGroup);
  //console.log('OTPProtectedPage GroupList:', props.GroupList);

  const history = useHistory();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isVerified, setIsVerified] = useState(false);
  const [value, setValue] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState("");

  const [seturl1, setURL1] = useState("");
  const [seturl2, setURL2] = useState("");
  const [seturl3, setURL3] = useState("");

  const [setuser1, setUser1] = useState("");
  const [setuser2, setUser2] = useState("");
  const [setuser3, setUser3] = useState("");

  const [setpass1, setPass1] = useState("");
  const [setpass2, setPass2] = useState("");
  const [setpass3, setPass3] = useState("");

  const [showPassword1, setShowPassword1] = useState(false);

  const handleShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const [showPassword2, setShowPassword2] = useState(false);

  const handleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const [showPassword3, setShowPassword3] = useState(false);

  const handleShowPassword3 = () => {
    setShowPassword3(!showPassword3);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const [firstEffectComplete, setFirstEffectComplete] = React.useState(false);

  React.useEffect(() => {
    //console.log('useEffect 1', props)
    setFirstEffectComplete(false);
    const fn = async () => {
      const token = await getAccessTokenSilently();
      const dgOrgConfig = await api.GetDGOrgConfig(token, props.minerGroup);
      //console.log('OTPProtectedPage dgOrgConfig:::::', dgOrgConfig)
      if (dgOrgConfig && dgOrgConfig.org && dgOrgConfig.org.config) {
        const cfg0 = dgOrgConfig.org.config;
        //console.log('OTPProtectedPage helloooo', props.minerGroup, { cfg0: cfg0 })
        await cfg.loadCfg(cfg0);
      } else {
        const cfg0 = {};
        await cfg.loadCfg(cfg0);
      }
      setReady(true);
      setIsVerified(true);
      setFirstEffectComplete(true);
      if (applyGroups.length > 0) {
        const updatedGroups = applyGroups.filter(
          (group) => group !== props.minerGroup
        );
        setApplyGroups(updatedGroups);
        localStorage.setItem(
          CONFIG_APPLY_GROUPS,
          JSON.stringify(updatedGroups)
        );
        setSelectAll(props.GroupList === updatedGroupList.length);
        localStorage.setItem(
          CONFIG_SELECT_ALL,
          (Array.isArray(props.GroupList)
            ? props.GroupList.length === updatedGroupList.length
            : false
          ).toString()
        );
      }
    };
    fn();
  }, [props.minerGroup]);

  React.useEffect(() => {
    if (!firstEffectComplete) {
      console.log("First useEffect is not completed yet");
      return;
    }
    // console.log("Execute the second useEffect")

    const fn1 = async () => {
      let data = cfg.getPool(0);
      //console.log('OTPProtectedPage getting the data from pool index 0', data)
      if (data) {
        setURL1(data.url);
        setUser1(data.user);
        setPass1(data.pass);
      }
      data = {};
      data = cfg.getPool(1);
      //console.log('OTPProtectedPage getting the data from pool index 1', data)
      if (data) {
        setURL2(data.url);
        setUser2(data.user);
        setPass2(data.pass);
      }
      data = {};
      data = cfg.getPool(2);
      //console.log('OTPProtectedPage getting the data from pool index 2 ', data)
      if (data) {
        setURL3(data.url);
        setUser3(data.user);
        setPass3(data.pass);
      }
    };
    fn1();
  }, [
    firstEffectComplete,
    isAuthenticated,
    getAccessTokenSilently,
    history,
    applyGroups,
  ]);

  const handleChange1 = (event) => {
    const target = event.target;
    const { id } = target;

    //console.log('OTPProtectedPage here is the id', id)
    if (id === "url1") {
      setURL1(target.value);
      cfg.setPoolConfig(0, 1, target.value);
    }
    if (id === "user1") {
      setUser1(target.value);
      cfg.setPoolConfig(0, 2, target.value);
    }
    if (id === "pass1") {
      setPass1(target.value);
      cfg.setPoolConfig(0, 3, target.value);
    }
  };

  const handleChange2 = (event) => {
    const target = event.target;
    const { id } = target;

    if (id === "url2") {
      setURL2(target.value);
      cfg.setPoolConfig(1, 1, target.value);
    }
    if (id === "user2") {
      setUser2(target.value);
      cfg.setPoolConfig(1, 2, target.value);
    }
    if (id === "pass2") {
      setPass2(target.value);
      cfg.setPoolConfig(1, 3, target.value);
    }
  };

  const handleChange3 = (event) => {
    const target = event.target;
    const { id } = target;

    if (id === "url3") {
      setURL3(target.value);
      cfg.setPoolConfig(2, 1, target.value);
    }
    if (id === "user3") {
      setUser3(target.value);
      cfg.setPoolConfig(2, 2, target.value);
    }
    if (id === "pass3") {
      setPass3(target.value);
      cfg.setPoolConfig(2, 3, target.value);
    }
  };

  const [oldCfg, setOldCfg] = React.useState({});
  const [newCfg, setNewCfg] = React.useState({});

  const defaultHandler = () => {
    cfg.defaultCfg();
    const newAppCfg = cfg.getCfg();
    setNewCfg(newAppCfg);
    const fn1 = async () => {
      let data = cfg.getPool(0);
      if (data) {
        setURL1(data.url);
        setUser1(data.user);
        setPass1(data.pass);
      }
      data = {};
      data = cfg.getPool(1);
      if (data) {
        setURL2(data.url);
        setUser2(data.user);
        setPass2(data.pass);
      }
      data = {};
      data = cfg.getPool(2);
      if (data) {
        setURL3(data.url);
        setUser3(data.user);
        setPass3(data.pass);
      }
    };
    fn1();
  };

  const revertHandler = () => {
    cfg.revertCfg();
    const newAppCfg = cfg.getCfg();
    console.log("In RevertHandler", newAppCfg.poolCfg);
    setNewCfg(newAppCfg);
    const fn1 = async () => {
      let data = cfg.getPool(0);
      if (data) {
        setURL1(data.url);
        setUser1(data.user);
        setPass1(data.pass);
      }
      data = {};
      data = cfg.getPool(1);
      if (data) {
        setURL2(data.url);
        setUser2(data.user);
        setPass2(data.pass);
      }
      data = {};
      data = cfg.getPool(2);
      if (data) {
        setURL3(data.url);
        setUser3(data.user);
        setPass3(data.pass);
      }
    };
    fn1();
  };

  const saveHandler = () => {
    //console.log('OTPProtectedPage saveHandler ...', props.minerGroup)
    const fn = async () => {
      const token = await getAccessTokenSilently();
      const b_result = await cfg.saveCfg(token, props.minerGroup);
      if (b_result === 0) {
        setSubmitSuccess("ok");
        setSuccessModalOpen(true);
      } else if (b_result === 2) {
        setSubmitSuccess("error");
        setSuccessModalOpen(true);
      } else {
        setSubmitSuccess("unauth");
        setSuccessModalOpen(true);
      }
      const oldAppCfg = cfg.getCfg0();
      setOldCfg(oldAppCfg);
      const newAppCfg = cfg.getCfg();
      setNewCfg(newAppCfg);
    };
    fn();
  };

  const handleSelectAll = (event) => {
    const allOptions = updatedGroupList;
    const selectedOptions = event.target.checked ? allOptions : [];
    console.log("handleSelectAll", selectedOptions);
    setApplyGroups(selectedOptions);
    localStorage.setItem(CONFIG_APPLY_GROUPS, JSON.stringify(selectedOptions));
    setSelectAll(event.target.checked);
    localStorage.setItem(CONFIG_SELECT_ALL, event.target.checked.toString());
  };

  const applyVal = (value) => {
    console.log("applyVal", value);
    setApplyGroups(value);
    localStorage.setItem(CONFIG_APPLY_GROUPS, JSON.stringify(value));
    setSelectAll(value.length === updatedGroupList.length);
    localStorage.setItem(
      CONFIG_SELECT_ALL,
      (Array.isArray(value)
        ? value.length === updatedGroupList.length
        : false
      ).toString()
    );
  };

  const applyZTPConfig = async () => {
    const newAppCfg = cfg.getCfg();
    console.log("In applyZTPConfig", newAppCfg);
    console.log("applyZTPConfig", applyGroups);

    const fn = async () => {
      const token = await getAccessTokenSilently();
      const b_result = await cfg.saveMultiCfg(token, applyGroups);
      if (b_result === 0) {
        setSubmitSuccess("ok");
        setSuccessModalOpen(true);
      } else if (b_result === 2) {
        setSubmitSuccess("error");
        setSuccessModalOpen(true);
      } else {
        setSubmitSuccess("unauth");
        setSuccessModalOpen(true);
      }
      const oldAppCfg = cfg.getCfg0();
      setOldCfg(oldAppCfg);
      const newAppCfg = cfg.getCfg();
      setNewCfg(newAppCfg);
    };
    if (applyGroups.length > 0) {
      fn();
    }
  };

  return isVerified ? (
    <React.Fragment>
      {ready ? (
        <Grid container spacing={6}>
          <Grid item xs={12} md={4} lg={4}>
            <Paper elevation={2} sx={{ borderRadius: "1rem" }}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                // sx={{ border: "5px dashed black" }}
                padding={6}
                gap={2}
              >
                <Typography variant={"h6"}>Pool 1</Typography>
                <TextField
                  id="url1"
                  label="URL"
                  variant="outlined"
                  value={seturl1}
                  onChange={handleChange1}
                  fullWidth
                  disabled={props.ReadOnlyMode}
                  inputProps={{
                    sx: {
                      color: "black",
                      fontSize: "14px",
                    },
                  }}
                />
                <TextField
                  id="user1"
                  label="User"
                  variant="outlined"
                  value={setuser1}
                  onChange={handleChange1}
                  fullWidth
                  disabled={props.ReadOnlyMode}
                  inputProps={{
                    sx: {
                      color: "black",
                      fontSize: "14px",
                    },
                  }}
                />
                <TextField
                  id="pass1"
                  type={showPassword1 ? "text" : "password"}
                  label="Password"
                  variant="outlined"
                  value={setpass1}
                  onChange={handleChange1}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword1}
                          edge="end"
                          disabled={props.ReadOnlyMode}
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={props.ReadOnlyMode}
                  inputProps={{
                    sx: {
                      color: "black",
                      fontSize: "14px",
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper elevation={2} sx={{ borderRadius: "1rem" }}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                // sx={{ border: "5px dashed black" }}
                padding={6}
                gap={2}
              >
                <Typography variant="h6">Pool 2</Typography>
                <TextField
                  id="url2"
                  label="URL"
                  variant="outlined"
                  value={seturl2}
                  onChange={handleChange2}
                  disabled={props.ReadOnlyMode}
                  inputProps={{
                    sx: {
                      color: "black",
                      fontSize: "14px",
                    },
                  }}
                />
                <TextField
                  id="user2"
                  label="User"
                  variant="outlined"
                  value={setuser2}
                  onChange={handleChange2}
                  disabled={props.ReadOnlyMode}
                  inputProps={{
                    sx: {
                      color: "black",
                      fontSize: "14px",
                    },
                  }}
                />
                <TextField
                  id="pass2"
                  type={showPassword2 ? "text" : "password"}
                  label="Password"
                  variant="outlined"
                  value={setpass2}
                  onChange={handleChange2}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword2}
                          edge="end"
                          disabled={props.ReadOnlyMode}
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={props.ReadOnlyMode}
                  inputProps={{
                    sx: {
                      color: "black",
                      fontSize: "14px",
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper elevation={2} sx={{ borderRadius: "1rem" }}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                // sx={{ border: "5px dashed black" }}
                padding={6}
                gap={2}
              >
                <Typography variant="h6">Pool 3</Typography>
                <TextField
                  id="url3"
                  label="URL"
                  variant="outlined"
                  value={seturl3}
                  onChange={handleChange3}
                  disabled={props.ReadOnlyMode}
                  inputProps={{
                    sx: {
                      color: "black",
                      fontSize: "14px",
                    },
                  }}
                />
                <TextField
                  id="user3"
                  label="User"
                  variant="outlined"
                  value={setuser3}
                  onChange={handleChange3}
                  m
                  disabled={props.ReadOnlyMode}
                  inputProps={{
                    sx: {
                      color: "black",
                      fontSize: "14px",
                    },
                  }}
                />
                <TextField
                  id="pass3"
                  type={showPassword3 ? "text" : "password"}
                  label="Password"
                  variant="outlined"
                  value={setpass3}
                  onChange={handleChange3}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword3}
                          edge="end"
                          disabled={props.ReadOnlyMode}
                        >
                          {showPassword3 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={props.ReadOnlyMode}
                  inputProps={{
                    sx: {
                      color: "black",
                      fontSize: "14px",
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        "help"
      )}
      {!props.ReadOnlyMode && (
        <Grid container sx={{ alignContent: "center", mb: 8, mt: 6 }}>
          <Grid item xs={12}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              gap={2}
            >
              <Button
                onClick={defaultHandler}
                variant="contained"
                sx={{ width: 100 }}
              >
                Clear
              </Button>
              <Button
                onClick={revertHandler}
                variant="contained"
                sx={{ width: 100 }}
              >
                Revert
              </Button>
              <Button
                sx={{ width: 100 }}
                onClick={saveHandler}
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </Grid>

          {submitSuccess === "ok" && (
            <SuccessModal
              isOpen={successModalOpen}
              onClose={handleCloseSuccessModal}
            />
          )}
          <Grid item xs={12}>
            {updatedGroupList.length > 0 && (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"center"}
              >
                <Box
                  display={"flex"}
                  width={"60%"}
                  border={"5px dashed rgb(224, 226, 236)"}
                  borderRadius={"0.5rem"}
                  flexDirection={"column"}
                  marginTop={"4%"}
                  gap={2}
                  padding={4}
                >
                  <Typography sx={{ mr: 10 }}>
                    Apply Mining Pool Config to Groups
                  </Typography>

                  <Stack direction="column" alignItems="left">
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ ml: -4.5, mb: -1.2 }}
                    >
                      <Checkbox
                        style={{ marginLeft: 8 }}
                        checked={selectAll}
                        onChange={(event) => handleSelectAll(event)}
                      />
                      <Typography variant="caption">Select All</Typography>
                    </Stack>

                    <Autocomplete
                      multiple
                      value={applyGroups}
                      onChange={(event, value) => applyVal(value)}
                      fullWidth
                      disableCloseOnSelect
                      id="combo-box-demo3"
                      options={updatedGroupList}
                      getOptionLabel={(option) =>
                        option === "Teraflux Group" ? "Default Group" : option
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option === "Teraflux Group"
                            ? "Default Group"
                            : option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          aria-label="Select mode"
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              fontSize: "12px",
                            },
                          }}
                        />
                      )}
                      // renderTags={() => null}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={
                              option === "Teraflux Group"
                                ? "Default Group"
                                : option
                            }
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      defaultValue={[]}
                    />
                  </Stack>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                  >
                    {applyGroups.length > 0 && (
                      <Button
                        onClick={applyZTPConfig}
                        variant="contained"
                        disabled={false}
                        sx={{ width: 100 }}
                      >
                        Apply
                      </Button>
                    )}
                    {applyGroups.length <= 0 && (
                      <Button
                        onClick={applyZTPConfig}
                        variant="contained"
                        disabled={true}
                        sx={{ width: 100 }}
                      >
                        Apply
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            {submitSuccess === "error" && (
              <FailModal
                isOpen={successModalOpen}
                onClose={handleCloseSuccessModal}
              />
            )}
            {submitSuccess === "unauth" && (
              <FailModalUnAuth
                isOpen={successModalOpen}
                onClose={handleCloseSuccessModal}
              />
            )}
          </Grid>
        </Grid>
      )}
      <Footer sub={true} />
    </React.Fragment>
  ) : (
    <PageLoading />
  );
  // <div>
  //     {/* <h1>OTP Protected Page</h1>
  //     <p>This page is protected by Auth0 OTP factor.</p> */}
  // </div>;
};

export default OTPProtectedPage;
