import * as React from "react";
import { useEffect, useState } from "react";
import PageContainer from "../components/PageContainer";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import PaperWrap from "../components/paper-wrap";
import Loading from "../components/loading";
import { TabPanel, a11yProps } from "../components/tab";
import ZTPConfig from "./ztp-config";
import MinerConfig from "./miner-config";
import StatsConfig from "./stats-config";
import ReviewConfig from "./review-config";
import * as cfg from "./cfg";
import * as api from "../api/api";
import Footer from "../components/footer";
import OTPProtectedPage from "./OTPProtectedPage";
import CloseIcon from "@mui/icons-material/Close";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import PageLoading from "../components/loading";
import { Typography, Stack } from "@mui/material";

const CONFIG_GROUP_NAME = "configGroupName";
const CONFIG_GROUP_LIST = "configGroupList";

const SuccessModal = ({ isOpen, onClose, Status }) => {
  console.log("status is", Status);
  let h4Msg = "";
  let pMsg = "";
  switch (Status) {
    case "ok":
      break;
    case "nouser":
      h4Msg = "Authentication Failed!";
      pMsg =
        "User is not part of the Organization. Please contact your Administrator";
      break;
    case "noauth":
      h4Msg = "Authorization Failed!";
      pMsg = "User doesn't have permission to view this page";
      break;
    case "fetchfailed":
      h4Msg = "Fetch Failed!";
      pMsg =
        "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine";
      break;
    default:
      h4Msg = "Unknown Error";
      pMsg =
        "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine";
      break;
  }
  if (Status !== "ok") {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose}>
        <h4 className="popup-title">{h4Msg}</h4>
        <p className="success-message">{pMsg}</p>
        <div className="button-container">
          <button onClick={onClose}>Close</button>
        </div>
      </Popup>
    );
  }
};

let validUser = -1;
export const MinerConfigSetup = () => {
  console.log("MinerConfigSetup ...");

  const { getAccessTokenSilently, user } = useAuth0();
  const [ready, setReady] = React.useState(false);
  const [successModalOpen, setSuccessModalOpen] = React.useState(false);
  const [submitMsg, setSubmitMsg] = React.useState("");

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  // const [minerGroup, setMinerGroup] = useState(localStorage.getItem(CONFIG_GROUP_NAME) || '');
  // const [minerGroups, setMinerGroups] = useState(
  //   JSON.parse(localStorage.getItem(CONFIG_GROUP_LIST)) || []
  // );

  const [minerGroup, setMinerGroup] = useState("Teraflux Group");
  const [minerGroups, setMinerGroups] = useState([]);
  const [isAllowed, setIsAllowed] = useState(false);

  const handleMinerGroup = async (value) => {
    console.log("selected handleMinerGroup", value);
    setMinerGroup(value);
    localStorage.setItem(CONFIG_GROUP_NAME, value);
  };

  React.useEffect(() => {
    const runFn = async () => {
      const token = await getAccessTokenSilently();
      const isAllowed = await api.IsPoolOperationAllowed(token);
      if (isAllowed === undefined || isAllowed === null) {
        validUser = 1;
        setSubmitMsg("something");
        setSuccessModalOpen(true);
        return;
      }
      if (
        isAllowed.status === "error" &&
        isAllowed.errorCode !== 1007 &&
        isAllowed.errorCode !== 1009
      ) {
        validUser = 1;
        setSubmitMsg("fetchfailed");
        setSuccessModalOpen(true);
        return;
      }
      if (isAllowed.status === "error" && isAllowed.errorCode === 1007) {
        validUser = 1;
        setSubmitMsg("nouser");
        setSuccessModalOpen(true);
        return;
      }
      if (
        isAllowed.status === "error" &&
        isAllowed.errorCode === 1009 &&
        isAllowed.role === "user"
      ) {
        validUser = 1;
        setSubmitMsg("noauth");
        setSuccessModalOpen(true);
        return;
      }
      if (isAllowed.role === "admin" || isAllowed.role === "user") {
        setIsAllowed(false);
      } else {
        setIsAllowed(true);
      }
      validUser = 0;
      setSubmitMsg("ok");
      setSuccessModalOpen(true);
      const deviceGroups = await api.GetDGNames(token);
      if (deviceGroups.status === "error") {
        if (deviceGroups.errorCode === 1007) {
          validUser = 1;
          setSubmitMsg("nouser");
          setSuccessModalOpen(true);
        } else {
          validUser = 1;
          setSubmitMsg("fetchfailed");
          setSuccessModalOpen(true);
        }
      } else {
        // console.log("deviceGroups", deviceGroups)
        const groupList = Object.keys(deviceGroups);
        var minerGroupList = [];
        for (let i = 0; i < groupList.length; i++) {
          minerGroupList.push(groupList[i]);
        }
        minerGroupList.sort();
        setMinerGroups(minerGroupList);
        localStorage.setItem(CONFIG_GROUP_LIST, JSON.stringify(minerGroupList));
        setReady(true);
        validUser = 0;
      }
    };
    runFn();
  }, [getAccessTokenSilently]);

  if (validUser === 0) {
    return (
      <PageContainer
        title="Mining Pool"
        description="this is the pool config page"
      >
        <Box paddingLeft={8} paddingRight={8}>
          <Box>
            <Grid container rowSpacing={8}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ flexGrow: 1, textAlign: "center", color: "#007bff" }}
                >
                  Pool Configuration
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={2}
                  ju
                >
                  <Typography variant={"p"}>
                    Load Mining Pool Configuration from
                  </Typography>
                  <Autocomplete
                    disablePortal
                    onChange={(event, value) => handleMinerGroup(value)}
                    sx={{
                      width: "350px",
                      mt: -3,
                      borderRadius: "8px",
                    }}
                    disableClearable
                    id="mode"
                    value={minerGroup}
                    options={minerGroups}
                    getOptionLabel={(option) =>
                      option === "Teraflux Group" ? "Default Group" : option
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        {option === "Teraflux Group" ? "Default Group" : option}
                      </li>
                    )}
                    getOptionSelected={(option, value) => option === value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Miner Group"
                        aria-label="Select mode"
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontSize: "14px",
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              {minerGroup && (
                <Grid item xs={12}>
                  <OTPProtectedPage
                    minerGroup={minerGroup}
                    GroupList={minerGroups}
                    ReadOnlyMode={!isAllowed}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </PageContainer>
    );
  } else if (validUser !== -1 && validUser !== 0) {
    return (
      <PageContainer
        title="Mining Pool"
        description="this is the pool config page"
      >
        <div>
          <SuccessModal
            isOpen={successModalOpen}
            onClose={handleCloseSuccessModal}
            Status={submitMsg}
          />
        </div>
      </PageContainer>
    );
  } else {
    return (
      <PageContainer
        title="Mining Pool"
        description="this is the pool config page"
      >
        <PageLoading />
      </PageContainer>
    );
  }
};

export default MinerConfigSetup;
