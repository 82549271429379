import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useRef } from "react";
import { Box, Table, TableBody, TableRow, TableCell, Toolbar, Typography, Button, Select, MenuItem } from '@mui/material';
import { UserA } from "../user/user";
import TextField from '@mui/material/TextField';
import * as api from '../api/api'
import Loading from "../components/loading";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Footer from '../components/footer';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { useTheme } from '@mui/material/styles';
import Plotly from 'plotly.js-dist';
import Tooltip from '@mui/material/Tooltip';

  

export const DVFS = () => {

    const { getAccessTokenSilently } = useAuth0();
    
    const [energyCost, setEnergyCost] = useState('');

    const [floatValue, setFloatValue] = useState(0);
    const [efficiencyData, setEfficiencyData]= useState([]);
    const [profitData, setProfitData]= useState([]);
    const [maxRate, setmaxRate]= useState([ ]);
    const [maxProfit, setmaxProfit]= useState([]);
    const [joules, setJoules]= useState([]);
    const [txFee, setTxFees] = useState([]);
    const [subsidy, setSubsidy] = useState([]);
    const [poolFee, setPoolFee] = useState([]);
    const [btcDifficulty, setbtcDifficulty] = useState([]);
    const [ElectricityCost, setElectricityCost] = useState([]);
    const [maxRevenue, setmaxRevenue] = useState([]);
    const [val2, setVal2] = useState([]);
    const [deviceList, setDeviceList]=useState([]);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    
    const [thsdone, setTHSdone]= useState([]);

    const [error, setError] = useState({
      error: false,
      helperText: "",
    })

    const checkVal2 = async(value) => {
    if (value.includes("select all")) {
      setVal2(deviceList)
    } else {
      if (val2.includes("select all")) {
        setVal2([])
      } else {
        setVal2(value)
      }
    }
  }

  
    const handleInputChange = (event) => {
      setEnergyCost(event.target.value);
    }
  
    const submitHandler = async (event) => {
      event.preventDefault();

      if (error.error) return
  
      const convertedValue = parseFloat(energyCost);

      setFloatValue(convertedValue);

      const fn = async () => {
        const token = await getAccessTokenSilently();
        const data1 = await api.PostBTC(token, convertedValue);
        if (data1) {
            setChartRendered1(false)
            setEfficiencyData(data1.efficiencyData)
            setProfitData(data1.profitData)
            setmaxRate(data1.maxRate)
            setmaxProfit(data1.maxProfit)
            setJoules(data1.bestEfficiency)
            setTxFees(data1.estimatedTransactionFee)
            setSubsidy(data1.estimatedSubsidy)
            setPoolFee(data1.estimatedPoolFee)
            setbtcDifficulty(data1.btcDifficulty)
            setElectricityCost(data1.electricityCost)
            setmaxRevenue(data1.maxRevenue)

        }
      }
      fn()
    }

    const setTHsHandler = async (event) => {
      event.preventDefault();
  
      if (error.error) return
  
      let convertedValue = parseFloat(maxRate.toFixed(3));

      setFloatValue(convertedValue);

      setTHSdone(1)
      var command = []
      command.push({"command" : "mode", "mode": "custom", "tune":"ths", "ths":convertedValue})
      var serials = [...val2]
      const index2 = serials.indexOf("select all")
      if (index2 > -1) {
        serials.splice(index2, 1);
      }
      const token = await getAccessTokenSilently();
      const info = {}
      info.serials = serials;
      info.commands = command;
      info.lastTs = new Date();
      await api.SendCommands(token, info);
    }
    
    const layout = {
      title: 'Efficiency and Profit per Day',
      xaxis: { title: 'Hash Rate (TH/s)' },
      yaxis: { title: 'Efficiency / Profit per day ($)' },
    };
 

    const chartRef1 = useRef(null);
    const [chartRendered1, setChartRendered1] = useState(false);

    useEffect(() => {
      const fn = async () => {
        const token = await getAccessTokenSilently();
        const devices = await api.GetAllDevices(token);
        if (devices === undefined) {
        }
        else {
          var deviceArray = [];
          for (let i = 0; i < devices.length; i++) {
            deviceArray.push(devices[i].SerialNo)
          }
          deviceArray.unshift("select all")
          console.log(deviceArray)
          setDeviceList(deviceArray)
        }
      }
      fn()

      console.log('chart render value', chartRendered1);

      let maxProfit1 = parseFloat(maxProfit).toFixed(3)
      let maxRate1 = parseFloat(maxRate).toFixed(3)
      let joules1 = parseFloat(joules).toFixed(0)
      let txFee1 = parseFloat(txFee).toFixed(3)
      let subsidy1 = parseFloat(subsidy).toFixed(2)
      let poolFee1 = parseFloat(poolFee).toFixed(2)
      let btcDifficulty1 = parseFloat(btcDifficulty).toFixed(2)
      let ElectricityCost1 = parseFloat(ElectricityCost).toFixed(2)
      let maxRevenue1 = parseFloat(maxRevenue).toFixed(3)

      const titleText = `
            <span style="color: green;">Max Profit:</span> ${maxProfit1} 
            <span style="color: blue;">Max Rate:</span> ${maxRate1} 
            <span style="color: red;">Joules:</span> ${joules1} 
            <span style="color: purple;">Tx Fee:</span> ${txFee1} 
            <span style="color: orange;">Subsidy:</span> ${subsidy1} 
            <span style="color: teal;">Pool Fee:</span> ${poolFee1} 
            <span style="color: magenta;">BTC Difficulty:</span> ${btcDifficulty1} 
            <span style="color: brown;">Electricity Cost:</span> ${ElectricityCost1}
            <span style="color: darkorchid;">Max Revenue:</span> ${maxRevenue1}
          `;
      const layout1 = {
        title: {
          text: titleText,
          font: { size: 10 },
          y: 15, // Set the y-position of the title slightly above the chart
          xref: 'paper',
          x: .01,
          yref: 'paper',
        },
        xaxis: { title: 'Hash Rate (TH/s)' },
        yaxis: { title: 'Profit ' },
        shapes: [
          {
            type: 'line',
            x0: maxRate1,
            x1: maxRate1,
            y0: 0,
            y1: 1,
            xref: 'x',
            yref: 'paper',
            line: { color: 'red', width: 2, dash: 'dash' },
          },
        ],
      };

      const chartConfig1 = {
        responsive: true,
        staticPlot: false,
      };

      if (chartRef1.current && !chartRendered1 && profitData.length > 0) {
        Plotly.newPlot(chartRef1.current, profitData, layout1, chartConfig1);
        setChartRendered1(true)
      }

    }, [chartRendered1, profitData, maxProfit, maxRate, joules, txFee, subsidy, poolFee, btcDifficulty, ElectricityCost, maxRevenue])

    return (
      <div>
        <Box component="form"
          onSubmit={submitHandler}
          noValidate
          autoComplete="off">
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell align="right">Enter energy cost (USD) per kWh</TableCell>
                <TableCell align="left">
                  <TextField
                    required
                    name="energyCost"
                    type="number"
                    value={energyCost}
                    step="0.01"
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Toolbar>
            <Typography variant="h4" component="div" sx={{ padding: 2, flexGrow: 1 }} />
            <Button
              type="submit"
              variant="contained">
              Submit
            </Button>
          </Toolbar>
        </Box>
        <div ref={chartRef1}></div>
        { (maxRate > 0) && (
          <Box display='flex'>
          <Typography sx = {{mt: "1%", ml: "20%"}}>Set Best THS {maxRate.toFixed(3)} for: </Typography>
           <Autocomplete
        multiple
        onChange={(event, value) => checkVal2(value)}
        value={val2}
        sx = {{
     
          width: 500,
          ml: "5%",
          mr: "5%",
          
        }}
        disableCloseOnSelect
        id="combo-box-demo3"
        options={deviceList}
        getOptionLabel={(option) => option}
        
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Devices"   />
        )}

      />
        <Box component="form"
           onSubmit={setTHsHandler}
           noValidate
           autoComplete="off">
            <Toolbar>
                <Typography variant="h4" component="div" sx={{ padding: 2, flexGrow: 1 }} />
                <Button
                  type="Set Max THs"
                  variant="contained">
                  Set THs
                </Button>
            </Toolbar>
        </Box>
        </Box>)}
        {(thsdone > 0) && (
          <div style={{ textAlign: 'center' }}>
            <p>Successfully Set Max THs</p>
          </div>
        )}
      </div> 
    )
  }  
export default withAuthenticationRequired(DVFS, {
  onRedirecting: () => <Loading />,
});