import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import { Stack, Box, Typography, FormControlLabel, Switch, Checkbox, Tab, TextField, Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle } from '@mui/material';
import { env } from '../../env';
import { displayTS } from './device-dialog';
import { darken, lighten } from '@mui/material/styles';
import Footer from '../../components/footer';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ClearIcon from '@mui/icons-material/Clear';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import * as api from '../../api/api'
import MenuItem from '@mui/material/MenuItem';
import PageLoading from '../../components/pageloading';
import { AT_chipLayout, AI_chipLayout, AD_chipLayout } from '../../constants';
import { useHistory, useLocation } from "react-router-dom";
import { List, arrayMove } from 'react-movable';
import Autocomplete from "@mui/material/Autocomplete";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


let validUser = -1
const SuccessModal = ({ isOpen, onClose, Status }) => {
  //console.log('status is', Status)
  let h4Msg = ''
  let pMsg = ''
  switch (Status) {
    case 'ok':
      break;
    case 'nouser':
      h4Msg = "Authentication Failed!"
      pMsg = "User is not part of the Organization. Please contact your Administrator"
      break;
    case 'fetchfailed':
      h4Msg = "Fetch Failed!"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
    default:
      h4Msg = "Unknown Error"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
  }
  if (Status !== 'ok' && Status !== 'something') {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose} >
        <h4 className="popup-title">{h4Msg}</h4>
        <p className="success-message">{pMsg}</p>
        <div className="button-container">
          <button onClick={onClose}>Close</button>
        </div>
      </Popup>
    );
  }
};

  const initialColVisibility = {
    chassis: true,
    customName: true,
    serial: true,
    model: true,
    firmware: true,
  };


  export default function GroupOperationsYYY() {
    const [count, setCount] = React.useState(100);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [paginationModel, setPaginationModel] = React.useState({page: 0, pageSize: 100})

    const [successModalOpen, setSuccessModalOpen] = React.useState(false);
    const [submitMsg, setSubmitMsg] = React.useState('');

    const [members, setMembers] = React.useState([])
    const [alertMembers, setAlertMembers] = React.useState([])
    const [selectAllAlertMembers, setSelectAllAlertMembers] = React.useState(false)
    const [numberOfChipsOverThreshold, setNumberOfChipsOverThreshold] = React.useState('')
    const [isminerGroupSelected, setIsMinerGroupSelected] = React.useState(false);
    const [minerGroups, setMinerGroups] = React.useState([]);
    const [minerGroup, setMinerGroup] = React.useState('')
    const [role, setRole] = React.useState('');
    const [openOperationsPopup, setOpenOperationsPopup] = React.useState(false)



    const [colWidths, setColWidths] = React.useState({
      chassis: 230,
      model: 230,
      firmware: 230,
      customName: 170,
      serial: 230,
    });


    const [colVisibilityModel, setColVisibilityModel] = React.useState(() => {
      const storedColVisibility = localStorage.getItem('GroupOperationsColVisibility');
      return storedColVisibility ? JSON.parse(storedColVisibility) : initialColVisibility;
    });

    const [sortModel, setSortModel] = React.useState([]);

    const [filterModel, setFilterModel] = React.useState(() => {
      const savedFilterModel = localStorage.getItem('GroupOperationsfilterModel');
      return savedFilterModel ? JSON.parse(savedFilterModel) : { items: [] };
    });

    const handleFilterModelChange = (newFilterModel) => {
      setFilterModel(newFilterModel);
      localStorage.setItem('GroupOperationsfilterModel', JSON.stringify(newFilterModel));
    };

    /* Do it for Customer Columns */
    React.useEffect(() => {
      const storedColWidths = localStorage.getItem('GroupOperationsColWidths');
      if (storedColWidths) {
        setColWidths(JSON.parse(storedColWidths));
      }
    }, []);

    React.useEffect(() => {
      const storedColVisibility = localStorage.getItem('GroupOperationsColVisibility');
      if (storedColVisibility) {
        setColVisibilityModel(JSON.parse(storedColVisibility));
      }
    }, []);

    React.useEffect(() => {
      const storedSortModel = localStorage.getItem('GroupOperationsSortModel');
      if (storedSortModel) {
        setSortModel(JSON.parse(storedSortModel));
      }
    }, []);

    React.useEffect(() => {
      localStorage.setItem('GroupOperationsColVisibility', JSON.stringify(colVisibilityModel));
    }, [colVisibilityModel]);


    React.useEffect(() => {
      localStorage.setItem('GroupOperationsColWidths', JSON.stringify(colWidths));
    }, [colWidths]);

    const handleColumnVisibilityChange = (newModel) => {
      setColVisibilityModel(newModel);
    };

    const handleSortModelChange = (newSortModel) => {
      setSortModel(newSortModel);
      localStorage.setItem('GroupOperationsSortModel', JSON.stringify(newSortModel));
    };

    function handleColumnWidthChange(width) {
        if (width.colDef.field==='chassis') {
          setColWidths({...colWidths, chassis: width.colDef.width})
        } else if (width.colDef.field==='model') {
          setColWidths({...colWidths, model: width.colDef.width})
        } else if (width.colDef.field==='firmware') {
          setColWidths({...colWidths, firmware: width.colDef.width})
        } else if (width.colDef.field==='customName') {
          setColWidths({...colWidths, customName: width.colDef.width})
        } else if (width.colDef.field==='serial') {
          setColWidths({...colWidths, serial: width.colDef.width})
        }
      }


    let columns = [
        {
        field: 'customName', headerName: 'Tag', width: colWidths.customName, maxWidth: 400,  hide: false, renderCell: (params) => (
          <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            {params.value}
          </div>
        ),
      },
      {
        field: 'serial', headerName: 'CB SN', width: colWidths.serial, maxWidth: 400,  hide:!colVisibilityModel.serial, renderCell: (params) => (
          <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            {params.value}
          </div>
        ),
      },
      {
        field: 'chassis', headerName: 'Chassis SN', width: colWidths.chassis, maxWidth: 400,  hide:!colVisibilityModel.chassis, renderCell: (params) => (
          <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            {params.value}
          </div>
        ),
      },
      {
        field: 'model', headerName: 'Model', width: colWidths.model, maxWidth: 400,  hide:!colVisibilityModel.model, renderCell: (params) => (
          <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            {params.value}
          </div>
        ),
      },
      {
        field: 'firmware', headerName: 'Version', width: colWidths.firmware, maxWidth: 400,  hide:!colVisibilityModel.firmware, renderCell: (params) => (
          <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            {params.value}
          </div>
        ),
      },
     ]





    const history = useHistory();
    const location = useLocation();

    const [searchStrings, setSearchStrings] = React.useState({
      dgname:'',
      version:'',
      model:'',
      name: '',
      chassis: '',
      CBSN: '',
    });

    const [previoussearchStrings, setPreviousSearchStrings] = React.useState({
        dgname:'',
        version:'',
        model:'',
        name: '',
        chassis: '',
        CBSN: '',
    });

    const setpreviousSearchStrings = async (searchStrings) => {
      setPreviousSearchStrings(searchStrings);
  };

  // Usage:
  // Call setPreviousSearchStrings whenever you want to update previousSearchStrings


    const handleCloseSuccessModal = () => {
      setSuccessModalOpen(false);
    };

    const { getAccessTokenSilently } = useAuth0();

    const PostDeviceOperation = async (page) => {
      let r = {
        count: 0,
        docs: []
      }

    //console.log('PostDeviceOperation', page, pageSize, searchStrings)
     try {
        const token = await getAccessTokenSilently();
        let response = await fetch(
          env.APIPath.deviceOperation,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              device: {
                limit: paginationModel.pageSize,
                skip: paginationModel.pageSize * page
              },
              filter: searchStrings, // AND filter for now
              pass: 2 // Get the pass 2 data
            })
          }
        );
        let data = await response.json();
        //console.log('postdeviceoperation response', data)
        if (data.status === "ok") {
          validUser = 0
          setSubmitMsg('ok')
          setSuccessModalOpen(true);
          // console.log('data count', data.count)
          if (data.count > 0) {
            data.docs.forEach((el) => {
              let dgname = ''
              if (el.DGName) {
                if (el.DGName === 'Teraflux Group' || el.DGName === 'Default Group') {
                  dgname = 'Default Group'
                } else {
                  dgname = el.DGName
                }
              }
              const doc = {
                serial: el.SerialNo ?? '',
                org_name: el.org_name ?? '',
                dgname: dgname,
                customName: el.CustomName ?? '',
                csTag: el.CSTag ?? '',
                firmware: '',
              }

              if (el.ipreport && (el.ipreport.IPReport === undefined || el.ipreport.IPReport === null)) {
                //console.log('full ipresport', el.ipreport)
                doc.model = el.ipreport.model ?? ''
                doc.firmware = el.ipreport.version ?? ''
                doc.ipreport = el.ipreport
                doc.chassis = el.ipreport.ChassisSerialNo ?? ''
              } else if (el.ipreport) {
                doc.ipreport = el.ipreport
                if (el.ipreport.IPReport && el.ipreport.IPReport.length > 0) {
                  doc.model = el.ipreport.IPReport[0].model ?? ''
                  doc.firmware = el.ipreport.IPReport[0].version ?? ''
                  doc.chassis = el.ipreport.IPReport[0].ChassisSerialNo ?? ''
                  if (el.ipreport.IPReport[0].pubKey) {
                    delete el.ipreport.IPReport[0].pubKey
                  }
                  if (el.ipreport.IPReport[0].tpmPubKey) {
                    delete el.ipreport.IPReport[0].tpmPubKey
                  }
                  if (el.ipreport.IPReport[0].InternalType) {
                    delete el.ipreport.IPReport[0].InternalType
                  }
                }
              }

              doc.remoteTuning = ''
              if (el['accept-remote-tuning'] &&
                  el['accept-remote-tuning']['accept-remote-tuning'] &&
                  el['accept-remote-tuning']['accept-remote-tuning'][0] &&
                  el['accept-remote-tuning']['accept-remote-tuning'][0].parameter) {
                    let rt = el['accept-remote-tuning']['accept-remote-tuning'][0].parameter.toUpperCase()
                    if (rt === "OFF") {
                      doc.remoteTuning = "Miner"
                    } else {
                      doc.remoteTuning = "FluxVision"
                    }
              } else {
                // console.log('Remote tuning info is not available for', el.SerialNo, ' ignore it...')
              }
              if (el.temperature) {
                doc.TempStats = el.temperature
                el.temperature.model = doc.model
                el.temperature.chassis = doc.chassis
                if (el.temperature.Temperature) {
                  const temp = [];
                  el.temperature.Temperature.forEach((board) => {
                    const boardTemp = [];
                    if (!(board.BoardTemp === undefined || board.BoardTemp === null)) {
                      board.BoardTemp.forEach((el2) => {
                        let t = el2.Temperature.toFixed(1)
                        boardTemp.push(t.toString());
                      });
                      temp.push(boardTemp.toString());
                    }
                  });
                  doc.temp = temp.toString();
                } else {
                  // console.log('Temperature info is not available for', el.SerialNo, ' ignore it...')
                }
              }
              doc.alertConfig = {}
              if (el.alertNotificationConfig) {
                const sortedKeys = Object.keys(el.alertNotificationConfig).sort();
                const sortedAlertConfig = {};
                sortedKeys.forEach(key => {
                  sortedAlertConfig[key] = el.alertNotificationConfig[key];
                });
                doc.alertConfig = sortedAlertConfig
              }
              let standby = ''
              if (el.mode) {
                doc.ModeStats = el.mode
                if (el.mode.Mode && el.mode.Mode.length > 0) {
                  const sleepStr = el.mode.Mode[0].Sleep === 'on' ? 'Sleeping' : 'Running';
                  standby = sleepStr
                  let tmpmode = `${el.mode.Mode[0].Mode}`
                  let modeTgtThs = `${el.mode.Mode[0].Ths}`
                  let modeTgtPower = `${el.mode.Mode[0].Power}`
                  const lowerCaseString = tmpmode.toLowerCase();
                  const capitalizedString = lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
                  doc.mode = capitalizedString + ', ' + sleepStr
                  if (modeTgtThs !== undefined && modeTgtThs !== null && modeTgtThs !== 'undefined') {
                    doc.mode = doc.mode + ',Ths ' + modeTgtThs
                  } else if (modeTgtPower !== undefined && modeTgtPower !== null && modeTgtPower !== 'undefined') {
                    doc.mode = doc.mode + ',Power ' + modeTgtPower
                  }
                } else {
                  // console.log('Mode info is not available for', el.SerialNo, ' ignore it...')
                }
              }
              if (el.summary && el.summary.updatedAt) {
                const { diffTime, timeStr } = displayTS(el.summary.updatedAt)
                //console.log(doc.serial,diffTime)
                if (diffTime <= 1000 * 60 * 15) {
                  if (standby === "Sleeping") {
                    doc.state = "Standby"
                  } else {
                    if (doc.totalTHs > 0) {
                      doc.state = "Running"
                    } else {
                      doc.state = "Error"
                    }
                  }
                } else {
                  doc.state = "Disconnected"
                }
              } else {
                // console.log('Summary updated info is not available for', el.SerialNo, ' ignore it...')
              }

              if (el.version) {
                doc.version = el.version
              }
              if (el.config) {
                doc.config = el.config
              }
              if (el.updatedAt) {
                const { diffTime, timeStr } = displayTS(el.updatedAt)
                doc.updatedAt = timeStr
              }
              r.docs.push(doc)
            });
          }
          r.count = data.count
        } else {
          if (data.errorCode === 1007) {
            validUser = 1
            setSubmitMsg('nouser')
            setSuccessModalOpen(true);
          } else {
            validUser = 1
            setSubmitMsg('fetchfailed')
            setSuccessModalOpen(true);
          }
          //console.log(data);
        }
      } catch (error) {
        validUser = 1
        setSubmitMsg('something')
        setSuccessModalOpen(true);
        console.log(error.message);
      }
      return r
    };

    const  isFilterProvided = async (obj) => {
      for (let key in obj) {
          if (obj.hasOwnProperty(key) && obj[key] !== "") {
              return true; // Return true if any value is not empty
          }
      }
      return false; // Return false if all values are empty
  }


    React.useEffect(() => {
      const storedUrlParams = localStorage.getItem('dl-urlParams_');
      const parsedUrlParams = JSON.parse(storedUrlParams);
      const urlParams = new URLSearchParams(location.search);
      if (urlParams && urlParams.toString()) {
        urlParams.forEach((value, key) => {
          searchStrings[key] = value;
        });
      } else if (parsedUrlParams && typeof parsedUrlParams === 'object') {
        Object.entries(parsedUrlParams).forEach(([key, value]) => {
          urlParams.set(key, value);
          searchStrings[key] = value;
        });
        localStorage.setItem('dl-urlParams_', JSON.stringify(Object.fromEntries(urlParams.entries())));
      }
      history.replace({ search: urlParams.toString() });
    }, []);

    React.useEffect(() => {

      let active = true;

      const fn = async () => {
        setLoading(true);
        let localPage = paginationModel.page
        const areSearchStringsEqual = JSON.stringify(searchStrings) === JSON.stringify(previoussearchStrings);
        if (await isFilterProvided(searchStrings) && !areSearchStringsEqual) {
          //console.log('filter changed', areSearchStringsEqual, searchStrings, previoussearchStrings)
          localPage = 0
          setPreviousSearchStrings(searchStrings)
          setPaginationModel({...paginationModel, page:0})
        }
        //console.log('useEffect', page, timegap, pageSize, searchStrings, previoussearchStrings)

        const { docs: newRows, count: count1 } = await PostDeviceOperation(localPage);

        if (!active) {
          setLoading(false);
          return;
        }

        setRows(newRows);
        setLoading(false);
        setCount(count1);
      }
      // setUrlParams()
      fn()
      return () => {
        active = false;
      }
    }, [paginationModel, searchStrings, previoussearchStrings]);


      React.useEffect(() => {
        const runFn = async () => {
          const token = await getAccessTokenSilently();
          const isAllowed = await api.IsOperationAllowed(token);
          if (isAllowed === undefined) {
            validUser = 1
            setSubmitMsg('something')
            setSuccessModalOpen(true);
            return
          } else if (isAllowed.status === 'error') {
            if (isAllowed.errorCode === 1007) {
              validUser = 1
              setSubmitMsg('nouser')
              setSuccessModalOpen(true);
            } else if (isAllowed.errorCode === 1009) {
              validUser = 1
              setSubmitMsg('noauth')
              setSuccessModalOpen(true);
            } else {
              validUser = 1
              setSubmitMsg('fetchfailed')
              setSuccessModalOpen(true);
            }
            console.log("Operation Allowed", isAllowed);
            return
          } else {
            validUser = 0
            setRole(isAllowed.role);
            setSubmitMsg('ok')
            setSuccessModalOpen(true);
          }
          const deviceGroups = await api.GetAllDeviceGroups(token);
          if (deviceGroups === undefined) {
            setMinerGroups([]);
          } else {
            var minerGroupList = [];
            for (let i = 0; i < deviceGroups.length; i++) {
              minerGroupList.push(deviceGroups[i].DGName);
            }
            minerGroupList.sort();
            setMinerGroups(minerGroupList);
          }

          await api.GetOrg(token, (data) => {
            setMembers(data.member);
            setAlertMembers(data.alertmember);
            setNumberOfChipsOverThreshold(data.numberOfChipsOverThreshold);
            setDesiredAlertInterval(data.desiredAlertInterval);
          })
        };
        runFn();
      }, [getAccessTokenSilently]);

    const getHoverBackgroundColor = (color, mode) =>
      mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    const getBackgroundColor = (color, mode) =>
      mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const handleSearchInputChange = (field, value) => {
      setSearchStrings((prevSearchStrings) => ({
        ...prevSearchStrings,
        [field]: value || '',
      }));
    };


    const handleVersionChange = (event) => {
      const urlParams = new URLSearchParams(location.search);
      const inputName = event.target.value;
      if (/^[a-zA-Z0-9-.]*$/.test(inputName)) {
        handleSearchInputChange('version', inputName);
        urlParams.set('version', inputName);
      } else {
        urlParams.set('version', '');
      }
      history.replace({ search: urlParams.toString() });
      localStorage.setItem('dl-urlParams_', JSON.stringify(Object.fromEntries(urlParams.entries())));
    };

    const handleChassisChange = (event) => {
      const urlParams = new URLSearchParams(location.search);
      const inputName = event.target.value;
      if (/^[a-zA-Z0-9]*$/.test(inputName)) {
        handleSearchInputChange('chassis', inputName);
        urlParams.set('chassis', inputName);
      } else {
        urlParams.set('chassis', '');
      }
      history.replace({ search: urlParams.toString() });
      localStorage.setItem('dl-urlParams_', JSON.stringify(Object.fromEntries(urlParams.entries())));
    };

    const handleCBSNChange = (event) => {
      const urlParams = new URLSearchParams(location.search);
      const inputName = event.target.value;
      if (/^[a-zA-Z0-9]*$/.test(inputName)) {
        handleSearchInputChange('CBSN', inputName);
        urlParams.set('CBSN', inputName);
      } else {
        urlParams.set('CBSN', '');
      }
      history.replace({ search: urlParams.toString() });
      localStorage.setItem('dl-urlParams_', JSON.stringify(Object.fromEntries(urlParams.entries())));
    };

    const handleModelChange = (event) => {
      const urlParams = new URLSearchParams(location.search);
      const inputName = event.target.value;
      if (/^[a-zA-Z0-9-. ]*$/.test(inputName)) {
        handleSearchInputChange('model', inputName);
        urlParams.set('model', inputName);
      } else {
        urlParams.set('model', '');
      }
      history.replace({ search: urlParams.toString() });
      localStorage.setItem('dl-urlParams_', JSON.stringify(Object.fromEntries(urlParams.entries())));
    };

    const handleCustomNameFilterChange = (event) => {
      const urlParams = new URLSearchParams(location.search);
      const inputName = event.target.value;
      if (/^[a-zA-Z0-9-.]*$/.test(inputName)) {
        urlParams.set('name', inputName);
        handleSearchInputChange('name', inputName);
      } else {
        urlParams.set('name', '');
      }
      history.replace({ search: urlParams.toString() });
      localStorage.setItem('dl-urlParams_', JSON.stringify(Object.fromEntries(urlParams.entries())));
    };

    const onClear = () => {
      const params = {
          dgname: minerGroup,
          version:'',
          model:'',
          name: '',
          chassis: '',
          CBSN: '',
      }
      setSearchStrings(params)
      setPreviousSearchStrings(params)
      const urlParams = new URLSearchParams(location.search);
      handleSearchInputChange('version', '');
      if (urlParams.has('version')) {
        urlParams.delete('version');
      }
      handleSearchInputChange('model', '');
      if (urlParams.has('model')) {
        urlParams.delete('model');
      }
      handleSearchInputChange('name', '');
      if (urlParams.has('name')) {
        urlParams.delete('name');
      }
      handleSearchInputChange('chassis', '');
      if (urlParams.has('chassis')) {
        urlParams.delete('chassis');
      }
      handleSearchInputChange('CBSN', '');
      if (urlParams.has('CBSN')) {
        urlParams.delete('CBSN');
      }
      history.replace({ search: urlParams.toString() });
      localStorage.setItem('dl-urlParams_', JSON.stringify(Object.fromEntries(urlParams.entries())));
    }

    function handleMinerGroupChange(event) {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[a-zA-Z0-9 ]*$/.test(inputName)) {
      handleSearchInputChange('dgname', inputName);
      urlParams.set('dgname', inputName);
    } else {
      urlParams.set('dgname', '');
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem('dl-urlParams_', JSON.stringify(Object.fromEntries(urlParams.entries())));
    setIsMinerGroupSelected(inputName !== '')
    }

    //update current miner group and reset selected miners if no miner group is seelcted
    React.useEffect(() => {
        if (searchStrings.dgname !== ''){
        setMinerGroup(searchStrings.dgname)
        setIsMinerGroupSelected(true)
        } else{
            setSelectionModel([])
            setPaginationModel({...paginationModel, page:0})
        }
      }, [searchStrings.dgname]);

      //reset selected miners + page and pagesize upon change in miner group
    React.useEffect(() => {
        setSelectionModel([])
        setPaginationModel({ pageSize:100, page:0 })
    }, [minerGroup])

    React.useEffect(() => {
        if(selectionModel.length === 0){
            setOpenOperationsPopup(false)
        }
    }, [selectionModel])



    //Operation Dialog functions

    const fetchMsgForKeys = (data) => {
      const msgs = {};
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const msg = data[key]?.result?.msg;
          msgs[key] = msg || "No message found";
        }
      }
      return msgs;
    };


   const [value, setValue] = React.useState('')

  React.useEffect(()=>{
    if (role === ('pooladmin' || 'superadmin')){
      setValue('0')
    }else{
      setValue('1')
    }
  },[role])

  const handleValueChange = (event, newValue) => {
    setValue(newValue);
  };

  // Upgrade Miner Tab


  const [upgradeChecked, setUpgradeChecked] = React.useState(true)
  const [URLChecked, setURLChecked] = React.useState(false)
  const [URLInput, setURLInput] = React.useState('')
  const [openUpgradeConfirmation, setOpenUpgradeConfirmation] = React.useState(false)
  const [upgradeConfirmationMessage, setUpgradeConfirmationMessage] = React.useState('')
  const [statusUpgrade, setStatusUpgrade] = React.useState(false)
  const [upgradeStatusMessage, setUpgradeStatusMessage] = React.useState('')
  const [getFWfail, setGetFWfail] = React.useState(false)
  const [fwjobMessage, setFWJobMessage] = React.useState({})
  const [fwMultipleJobMessage, setFWMultipleJobMessage] = React.useState([])


  function handleUpgradeChecked(){
    setUpgradeChecked(true)
    setURLChecked(false)
  }

  function handleURLChecked(){
    setURLChecked(true)
    setUpgradeChecked(false)
  }

  function handleURLInput(event){
    setURLInput(event.target.value)
  }

  const isValidURL = (url) => {
    const urlRegex = /^(http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  function handleCloseUpgradeConfirmation() {
    setOpenUpgradeConfirmation(false)
  }

  const handleUpgradeUpgrade = async () => {
    // console.log('upgrading devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    if (minerGroup === 'Teraflux Group') {
      tmpMinerGroup = 'Default Group';
    }
    if (URLChecked && !isValidURL(URLInput)) {
      setUpgradeConfirmationMessage('Invalid URL: Please enter a valid URL')
      setOpenUpgradeConfirmation(true);
      return
    }
    let commandList = []
    if (upgradeChecked) {
      commandList = [{"command":"firmware-upgrade", "version": "latest"}]
    } else {
      commandList = [{"command":"firmware-upgrade", "url": URLInput}]
    }
    // console.log('commandList:', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.serials = selectionModel;
      dgdata.dgname = minerGroup;
      dgdata.commands = commandList;
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null ) {
        setUpgradeConfirmationMessage(`Error in upgrading miners for ${tmpMinerGroup}`);
        setOpenUpgradeConfirmation(true);
        console.log(`Failed to upgrade ${selectionModel} miners for ${tmpMinerGroup}`);
      } else if (resp.status === "ok") {
        console.log(`succeeded in upgrading ${selectionModel} miners for ${tmpMinerGroup} `,JSON.stringify(resp));
        let msg = `Successfully sent upgrade command to miners for ${tmpMinerGroup}. ` + resp.msg
        setUpgradeConfirmationMessage(msg);
        setOpenUpgradeConfirmation(true);
      } else if (resp.status === 'error') {
        console.log(`status: error in response for upgrading ${selectionModel} in ${tmpMinerGroup}`)
        let msg = `Failed to upgrade miners for ${tmpMinerGroup} ` + resp.msg
        setUpgradeConfirmationMessage(msg);
        setOpenUpgradeConfirmation(true);
      } else {
        let msg = ''
        if (resp.msg) {
          msg = `Failed to upgrade miners for ${tmpMinerGroup} ` + resp.msg
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg = `Failed to upgrade miners for ${tmpMinerGroup} ` + JSON.stringify(msgs);
        }
        setUpgradeConfirmationMessage(msg);
        setOpenUpgradeConfirmation(true);
        console.log(`failed to upgrade ${selectionModel} miners for ${tmpMinerGroup}`);
      }
    }

  };

  function handleCloseStatusUpgrade() {
    setStatusUpgrade(false)
  }

  const handleStatusUpgrade = async () => {
    // console.log('upgrade status for devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    if (minerGroup === 'Teraflux Group') {
      tmpMinerGroup = 'Default Group';
    }
    if (minerGroup !== "") {
    const token = await getAccessTokenSilently();
    let dgdata = {};
    dgdata.serials = selectionModel;
    dgdata.dgname = minerGroup;
    dgdata.command = 'firmware-upgrade';
    // console.log('handleStatusUpgrade dgdata:', dgdata)
    const resp = await api.GetCommandStatus(token, dgdata);
    //console.log('handleStatusUpgrade resp:', JSON.stringify(resp))
    if (resp === undefined || resp === null ) {
      setUpgradeStatusMessage(`Error in getting firmware-upgrade status for ${tmpMinerGroup}.`);
      console.log(`Error in getting firmware-upgrade status for ${minerGroup}`)
      setStatusUpgrade(true);
      setGetFWfail(true);
    } else if (resp.status === "ok") {
      console.log(`Succeeded in getting firmware-upgrade status for ${minerGroup}`)
      if (resp.msg.length === 0){
        let msg = `No firmware-upgrade status found for selected ${selectionModel.length > 1 ? 'miners' : 'miner'}.`;
        setUpgradeStatusMessage(msg);
        setGetFWfail(true);
      } else if (resp.msg.length === 1){
        let msg_one = resp.msg[0]
        setFWJobMessage(msg_one);
        setGetFWfail(false);
      } else{
        let msg_mult = resp.msg
        setFWMultipleJobMessage(msg_mult)
        setGetFWfail(false)
      }
      setStatusUpgrade(true);
    } else {
      let msg = 'Failed to get firmware-upgrade status for ' + minerGroup + '. ' + resp.msg;
      setUpgradeStatusMessage(msg);
      setGetFWfail(true);
      console.log(`Failed to get firmware-upgrade status for ${minerGroup} `, resp.msg);
      setStatusUpgrade(true);
    }
    }
  };

  const handleRestartMinerUpgrade = async () => {
    // console.log('upgrade restart for devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    if (minerGroup === 'Teraflux Group') {
      tmpMinerGroup = 'Default Group';
    }
    let commandList = [{"command":"restart"}]
    // console.log('commandList', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.serials = selectionModel;
      dgdata.dgname = minerGroup;
      dgdata.commands = commandList;
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null ) {
        setOpenUpgradeConfirmation(true);
        setUpgradeConfirmationMessage(`Error in restart miners for ${tmpMinerGroup}`);
        console.log(`failed to restart ${selectionModel} miners for ${tmpMinerGroup}`);
      } else if (resp.status === "ok") {
        console.log(`succeeded in sending restart command ${selectionModel} miners for ${tmpMinerGroup} `,JSON.stringify(resp));
        let msg = `Successfully sent restart command to miners for ${tmpMinerGroup}. ` + resp.msg
        setUpgradeConfirmationMessage(msg);
        setOpenUpgradeConfirmation(true);
      } else {
        let msg = `Failed to restart miners for ${tmpMinerGroup}` + resp.msg
        setUpgradeConfirmationMessage(msg);
        console.log(`failed to restart ${selectionModel} miners for ${tmpMinerGroup}`);
        setOpenUpgradeConfirmation(true);
      }
    }
  };

  function handleClearUpgrade(){
    setUpgradeChecked(true)
    setURLChecked(false)
    setURLInput('')
  };

  // Tuning Config Tab

  const [tuningChecked, setTuningChecked] = React.useState(true);
  const [statusTuning, setStatusTuning] = React.useState(false)
  const [openTuningConfigConfirmation, setOpenTuningConfigConfirmation] = React.useState(false)
  const [tuningConfigConfirmationMessage, setTuningConfigConfirmationMessage] = React.useState('')
  const [tuningConfigStatusMessage, setTuningConfigStatusMessage] = React.useState('')
  const [getTunefail, setGetTunefail] = React.useState(false)
  const [tuneJobMessage, setTuneJobMessage] = React.useState({})
  const [tuneMultipleJobMessage, setTuneMultipleJobMessage] = React.useState([])

  const handleTuningChecked = (event) => {
    setTuningChecked(event.target.checked);
  };

  function handleCloseTuningConfigConfirmation() {
    setOpenTuningConfigConfirmation(false)
  }

  const handleSendTuningConfig = async () => {
    // console.log('tuning devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    if (minerGroup === 'Teraflux Group') {
      tmpMinerGroup = 'Default Group';
    }
    let commandList = []
    if (tuningChecked) {
      commandList = [{"command":"accept-remote-tuning", "parameter": "on"}]
    } else {
      commandList = [{"command":"accept-remote-tuning", "parameter": "off"}]
    }
    // console.log('commandList', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.serials = selectionModel;
      dgdata.dgname = minerGroup;
      dgdata.commands = commandList;
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null ) {
        setTuningConfigConfirmationMessage(`Error in sending the command to ${tmpMinerGroup} miners`);
        console.log(`failed to send the command to ${selectionModel} miners for ${tmpMinerGroup}`);
        setOpenTuningConfigConfirmation(true);
      } else if (resp.status === "ok") {
        console.log(`succeeded in sending the command to ${selectionModel} miners for ${tmpMinerGroup} `,JSON.stringify(resp));
        let msg = `Successfully sent the command to miners for ${tmpMinerGroup}. ` + resp.msg
        setTuningConfigConfirmationMessage(msg);
        setOpenTuningConfigConfirmation(true);
      } else if (resp.status === "error") {
        console.log(`status: error in response for tuning ${selectionModel} in ${tmpMinerGroup}`)
        let msg = `Failed to send the command to miners for ${tmpMinerGroup} ` + resp.msg
        setTuningConfigConfirmationMessage(msg);
        setOpenTuningConfigConfirmation(true);
      } else {
        setOpenTuningConfigConfirmation(true);
        let msg = ''
        if (resp.msg) {
          msg = `Failed to send the command to miners for ${tmpMinerGroup} ` + resp.msg
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg = `Failed to send the command to miners for ${tmpMinerGroup} ` + JSON.stringify(msgs)
        }
        setTuningConfigConfirmationMessage(msg);
        console.log(`failed to send the command to ${selectionModel} miners for ${tmpMinerGroup} ${msg}`);
      }
    }

  };

  function handleCloseStatusTuning(){
    setStatusTuning(false)
  };

  const handleStatusTuningConfig = async () => {
    // console.log('status for tuning devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    if (minerGroup === 'Teraflux Group') {
      tmpMinerGroup = 'Default Group';
    }
    if (minerGroup !== "") {
    const token = await getAccessTokenSilently();
    let dgdata = {};
    dgdata.serials = selectionModel;
    dgdata.dgname = minerGroup;
    dgdata.command = 'accept-remote-tuning';
    // console.log('handleStatusTuningConfig dgdata:', dgdata)
    const resp = await api.GetCommandStatus(token, dgdata);
    // console.log('handleStatusTuningConfig resp:', resp)
    if (resp === undefined || resp === null ) {
      console.log(`Error in getting ${dgdata.command} status for ${tmpMinerGroup}`)
      setTuningConfigStatusMessage(`Error in getting the ${dgdata.command} status for ${tmpMinerGroup}`);
      setGetTunefail(true);
      setStatusTuning(true);
    } else if (resp.status === "ok") {
      console.log(`Succeeded in getting ${dgdata.command} status for ${tmpMinerGroup}`)
      if (resp.msg.length === 0) {
        let msg = `No ${dgdata.command} status found for selected miners`;
        setTuningConfigStatusMessage(msg);
        setGetTunefail(true);
      } else if(resp.msg.length === 1){
        let msg_one = resp.msg[0]
        setTuneJobMessage(msg_one);
        setGetTunefail(false);
      } else{
        let msg_mult = resp.msg
        setTuneMultipleJobMessage(msg_mult)
        setGetTunefail(false)
      }
      setStatusTuning(true);
    } else {
      let msg = `Failed to get ${dgdata.command} for ` + tmpMinerGroup + '. ' + resp.msg;
      setTuningConfigStatusMessage(msg);
      setGetTunefail(true);
      console.log(`Failed to get ${dgdata.command} status for ${tmpMinerGroup} `, resp.msg);
      setStatusTuning(true);
    }
}
  };

  function handleClearTuningConfig(){
    setTuningChecked(true)
  }

  // Standby Mode Tab

  const [standbyChecked, setStandbyChecked] = React.useState(true);
  const [statusStandby, setStatusStandby] = React.useState(false)
  const [openStandbyConfirmation, setOpenStandbyConfirmation] = React.useState(false)
  const [standbyConfirmationMessage, setStandbyConfirmationMessage] = React.useState('')
  const [standbyStatusMessage, setStandbyStatusMessage] = React.useState('')
  const [getSleepfail, setGetSleepfail] = React.useState(false)
  const [standbyJobMessage, setStandbyJobMessage] = React.useState({})
  const [standbyMultipleJobMessage, setStandbyMultipleJobMessage] = React.useState([])

  const handleStandbyModeChecked = (event) => {
    setStandbyChecked(event.target.checked);
  };

  function handleCloseStandbyConfirmation() {
    setOpenStandbyConfirmation(false)
  }

  const handleSendStandbyMode = async () => {
    // console.log('standby mode for devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    if (minerGroup === 'Teraflux Group') {
      tmpMinerGroup = 'Default Group';
    }
    let commandList = []
    if (standbyChecked) {
      commandList = [{"command":"fvmode", "sleep": "on"}]
    } else {
      commandList = [{"command":"fvmode", "sleep": "off"}]
    }
    // console.log('commandList', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.serials = selectionModel;
      dgdata.dgname = minerGroup;
      dgdata.commands = commandList;
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null ) {
        setStandbyConfirmationMessage(`Error in sending the command to ${tmpMinerGroup} miners`);
        console.log(`Failed to send the command to ${selectionModel} miners for ${tmpMinerGroup}`);
        setOpenStandbyConfirmation(true);
      } else if (resp.status === "ok") {
        console.log(`succeeded in sending the command to ${selectionModel} miners for ${tmpMinerGroup} `,JSON.stringify(resp));
        let msg = `Successfully sent the command to miners for ${tmpMinerGroup}. ` + resp.msg
        setStandbyConfirmationMessage(msg);
        setOpenStandbyConfirmation(true);
      } else if (resp.status === "error") {
        console.log(`status: error in response for setting standby mode for ${selectionModel} in ${tmpMinerGroup}`)
        let msg = `Failed to send the command to miners for ${tmpMinerGroup} ` + resp.msg
        setStandbyConfirmationMessage(msg);
        setOpenStandbyConfirmation(true);
      } else {
        setOpenStandbyConfirmation(true);
        let msg = ''
        if (resp.msg) {
          msg = `Failed to send the command to miners for ${tmpMinerGroup} ` + resp.msg
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg = `Failed to send the command to miners for ${tmpMinerGroup} ` + JSON.stringify(msgs)
        }
        setOpenStandbyConfirmation(msg);
        console.log(`failed to send the command to ${selectionModel} miners for ${tmpMinerGroup} ${msg}`);
      }
    }
  };


  const handleStatusStandbyMode = async () => {
    // console.log('status for standby mode for devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    if (minerGroup === 'Teraflux Group') {
      tmpMinerGroup = 'Default Group';
    }
    if (minerGroup !== "" ) {
    const token = await getAccessTokenSilently();
    let dgdata = {};
    dgdata.serials = selectionModel;
    dgdata.dgname = minerGroup;
    dgdata.command = 'mode';
    const resp = await api.GetCommandStatus(token, dgdata);
    if (resp === undefined || resp === null ) {
      setStandbyStatusMessage(`Error in getting the ${dgdata.command} status for ${tmpMinerGroup}.`);
      setGetSleepfail(true);
      setStatusStandby(true)
    } else if (resp.status === "ok" ) {
        if (resp.msg.length === 0) {
            let msg = `No ${dgdata.command} status found for selected miners.`;
            setStandbyStatusMessage(msg);
            setGetSleepfail(true);
        }else if (resp.msg.length === 1){
            let msg = resp.msg[0];
            setStandbyJobMessage(msg);
            setGetSleepfail(false);
       }else {
        let msg = resp.msg
        setStandbyMultipleJobMessage(msg);
        setGetSleepfail(false);
      }
      setStatusStandby(true);
    } else {
      let msg = `Failed to get ${dgdata.command} for ` + tmpMinerGroup + '. ' + resp.msg;
      setStandbyStatusMessage(msg);
      setGetSleepfail(true);
      setStatusStandby(true);
      console.log(`Failed to get ${dgdata.command} status for ${tmpMinerGroup}. `, resp.msg);
    }
  }
  };

  function handleCloseStatusStandby(){
    setStatusStandby(false)
  };

  function handleClearStandbyMode(){
    setStandbyChecked(true)
  }

  // Alert Notification Tab

  const [hashboardMalfChecked, setHashboardMalfChecked] = React.useState(true)
  const [fanMalfChecked, setFanMalfChecked] = React.useState(true)
  const [PSUMalfChecked, setPSUMalfChecked] = React.useState(true)
  const [chipTempChecked, setChipTempChecked] = React.useState(true)
  const [desiredAlertInterval, setDesiredAlertInterval] = React.useState('')
  const [thresholdValue, setThresholdValue] = React.useState('')
  const [openAlertConfirmation, setOpenAlertConfirmation] = React.useState(false)
  const [alertConfirmationMessage, setAlertConfirmationMessage] = React.useState('')

  function handleDesiredAlertInterval(event){
    const value = event.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(value)) {
      setDesiredAlertInterval(value);
    }
  }

  function handleUpdateAlertMembers(value){
    setAlertMembers(value)
    setSelectAllAlertMembers(value.length === members.length);
  }

  function handleHashboardMalfChecked(event){
    setHashboardMalfChecked(event.target.checked)
  }

  function handleFanMalfChecked(event){
    setFanMalfChecked(event.target.checked)
  }

  function handlePSUMalfChecked(event){
    setPSUMalfChecked(event.target.checked)
  }

  function handleChipTempChecked(event){
    setChipTempChecked(event.target.checked)
  }

  function handleThresholdValueInput(event){
    const value = event.target.value;
    const regex = /^[0-9.]*$/;
    if (regex.test(value)) {
      setThresholdValue(value);
    }
  }

  function handleNumberOfChipsOverThresholdInput(event){
    const value = event.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(value)) {
      setNumberOfChipsOverThreshold(value);
    }
  }

  const handleSelectAllAlertMembers = (event) => {
    const allOptions = members;
    const selectedOptions = event.target.checked ? allOptions : [];
    setAlertMembers(selectedOptions);
    setSelectAllAlertMembers(event.target.checked);
  };

  function handleClearSelections() {
    setHashboardMalfChecked(false)
    setFanMalfChecked(false)
    setPSUMalfChecked(false)
    setChipTempChecked(false)
  }

  function handleCloseAlertConfirmation() {
    setOpenAlertConfirmation(false)
  }

  const handleSaveAlertConfig = async () => {
    // console.log('alert notifs for devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    if (minerGroup === 'Teraflux Group') {
      tmpMinerGroup = 'Default Group';
    }
    let alertNotificationConfig = {
      "fanMalfunction": hashboardMalfChecked,
      "hashboardMalfunction": fanMalfChecked,
      "chipTempMalfunction": PSUMalfChecked,
      "psuMalfunction": chipTempChecked,
      "chipThreshold": parseFloat(thresholdValue)
    }
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.serials = selectionModel;
      dgdata.dgname = minerGroup;
      dgdata.alertNotificationConfig = alertNotificationConfig;
      dgdata.alertmember = alertMembers;
      dgdata.numberOfChipsOverThreshold = parseInt(numberOfChipsOverThreshold);
      if (isNaN(dgdata.numberOfChipsOverThreshold) || dgdata.numberOfChipsOverThreshold <= 0) {
        dgdata.numberOfChipsOverThreshold = 0
      }
      dgdata.desiredAlertInterval = parseInt(desiredAlertInterval);
      if (isNaN(dgdata.desiredAlertInterval)) {
        setAlertConfirmationMessage(`Please enter a valid desired alert interval`);
        setOpenAlertConfirmation(true);
        return
      }
      const resp = await api.SendAlertNotificationConfig(token, dgdata);
      if (resp === undefined || resp === null ) {
        console.log(`Failed to send the alert notifs request to ${selectionModel} miners for ${tmpMinerGroup}`);
        setAlertConfirmationMessage(`Error in saving the alert notification config to the selected miners in ${tmpMinerGroup}`);
        setOpenAlertConfirmation(true);
      } else if (resp.status === "ok") {
        console.log(`succeeded in sending the alert notifs request to ${selectionModel} miners for ${tmpMinerGroup} `,JSON.stringify(resp));
        let msg = `Successfully saved the alert notification config for the selected miners in ${tmpMinerGroup} `
        setAlertConfirmationMessage(msg);
        setOpenAlertConfirmation(true);
      } else {
        let msg = `Failed to save the alert notification config for the selected miners in ${tmpMinerGroup} ` + resp.msg
        setAlertConfirmationMessage(msg);
        setOpenAlertConfirmation(true);
      }
    }
  };

  function handleClearAlertConfig(){
    setDesiredAlertInterval('')
    setHashboardMalfChecked(false)
    setFanMalfChecked(false)
    setPSUMalfChecked(false)
    setChipTempChecked(false)
    setAlertMembers([])
  }

  // Migrate Miners Tab

  const [destinationGroup, setDestinationGroup] = React.useState(true)
  const [openMigrateMinersConfirmation, setOpenMigrateMinersConfirmation] = React.useState(false)
  const [migrateMinersConfirmationMessage, setMinersMigrateConfirmationMessage] = React.useState('')

  function handleSelectDestinationGroup(event) {
    setDestinationGroup(event.target.value)
  }

  function handleClearMigrateMiners() {
    setDestinationGroup('')
  }

  const handleMigrateMigrateMiners = async () => {
    // console.log('SOURCE MINER GROUP', minerGroup);
    // console.log('DESTINATION MINER GROUP', destinationGroup);
    // console.log('MOVING DEVICES:', selectionModel)
    let tmpMinerGroup = minerGroup;
    let tmpTargetGroup = destinationGroup;
    if (minerGroup === 'Teraflux Group') {
      tmpMinerGroup = 'Default Group';
    }
    if (destinationGroup === 'Teraflux Group') {
      tmpTargetGroup = 'Default Group';
    }
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.sourcegroup = minerGroup;
      dgdata.destinationgroup = destinationGroup;
      dgdata.devices = selectionModel;
      const resp = await api.MoveDGMember(token, dgdata);
      if (resp === undefined || resp === null ) {
        setMinersMigrateConfirmationMessage(`Error in migrating miners from ${tmpMinerGroup} to ${tmpTargetGroup}`);
        console.log(
          `failed to move ${selectionModel} miners from ${tmpMinerGroup} to ${tmpTargetGroup}`
        );
        setOpenMigrateMinersConfirmation(true);
      } else if (resp.status === "ok") {
        console.log(
          `succeeded in moving ${selectionModel} miners from ${tmpMinerGroup} to ${tmpTargetGroup}`,
          JSON.stringify(resp)
        );
        setMinersMigrateConfirmationMessage(`Successfully migrated miners from ${tmpMinerGroup} to ${tmpTargetGroup}`);
        setOpenMigrateMinersConfirmation(true);
      } else {
        setMinersMigrateConfirmationMessage(`Failed to migrate miners from ${tmpMinerGroup} to ${tmpTargetGroup}`);
        console.log(
          `failed to move ${selectionModel} miners from ${tmpMinerGroup} to ${tmpTargetGroup}`
        );
        setOpenMigrateMinersConfirmation(true);
      }
    }
    const { docs: newRows, count: count1 } = await PostDeviceOperation(paginationModel.page);
    setRows(newRows)
    setCount(count1)
  };

  function handleCloseMigrateMinersConfirmation() {
    setOpenMigrateMinersConfirmation(false)
  }

  //general actions handle functions

  function handleClickOpen(){
    setOpenOperationsPopup(true);
   };

  function handleClose(){
    setOpenOperationsPopup(false);
    if (role === ('pooladmin' || 'superadmin')){
        setValue('0')
    }else{
    setValue('1')
    }
    setDesiredAlertInterval('')
    setURLInput('')
    handleClearSelections()
    setSelectAllAlertMembers(false)
    setUpgradeChecked(true)
    setURLChecked(false)
    setTuningChecked(true)
    setStandbyChecked(true)
    setAlertMembers([])
    setFWJobMessage('')
    setTuneJobMessage('')
    setStandbyJobMessage('')
    setStandbyMultipleJobMessage('')
    setTuneMultipleJobMessage('')
    setUpgradeConfirmationMessage('')
    setTuningConfigStatusMessage('')
    setStandbyConfirmationMessage('')
    setGetFWfail(false)
    setGetTunefail(false)
    setGetSleepfail(false)
    setDesiredAlertInterval('')
    setNumberOfChipsOverThreshold('')
    setThresholdValue('')
    setDestinationGroup('')
  };

  React.useEffect(() => {
    setDesiredAlertInterval('')
    setURLInput('')
    handleClearSelections()
    setTuningChecked(true)
    setStandbyChecked(true)
    setFWJobMessage('')
    setTuneJobMessage('')
    setStandbyJobMessage('')
    setTuneMultipleJobMessage('')
    setUpgradeConfirmationMessage('')
    setTuningConfigStatusMessage('')
    setStandbyConfirmationMessage('')
    setStandbyMultipleJobMessage('')
    setGetFWfail(false)
    setGetTunefail(false)
    setGetSleepfail(false)
    setDesiredAlertInterval('')
    setNumberOfChipsOverThreshold('')
    setThresholdValue('')
    setDestinationGroup('')
  }, [value])

    if (validUser === 0) {
      return (
        <Box sx={{
          '& .rowGreen': {
            bgcolor: (theme) =>
              getBackgroundColor('#FFA500', theme.palette.mode),
          },
        }}>
          <Typography
              variant="h4"
              component="div"
              sx={{ flexGrow: 1, textAlign: "center", color: "#007bff" }}
              >
              Miner Group Operations
          </Typography>
          <br/><br/>
          <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 2, ml: 6  }}>
          <Typography sx={{ mr: 4 }}>Miner Group:</Typography>
          <TextField
          label="Select Miner Group (required)"
          value={searchStrings.dgname}
          onChange={handleMinerGroupChange}
          sx={{ ml: 1, minWidth: '400px', '& .MuiInputLabel-root': { fontSize: '12px' } }}
          select
          >
            <MenuItem value="">
                Select
            </MenuItem>
          {minerGroups.map((group, index) => (
        <MenuItem key={index} value={group === 'Teraflux Group' ? 'Default Group' : group}>
          {group === 'Teraflux Group' ? 'Default Group' : group}
        </MenuItem>
            ))}
          </TextField>
          {isminerGroupSelected &&
            <Box >
            <Button variant="contained" disabled={selectionModel.length === 0} onClick={handleClickOpen} sx={{ mt:0.7, ml:8, fontSize: '11px' ,lineHeight: '1.4' }}>Actions</Button>
            </Box>}
            </Stack>
        {isminerGroupSelected &&
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 2,  ml: 3  }}>
            <Stack direction="row"  alignItems="center" sx={{ mt: 2, ml: 3  }}>
            <Typography sx={{ mr: 3}}>Filters:</Typography>
            <TextField
            label="Tag"
            value={searchStrings.name}
            onChange={handleCustomNameFilterChange}
            sx={{ ml: 1, minWidth: '100px', '& .MuiInputLabel-root': { fontSize: '12px' } }}
            />
            <TextField
            label="CB Serial"
            value={searchStrings.CBSN}
            onChange={handleCBSNChange}
            sx={{ ml: 1, minWidth: '100px', '& .MuiInputLabel-root': { fontSize: '12px' } }}
            />
            <TextField
            label="Chassis"
            value={searchStrings.chassis}
            onChange={handleChassisChange}
            sx={{ ml: 1, minWidth: '100px', '& .MuiInputLabel-root': { fontSize: '12px' } }}
            />
            <TextField
            label="Model"
            value={searchStrings.model}
            onChange={handleModelChange}
            sx={{ ml: 1, minWidth: '100px', '& .MuiInputLabel-root': { fontSize: '12px' } }}
            />
            <TextField
            label="Version"
            value={searchStrings.version}
            onChange={handleVersionChange}
            sx={{ ml: 1, minWidth: '100px', '& .MuiInputLabel-root': { fontSize: '12px' } }}
            />
          <IconButton onClick={onClear} color="primary">
            <ClearIcon />
          </IconButton>
          <Typography component="div" sx={{ ml: 1, flexGrow: 1 }} />
          </Stack>
          </Stack>}
          {isminerGroupSelected && (
            <>
            <Dialog
            open={openOperationsPopup}
            maxWidth={400}
          >
            <DialogTitle >
                <Typography variant="h5" sx={{ mt:3 }}>Miner Operations</Typography>
            </DialogTitle>
            <DialogContent>
            <TabContext value={value} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    {(role === ('pooladmin' || 'superadmin')) ?
                    <TabList onChange={handleValueChange} centered >
                        <Tab label="Upgrade Miners" value="0" style={{ fontSize:'13px' }}/>
                        <Tab label="Tuning Config" value="1" style={{ fontSize:'13px' }}/>
                        <Tab label="Standby Mode" value="2" style={{ fontSize:'13px' }}/>
                        <Tab label="Alert Notifications" value="3" style={{ fontSize:'13px' }}/>
                        <Tab label="Migrate Miners" value="4" style={{ fontSize:'13px' }}/>
                    </TabList> :
                     <TabList onChange={handleValueChange} centered >
                     <Tab label="Tuning Config" value="1" style={{ fontSize:'13px' }}/>
                     <Tab label="Standby Mode" value="2" style={{ fontSize:'13px' }}/>
                     <Tab label="Alert Notifications" value="3" style={{ fontSize:'13px' }}/>
                     <Tab label="Migrate Miners" value="4" style={{ fontSize:'13px' }}/>
                 </TabList>
    }
                </Box>
                <TabPanel value="0">

                            <Stack direction="row" alignItems='center' sx={{mt: 2}}>
                            <Typography sx={{mr: 6}}>Firmware Upgrade Option:</Typography>
                            <Typography variant='body2'>Latest</Typography>
                                <Checkbox
                                    checked={upgradeChecked}
                                    onChange={handleUpgradeChecked}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    sx={{mr:4}}
                                />
                            <Typography variant='body2'>URL</Typography>
                                <Checkbox
                                    checked={URLChecked}
                                    onChange={handleURLChecked}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Stack>
                            {URLChecked &&
                            <Stack direction="row" alignItems='center' sx={{ mt: 3}}>
                                <Typography sx={{mr: 6}}>Firmware URL:</Typography>
                                <TextField variant="standard" placeholder='Enter valid URL' inputProps={{
                                    sx: {
                                        width:'350px',
                                        fontSize: '13.5px',
                                        '&::placeholder': {
                                        color: 'gray',
                                        opacity: 0.75,
                                        fontStyle: 'italic',
                                        fontSize: '12px'
                                        }
                                    },
                                }}
                                value={URLInput}
                                onChange={handleURLInput}
                                />
                            </Stack>
                            }
                            <Stack direction="column" alignItems='left' >
                            <Typography variant="caption" sx={{mt: 6, fontStyle: 'italic'}}><strong>NOTE: </strong>Before upgrading all miners, please ensure to upgrade one miner initially and check its status using the 'UPGRADE STATUS' button.</Typography>
                            <Typography variant="caption">On success, restart miner and verify its status in the jobs tab.</Typography>
                            </Stack>
                </TabPanel>
                <TabPanel value="1">
                    <Stack direction="row" alignItems='center' sx={{mt: 2}}>

                            <Typography>Use FluxVision Tuning Config</Typography>
                            <FormControlLabel control={<Switch checked={tuningChecked} onChange={handleTuningChecked} inputProps={{ 'aria-label': 'controlled' }} sx={{ml: 5}}/>}
                            label={
                                <Typography variant="body2" sx={{ fontSize: 'smaller' }}>
                                {tuningChecked ? 'On' : 'Off'}
                                </Typography>} />

                   </Stack>
                   <br></br>
                   <Typography variant="caption" style={{ fontStyle: 'italic'}}><strong>NOTE: </strong>When 'ON' is selected, the tune settings of selected miner(s) will be overwritten with FluxVision tune settings</Typography>
                </TabPanel>
                <TabPanel value="2">
                   <Stack direction="row" alignItems='center' sx={{mt: 2}}>

                            <Typography>Put {selectionModel.length === 1 ? 'miner' : 'miners'} in Standby Mode</Typography>
                            <FormControlLabel control={<Switch checked={standbyChecked} onChange={handleStandbyModeChecked} inputProps={{ 'aria-label': 'controlled' }} sx={{ml: 5}}/>}
                            label={
                                <Typography variant="body2" sx={{ fontSize: 'smaller' }}>
                                {standbyChecked ? 'On' : 'Off'}
                                </Typography>} />

                   </Stack>
                </TabPanel>
                <TabPanel value="3">
                            <Stack direction="row" alignItems='center' sx={{mt: 2, mb: 8}}>
                                <Typography sx={{ mr: 6 }}>Desired interval between alert notifications:</Typography>
                                <TextField variant="standard" placeholder='Set to 0 min. to turn off alerts' inputProps={{
                                    sx: {
                                        width:'300px',
                                        fontSize: '13.5px',
                                        '&::placeholder': {
                                        color: 'gray',
                                        opacity: 0.75,
                                        fontStyle: 'italic',
                                        fontSize: '12px'
                                        }
                                    },
                                }}
                                value={desiredAlertInterval}
                                onChange={handleDesiredAlertInterval}
                                />
                            </Stack>
                            <Stack direction="row" alignItems='top' sx={{mt: 1, mb: 4}}>
                            <Typography sx={{ mt: 9, mb: 1, mr: 6}}>Select registered email(s) to recieve alerts:</Typography>
                            <Stack direction="column" alignItems='left' >
                                <Stack direction="row" alignItems='center' sx={{m:-3.2}}>
                                                <Checkbox
                                                    checked={selectAllAlertMembers}
                                                    onChange={(event) => handleSelectAllAlertMembers(event)}
                                                    style={{
                                                        transform: "scale(0.8)"
                                                    }}
                                                />
                                                <Typography variant='caption'>Select All</Typography>
                                </Stack>
                                <Autocomplete
                                        sx={{ width: 500, mb: 2, mt:1 }}
                                        multiple
                                        value={alertMembers || []}
                                        onChange={(event, value) => handleUpdateAlertMembers(value)}
                                        disableCloseOnSelect
                                        id="combo-box-demo3"
                                        options={members}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}
                                            style={{
                                                whiteSpace: 'normal', fontSize:'14px'
                                            }}>
                                            <Checkbox
                                                style={{ marginRight: 8,  transform: 'scale(0.8)'}}
                                                checked={selected}
                                            />
                                            {option}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            size="small"
                                            aria-label="Select mode"
                                            inputProps={{
                                                ...params.inputProps,
                                                style: {
                                                fontSize: "11px",
                                                },
                                            }}
                                            placeholder='Select email(s) to get alerts'
                                            />
                                        )}
                                        defaultValue={[]}
                                        />
                      </Stack>
                            </Stack>
                            <Typography variant="caption" sx={{mb:6, fontStyle: 'italic'}}><strong>NOTE:</strong> Interval and Alert notification email list is common across all the groups</Typography>
                            <Typography sx={{mt: 7}}><strong>Select the options below to recieve alerts:</strong></Typography>
                            <Stack direction="row" alignItems='center' sx={{mt: 2}}>
                                <Checkbox
                                    checked={hashboardMalfChecked}
                                    onChange={handleHashboardMalfChecked}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <Typography variant='body2'>Hash Board Malfunction</Typography>
                            </Stack>
                            <Stack direction="row" alignItems='center' >
                                <Checkbox
                                    checked={fanMalfChecked}
                                    onChange={handleFanMalfChecked}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <Typography variant='body2'>Fan Malfunction</Typography>
                            </Stack>
                            <Stack direction="row" alignItems='center' >
                                <Checkbox
                                    checked={PSUMalfChecked}
                                    onChange={handlePSUMalfChecked}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <Typography variant='body2'>PSU Malfunction</Typography>
                            </Stack>
                            <Stack direction="row" alignItems='center' >
                                <Checkbox
                                    checked={chipTempChecked}
                                    onChange={handleChipTempChecked}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <Typography variant='body2'>Chip Temperature</Typography>
                            </Stack>
                            <Box sx={{ display: 'flex', mt: 2, ml:3 }}>
                                <Typography variant="body" component="div" sx={{ whiteSpace: 'nowrap', textDecoration: 'underline', cursor: 'pointer', fontSize: '12px', fontStyle: 'italic', color: '#1976D2' }} onClick={handleClearSelections}>Clear Selections</Typography>
                            </Box>
                            {chipTempChecked &&
                            <div >
                                <br />
                                <Stack direction="row" alignItems='center' sx={{ mt: 2}}>
                                    <Typography sx={{mr: 6}}>Chip Temperature Threshold:</Typography>
                                    <TextField variant="standard" placeholder='Chip °C to get alerts' inputProps={{
                                        sx: {
                                            width:'350px',
                                            fontSize: '13.5px',
                                            '&::placeholder': {
                                            color: 'gray',
                                            opacity: 0.75,
                                            fontStyle: 'italic',
                                            fontSize: '12px'
                                            }
                                        },
                                    }}
                                    value={thresholdValue}
                                    onChange={handleThresholdValueInput}
                                    />
                                </Stack>
                                <Stack direction="row" alignItems='center' sx={{ mt: 2}}>
                                    <Typography sx={{mr: 6}}>Number Of Chips °C Over Threshold:</Typography>
                                    <TextField variant="standard" placeholder='Enter number' inputProps={{
                                        sx: {
                                            width:'350px',
                                            fontSize: '13.5px',
                                            '&::placeholder': {
                                            color: 'gray',
                                            opacity: 0.75,
                                            fontStyle: 'italic',
                                            fontSize: '12px'
                                            }
                                        },
                                    }}
                                    value={numberOfChipsOverThreshold}
                                    onChange={handleNumberOfChipsOverThresholdInput}
                                    />
                                </Stack>
                                <Stack direction="column" alignItems='left' sx={{ mt: 5}}>
                                <Typography variant="caption"  style={{ fontStyle: 'italic'}} ><strong>NOTE: </strong>The number of chips °C over threshold value is common across all the groups. It is checked against number of chips from all the hash </Typography>
                                 <Typography variant="caption"  style={{ fontStyle: 'italic'}} >boards for each selected miner.</Typography>
                                </Stack>
                            </div>
                            }

                </TabPanel>
                <TabPanel value="4">
                    <Stack direction="row" alignItems="center" sx={{ mt: 2  }}>
                        <Typography variant='body1' sx={{ mr: 4 }}>Destination Miner Group:</Typography>
                        <TextField
                        label="Select Destination Miner Group"
                        value={destinationGroup}
                        onChange={handleSelectDestinationGroup}
                        sx={{ ml: 1, minWidth: '300px', '& .MuiInputLabel-root': { fontSize: '12px' } }}
                        select
                        >
                            <MenuItem value="">
                                Select
                            </MenuItem>
                        {minerGroups.map((group, index) => (
                        <MenuItem key={index} value={group === 'Teraflux Group' ? 'Default Group' : group}>
                        {group === 'Teraflux Group' ? 'Default Group' : group}
                        </MenuItem>
                            ))}
                            </TextField>
                    </Stack>
                </TabPanel>
            </TabContext>
              </DialogContent>
              <DialogActions style={{ justifyContent: "right" }}>
              {value === '0' &&
                <>
                <Button onClick={handleClearUpgrade} sx={{m:2}}>Clear</Button>
              <Button onClick={handleUpgradeUpgrade} sx={{m:2}}>Upgrade</Button>
              <Button onClick={handleStatusUpgrade} sx={{m:2}}>Status</Button>
              <Button onClick={handleRestartMinerUpgrade} sx={{m:2}}>Restart Miner</Button>
              </>
                }
              {value === '1' &&
                <>
              <Button onClick={handleClearTuningConfig} sx={{m:2}}>Clear</Button>
              <Button onClick={handleSendTuningConfig} sx={{m:2}}>Send</Button>
              <Button onClick={handleStatusTuningConfig} sx={{m:2}}>Status</Button>
              </>
                }
                {value === '2' &&
                <>
              <Button onClick={handleClearStandbyMode} sx={{m:2}}>Clear</Button>
              <Button onClick={handleSendStandbyMode} sx={{m:2}}>Send</Button>
              <Button onClick={handleStatusStandbyMode} sx={{m:2}}>Status</Button>
              </>
                }
                {value === '3' &&
                <>
              <Button onClick={handleClearAlertConfig} sx={{m:2}}>Clear</Button>
              <Button onClick={handleSaveAlertConfig} sx={{m:2}}>Save</Button>
              </>
                }
                {value === '4' &&
                <>
              <Button onClick={handleClearMigrateMiners} sx={{m:2}}>Clear</Button>
              <Button onClick={handleMigrateMigrateMiners} sx={{m:2}}>Migrate</Button>
              </>
                }
                <Button onClick={handleClose} sx={{m:2}}>Close</Button>
              </DialogActions>
          </Dialog>



          <Dialog
        open={openUpgradeConfirmation}
      >
        <DialogContent>
        <DialogTitle>
          <Typography variant='h6'>Upgrade Result</Typography>
        </DialogTitle>
          <Typography variant="body2" sx={{ fontSize: 'smaller' }}>{upgradeConfirmationMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpgradeConfirmation}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={statusUpgrade}
      >
        <DialogContent>
        <DialogTitle>
          <Typography variant='h6' sx={{mt:1, mb:2}}>Upgrade Status:</Typography>
        </DialogTitle>
          {getFWfail === false && Object.keys(fwjobMessage).length > 0 && (
            <>
            <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Serial No:</b> {fwjobMessage.SerialNo}</Typography>
            <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Status:</b> {fwjobMessage.Status}</Typography>
            <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Message:</b> {fwjobMessage.Msg}</Typography>
            <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Updated At:</b> {displayTS(fwjobMessage.updatedAt).timeStr}</Typography>
            </>
          )
          }
          {getFWfail === false && fwMultipleJobMessage.length > 0 && (
            fwMultipleJobMessage.map((item, index) => (
                <div key={index}>
                    <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Serial No:</b> {item.SerialNo}</Typography>
                    <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Status:</b> {item.Status}</Typography>
                    <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Message:</b> {item.Msg}</Typography>
                    <Typography variant='body1' sx={{ fontSize: 'smaller', mb:3.5 }}><b>Updated At:</b> {displayTS(item.updatedAt).timeStr}</Typography>
                </div>
              ))
          )
          }
          {getFWfail === true && (
            <Typography variant="body2" sx={{ fontSize: 'smaller' }}>{upgradeStatusMessage}</Typography>)
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStatusUpgrade}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openTuningConfigConfirmation}
      >
        <DialogContent>
        <DialogTitle>
          <Typography variant='h6'>Tuning Result</Typography>
        </DialogTitle>
          <Typography variant="body2" sx={{ fontSize: 'smaller' }}>{tuningConfigConfirmationMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTuningConfigConfirmation}>OK</Button>
        </DialogActions>
      </Dialog>

          <Dialog
        open={statusTuning}
      >

        <DialogTitle>
          <Typography variant='h6' sx={{mt:1, mb:2}}>Tuning Config Status:</Typography>
        </DialogTitle>
        <DialogContent>
          {getTunefail === false && Object.keys(tuneJobMessage).length > 0 && (
            <>
            <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Serial No:</b> {tuneJobMessage.SerialNo}</Typography>
            <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Status:</b> {tuneJobMessage.Status}</Typography>
            <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Message:</b> {tuneJobMessage.Msg}</Typography>
            <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Updated At:</b> {displayTS(tuneJobMessage.updatedAt).timeStr}</Typography>
            </>
          )
          }
          {getTunefail === false && tuneMultipleJobMessage.length > 0 && (
            tuneMultipleJobMessage.map((item, index) => (
                <div key={index}>
                    <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Serial No:</b> {item.SerialNo}</Typography>
                    <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Status:</b> {item.Status}</Typography>
                    <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Message:</b> {item.Msg}</Typography>
                    <Typography variant='body1' sx={{ fontSize: 'smaller', mb:3.5 }}><b>Updated At:</b> {displayTS(item.updatedAt).timeStr}</Typography>
                </div>
              ))
          )
          }
          {getTunefail === true && (
            <Typography variant="body2" sx={{ fontSize: 'smaller' }}>{tuningConfigStatusMessage}</Typography>)
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStatusTuning}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openStandbyConfirmation}
      >
        <DialogContent>
        <DialogTitle>
          <Typography variant='h6'>Mode Change Result</Typography>
        </DialogTitle>
          <Typography variant="body2" sx={{ fontSize: 'smaller' }}>{standbyConfirmationMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStandbyConfirmation}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={statusStandby}
      >
      <DialogTitle>
          <Typography variant='h6' sx={{mt:1, mb:2}}>Mode Status:</Typography>
        </DialogTitle>
        <DialogContent>
          {getSleepfail === false && Object.keys(standbyJobMessage).length > 0 && (
            <>
            <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Serial No:</b> {standbyJobMessage.SerialNo}</Typography>
            <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Status:</b> {standbyJobMessage.Status}</Typography>
            <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Message:</b> {standbyJobMessage.Msg}</Typography>
            <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Updated At:</b> {displayTS(standbyJobMessage.updatedAt).timeStr}</Typography>
            </>
          )
          }
          {getSleepfail === false && standbyMultipleJobMessage.length > 0 && (
            standbyMultipleJobMessage.map((item, index) => (
                <div key={index}>
                    <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Serial No:</b> {item.SerialNo}</Typography>
                    <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Status:</b> {item.Status}</Typography>
                    <Typography variant='body1' sx={{ fontSize: 'smaller' }}><b>Message:</b> {item.Msg}</Typography>
                    <Typography variant='body1' sx={{ fontSize: 'smaller', mb:3.5}}><b>Updated At:</b> {displayTS(item.updatedAt).timeStr}</Typography>
                </div>
              ))
          )
          }
          {getSleepfail === true && (
            <Typography variant="body2" sx={{ fontSize: 'smaller' }}>{standbyStatusMessage}</Typography>)
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStatusStandby}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAlertConfirmation}
      >
        <DialogContent>
        <DialogTitle>
          <Typography variant='h6'>Alert Result</Typography>
        </DialogTitle>
          <Typography variant="body2" sx={{ fontSize: 'smaller' }}>{alertConfirmationMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertConfirmation}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openMigrateMinersConfirmation}
      >
        <DialogContent>
        <DialogTitle>
          <Typography variant='h6'>Migrate Miners Result</Typography>
        </DialogTitle>
          <Typography variant="body2" sx={{ fontSize: 'smaller' }}>{migrateMinersConfirmationMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMigrateMinersConfirmation}>OK</Button>
        </DialogActions>
      </Dialog>


        </>
        )}
          <br/>
          {isminerGroupSelected && (
            <DataGrid
            autoHeight
            getRowId={(row) => row.serial}
            rows={rows}
            columns={columns}
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 60}
            pagination
            pageSizeOptions={[50, 100]}
            rowCount={count}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            paginationMode="server"
            onRowSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            rowSelectionModel={selectionModel}
            loading={loading}
            checkboxSelection
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            sx={{m:5, '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '6px' },
      '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '10px' },
      '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },}}
            onColumnWidthChange={handleColumnWidthChange}
            onColumnResize={(params) => handleColumnWidthChange(params)}
            columnVisibilityModel={colVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => handleColumnVisibilityChange(newModel)}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterModel={filterModel}
            onFilterModelChange={handleFilterModelChange}
            />
          )}
          <Footer />
        </Box >
      );
    } else if (validUser !== -1 && validUser !== 0) {
      return (
        <div>
          <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg} />
        </div>
      );
    } else {
      return <PageLoading />;
    }
  }