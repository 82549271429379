import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useRef } from "react";
import { Paper, Grid, Box, Table, TableBody, TableRow, TableCell, Toolbar, Typography, Button, Select, MenuItem, Tabs, Tab, IconButton } from '@mui/material';
import { UserA } from "../user/user";
import * as api from '../api/api'
import Loading from "../components/loading";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Footer from '../components/footer';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { useTheme } from '@mui/material/styles';
import Plotly from 'plotly.js-dist';
import Tooltip from '@mui/material/Tooltip';
import PageContainer from '../components/PageContainer';
import InfoBlock from '../socket/InfoBlock';
import { CSVLink } from 'react-csv';
import PaperWrap from "../components/paper-wrap";
import { TabPanel, a11yProps } from '../components/tab';
import 'reactjs-popup/dist/index.css';
import CloseIcon from '@mui/icons-material/Close';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './fluxgpt.css';
import { TextField } from '@mui/material';
import { env } from '../env';
import PageLoading from "../components/pageloading";


const CHAT_HISTORY_KEY = 'chatHistory';

const SuccessModal = ({ isOpen, onClose, Status }) => {
  console.log('status is', Status)
  let h4Msg = ''
  let pMsg = ''
  switch (Status) {
    case 'ok':
    case 'empty':
      break;
    case 'fetchfailed':
      h4Msg = "Fetch Failed!"
      pMsg = "Unable to fetch user data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
    case 'nouser':
        h4Msg = "Authentication Failed!"
        pMsg = "User is not part of the Organization. Please contact your Administrator"
      break;
    default:
      h4Msg = "Unknown Error"
      pMsg = "Unable to fetch user data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
  }
  if (Status !== 'ok') {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose} >
          <h4 className="popup-title">{h4Msg}</h4>
          <p className="success-message">{pMsg}</p>
          <div className="button-container">
            <button onClick={onClose}>Close</button>
          </div>
      </Popup>
    );
  }
};

let validUser = -1

let auth_token = ""

export const FluxGPT = () => {

    const { getAccessTokenSilently } = useAuth0();
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [submitMsg, setSubmitMsg] = useState('ok');
    const [userMessage, setUserMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const inputRef = useRef(null);
    const [typingMessage, setTypingMessage] = useState('');
    const [inputDisabled, setInputDisabled] = useState(false);
    const chatHistoryRef = useRef(null);
    const [socket, setSocket] = useState(null);

    const [messages, setMessages] = useState(
      JSON.parse(localStorage.getItem(CHAT_HISTORY_KEY)) || []
    );

    const handleCloseSuccessModal = () => {
      setSuccessModalOpen(false);
    };

    let websocketUrl = ''
    if (env.REACT_APP_AUTH0_DOMAIN === 'auradine.us.auth0.com') {
      websocketUrl = "wss://fluxgpt.auradine.com:443/streamFluxGPT"
    } else {
      websocketUrl = "wss://stagingfluxgpt.auradine.gold:443/streamFluxGPT"
    }

  useEffect(() => {
    const fn = async () => {
      const token = await getAccessTokenSilently();
      await api.GetUser(token, (data) => {
        if (data.status === 'error') {
          if (data.errorCode === 1007) {
            validUser = 1
            setSubmitMsg("nouser");
            setSuccessModalOpen(true);
            auth_token = ""
          } else {
            validUser = 1
            setSubmitMsg("fetchfailed");
            setSuccessModalOpen(true);
            auth_token = ""
          }
        } else {
          setSubmitMsg("ok");
          setSuccessModalOpen(true);
          validUser = 0
          auth_token = "Bearer " + token
        }
      })
    }
    fn()

  }, []);


  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  async function sendMessageToBotWithStreaming(body, newmsg) {
    try {

        console.log("open the connection", websocketUrl)
        const token = await getAccessTokenSilently()
        auth_token = "Bearer " + token
        let wsURL = `${websocketUrl}?token=${auth_token}`

        const newSocket = new WebSocket(wsURL);

        await new Promise((resolve) => {
          newSocket.onopen = () => {
              console.log("WebSocket connection opened.");
              setSocket(newSocket);
              resolve();
          };
          newSocket.onerror = (error) => {
            console.error("WebSocket error:", error);
            const data = { content: "Unable to connect to FluxGPT™ server. Please try again later", role: "assistant"}
            setMessages([...messages, { content: userMessage, role: 'user' }, { content: data.content, role: 'assistant' }]);
            setTimeout(() => {
              inputRef.current.focus();
            }, 0);
            setUserMessage('');
            setTypingMessage('');
            setInputDisabled(false);
            setSubmitting(false);
            return
          };
        });

        console.log("send the message" )

        newSocket.send(JSON.stringify({"body": JSON.stringify(body)}));
        setTypingMessage('FluxGPT™ is typing...');

        var return_message = "";
        var isMsgReceived = false;

        newSocket.onmessage = (event) => {
          setUserMessage('');
          isMsgReceived = true;
          const response_chunk = event.data;
          //console.log('response', response_chunk);
          return_message += response_chunk;
          const data = { content: return_message, role: "assistant"}
          setMessages([...messages, newmsg, data]);
          const updatedMessages = [...messages, newmsg, data];
          localStorage.setItem(CHAT_HISTORY_KEY, JSON.stringify(updatedMessages));
        };
        // Event handler for when the WebSocket connection is closed
        newSocket.onclose = (event) => {
          if (event.wasClean) {
              console.log("WebSocket connection closed cleanly.", event.reason);
          } else {
              console.error("WebSocket connection abruptly closed.", event.reason);
          }
          if (return_message === "") {
            console.log("Print event reason", event.reason)
            let data = ''
            if (event.reason === "") {
              data = { content: "FluxGPT™ server is unable to provide immediate assitance. Please try again later.", role: "assistant"}
            } else {
              data = { content: event.reason, role: "assistant"}
            }
            setMessages([...messages, newmsg, data]);
            const updatedMessages = [...messages, newmsg, data];
            localStorage.setItem(CHAT_HISTORY_KEY, JSON.stringify(updatedMessages));
          }
          isMsgReceived = true;
          setUserMessage('');
          setTypingMessage('');
          setInputDisabled(false);
          setSubmitting(false);
          setTimeout(() => {
            if (inputRef.current) inputRef.current.focus();
          }, 0);
        };
        if (isMsgReceived === false) {
            setTimeout(() => {
                  if (isMsgReceived === false) {
                    console.log("Server response delayed. Close the connection")
                    newSocket.close();
                    setSocket(null);
                    const data = { content: "FluxGPT™ server is unable to provide immediate assitance. Please try again later.", role: "assistant"}
                    setMessages([...messages, newmsg, data]);
                    const updatedMessages = [...messages, newmsg, data];
                    localStorage.setItem(CHAT_HISTORY_KEY, JSON.stringify(updatedMessages));
                    setUserMessage('');
                    setTypingMessage('');
                    setInputDisabled(false);
                    setSubmitting(false);
                    setTimeout(() => {
                      if (inputRef.current) inputRef.current.focus();
                    }, 0);
                  } else {
                    console.log("Server response received")
                  }
            }, 60000);
        }
    } catch (error) {
        console.log("chatbot request failed with error", error);
        const data = { content: "Something went wrong. Please try again later", role: "assistant"}
        setUserMessage('');
        setTypingMessage('');
        setInputDisabled(false);
        setSubmitting(false);
        setMessages([...messages, newmsg, data]);
        const updatedMessages = [...messages, newmsg, data];
        localStorage.setItem(CHAT_HISTORY_KEY, JSON.stringify(updatedMessages));
        setTimeout(() => {
          if (inputRef.current) inputRef.current.focus();
        }, 0);
    }
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    if (submitting || !userMessage.trim()) {
      setTimeout(() => {
        if (inputRef.current) inputRef.current.focus();
      }, 0);
      return;
    }

    // const token = await getAccessTokenSilently();
    let body = []
    let history = JSON.parse(localStorage.getItem(CHAT_HISTORY_KEY))
    if (history.length > 0) {
      history.forEach((chatEntry) => {
        body.push(chatEntry);
      });
    }
    let newmsg = {"content": userMessage, "role": "user"}
    body.push(newmsg)

    setInputDisabled(true);
    setSubmitting(true);
    /*
     * Do not add the user message to the state until the server starts responding
     */
   //setMessages([...messages, { content: userMessage, role: 'user' }]);

    await sendMessageToBotWithStreaming(body, newmsg);

    setTimeout(() => {
      if (inputRef.current) inputRef.current.focus();
    }, 0);
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatHistoryRef.current) {
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
      }
    }, 0);
  };

  useEffect(() => {
    const storedChatHistory = JSON.parse(localStorage.getItem(CHAT_HISTORY_KEY));
    if (storedChatHistory) {
      setMessages(storedChatHistory);
      setTimeout(() => {
        scrollToBottom();
      }, 0);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(CHAT_HISTORY_KEY, JSON.stringify(messages));
    setTimeout(() => {
      scrollToBottom();
    }, 0);
  }, [messages]);

  const handleInputChange = (e) => {
    const text = e.target.value;
    setUserMessage(text);
  };

  const handleClearChat = () => {
    setMessages([]);
  };

  function renderBoldKeywords(content) {
    const keywords = ['support@auradine.com',
                      'sales@auradine.com',
                      'info@auradine.com',
                      'Auradine FluxOS release notes 2023-10.01',
                      'Auradine Teraflux FAQ',
                      'Teraflux Starter Kit User Manual',
                      'Auradine Teraflux AT1500 Data Sheet',
                      'Fortune Miner API v1.0 Manual',
                      'FluxVision Cloud Management Solution FAQ'
                    ];
      keywords.forEach((keyword) => {
      const regExp = new RegExp(keyword, 'gi');
      content = content.replace(regExp, (match) => `<span class="bold-keyword">${match}</span>`);
    });

    return content;
  }

  if (validUser === 0) {
      return (
        <PageContainer title="FluxGPT" description="this is the FluxGPT page">
          <div className="GPT">
              <header className="GPT-header">
              <h2>Welcome to FluxGPT™ <span className="beta-text">BETA</span></h2>
              <div className="chat-container">
              <div className="chat-history" ref={chatHistoryRef}>
                  {Array.isArray(messages) && messages.length > 0 ? (messages.reduce((groupedMessages, message, index) => {
                    if (index === 0 || message.role !== messages[index - 1].role) {
                      groupedMessages.push(
                        <div
                          key={`message-group-${index}`}
                          className={`message ${message.role}`}
                          style={{ maxWidth: "100%", wordWrap: "break-word", overflowWrap: "break-word" }}
                        >
                        {message.role === 'assistant' ? (
                        <div dangerouslySetInnerHTML={{ __html: renderBoldKeywords(message.content) }} />
                            ) : (
                              message.content
                        )}
                        </div>
                      );
                    } else {
                      /* This will never be hit */
                      groupedMessages[groupedMessages.length - 1] = (
                        <div
                          key={`message-group-${index}`}
                          className={`message ${message.role}`}
                          style={{ maxWidth: "100%", wordWrap: "break-word", overflowWrap: "break-word" }}
                        >
                          {groupedMessages[groupedMessages.length - 1].props.children + message.content}
                        </div>
                      );
                    }
                    return groupedMessages;
                  }, [])
                  ) : (<div/>)}
              </div>
              <form onSubmit={handleSubmit}>
                  <div className="message-input-form">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <textarea
                          type="text"
                          id="userMessage"
                          placeholder="Ask FluxGPT™ a question..."
                          key="userMessage"
                          value={userMessage}
                          onChange={handleInputChange}
                          style={{
                            textAlign: "left",
                            width: "920px",
                            resize: "none",
                            fontSize: "14px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            padding: "2px",
                            backgroundColor: "#fff",
                          }}
                          ref={inputRef}
                          disabled={inputDisabled}
                          onKeyDown={handleKeyDown}
                          autoFocus
                        />
                      <button
                        type="button"
                        className="send-button"
                        onClick={handleSubmit}
                        disabled={!userMessage.trim() || submitting}
                        style={{
                          background: "teal",
                          border: "none",
                          cursor: "pointer",
                          marginTop: "-11px",
                          marginBottom: "-10px"
                        }}
                      >
                        <span style={{ fontSize: "18px" }}>&#10148;</span>
                      </button>
                      <button
                          type="button"
                          className="clear-button"
                          onClick={handleClearChat}
                          disabled={submitting}
                          style={{
                            background: "lightblue",
                            border: "none",
                            cursor: "pointer",
                            marginTop: "-11px",
                            marginBottom: "-10px",
                            transition: "background-color 0.3s",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              transition: "transform 0.3s",
                            }}
                          >
                            🗑️
                          </span>
                      </button>
                      </div>
                  </div>
              </form>
              {typingMessage && (
                <p
                  style={{
                    textAlign: 'left',
                    fontSize: '12px',
                    fontStyle: 'italic',
                    color: 'white',
                  }}
                >
                  {typingMessage}
                </p>
              )}
              <br/>
              <p style={{ fontSize: "small", textAlign: "left", fontStyle: "italic", color: "#CCCCCC" }}>
                <strong>Disclaimer:</strong> FluxGPT™ response may occasionally contain inaccuracies, It is important to note that the Auradine does not accept liability for any losses that may result from</p>
              <p style={{ fontSize: "small", textAlign: "left", fontStyle: "italic", color: "#CCCCCC" }}>
                direct use of this information. Users are advised to exercise caution and independently verify any critical information.
              </p>
            </div>
            </header>
            <Footer/>
          </div>
          </PageContainer>
      );
  } else if (validUser !== -1 && validUser !== 0) {
    return(
      <PageContainer title="FluxGPT" description="this is the FluxGPT page">
      <div>
        <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg}/>
      </div>
      </PageContainer>
    );
  } else {
    return <PageContainer title="FluxGPT" description="this is the FluxGPT page"><PageLoading /></PageContainer>
  }
};

export default withAuthenticationRequired(FluxGPT, {
  onRedirecting: () => <Loading />,
});
