import { Box, Typography } from "@mui/material";
import React from "react";
import NoDataIcon from "../../../assets/no_data.svg";

type AuradineDataNotFoundStateProps = {
  message: string;
};

const AuradineDataNotFoundState: React.FC<AuradineDataNotFoundStateProps> = (
  props: AuradineDataNotFoundStateProps
): React.ReactElement => {
  const { message } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={"center"}
      alignItems={"center"}
      alignContent={"center"}
      gap={4}
      height={"100%"}
    >
      <img
        style={{ width: "20rem", height: "20rem" }}
        src={NoDataIcon}
        alt="empty basket"
      />
      <Typography style={{ fontSize: "1.375rem", fontWeight: 400 }}>
        {message}
      </Typography>
    </Box>
  );
};

export default AuradineDataNotFoundState;
