import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import * as api from '../api/api'

const isPhoneValid = (value) => {
  /*
  Valid numbers:
    (123) 456-7890
    (123)456-7890
    123-456-7890
    123.456.7890
    1234567890
    +31636363634
    075-63546725
  */

  const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return (regex.test(value) === true);
}

const EditUser = ({ usera, setEdit }) => {
  const { getAccessTokenSilently } = useAuth0();

  //  console.log(usera)
  const initialUsername = usera.name;
  const [username, setUsername] = useState(initialUsername)
  const [userphone, setUserphone] = useState(usera.phone)
  const [useraddress, setUseraddress] = useState(usera.address)
  const [nameFilled, setNameFilled] = React.useState(false);

  const [error, setError] = useState({
    error: false,
    helperText: "",
  })

  const [isUsernameEdited, setIsUsernameEdited] = useState(false);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    //console.log({ name: name, value: value })

    if (name === "phone") {
      // if (isPhoneValid(value) === false) {
      //   setError({ error: true, helperText: "Invalid phone number" });
      // } else {
      //   setError({ error: false, helperText: "" });
      // }
      setUserphone(value)
    }

    if (name === "name") {
      setUsername(value)
      setNameFilled(value.trim() !== '');
      setIsUsernameEdited(value !== initialUsername);
    }

    if (name === "address") {
      setUseraddress(value)
    }
  }

  const submitHandler = async (event) => {
    event.preventDefault();

    if (error.error) return

    const fn = async () => {
      const token = await getAccessTokenSilently();
      await api.PostUser(token, {
        name: username,
        address: useraddress,
        phone: userphone,
      });
      setEdit(false)
    }
    fn()
  }

  return (
    <div>
      <Box component="form"
        onSubmit={submitHandler}
        noValidate
        autoComplete="off">
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell align="right">Name:</TableCell>
              <TableCell align="left">
                <TextField
                  required
                  name="name"
                  value={username}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right">Address:</TableCell>
              <TableCell align="left">
                <TextField
                  name="address"
                  value={useraddress}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right">Phone:</TableCell>
              <TableCell align="left">
                <Tooltip title={<div>Valid format:<br />(123) 456-7890<br />(123)456-7890<br />123-456-7890<br />123.456.7890<br />1234567890</div>}>
                  <TextField
                    name="phone"
                    value={userphone}
                    onChange={handleInputChange}
                    helperText={error.helperText}
                    error={error.error}
                    variant="outlined"
                    size="small" />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Toolbar>
          <Typography variant="h4" component="div" sx={{ padding: 2, flexGrow: 1 }} />
          <Button
            disabled={(isUsernameEdited || username.trim() === '') && !nameFilled}
            type="submit"
            variant="contained">
            Save
          </Button>
        </Toolbar>
      </Box>
    </div>
  )
}

export default EditUser;