import React from 'react';
import { Card, CardContent, Typography, Box, Fab, Paper } from '@mui/material';

const InfoBlock = ({info, title, color, unit, orientation}) => {
	return (
            <Paper variant="outlined"  elevation={0} sx={{background: color, width: 200}}>
            <Box >
              <Typography
                variant="h9"
                fontWeight="300"
                sx={{
                  marginTop: '5px',
                  marginLeft: '5px',
                  color: 'white'
                }}
                gutterBottom
              >
                {title}
              </Typography>
              
            </Box>
            {orientation === "left" ?
            <Box sx={{marginTop: '10px', }} display="flex" alignItems="flex-start" justifyContent="center">
            <Typography
              variant="h9"
              fontWeight="400"
              color="textSecondary"
              sx={{
                marginBottom: '0',
                marginTop: '10px',
                opacity: '1.0',
                color: 'white'
              }}
              gutterBottom
            >
              {unit}
            </Typography>
                <Typography
              variant="h9"
              fontWeight="500"
              sx={{
                color: 'white',
                marginTop: '10px',
                marginLeft: '5px',
              }}
              gutterBottom
            >
              {info}
            </Typography>
            </Box>
            :
            <Box sx={{marginTop: '10px', }} display="flex" alignItems="flex-start" justifyContent="center">
            <Typography
              variant="h9"
              fontWeight="400"
              color="textSecondary"
              sx={{
                marginBottom: '0',
                marginTop: '10px',
                opacity: '1.0',
                color: 'white'
              }}
              gutterBottom
            >
              {info}
            </Typography>
                <Typography
              variant="h9"
              fontWeight="500"
              sx={{
                color: 'white',
                marginTop: '10px',
                marginLeft: '5px',
              }}
              gutterBottom
            >
              {unit}
            </Typography>
            </Box>
          }

            </Paper>
	)
}
export default InfoBlock;