import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useRef } from "react";
import { Paper, Grid, Box, Table, TableBody, TableRow, TableCell, Toolbar, Typography, Button, Select, MenuItem, Tabs, Tab, IconButton } from '@mui/material';
import { UserA } from "../user/user";
import TextField from '@mui/material/TextField';
import * as api from '../api/api'
import Loading from "../components/loading";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Footer from '../components/footer';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { useTheme } from '@mui/material/styles';
import Plotly from 'plotly.js-dist';
import Tooltip from '@mui/material/Tooltip';
import PageContainer from '../components/PageContainer';
import InfoBlock from '../socket/InfoBlock';
import { CSVLink } from 'react-csv';
import PaperWrap from "../components/paper-wrap";
import { TabPanel, a11yProps } from '../components/tab'; 
import 'reactjs-popup/dist/index.css';
import CloseIcon from '@mui/icons-material/Close';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import * as constants from '../constants';

const SuccessModal = ({ isOpen, onClose, Status }) => {
  console.log('status is', Status)
  let h4Msg = ''
  let pMsg = ''
  switch (Status) {
    case 'ok':
    case 'empty':
      break;
    case 'fetchfailed':
      h4Msg = "Fetch Failed!"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
    case 'nouser':
        h4Msg = "Authentication Failed!"
        pMsg = "User is not part of the Organization. Please contact your Administrator"
      break;
    default:
      h4Msg = "Unknown Error"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
  }
  if (Status !== 'ok') {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose} >
          <h4 className="popup-title">{h4Msg}</h4>
          <p className="success-message">{pMsg}</p>
          <div className="button-container">
            <button onClick={onClose}>Close</button>
          </div>
      </Popup>
    );
  }
};

let validUser = -1

function generateRandomEfficiencyInRange(min, max) {
  return min + Math.random() * (max - min);
}

function generateRandomData(numDevices, inputTHs, inputEfficiency) {
  // Create an array to store the generated data
  const generatedData = [];

  // Generate data for the first device based on the input values
  generatedData.push({ THs: inputTHs, efficiency: inputEfficiency });

  // Generate data for the remaining devices
  for (let i = 1; i < numDevices; i++) {
    // Generate a random efficiency value within a range close to the input efficiency
    const minEfficiency = Math.max(inputEfficiency - 2, 25); // Adjust the range as needed
    const maxEfficiency = Math.min(inputEfficiency + 2, 27); // Adjust the range as needed
    const randomEfficiency = generateRandomEfficiencyInRange(minEfficiency, maxEfficiency);

    // Calculate the random TH/s value to maintain the same efficiency ratio as the input
    const randomTHs = inputTHs * (inputEfficiency / randomEfficiency);

    generatedData.push({ THs: randomTHs, efficiency: randomEfficiency });
  }

  return generatedData;
}

// Calculate the average efficiency for the generated random data
function calculateAverageEfficiency(data) {
  const totalEfficiency = data.reduce((sum, device) => sum + device.efficiency, 0);
  const averageEfficiency = totalEfficiency / data.length;
  return averageEfficiency;
}

const DASHBOARD_GROUP_NAME = 'dashboardGroupName';
const DASHBOARD_GROUP_LIST = 'dashboardGroupList';

export const Dashboard = () => {

    const chartRef = useRef(null);

    const [inputIsPerGroup, setinputIsPerGroup] = useState(
      localStorage.getItem('inputIsPerGroup') === 'true' ? true : false
    );
    const [successModalOpen, setSuccessModalOpen] = useState(false);

    // const [minerGroup, setMinerGroup] = useState(localStorage.getItem(DASHBOARD_GROUP_NAME) || '');
    // const [minerGroups, setMinerGroups] = useState(
    //   JSON.parse(localStorage.getItem(DASHBOARD_GROUP_LIST)) || []
    // );

    const [minerGroup, setMinerGroup] = useState('Teraflux Group');
    const [minerGroups, setMinerGroups] = useState([]);

    const { user: user0, getAccessTokenSilently } = useAuth0();

    const [value, setValue] = useState(0);
    const [dgInfo, setDgInfo] = useState('');
    const [bitcoinPrice, setBitcoinPrice] = useState('')
    const [EnergyPrice, setEnergyPrice] = useState('')
    const [blockReward, setBlockReward] = useState('')
    const [btcDiff, setBtcDiff] = useState('')
    const [currentThs, setCurrentThs] = useState('')
    const [currentEff, setCurrentEff] = useState('')
    const [currentRev, setCurrentRev] = useState('')
    const [aggCurrentRev, setAggCurrentRev] = useState('')
    const [currentElec, setCurrentElec] = useState('')
    const [aggCurrentElec, setAggCurrentElec] = useState('')
    const [currentPCost, setCurrentPCost] = useState('')
    const [currentMProfit, setCurrentMProfit] = useState('')
    const [aggCurrentMProfit, setAggCurrentMProfit] = useState('')
    const [optimalThs, setOptimalThs] = useState('')
    const [optimalEff, setOptimalEff] = useState('')
    const [optimalRev, setOptimalRev] = useState('')
    const [aggOptimalRev, setAggOptimalRev] = useState('')
    const [optimalElec, setOptimalElec] = useState('')
    const [aggOptimalElec, setAggOptimalElec] = useState('')
    const [optimalPCost, setOptimalPCost] = useState('')
    const [optimalMProfit, setOptimalMProfit] = useState('')
    const [aggOptimalMProfit, setAggOptimalMProfit] = useState('')
    const [TuningMode, setTuningMode] = useState('')
    const [TunedBy, setTunedBy] = useState('')
    const [PowerConstraintMax, setPowerConstraintMax] = useState('')
    const [TotalMiners, setTotalMiners] = useState('')
    const [LastTunedAt, setLastTunedAt] = useState('')
    const [LastTunedJobStatus, setLastTunedJobStatus] = useState('')
    const [NextTunedAt, setNextTunedAt] = useState('')
    const [submitMsg, setSubmitMsg] = useState('');
    const [AverageEfficiency, setaverageEfficiency] = useState('')
    const [OptAverageEfficiency, setOptaverageEfficiency] = useState('')
    
    const [isLoading, setIsLoading] = useState(false);
    const [isTabEnabled, setIsTabEnabled] = useState(true);

    const [showTgtLine, setshowTgtLine] = useState(false)
    const [showOptLine, setshowOptLine] = useState(false)
    const [showOtherLines, setshowOtherLines] = useState(false)
    const [fillColor1, setFillColor1] = useState('white');
    const [fillColor2, setFillColor2] = useState('white');

    const [showBanner, setShowBanner] = useState(false);

    const AutoTuneInterval = 3600

    const handleCloseSuccessModal = () => {
      setSuccessModalOpen(false);
    };

    const handleBannerClose = () => {
      setShowBanner(false);
    };

    const handleTabChange = (event, newValue) => {
      if (isLoading) {
        event.preventDefault();
      } else {
        setValue(newValue);
      }
    };
    const fetchDgInfo = async (resetValues, dgname) => {
        const value = dgname
        const token = await getAccessTokenSilently();
        const info = {}

        if (resetValues) {
          setTuningMode('')
          setTunedBy('')
          setPowerConstraintMax('')
          setTotalMiners(0)
          setBitcoinPrice(0)
          setBlockReward(0)
          setBtcDiff(0)
          setEnergyPrice(0)
          /*
          * Initialize Dashboard Target Config Details Per Miner
          */
          setCurrentThs(0)
          setCurrentEff(0)
          setCurrentRev(0)
          setCurrentPCost(0)
          setCurrentElec(0)
          setCurrentMProfit(0)
          /*
          * Initialize Dashboard Target Config Details Per Group
          */        
          setAggCurrentRev(0)
          setAggCurrentElec(0)
          setAggCurrentMProfit(0)
          /*
          * Initialize Dashboard Optimal Config Details Per Miner
          */
          setOptimalThs(0)
          setOptimalEff(0)
          setOptimalRev(0)
          setOptimalPCost(0)
          setOptimalElec(0)
          setOptimalMProfit(0)
          /*
          * Initialize Dashboard Optimal Config Details Per Group
          */       
          setAggOptimalRev(0)
          setAggOptimalElec(0)
          setAggOptimalMProfit(0)

          /*
          * Last and Next Tuned Information
          */
          setLastTunedAt('')
          setNextTunedAt('')
          setLastTunedJobStatus('')
        }

        if (value !== '') {
          info.dgName = value
          let op = 'getdginfo'
          const dgInfoCurrent = await api.GetDGInfo(token, info, op);
          if (dgInfoCurrent === undefined) {
            validUser = 1
            if (resetValues) {
              setSubmitMsg('something')
              setSuccessModalOpen(true);
            }
          } else if (dgInfoCurrent.status === 'error') {
            console.log(dgInfoCurrent)
            if (dgInfoCurrent.errorCode === 1007) {
              validUser = 1
              if (resetValues) {
                setSubmitMsg('nouser')
                setSuccessModalOpen(true);
              }
            } else {
              validUser = 1
              if (resetValues) {
                setSubmitMsg('fetchfailed')
                setSuccessModalOpen(true);
              }
            }
          } else if (!dgInfoCurrent.hasOwnProperty('devicegroup')) {
            validUser = 1
            if (resetValues) {
              setSubmitMsg('empty')
              setSuccessModalOpen(true);
            }
          }
          else {
            validUser = 0
            setSubmitMsg('ok')
            //console.log('Response from BE', dgInfoCurrent)
            setDgInfo(dgInfoCurrent)
            /*
              * Last Tuned Information
              */
            let utcDate = new Date()
            if (dgInfoCurrent.devicegroup.hasOwnProperty('LastTunedAt')) {
              utcDate = new Date(dgInfoCurrent.devicegroup.LastTunedAt);
              const localDate = utcDate.toLocaleString();
              setLastTunedAt(localDate)
            }
            if (dgInfoCurrent.devicegroup.hasOwnProperty('LastTunedJobStatus')) {
                  const lowerCaseString = dgInfoCurrent.devicegroup.LastTunedJobStatus.toLowerCase();
                  const capitalizedString = lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
                  setLastTunedJobStatus(capitalizedString)
            }
            /*
              * Summary Details
              */
            if (dgInfoCurrent.devicegroup.hasOwnProperty('TuningMode')) {
              setTuningMode(dgInfoCurrent.devicegroup.TuningMode)
              if (dgInfoCurrent.devicegroup.TuningMode === 'Auto') {
                const nextUtcDate = new Date(utcDate.getTime() + AutoTuneInterval * 1000);
                const localNextDate = nextUtcDate.toLocaleString();
                setNextTunedAt(localNextDate)
              }
            }

            if (dgInfoCurrent.devicegroup.hasOwnProperty('TunedBy')) {
              setTunedBy(dgInfoCurrent.devicegroup.TunedBy)
            }

            if (dgInfoCurrent.devicegroup.hasOwnProperty('PowerConstraintMax') && 
                dgInfoCurrent.devicegroup.PowerConstraintMax !== -1) {
              setPowerConstraintMax(dgInfoCurrent.devicegroup.PowerConstraintMax)
            }

            if (dgInfoCurrent.devicegroup.hasOwnProperty('PowerOperationMode'))
              setShowBanner(dgInfoCurrent.devicegroup.TuningMode)

            if (dgInfoCurrent.devicegroup.hasOwnProperty('TotalDevices')) {
              setTotalMiners(dgInfoCurrent.devicegroup.TotalDevices)
            }

            /*
              * Dashboard BTC Details
              */

            if (dgInfoCurrent.devicegroup.hasOwnProperty('bitcoinPrice'))
              setBitcoinPrice(dgInfoCurrent.devicegroup.bitcoinPrice)

            if (dgInfoCurrent.devicegroup.hasOwnProperty('blockreward'))
              setBlockReward(dgInfoCurrent.devicegroup.blockreward)

            if (dgInfoCurrent.devicegroup.hasOwnProperty('btcDifficulty'))
              setBtcDiff((dgInfoCurrent.devicegroup.btcDifficulty/1000000000000).toFixed(2))

            if (dgInfoCurrent.devicegroup.hasOwnProperty('energyPrice'))
              setEnergyPrice(dgInfoCurrent.devicegroup.energyPrice)
            /*
              * Dashboard Target Config Details Per Miner
              */
            if (dgInfoCurrent.devicegroup.hasOwnProperty('currentmaxRate')) {
                setCurrentThs(dgInfoCurrent.devicegroup.currentmaxRate)
                setshowTgtLine(true)
            }
          
            if (dgInfoCurrent.devicegroup.hasOwnProperty('currentEfficiency'))
                setCurrentEff(dgInfoCurrent.devicegroup.currentEfficiency.toFixed(2))

              // Specify the number of devices
              const numDevices = dgInfoCurrent.devicegroup.TotalDevices;

              // Specify the input TH/s and efficiency values for the first device
              let inputTHs = 150;
              let inputEfficiency = 25.6;

              if (dgInfoCurrent.devicegroup.hasOwnProperty('currentmaxRate')) {
                inputTHs = dgInfoCurrent.devicegroup.currentmaxRate
              }

              if (dgInfoCurrent.devicegroup.hasOwnProperty('currentEfficiency')) {
                inputEfficiency = dgInfoCurrent.devicegroup.currentEfficiency
              }

              // Generate random TH/s and efficiency values for the specified number of devices
              let randomData = generateRandomData(numDevices, inputTHs, inputEfficiency);

              // Calculate the average efficiency for the randomData
              const averageEfficiency = calculateAverageEfficiency(randomData);
              setaverageEfficiency(averageEfficiency)

              console.log("Average Efficiency:", averageEfficiency);

              // Specify the input TH/s and efficiency values for the first device
              inputTHs = 123;
              inputEfficiency = 24;

              if (dgInfoCurrent.devicegroup.hasOwnProperty('OptimalHashRate')) {
                  inputTHs = dgInfoCurrent.devicegroup.OptimalHashRate
              }

              if (dgInfoCurrent.devicegroup.hasOwnProperty('bestEfficiency')) {
                  inputEfficiency = dgInfoCurrent.devicegroup.bestEfficiency
              }

              // Generate random TH/s and efficiency values for the specified number of devices
              randomData = generateRandomData(numDevices, inputTHs, inputEfficiency);

              // Calculate the average efficiency for the randomData
              const OptaverageEfficiency = calculateAverageEfficiency(randomData);
              setOptaverageEfficiency(OptaverageEfficiency)
          
            if (dgInfoCurrent.devicegroup.hasOwnProperty('currentmaxRevenue'))
                setCurrentRev(dgInfoCurrent.devicegroup.currentmaxRevenue.toFixed(2))

            if (dgInfoCurrent.devicegroup.hasOwnProperty('estimatedPoolFee'))
                setCurrentPCost(dgInfoCurrent.devicegroup.estimatedPoolFee.toFixed(2))

            if (dgInfoCurrent.devicegroup.hasOwnProperty('currentElectricityCost'))
                setCurrentElec(dgInfoCurrent.devicegroup.currentElectricityCost.toFixed(2))

            if (dgInfoCurrent.devicegroup.hasOwnProperty('currentmaxProfit'))
                setCurrentMProfit(dgInfoCurrent.devicegroup.currentmaxProfit.toFixed(2))
            /*
              * Dashboard Target Config Details Per Group
              */
            if (dgInfoCurrent.devicegroup.hasOwnProperty('currentaggrmaxRevenue'))
                setAggCurrentRev(dgInfoCurrent.devicegroup.currentaggrmaxRevenue.toFixed(2))

            if (dgInfoCurrent.devicegroup.hasOwnProperty('currentaggrElectricityCost'))
                setAggCurrentElec(dgInfoCurrent.devicegroup.currentaggrElectricityCost.toFixed(2))

            if (dgInfoCurrent.devicegroup.hasOwnProperty('currentaggrmaxProfit'))
                setAggCurrentMProfit(dgInfoCurrent.devicegroup.currentaggrmaxProfit.toFixed(2))
            /*
              * Dashboard Optimal Config Details Per Miner
              */
            if (dgInfoCurrent.devicegroup.hasOwnProperty('OptimalHashRate')) {
                setOptimalThs(dgInfoCurrent.devicegroup.OptimalHashRate)
                setshowOptLine(true)
            }

            if (dgInfoCurrent.devicegroup.hasOwnProperty('bestEfficiency'))
                setOptimalEff(dgInfoCurrent.devicegroup.bestEfficiency.toFixed(2))

            if (dgInfoCurrent.devicegroup.hasOwnProperty('maxRevenue'))
                setOptimalRev(dgInfoCurrent.devicegroup.maxRevenue.toFixed(2))

            if (dgInfoCurrent.devicegroup.hasOwnProperty('estimatedPoolFee'))
                setOptimalPCost(dgInfoCurrent.devicegroup.estimatedPoolFee.toFixed(2))

            if (dgInfoCurrent.devicegroup.hasOwnProperty('electricityCost'))
                setOptimalElec(dgInfoCurrent.devicegroup.electricityCost.toFixed(2))

            if (dgInfoCurrent.devicegroup.hasOwnProperty('maxProfit'))
                setOptimalMProfit(dgInfoCurrent.devicegroup.maxProfit.toFixed(2))
            /*
              * Dashboard Optimal Config Details Per Group
              */
            if (dgInfoCurrent.devicegroup.hasOwnProperty('aggrmaxRevenue'))
                setAggOptimalRev(dgInfoCurrent.devicegroup.aggrmaxRevenue.toFixed(2))
            
            if (dgInfoCurrent.devicegroup.hasOwnProperty('aggrelectricityCost'))
                setAggOptimalElec(dgInfoCurrent.devicegroup.aggrelectricityCost.toFixed(2))
            
            if (dgInfoCurrent.devicegroup.hasOwnProperty('aggrmaxProfit'))
                setAggOptimalMProfit(dgInfoCurrent.devicegroup.aggrmaxProfit.toFixed(2))
          }
        }
    }

  const renderChart = () => {
    if (chartRef.current) {
        console.log('render chart  ', inputIsPerGroup,  minerGroup, value)
        if (inputIsPerGroup === true) {
          if (dgInfo !== '' && dgInfo.hasOwnProperty('devicegroup') && dgInfo.devicegroup.hasOwnProperty('aggrChartProfitData')) {
            console.log('in renderChart is true and dginfo exists')
            let profitData = [
              {
                "x": dgInfo.devicegroup.aggrChartProfitData[0].x,
                "y": dgInfo.devicegroup.aggrChartProfitData[0].y,
                "type": "scatter",
                "name": "Profit per day",
                "hovertemplate": 'Hash: %{x:.2f} TH/s<extra></extra>' + '<br>Profit: %{y:$.2f}',
                mode: 'lines',
                line: { shape: 'spline', smoothing: 0.5 }
              }
            ]
            let maxRate1 = 0;
            let maxRate2 = 0;
            let maxRate3 = 0;
            let maxRate4 = 0;
            let color1, color2, color3, color4;
            if (dgInfo.devicegroup.hasOwnProperty('OptimalHashRate')) {
              maxRate1 = dgInfo.devicegroup.OptimalHashRate
              color1 = 'orange'
              setFillColor1(color1)
            }
            if (dgInfo.devicegroup.hasOwnProperty('currentmaxRate')) {
              maxRate2 = dgInfo.devicegroup.currentmaxRate
              color2 = 'green'
              setFillColor2(color2)
            }
            if (maxRate1 === maxRate2) {
              color1 = 'green'
              color2 = 'green'
              setFillColor1(color1)
              setFillColor2(color1)
            }
            if (maxRate2 <= constants.AT1500_MAXTHS) {
              maxRate3 = maxRate2
              maxRate4 = maxRate2
              color3 = 'green'
              color4 = 'green'
              setFillColor2(color3)
            } else if (maxRate2 > constants.AT1500_MAXTHS && maxRate2 <= constants.AI2500_MAXTHS) {
              maxRate3 = constants.AT1500_MAXTHS
              maxRate4 = maxRate2
              color3 = 'blue'
              color4 = 'green'
              setFillColor2(color4)
            } else if (maxRate2 > constants.AI2500_MAXTHS) {
              maxRate3 = constants.AT1500_MAXTHS
              maxRate4 = constants.AI2500_MAXTHS
              color3 = 'blue'
              color4 = 'purple'
            }
            if (maxRate3 !== maxRate2 || maxRate4 !== maxRate2) {
              setshowOtherLines(true)
            }
            const titleText = ''
            const layout = {
              title: {
                text: titleText,
                font: { size: 16 },
                x: 0, //Set the x-position of the title in the center
                y: 1.0, //Set the y-position of the title slightly above the chart
                xref: 'paper',
                yref: 'paper',
              },
              xaxis: { title: 'Hash Rate (TH/s)' },
              yaxis: { title: 'Profit Per Group ' },
              shapes: [
                {
                  type: 'line',
                  x0: maxRate1,
                  x1: maxRate1,
                  y0: 0,
                  y1: 1,
                  xref: 'x',
                  yref: 'paper',
                  line: { color: color1, width: 2, dash: 'dash', smoothing: 0.5 },
                },
                {
                  type: 'line',
                  x0: maxRate2,
                  x1: maxRate2,
                  y0: 0,
                  y1: 1,
                  xref: 'x',
                  yref: 'paper',
                  line: { color: color2, width: 2, dash: 'dash', smoothing: 0.5 },
                },
                {
                  type: 'line',
                  x0: maxRate3,
                  x1: maxRate3,
                  y0: 0,
                  y1: 1,
                  xref: 'x',
                  yref: 'paper',
                  line: { color: color3, width: 2, dash: 'dash', smoothing: 0.5 }, // Specify color for maxRate3
                },
                {
                  type: 'line',
                  x0: maxRate4,
                  x1: maxRate4,
                  y0: 0,
                  y1: 1,
                  xref: 'x',
                  yref: 'paper',
                  line: { color: color4, width: 2, dash: 'dash', smoothing: 0.5 }, // Specify color for maxRate4
                },
              ],
            };
            if (chartRef.current) {
              Plotly.newPlot(chartRef.current, profitData, layout);
            }
          }
        } else {
            if (dgInfo !== '' && dgInfo.hasOwnProperty('devicegroup') && dgInfo.devicegroup.hasOwnProperty('ChartProfitData')) {
            console.log('in renderChart  is false and dginfo exists')
            let profitData=[
              {
                "x": dgInfo.devicegroup.ChartProfitData[0].x,
                "y": dgInfo.devicegroup.ChartProfitData[0].y,
                "type": "scatter",
                "name": "Profit per day",
                "hovertemplate": 'Hash: %{x:.2f} TH/s<extra></extra>' + '<br>Profit: %{y:$.2f}',
                mode: 'lines',
                line: { shape: 'spline', smoothing: 0.5 }
              }
            ]
            let maxRate1 = 0;
            let maxRate2 = 0;
            let maxRate3 = 0;
            let maxRate4 = 0;
            let color1, color2, color3, color4;
            if (dgInfo.devicegroup.hasOwnProperty('OptimalHashRate')) {
              maxRate1 = dgInfo.devicegroup.OptimalHashRate
              color1 = 'orange'
              setFillColor1(color1)
            }
            if (dgInfo.devicegroup.hasOwnProperty('currentmaxRate')) {
              maxRate2 = dgInfo.devicegroup.currentmaxRate
              color2 = 'green'
              setFillColor2(color2)
            }
            if (maxRate1 === maxRate2) {
              color1 = 'green'
              color2 = 'green'
              setFillColor1(color1)
              setFillColor2(color1)
            }
            if (maxRate2 <= constants.AT1500_MAXTHS) {
              maxRate3 = maxRate2
              maxRate4 = maxRate2
              color3 = 'green'
              color4 = 'green'
              setFillColor2(color3)
            } else if (maxRate2 > constants.AT1500_MAXTHS && maxRate2 <= constants.AI2500_MAXTHS) {
              maxRate3 = constants.AT1500_MAXTHS
              maxRate4 = maxRate2
              color3 = 'blue'
              color4 = 'green'
              setFillColor2(color4)
            } else if (maxRate2 > constants.AI2500_MAXTHS) {
              maxRate3 = constants.AT1500_MAXTHS
              maxRate4 = constants.AI2500_MAXTHS
              color3 = 'blue'
              color4 = 'purple'
            }
            if (maxRate3 !== maxRate2 || maxRate4 !== maxRate2) {
              setshowOtherLines(true)
            }

            const titleText = ''
            const layout = {
              title: {
                text: titleText,
                font: { size: 16 },
                x: 0.5, 
                y: 1.0,
                xref: 'paper',
                yref: 'paper',
              },
              xaxis: { title: 'Hash Rate (TH/s) Per Miner' },
              yaxis: { title: 'Profit Per Miner ' },
              shapes: [
                {
                  type: 'line',
                  x0: maxRate1,
                  x1: maxRate1,
                  y0: 0,
                  y1: 1,
                  xref: 'x',
                  yref: 'paper',
                  line: { color: color1, width: 2, dash: 'dash', smoothing: 0.5 },
                },
                {
                  type: 'line',
                  x0: maxRate2,
                  x1: maxRate2,
                  y0: 0,
                  y1: 1,
                  xref: 'x',
                  yref: 'paper',
                  line: { color: color2, width: 2, dash: 'dash', smoothing: 0.5 },
                },
                {
                  type: 'line',
                  x0: maxRate3,
                  x1: maxRate3,
                  y0: 0,
                  y1: 1,
                  xref: 'x',
                  yref: 'paper',
                  line: { color: color3, width: 2, dash: 'dash', smoothing: 0.5 }, // Specify color for maxRate3
                },
                {
                  type: 'line',
                  x0: maxRate4,
                  x1: maxRate4,
                  y0: 0,
                  y1: 1,
                  xref: 'x',
                  yref: 'paper',
                  line: { color: color4, width: 2, dash: 'dash', smoothing: 0.5 }, // Specify color for maxRate4
                },
              ],
            };
            if (chartRef.current) {
              Plotly.newPlot(chartRef.current, profitData, layout);
            }
            }
        }
    }
  };
  
  useEffect(() => {
    const runFn = async () => {
        const token = await getAccessTokenSilently();
        const deviceGroups = await api.GetDGNames(token);
        if (deviceGroups.status === 'error') {
            if (deviceGroups.errorCode === 1007) {
                validUser = 1
                setSubmitMsg('nouser')
                setSuccessModalOpen(true);
            } else {
                validUser = 1
                setSubmitMsg('fetchfailed')
                setSuccessModalOpen(true);
            }
        } else {
          const groupList = Object.keys(deviceGroups)
          var minerGroupList = [];
          for (let i=0; i < groupList.length; i++) {
              minerGroupList.push(groupList[i]);
          }
          setMinerGroups(minerGroupList)
          localStorage.setItem(DASHBOARD_GROUP_LIST, JSON.stringify(minerGroupList));
          validUser = 0
      }
    }
    runFn();
}, [getAccessTokenSilently]);

 //Fetch dgInfo when the component mounts
  useEffect(() => {
    console.log('in useeffect', minerGroup)
    fetchDgInfo(true, minerGroup);
  }, [minerGroup]);

  //Render the chart when inputIsPerGroup or dgInfo changes
  useEffect(() => {
    renderChart();
  }, [inputIsPerGroup, dgInfo, value]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      console.log("Refresh the dashboard", minerGroup)
      fetchDgInfo(false, minerGroup);
      renderChart();
    }, 1200000); // 2 minutes in milleseconds
    // Cleanup the interval on component unmount
    return () => clearInterval(refreshInterval);
  }, [minerGroup]); // Empty dependency array to run only on component mount


const HistoricChart = ({devicegroupInfo, buttonInfo}) => {
  
  const [selectedDuration, setSelectedDuration] = useState(
    localStorage.getItem('selectedDuration') || '1w'
  );
  const [selectedData, setSelectedData] = useState([]);

  // Function to handle changes in selectedDuration
  function handleDurationChange(newDuration) {
    console.log('New Duration is set to ', newDuration)
    setSelectedDuration(newDuration);
    // Store the new selectedDuration value in localStorage
    localStorage.setItem('selectedDuration', newDuration);
  }
  
  useEffect(() => {
    var devicegroupInf = devicegroupInfo
    let historicData = []
    if (devicegroupInf !== '' && (devicegroupInf !== 'undefined') && (devicegroupInf !== null) 
        && devicegroupInf.devicegroup.PerGroupHistoryData && devicegroupInf.devicegroup.PerMinerHistoryData) {
      if (buttonInfo) {
        historicData = devicegroupInf.devicegroup.PerGroupHistoryData
        historicData.sort((a, b) => new Date(a.date) - new Date(b.date));
      } else {
        historicData = devicegroupInf.devicegroup.PerMinerHistoryData
        historicData.sort((a, b) => new Date(a.date) - new Date(b.date));
      }
      const filteredData = historicData.filter((item) => {
        const currentDate = new Date(item.date);
        const endDate = new Date();
        const startDate = new Date();
        switch (selectedDuration) {
          case '1d':
            startDate.setDate(endDate.getDate() - 1);
            break;
          case '1w':
            startDate.setDate(endDate.getDate() - 7);
            break;
          case '1m':
            startDate.setMonth(endDate.getMonth() - 1);
            break;
          case '1yr':
            startDate.setFullYear(endDate.getFullYear() - 1);
            break;
          default:
            break;
        }
        return currentDate >= startDate && currentDate <= endDate;
      });
      setSelectedData(filteredData);
    }
  }, [selectedDuration]);

  useEffect(() => {
    const hashRatesByDate = {};
  
    selectedData.forEach((item) => {
      const date = new Date(item.date).toISOString();
      
      if (!hashRatesByDate[date]) {
        hashRatesByDate[date] = [];
      }
      hashRatesByDate[date].push({
        TuningMode: item.TuningMode,
        hashrate: item.hashrate,
        TunedBy: item.TunedBy,
        PowerConstraintMax: item.PowerConstraintMax
      });
    });
  
    const hashRateTraces = Object.entries(hashRatesByDate).map(([date, data]) => ({
      x: data.map((item) => new Date(date)), // X-values as timestamps
      y: data.map((item) => item.hashrate),  // Y-values as hashrates
      type: 'scatter',
      mode: 'lines+markers',
      text: data.map((item) => {
        if (item.TunedBy === 'Power') {
          return `${item.TuningMode} | ${item.TunedBy} | ${item.hashrate} | ${item.PowerConstraintMax}`;
        } else {
          return `${item.TuningMode} | ${item.TunedBy} | ${item.hashrate}`;
        }
      }),
      hoverinfo: 'text',
      line: { shape: 'spline' },
    }));
      
    const data = [...hashRateTraces];
    const rangeLow = constants.MINTHS;
    const rangeHigh = constants.MAXTHS
      
    const chartLayout = {
      title: 'Historic Data for HashRate by Tuning Mode',
      xaxis: {
        title: 'Date',
        tickmode: 'array',
        //tickvals: data.flatMap(trace => trace.x), // Use flatMap to combine x-values of all traces
        ticktext: data.flatMap(trace => trace.x.map(date => date.toLocaleString())),
      },
      yaxis: {
        title: 'HashRate',
        range: [rangeLow, rangeHigh],
        side: 'left',
        showgrid: false,
      },
      showlegend: false,
    };
      
    Plotly.newPlot('chart-container', data, chartLayout);
  }, [selectedData]);
  
  

  return (
    <div>
      <br/><br/>
      <div className="duration-buttons">
        <button
          onClick={() => handleDurationChange('1d')}
          className={selectedDuration === '1d' ? 'active' : ''}
        >
          1D
        </button>
        <button
          onClick={() => handleDurationChange('1w')}
          className={selectedDuration === '1w' ? 'active' : ''}
        >
          1W
        </button>
        <button
          onClick={() => handleDurationChange('1m')}
          className={selectedDuration === '1m' ? 'active' : ''}
        >
          1M
        </button>
        <button
          onClick={() => handleDurationChange('1yr')}
          className={selectedDuration === '1yr' ? 'active' : ''}
        >
          1YR
        </button>
      </div>
      <div id="chart-container" className="chart-container" style={{ width: 1000,}} />
    </div>
  );
  
};

const exportToCSV = (minerGroup) => {
  var csvData = []
  if (inputIsPerGroup) {
    if ((dgInfo !== '') && (dgInfo.devicegroup) && (dgInfo.devicegroup.PerGroupHistoryData) && 
        (dgInfo.devicegroup.PerGroupHistoryData.length>0)) {
        csvData = dgInfo.devicegroup.PerGroupHistoryData.map((item) => ({
            MinerGroup: minerGroup,
            Date: item.date,
            TuningMode: item.TuningMode,
            TunedBy: item.TunedBy,
            PowerConstraintMax: item.PowerConstraintMax,

            TotalMiners: item.TotalDevices,
    
            Profit: item.profit,
    
            Hashrate: item.hashrate,
            Efficiency:item.bestEfficiency,
    
            BTCPriceinUSD:item.bitcoinPrice,
            BlockReward:item.blockreward,
            BTCDifficulty:item.btcDifficulty,
    
            EnergyPrice:item.energyPrice,
            estimatedPoolFee:item.estimatedPoolFee,
            ElectricityCost:item.electricityCost,
            Revenue:item.maxRevenue,
          }));
      csvData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
    }
  } else {
    if ((dgInfo !== '') && (dgInfo.devicegroup) && (dgInfo.devicegroup.PerMinerHistoryData) && 
        (dgInfo.devicegroup.PerMinerHistoryData.length>0)) {
          csvData = dgInfo.devicegroup.PerMinerHistoryData.map((item) => ({
            MinerGroup: minerGroup,
            Date: item.date,
            TuningMode: item.TuningMode,
            TunedBy: item.TunedBy,
            PowerConstraintMax: item.PowerConstraintMax,
            TotalMiners: item.TotalDevices,
    
            Profit: item.profit,
    
            Hashrate: item.hashrate,
            Efficiency:item.bestEfficiency,
    
            BTCPriceinUSD:item.bitcoinPrice,
            BlockReward:item.blockreward,
            BTCDifficulty:item.btcDifficulty,
    
            EnergyPrice:item.energyPrice,
            estimatedPoolFee:item.estimatedPoolFee,
            ElectricityCost:item.electricityCost,
            Revenue:item.maxRevenue,
        }));
      csvData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
    }
  }

  // Define the CSV headers
  const headers = [
    { label: 'Miner Group', key: 'MinerGroup'},
    { label: 'Date', key: 'Date' },
    { label: 'Tuning Mode', key: 'TuningMode' },
    { label: 'TunedBy', key: 'TunedBy' },
    { label: 'PowerConstraintMax', key: 'PowerConstraintMax' },
    { label: 'TotalMiners', key: 'TotalMiners' },
    { label: 'Profit', key: 'Profit' },
    { label: 'Hashrate', key: 'Hashrate' },
    { label: 'Efficiency', key: 'Efficiency' },
    { label: 'BTCPriceinUSD', key: 'BTCPriceinUSD' },
    { label: 'BlockReward', key: 'BlockReward' },
    { label: 'BTCDifficulty', key: 'BTCDifficulty' },
    { label: 'EnergyPrice', key: 'EnergyPrice' },
    { label: 'estimatedPoolFee', key: 'estimatedPoolFee' },
    { label: 'ElectricityCost', key: 'ElectricityCost' },
    { label: 'Revenue', key: 'Revenue' },
  ];

  if (dgInfo !== '' && (dgInfo.devicegroup)) {
    return (
        <CSVLink
          data={csvData}
          headers={headers}
          filename="historic_data.csv"
          className="csv-link"
        >
          Export to CSV
      </CSVLink>
    );
  }
};

  // const handleinputIsPerGroup = (event) => {
  //   setinputIsPerGroup(!inputIsPerGroup);
  //   if (chartRef.current) {
  //     Plotly.purge(chartRef.current);
  //   }
  // };

  // Function to handle changes in the radio button selection
  function handleinputIsPerGroupChange(event) {
    const newValue = event.target.value === 'true';
    console.log('New Radion button is set to ', newValue ? 'group' : 'miner')
    setinputIsPerGroup(newValue);
    // Store the new selected value in localStorage
    localStorage.setItem('inputIsPerGroup', newValue.toString());
    
    // Add your chart plotting logic here
    if (chartRef.current) {
      Plotly.purge(chartRef.current);
      // Plot your chart with the updated inputIsPerGroup value
    }
  }

  const DownArrowIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 20 20">
        <path d="M0 0L10 15L20 0H0Z" fill="blue" />
        </svg>
    )
  }

  const NegativeIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20">
        <rect x="4" y="8" width="12" height="4" fill="#333" />
      </svg>
    );
  }
  const EqualIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20">
        <rect x="2" y="8" width="16" height="4" fill="green" />
        <rect x="2" y="13" width="16" height="4" fill="green" />
      </svg>
    );
  }


  const handleMinerGroup = async(value) => {
    setMinerGroup(value);
    localStorage.setItem(DASHBOARD_GROUP_NAME, value);
  }

  if (validUser === 0) {
  return (
    <PageContainer title="Dashboard" description="this is a Dashboard for Maximum HashRate/Profit">
      {/* {showBanner && (
        <Box sx={{ mt: 2, backgroundColor: 'white', color: 'gray', padding: '5px', display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" style={{ color: 'gray', fontWeight: 'bolder'}} className="blinking-text">Note: </Typography>
          <Typography variant="body1" style={{ marginLeft: '10px', color: 'gray' }}>Miner performance may differ from tuning parameters due to Power Constraints</Typography>
          <IconButton size="small" color="inherit" onClick={handleBannerClose} sx={{ ml: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </Box>
      )} */}
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px', textAlign: 'left' }}>
            <span style={{ marginRight: '10px' }}>Select a Miner Group:</span>
            <Autocomplete
              disablePortal
              onChange={(event, value) => handleMinerGroup(value)}
              sx = {{
              width: '200px',
              }}
              disableClearable
              id="mode"
              value = {minerGroup}
              options={minerGroups}
              getOptionLabel={(option) =>
                option === 'Teraflux Group' ? 'Default Group' : option
              }
              renderOption={(props, option, { selected }) => (
              <li {...props}>
                    {option === 'Teraflux Group' ? 'Default Group' : option}
              </li>
              )}
              getOptionSelected={(option, value) => option === value}
              renderInput={(params) => (
              <TextField {...params} size="small"  aria-label="Select mode" />
              )}
            />
        </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleTabChange} aria-label="Profile">
          <Tab sx = {{textTransform: "none", width:'300px'}} label="AutoTune Dashboard" {...a11yProps(0)} />
          <Tab sx = {{textTransform: "none", width:'300px'}} label="Tuning History" {...a11yProps(1)} />
        </Tabs>
      </Box>
    <TabPanel value={value} index={0} disabled={!isTabEnabled}>
      {minerGroup !== '' && (
          <div className="toggle-container">
              <span className="toggle-text1">Miner Count: {TotalMiners}</span>
              <span className="toggle-text2">Tuning Mode: {TuningMode}({TunedBy})</span>
              <span className="toggle-text1">Last Tune Done At: {LastTunedAt}</span>
              <span className="toggle-text2">Last Tuned Operation Status: {LastTunedJobStatus}</span>
              {NextTunedAt !== '' && <span className="toggle-text1">Next AutoTune At: {NextTunedAt}</span>}
              {PowerConstraintMax !== '' && TunedBy === 'Power' && <span className="toggle-text1">Power(W): {PowerConstraintMax}</span>}
           </div>
      )}
      <br/><br/>
      <Grid container spacing={1} sx={{ }}>
            <Grid item>
              <InfoBlock title = {"BTC Price"} info = {bitcoinPrice || 0.0} color = {'#cfb53b'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>
            <Grid item>
              <InfoBlock title = {"Block Reward"} info = {blockReward || 0.0} color = {'purple'} unit = {'BTC'} orientation = {"right"} fontsize={'5px'}/>
            </Grid>
            <Grid item>
              <InfoBlock title = {"BTC Difficulty"} info = {btcDiff || 0} color = {'magenta'} unit = {'T'} orientation = {"right"} fontsize={'5px'}/>
            </Grid>
            <Grid item>
              <InfoBlock title = {"Energy Price"} info = {EnergyPrice || 0} color = {'green'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>      
      </Grid>
      <br/>
      <div className="toggle-container-left">
        <Typography variant="h7" component="div" sx={{ ml: '10px', mr: '5px' }}>
        <span className="toggle-text1">Per Miner </span>
        </Typography>
        <label className={`radio-button ${!inputIsPerGroup ? 'active' : ''}`}>
          <input
            type="radio"
            value="false"
            name="hashRateOption"
            checked={!inputIsPerGroup}
            onChange={handleinputIsPerGroupChange}
          />
          <div className="radio-button-indicator"></div>
        </label>
        <Typography variant="h7" component="div" sx={{ mr: '5px' }}>
        <span className="toggle-text1">All Miners </span>
        </Typography>
        <label className={`radio-button ${inputIsPerGroup ? 'active' : ''}`}>
          <input
            type="radio"
            value="true"
            name="hashRateOption"
            checked={inputIsPerGroup}
            onChange={handleinputIsPerGroupChange}
          />
          <div className="radio-button-indicator"></div>
        </label>
      </div>
      <br/>
      <div style={{ display: 'flex', 'alignitems': 'flex-start', width: 1300}}>
      {inputIsPerGroup === true && (
       <div id="chartContainer" ref={chartRef} style={{ width: 1500,}}/>
      )}
      {inputIsPerGroup === false && (
       <div id="chartContainer" ref={chartRef} style={{ width: 1500,}}/>
      )}
      {inputIsPerGroup === false && (
        <Grid container spacing={1} sx={{ }}>
            <Grid item>
              <Typography variant="h8" sx={{ mt: -8}}>Target Configuration</Typography>
              <InfoBlock title = {"Target THs and J/TH"} info = {(currentThs ? currentThs : 0.0)+ " and " + (currentEff ? currentEff:0) } color = {'blue'} unit = {''} orientation = {"left"}/>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><DownArrowIcon /></Box>
              <InfoBlock title = {"Revenue"} info = {currentRev || 0.0} color = {'darkorchid'} unit = {'$'} orientation = {"left"} fontsize={'5px'} />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><NegativeIcon/></Box>
              <InfoBlock title = {"Pool and Electricity Cost"} info = {'2%' + ' BTC and $' + currentElec} color = {'red'} unit = {''} orientation = {"left"} fontsize={'5px'}/>
              <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><EqualIcon/></Box>
              </Grid>
              <InfoBlock title = {"Max Profit"} info = {currentMProfit || 0.0} color = {'blue'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>
            <Grid item>
              <Typography variant="h8" sx = {{mt: -8}}>Optimal Configuration</Typography>
              <InfoBlock title = {"Optimal THs and J/TH"} info = {(optimalThs ? optimalThs : 0.0)+ " and " + (optimalEff ?optimalEff:0) } color = {'blue'} unit = {''} orientation = {"left"}/>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><DownArrowIcon /></Box>
              <InfoBlock title="Revenue" info={optimalRev || 0.0} color="darkorchid" unit="$" orientation="left" fontsize="5px"/>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><NegativeIcon/></Box>
              <InfoBlock title = {"Pool and Electricity Cost"} info = {'2%' + ' BTC and $' + optimalElec} color = {'red'} unit = {''} orientation = {"left"} fontsize={'5px'}/>
              <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><EqualIcon/></Box>
              </Grid>
              <InfoBlock title = {"Max Profit"} info = {optimalMProfit || 0.0} color = {'blue'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>

        </Grid>
      )}
      {inputIsPerGroup === true  && (
        <Grid container spacing={1} sx={{ mt: '0' }}>
            <Grid item>
            <Typography variant="h8" sx={{ mt: -8}}>Target Configuration</Typography>
              <InfoBlock title = {"Target THs and J/TH"} info = {(currentThs ? (TotalMiners * currentThs).toFixed(2) : 0.0)+ " and " + (currentEff ? (1 * AverageEfficiency).toFixed(2):0) } color = {'blue'} unit = {''} orientation = {"left"}/>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><DownArrowIcon /></Box>
              <InfoBlock title = {"Revenue"} info = {aggCurrentRev || 0.0} color = {'darkorchid'} unit = {'$'} orientation = {"left"} fontsize={'5px'} />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><NegativeIcon/></Box>
              <InfoBlock title = {"Pool and Electricity Cost"} info = {'2%' + ' BTC and $' + aggCurrentElec} color = {'red'} unit = {''} orientation = {"left"} fontsize={'5px'}/>
              <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><EqualIcon/></Box>
              </Grid>
              <InfoBlock title = {"Max Profit"} info = {aggCurrentMProfit || 0.0} color = {'blue'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>
            <Grid item>
              <Typography variant="h8" sx = {{mt: -8}}>Optimal Configuration</Typography>
              <InfoBlock title = {"Optimal THs and J/TH"} info = {(optimalThs ? (TotalMiners * optimalThs).toFixed(2) : 0.0)+ " and " + (optimalEff ? (1 * OptAverageEfficiency).toFixed(2):0) } color = {'blue'} unit = {''} orientation = {"left"}/>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><DownArrowIcon /></Box>
              <InfoBlock title = {"Revenue"} info = {aggOptimalRev || 0.0} color = {'darkorchid'} unit = {'$'} orientation = {"left"} fontsize={'5px'} />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><NegativeIcon/></Box>
              <InfoBlock title = {"Pool and Electricity Cost"} info = {'2%' + ' BTC and $' + aggOptimalElec} color = {'red'} unit = {''} orientation = {"left"} fontsize={'5px'}/>
              <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><EqualIcon/></Box>
              </Grid>
              <InfoBlock title = {"Max Profit"} info = {aggOptimalMProfit || 0} color = {'blue'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>
        </Grid>
      )}
      </div>
      { showTgtLine && (
          <div style={{ display: 'flex', justifyContent: 'left' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="220" height="20" >
              <text x="10" y="15" fill={fillColor2} fontSize="12">
                ----
              <tspan fill='black' fontSize="10"> Applied THs</tspan>
            </text>
          </svg>
          </div>
      )}
      { showOptLine && (
          <div style={{ display: 'flex', justifyContent: 'left' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="220" height="20">
              <text x="10" y="15" fill={fillColor1} fontSize="12">
                ----
              <tspan fill='black' fontSize="10"> Optimal THs</tspan>
            </text>
          </svg>
          </div>
      )}
      { showOtherLines && (
          <div style={{ display: 'flex', justifyContent: 'left' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="630" height="20">
            <text x="10" y="15" fill="black" fontSize="10">
                Other colored vertical line(s) indicates that some of the miner models maximum THs is less than the THs shown in the Target Configuration 
            </text>
          </svg>
          </div>
      )}
      {/* { showOtherLines && (
          <div style={{ display: 'flex', justifyContent: 'left' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="630" height="20">
            <text x="10" y="15" fill="black" fontSize="10">
                A few miners are tuned to the Max THs as per their specifications 
            </text>
          </svg>
          </div>
      )} */}
      <div style={{ display: 'flex', justifyContent: 'left' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="320" height="20">
          <text x="10" y="15" fill="black" fontSize="12">
          Note: 
          <tspan fill='black' fontSize="10"> Estimated Revenue, Cost and Profit calculations are per day</tspan>
        </text>
      </svg>
      </div>
      <br/>
    </TabPanel>
    <br/>
    <div style={{ textAlign: 'center' }}>
    <br/>
    <TabPanel value={value} index={1} disabled={!isTabEnabled}>
       <br/>
       {minerGroup !== '' && (
          <div className="toggle-container">
              <span className="toggle-text1">Miner Count: {TotalMiners}</span>
              <span className="toggle-text2">Tuning Mode: {TuningMode}({TunedBy})</span>
              <span className="toggle-text1">Last Tune Done At: {LastTunedAt}</span>
              <span className="toggle-text2">Last Tuned Operation Status: {LastTunedJobStatus}</span>
              {NextTunedAt !== '' && <span className="toggle-text1">Next AutoTune At: {NextTunedAt}</span>}
              {PowerConstraintMax !== '' && TunedBy === 'Power' && <span className="toggle-text1">Power(W): {PowerConstraintMax}</span>}
          </div>
      )}
      <br/>
      <div className="toggle-container">
        <Typography variant="h7" component="div" sx={{ ml: '10px', mr: '5px' }}>
        <span className="toggle-text1">Per Miner </span>
        </Typography>
        <label className={`radio-button ${!inputIsPerGroup ? 'active' : ''}`}>
          <input
            type="radio"
            value="false"
            name="hashRateOption"
            checked={!inputIsPerGroup}
            onChange={handleinputIsPerGroupChange}
          />
          <div className="radio-button-indicator"></div>
        </label>
        <Typography variant="h7" component="div" sx={{ mr: '5px' }}>
        <span className="toggle-text1">All Miners </span>
        </Typography>
        <label className={`radio-button ${inputIsPerGroup ? 'active' : ''}`}>
          <input
            type="radio"
            value="true"
            name="hashRateOption"
            checked={inputIsPerGroup}
            onChange={handleinputIsPerGroupChange}
          />
          <div className="radio-button-indicator"></div>
        </label>
      </div>
        <div>
          <div><HistoricChart devicegroupInfo = {dgInfo} buttonInfo = {inputIsPerGroup} /></div>
        </div>
        <br/>
        <div>
        <div>{exportToCSV(minerGroup)}</div>
        </div>
    </TabPanel>
    </div>
    <Footer />

    </PageContainer>
  );
  } else if (validUser !== -1 && validUser !== 0) {
    return(
    <div>
    <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg}/>
    </div>);
  }
};

export default withAuthenticationRequired(Dashboard, {
  onRedirecting: () => <Loading />,
});
