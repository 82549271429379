import React from "react";
import { Box, Stack, Typography } from '@mui/material';
import Command from "./command";
import PaperWrap from "../components/paper-wrap";
import Footer from '../components/footer';

export const ZTPConfig = () => {

  console.log('ztp config ...')

  return (
    <Box>
      <Stack direction="row">
        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }} >ZTP Config</Typography>
      </Stack>
      <Box sx={{ mt: 2 }}>
        <PaperWrap><Command label="Enable ZTP" name="ztp" /></PaperWrap>
      </Box>
      <Box sx={{ mt: 2 }}>
        <PaperWrap><Command label="Enable WS" name="ws" interval={false} /></PaperWrap>
      </Box>
      <Box sx={{ mt: 2 }}>
        <PaperWrap><Command label="IP Report" name="ipreport" /></PaperWrap>
      </Box>
      <Footer />
    </Box>
  );
};

export default ZTPConfig;
