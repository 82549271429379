import * as api from '../api/api'

const AppCfgDefault = {
    "ztp": {
        "enabled": true,
        "interval": 60
    },
    "ws": {
        "enabled": true,
        "interval": 60
    },
    "summary": {
        "enabled": true,
        "interval": 60
    },
    "mode": {
        "enabled": true,
        "interval": 60
    },
    "led": {
        "enabled": true,
        "interval": 60
    },
    "ipreport": {
        "enabled": true,
        "interval": 120
    },
    "gettimedate": {
        "enabled": true,
        "interval": 120
    },
    "telemetry": {
        "enabled": true,
        "interval": 180
    },
    "pools": {
        "enabled": true,
        "interval": 300
    },
    "stats": {
        "enabled": true,
        "interval": 300
    },
    "lcd": {
        "enabled": true,
        "interval": 300
    },
    "fan": {
        "enabled": true,
        "interval": 300
    },
    "temperature": {
        "enabled": true,
        "interval": 300
    },
    "psu": {
        "enabled": true,
        "interval": 300
    },
    "frequency": {
        "enabled": true,
        "interval": 300
    },
    "voltage": {
        "enabled": true,
        "interval": 300
    },
    "accept-remote-tuning": {
        "enabled": true,
        "interval": 300
    },
    "version": {
        "enabled": true,
        "interval": 3600
    },
    "config": {
        "enabled": true,
        "interval": 3600
    },
    "devs": {
        "enabled": true,
        "interval": 3600
    },
    "coin": {
        "enabled": true,
        "interval": 3600
    },
    "devdetails": {
        "enabled": true,
        "interval": 3600
    },
    "poolCfg": [
      {
        "url": "",
        "user": "",
        "pass": ""
      },
      {
        "url": "",
        "user": "",
        "pass": ""
      },
      {
        "url": "",
        "user": "",
        "pass": ""
      }
    ]
}

// console.log({ AppCfgDefault: AppCfgDefault })

/* synced with DB */
let AppCfg0 = {}

/* current copy */
let AppCfg = {}

const getPool = (index) => {
    if (index > 2 || index < 0) return null;
    if (!AppCfg || !AppCfg.poolCfg || !AppCfg.poolCfg[index]) return null;
    return AppCfg.poolCfg[index];
};

const setPool = (index, value) => {
    if (index > 2 || index < 0) return false;

    console.log('in cfg setPool', index, value)
    // const ts = new Date()
    // value['updatedAt'] = ts
    if (!AppCfg.poolCfg) {
        AppCfg.poolCfg = [{}, {}, {}]
    }
    AppCfg.poolCfg[index] = value;
    console.log('in cfg setPool', { poolCfg: AppCfg.poolCfg })

    return true;
};

const setPoolConfig = (index, type, value) => {
    if (index > 2 || index < 0) return false;

    if (type === 1)
    AppCfg.poolCfg[index].url = value

    if (type === 2)
    AppCfg.poolCfg[index].user = value

    if (type === 3)
    AppCfg.poolCfg[index].pass = value

    console.log('in cfg setPool', { poolCfg: AppCfg.poolCfg })

    return true;
};

const drCommands = [
    'ztp',
    'ws',
    'ping',
    'ipreport',
    'version',
    'config',
    'devs',
    'summary',
    'pools',
    'coin',
    'devdetails',
    'stats',
    'lcd',
    'fan',
    'temperature',
    'mode',
    'psu',
    'led',
    'telemetry',
    'techsupport2'
];
const isValidCommand = (cmd) => {
    return drCommands.includes(cmd)
}
const getCmd = (name) => {
    if (!isValidCommand(name)) return null

    console.log('getCmd ... ' + name)

    return AppCfg[name] ?? AppCfgDefault[name];
};
const setCmd = (name, value) => {
    console.log('setCmd ...')
    console.log({ name: name, value: value })

    if (!isValidCommand(name)) return false

    if (name === 'ping') return false

    // const ts = new Date()
    // value['updatedAt'] = ts
    AppCfg[name] = value

    console.log({ [name]: AppCfg[name] })

    return true;
};
const getCfg0 = () => {
    return AppCfg0
}
const getCfg = () => {
    return AppCfg
}
const loadCfg = async (cfg0) => {
    // ztp is the default name of the configuration
    // it can be per miner group if needed
    // console.log("loadCfg Input here:: ", cfg0)
    if (cfg0.ztp) {
        console.log('get the config from argument ...')
        AppCfg0 = JSON.parse(JSON.stringify(cfg0.ztp))
    } else {
        // AppCfgDefault["ztp"] = {
        //     enabled: true
        // }
        console.log('AppCfgDefault ...')
        console.log(AppCfgDefault)
        // deep copy object
        AppCfg0 = JSON.parse(JSON.stringify(AppCfgDefault));
    }
    // console.log('load', AppCfg0)
    // deep copy object
    AppCfg = JSON.parse(JSON.stringify(AppCfg0));

}
const defaultCfg = () => {
    AppCfg = JSON.parse(JSON.stringify(AppCfgDefault));
}

const revertCfg = () => {
    AppCfg = JSON.parse(JSON.stringify(AppCfg0));
}
const saveCfg = async (token, minerGroup) => {
    const org = {
        config: {
            ztp: AppCfg
        }
    }
    console.log('saving cfg ...', org )
    if (minerGroup === 'Teraflux Group') {
        const result = await api.PostOrg(token, org)
        console.log('Post Org Result', JSON.stringify(result))
        if (result.status === 'ok') {
            await loadCfg({ ztp: AppCfg })
            return 0
        } else {
            if (result.hasOwnProperty('errorCode') && (result.errorCode === 1009)) {
                console.log(result.msg)
                return 1
            }
            console.log("Failed to update the Org with Mining pool configuration", result.msg)
            return 2
        }
    }
    if (minerGroup !== undefined) {
        const result = await api.PostDGOrgConfig(token, minerGroup, org.config)
        console.log('PostDGOrgConfig Result', JSON.stringify(result))
        if (result.status === 'ok') {
            await loadCfg({ ztp: AppCfg })
            return 0
        } else {
            if (result.hasOwnProperty('errorCode') && (result.errorCode === 1009)) {
                console.log(result.msg)
                return 1
            }
            console.log("Failed to update the DG with Mining pool configuration", result.msg)
            return 2
        }
    }
}

const saveMultiCfg = async (token, minerGroups) => {
    const org = {
        config: {
            ztp: AppCfg
        }
    }
    console.log('saving multi cfg ...', org )
    if (minerGroups.includes('Teraflux Group')) {
        const result = await api.PostOrg(token, org)
        console.log('Post Org Result', JSON.stringify(result))
        if (result.status === 'ok') {
            await loadCfg({ ztp: AppCfg })
            return 0
        } else {
            if (result.hasOwnProperty('errorCode') && (result.errorCode === 1009)) {
                console.log(result.msg)
                return 1
            }
            console.log("Failed to update the Org with Mining pool configuration", result.msg)
            return 2
        }
    }
    if (minerGroups !== undefined) {
        const result = await api.PostMultiDGOrgConfig(token, minerGroups, org.config)
        console.log('PostMultiDGOrgConfig Result', JSON.stringify(result))
        if (result.status === 'ok') {
            await loadCfg({ ztp: AppCfg })
            return 0
        } else {
            if (result.hasOwnProperty('errorCode') && (result.errorCode === 1009)) {
                console.log(result.msg)
                return 1
            }
            console.log("Failed to update the DG with Mining pool configuration", result.msg)
            return 2
        }
    }
}

export {
    getPool,
    setPool,
    getCmd,
    setCmd,
    getCfg0,
    getCfg,
    loadCfg,
    revertCfg,
    saveCfg,
    saveMultiCfg,
    defaultCfg,
    setPoolConfig
};
