import { env } from "../env";

const GetOrg = async (token, callback) => {
  try {
    const response = await fetch(env.APIPath.org, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "ok") {
      if (callback) {
        callback(data.org);
      }
      //console.log(JSON.stringify(data.org))
    } else {
      if (callback) {
        callback(data);
      }
      console.log("error", data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const PostOrg = async (token, org) => {
  try {
    // console.log(org);
    const response = await fetch(env.APIPath.org, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        org: org,
      }),
    });
    const data = await response.json();
    // console.log('Post Org', JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const PostOrgTechsupport = async (token, org) => {
  try {
    // console.log(org);
    const response = await fetch(env.APIPath.orgtechsupport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        org: org,
      }),
    });
    const data = await response.json();
    // console.log('Post Org', JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const GetDGOrgConfig = async (token, dgname) => {
  // console.log("GetDGOrgConfig dgname", dgname)
  try {
    console.log(dgname);
    const response = await fetch(env.APIPath.getdgorgconfig, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        dgname: dgname,
      }),
    });
    const data = await response.json();
    // console.log('GetDGOrgConfig get DG Org config', dgname, data);
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const PostDGOrgConfig = async (token, dgname, config) => {
  try {
    // console.log(dgname);
    const response = await fetch(env.APIPath.postdgorgconfig, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        dgname: dgname,
        config: config,
      }),
    });
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const PostMultiDGOrgConfig = async (token, dglist, config) => {
  try {
    // console.log(dglist);
    const response = await fetch(env.APIPath.postmultidgorgconfig, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        dglist: dglist,
        config: config,
      }),
    });
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const GetUser = async (token, callback) => {
  try {
    const response = await fetch(env.APIPath.user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "ok") {
      if (callback) {
        callback(data.user);
      }
    } else {
      if (callback) {
        callback(data);
      }
    }
  } catch (error) {
    console.log(error.message);
  }
};

const GetUserUseQuery = async (token) => {
  try {
    const response = await fetch(env.APIPath.user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      // You could optionally throw an error here
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("Data fetched:", data);

    if (data.status === "ok") {
      return data; // Return user data
    } else {
      // You could throw an error here or return an empty object
      throw new Error(data.message || "Failed to fetch user info");
    }
  } catch (error) {
    console.error("Error:", error.message);
    // Optionally, return some default value or throw the error
    throw error; // React Query will handle the error state
  }
};

const GetUserTechsupport = async (token, callback) => {
  try {
    const response = await fetch(env.APIPath.usertechsupport, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "ok") {
      if (callback) {
        callback(data.user);
      }
    } else {
      if (callback) {
        callback(data);
      }
      // console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const GetUserTechsupportFilter = async (token, callback) => {
  try {
    const response = await fetch(env.APIPath.usertechsupportfilter, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "ok") {
      if (callback) {
        callback(data.user);
      }
    } else {
      if (callback) {
        callback(data);
      }
      // console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const GetJobs = async (token, callback) => {
  try {
    const response = await fetch(env.APIPath.jobs, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "ok") {
      if (callback) {
        callback(data.job);
      }
    } else {
      console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const PostUser = async (token, user) => {
  try {
    // console.log('in PostUser', user);
    const response = await fetch(env.APIPath.user, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ user: user }),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data);
    } else {
      console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const PostUserPreference = async (token, userPreference) => {
  try {
    // console.log('in PostUserPreference', userPreference);
    const response = await fetch(env.APIPath.preference, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ user: userPreference }),
    });
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log("catch error:", error.message);
  }
};

const PostAPIKey = async (token, user) => {
  try {
    const response = await fetch(env.APIPath.handleAPIKey, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ user: user }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const PostBTC = async (token, btcdata) => {
  try {
    const response = await fetch(env.APIPath.btc, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(btcdata),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data)
      return data;
    } else {
      console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const AddDG = async (token, dgdata) => {
  try {
    const response = await fetch(env.APIPath.addDG, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dgdata),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log('add dg', data)
      return data;
    } else {
      console.log("add dg error", data);
      return data;
    }
  } catch (error) {
    console.log(" add dg catch error", error.message);
  }
};

const DeleteDG = async (token, dgdata) => {
  try {
    const response = await fetch(env.APIPath.deleteDG, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dgdata),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log('delete dg', data)
      return data;
    } else {
      console.log("delete dg error", data);
      return data;
    }
  } catch (error) {
    console.log("delete dg catch error", error.message);
  }
};

const AddDGMember = async (token, dgdata) => {
  try {
    const response = await fetch(env.APIPath.addDGmember, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dgdata),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data)
      return data;
    } else {
      console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const DeleteDGMember = async (token, dgdata) => {
  try {
    const response = await fetch(env.APIPath.deleteDGmember, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dgdata),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data)
      return data;
    } else {
      console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const MoveDGMember = async (token, dgdata) => {
  try {
    const response = await fetch(env.APIPath.moveDGmember, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dgdata),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data);
      return data;
    } else {
      return data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const GenerateTechsupport = async (token, request) => {
  try {
    const response = await fetch(env.APIPath.minerTechsupport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(request),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data);
      return data;
    } else {
      console.log(data);
      return data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const GetTSJobStatus = async (token, request) => {
  try {
    const response = await fetch(env.APIPath.getTSJobStatus, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(request),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data);
      return data;
    } else {
      console.log(data);
      return data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const GetCommandStatus = async (token, request) => {
  try {
    const response = await fetch(env.APIPath.getCommandStatus, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(request),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data);
      return data;
    } else {
      console.log(data);
      return data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const AddCustomNames = async (token, minernames) => {
  try {
    const response = await fetch(env.APIPath.addCustomNames, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(minernames),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data);
      return data;
    } else {
      return data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const PostManualTune = async (token, tunedata) => {
  try {
    const response = await fetch(env.APIPath.manualEfficiencyTune, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(tunedata),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data)
      return data;
    } else {
      console.log(data);
      return data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const PostPowerCurtail = async (token, pcInfo, op) => {
  // console.log('PostPowerCurtail', pcInfo, op)
  try {
    const response = await fetch(env.APIPath[op], {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pcInfo),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data)
      return data;
    } else {
      console.log(data);
      return data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const DeletePowerCurtail = async (token, pcInfo) => {
  // console.log('PostPowerCurtail', pcInfo)
  try {
    const response = await fetch(env.APIPath.powerCurtailSchedule, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pcInfo),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data)
      return data;
    } else {
      console.log(data);
      return data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const PostOnboard = async (token, onboardData) => {
  try {
    const response = await fetch(env.APIPath.onboard, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(onboardData),
    });
    const data = await response.json();
    if (data.status === "ok") {
      console.log(data);
      return data;
    } else {
      console.log(data);
      return data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const PostImportDevices = async (token, importData) => {
  try {
    const response = await fetch(env.APIPath.importDevices, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(importData),
    });
    const data = await response.json();
    if (data.status === "ok") {
      console.log("PostImportDevices", data);
      return data;
    } else {
      console.log("PostImportDevices", data);
      return data;
    }
  } catch (error) {
    console.log("PostImportDevices", error.message);
  }
};

const PostDeregisterDevices = async (token, importData) => {
  try {
    const response = await fetch(env.APIPath.deregisterDevices, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(importData),
    });
    const data = await response.json();
    if (data.status === "ok") {
      console.log("deregisterDevices", data);
      return data;
    } else {
      console.log("deregisterDevices", data);
      return data;
    }
  } catch (error) {
    console.log("deregisterDevices", error.message);
  }
};

const PostRemoveOrgTechsupport = async (token, info) => {
  try {
    const response = await fetch(env.APIPath.removeOrgTechsupport, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(info),
    });
    const data = await response.json();
    if (data.status === "ok") {
      console.log("PostRemoveOrgTechsupport", data);
      return data;
    } else {
      console.log("PostRemoveOrgTechsupport", data);
      return data;
    }
  } catch (error) {
    console.log("PostRemoveOrgTechsupport", error.message);
  }
};

const PostRemoveDevices = async (token, importData) => {
  try {
    const response = await fetch(env.APIPath.removeDevices, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(importData),
    });
    const data = await response.json();
    if (data.status === "ok") {
      console.log("PostRemoveDevices", data);
      return data;
    } else {
      console.log("PostRemoveDevices", data);
      return data;
    }
  } catch (error) {
    console.log("PostRemoveDevices", error.message);
  }
};

const PostFluxGPT = async (token, body) => {
  try {
    let url = "https://stagingfluxgpt.auradine.gold:443/askFluxGPT";
    const prepareCmd = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ body: JSON.stringify(body) }),
    };
    const response = await fetch(url, prepareCmd);
    const data = await response.json();
    if (data.status === "ok") {
      //console.log(data)
      return data;
    } else {
      console.log("received error from server");
      return data;
    }
  } catch (error) {
    console.log("Fetch from FluxGPT Failed", error.message);
  }
};

const PostAutoTune = async (token, tunedata) => {
  try {
    const response = await fetch(env.APIPath.autotune, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(tunedata),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data)
      return data;
    } else {
      console.log(data);
      return data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const PostDGisTgtHR = async (token, req) => {
  try {
    const response = await fetch(env.APIPath.dgisTgtHR, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(req),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data)
      return data;
    } else {
      console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const PostInvite = async (token, invite) => {
  try {
    const response = await fetch(env.APIPath.invite, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        invite: invite,
      }),
    });
    const data = await response.json();
    if (data.status === "ok") {
      //console.log(data);
    } else {
      console.log(data);
    }
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const PostDomains = async (token, domains) => {
  try {
    const response = await fetch(env.APIPath.domains, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        whitelist: domains,
      }),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data);
    } else {
      console.log(data);
    }
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const MakeAdminOtherUser = async (token, otheruser) => {
  try {
    const response = await fetch(env.APIPath.otheruser, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        otheruser: otheruser,
      }),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data);
    } else {
      console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const DeleteOtherUser = async (token, otheruser) => {
  try {
    const response = await fetch(env.APIPath.otheruser, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        otheruser: otheruser,
      }),
    });
    const data = await response.json();
    if (data.status === "ok") {
      // console.log(data);
    } else {
      console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const GetPrivacy = async (token, callback) => {
  try {
    const response = await fetch(env.APIPath.privacy, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    // console.log(data);
    if (data.status === "ok") {
      if (callback) {
        callback(data.privacy);
      }
    } else {
      console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const PostPrivacy = async (token, privacy) => {
  try {
    const response = await fetch(env.APIPath.privacy, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        privacy: privacy,
      }),
    });
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const SendCommands = async (token, info) => {
  try {
    console.log(env.APIPath.cloudcommand);
    const response = await fetch(env.APIPath.cloudcommand, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        SN: info.serials,
        CMD: info.commands,
        lastTs: info.lastTs,
      }),
    });
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const SendGroupCommands = async (token, info) => {
  try {
    const response = await fetch(env.APIPath.groupcommands, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(info),
    });
    const data = await response.json();
    // console.log(JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const SendAlertNotificationConfig = async (token, info) => {
  try {
    const response = await fetch(env.APIPath.alertNotificationConfig, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(info),
    });
    const data = await response.json();
    // console.log(JSON.stringify(data));
    return data;
  } catch (error) {
    console.log(info, error.message);
  }
  return {
    status: "error",
  };
};

const CheckDatabase = async (token, info) => {
  try {
    const response = await fetch(env.APIPath.checkdatabase, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        SN: info.serials,
        CMD: info.commands,
        lastTs: info.lastTs,
      }),
    });
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const GetValidCommands = async (token) => {
  try {
    const response = await fetch(env.APIPath.validcommands, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "ok") {
      return data.msg;
    } else {
      console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const GetAllDevices = async (token) => {
  try {
    const response = await fetch(env.APIPath.devicelist, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "ok") {
      return data.devicelist;
    } else {
      console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const GetAllDeviceGroups = async (token) => {
  try {
    const response = await fetch(env.APIPath.dglist, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "ok") {
      return data.devicegrouplist;
    } else {
      console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

const IsOperationAllowed = async (token) => {
  try {
    const response = await fetch(env.APIPath.isOperationAllowed, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "ok") {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const IsPoolOperationAllowed = async (token) => {
  try {
    const response = await fetch(env.APIPath.isPoolOperationAllowed, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "ok") {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const GetDGInfo = async (token, info, op) => {
  try {
    const response = await fetch(env.APIPath[op], {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        dgname: info.dgName,
      }),
    });
    const data = await response.json();
    //console.log(data);
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const GetEnergyPrice = async (token, info) => {
  try {
    const response = await fetch(env.APIPath.getEnergyPrice, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ISO: info.ISO,
        priceZone: info.priceZone,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const GetHashPrice = async (token) => {
  try {
    const response = await fetch(env.APIPath.getHashPrice, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const GetDGDevices = async (token, info) => {
  try {
    const response = await fetch(env.APIPath.dgdevices, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        dgname: info.dgName,
      }),
    });
    const data = await response.json();
    //console.log(data);
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const GetOrgDevices = async (token, info) => {
  try {
    const response = await fetch(env.APIPath.orgdevices, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        org_id: info.org_id,
      }),
    });
    const data = await response.json();
    //console.log(data);
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const GetDGView = async (token, info) => {
  try {
    const response = await fetch(env.APIPath.dgview, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "ok") {
      return data.dgview;
    } else {
      console.log(data);
      return {
        status: "error",
      };
    }
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const GetDGNames = async (token) => {
  try {
    const response = await fetch(env.APIPath.dgnames, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "ok") {
      return data.devicegrouplist;
    } else {
      console.log(data);
      return {
        status: "error",
      };
    }
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const PostDGConfig = async (token, info) => {
  try {
    const response = await fetch(env.APIPath.dgconfig, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        dgname: info.dgName,
        config: info.config,
      }),
    });
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const PostMultiDGConfig = async (token, info) => {
  try {
    const response = await fetch(env.APIPath.multidgconfig, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        dglist: info.dglist,
        config: info.config,
      }),
    });
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const PostAutoTuneV2Config = async (token, configInfo, method) => {
  try {
    // console.log("PostAutoTuneV2Config", method);
    if (method === "POST") {
      const response = await fetch(env.APIPath.autoTuneV2Config, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          config_type: configInfo.config_type,
          config_name: configInfo.config_name,
          config: configInfo.config,
        }),
      });
      const data = await response.json();
      // console.log("PostAutoTuneV2Config Post", JSON.stringify(data));
      return data;
    } else if (method === "DELETE") {
      const response = await fetch(env.APIPath.autoTuneV2Config, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          config_type: configInfo.config_type,
          config_name: configInfo.config_name,
        }),
      });
      const data = await response.json();
      // console.log("PostAutoTuneV2Config Delete", JSON.stringify(data));
      return data;
    } else if (method === "GET") {
      const response = await fetch(env.APIPath.getAutoTuneV2Config, {
        method: "POST", //BE it is a post method
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          config_type: configInfo.config_type,
          config_name: configInfo.config_name,
        }),
      });
      const data = await response.json();
      // console.log("GetAutoTuneV2Config Get", JSON.stringify(data));
      return data;
    } else if (method === "GETALL") {
      const response = await fetch(env.APIPath.getAllAutoTuneV2Config, {
        method: "POST", //BE it is a post method
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          config_type: configInfo.config_type,
        }),
      });
      const data = await response.json();
      // console.log("GetAllAutoTuneV2Config Get", JSON.stringify(data));
      return data;
    }
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const PostAttachAutoTuneV2Config = async (token, configInfo, dglist) => {
  try {
    const response = await fetch(env.APIPath.attachAutoTuneV2Config, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        dglist: dglist,
        config_type: configInfo.config_type,
        config_name: configInfo.config_name,
        attach: configInfo.attach,
      }),
    });
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const PostDesiredAlertIntervalCSTeam = async (token, info) => {
  try {
    const response = await fetch(env.APIPath.desiredAlertIntervalCSTeam, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        desiredAlertInterval: info.desiredAlertInterval,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
  return {
    status: "error",
  };
};

const FetchDashboardStatistics = async (queryKey, params) => {
  try {
    const response = await fetch(env.APIPath.summary, {
      ...params,
      body: JSON.stringify(params.body),
    });
    if (!response.ok) {
      throw new Error(`HTTP error ! Status :${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data from API", error.message);
    throw error;
  }
};

const GetAllDeviceGroupsUseQuery = async (queryKey, token) => {
  try {
    const response = await fetch(env.APIPath.dglist, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.status === "ok") {
      return data.devicegrouplist;
    } else {
      console.log(data);
    }
  } catch (error) {
    console.log(error.message);
  }
};

export {
  PostDesiredAlertIntervalCSTeam,
  GetOrg,
  PostAutoTuneV2Config,
  PostAttachAutoTuneV2Config,
  PostOrg,
  PostOrgTechsupport,
  GetUser,
  GetUserUseQuery,
  GetUserTechsupport,
  GetUserTechsupportFilter,
  GetJobs,
  PostUser,
  PostUserPreference,
  PostAPIKey,
  PostBTC,
  PostManualTune,
  PostPowerCurtail,
  DeletePowerCurtail,
  PostOnboard,
  PostImportDevices,
  PostDeregisterDevices,
  PostRemoveOrgTechsupport,
  PostRemoveDevices,
  PostAutoTune,
  PostDGisTgtHR,
  PostInvite,
  MakeAdminOtherUser,
  DeleteOtherUser,
  GetPrivacy,
  PostPrivacy,
  SendCommands,
  SendGroupCommands,
  CheckDatabase,
  GetValidCommands,
  GetAllDevices,
  GetAllDeviceGroups,
  GetDGInfo,
  GetDGDevices,
  GetEnergyPrice,
  GetHashPrice,
  GetOrgDevices,
  PostDGConfig,
  PostMultiDGConfig,
  AddDGMember,
  DeleteDGMember,
  MoveDGMember,
  GenerateTechsupport,
  GetTSJobStatus,
  GetCommandStatus,
  AddDG,
  DeleteDG,
  GetDGOrgConfig,
  PostDGOrgConfig,
  PostMultiDGOrgConfig,
  PostFluxGPT,
  GetDGView,
  GetDGNames,
  IsOperationAllowed,
  IsPoolOperationAllowed,
  AddCustomNames,
  SendAlertNotificationConfig,
  PostDomains,
  FetchDashboardStatistics,
  GetAllDeviceGroupsUseQuery,
};
