
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import { Stack, Box, Typography } from '@mui/material';
import { env } from '../../env';
import { RefreshInterval } from '../../components/refresh-interval';
import { displayTS, DeviceDialog } from './device-dialog'
import { darken, lighten } from '@mui/material/styles';
import Footer from '../../components/footer';
import CloseIcon from '@mui/icons-material/Close';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import PageLoading from '../../components/pageloading';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PageContainer from '../../components/PageContainer';

const DEFAULT_INTERVAL = 60

let validUser = -1
const SuccessModal = ({ isOpen, onClose, Status }) => {
  // console.log('status is', Status)
  let h4Msg = ''
  let pMsg = ''
  switch (Status) {
    case 'ok':
      break;
    case 'nouser':
      h4Msg = "Authentication Failed!"
      pMsg = "User is not part of the Organization. Please contact your Administrator"
      break;
    case 'noauth':
        h4Msg = "Authorization Failed!"
        pMsg = "User doesn't have permission to view this page"
        break;
    case 'fetchfailed':
      h4Msg = "Fetch Failed!"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
    default:
      h4Msg = "Unknown Error"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
  }
  if (Status !== 'ok' && Status !== 'something') {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose} >
        <h4 className="popup-title">{h4Msg}</h4>
        <p className="success-message">{pMsg}</p>
        <div className="button-container">
          <button onClick={onClose}>Close</button>
        </div>
      </Popup>
    );
  }
};

const renderJSONPretty = (params) => {
  if (params.value) {
    const text = JSON.stringify(params.value, null, 4)
    return <DeviceDialog name={params.field} text={text} code={params.value} />
  }
  return ""
}

const initialColVisibility = {
  dgname: true,
  poolconfig: true,
  priceconfig: true,
  demandconfig: true,
  count: true,
  hashing: true,
  aggrTHs: true,
  aggrJTHs: true,
  aggrPower: true,
};

export default function GroupInfo() {
  const [count, setCount] = React.useState(100);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]);

  const [timegap, setTimegap] = React.useState(DEFAULT_INTERVAL);
  const [lastRefresh, setLastRefresh] = React.useState("");

  const [successModalOpen, setSuccessModalOpen] = React.useState(false);
  const [submitMsg, setSubmitMsg] = React.useState('');
  const [colWidths, setColWidths] = React.useState({
    dgname        : 200,
    poolconfig    : 180,
    priceconfig   : 180,
    demandconfig  : 180,
    count         : 100,
    hashing       : 100,
    aggrTHs       : 100,
    aggrJTHs      : 100,
    aggrPower     : 100,
})

const [colVisibilityModel, setColVisibilityModel] = React.useState(() => {
  const storedColVisibility = localStorage.getItem('ViewColVisibility');
  return storedColVisibility ? JSON.parse(storedColVisibility) : initialColVisibility;
});

const [filterModel, setFilterModel] = React.useState(() => {
  const savedFilterModel = localStorage.getItem('ViewfilterModel');
  return savedFilterModel ? JSON.parse(savedFilterModel) : { items: [] };
});

const handleFilterModelChange = (newFilterModel) => {
  setFilterModel(newFilterModel);
  localStorage.setItem('ViewfilterModel', JSON.stringify(newFilterModel));
};

const [sortModel, setSortModel] = React.useState([]);

const columns = [
  { field: 'dgname', headerName: 'Group Name', width: colWidths.dgname, maxWidth: 400, hide:!colVisibilityModel.dgname, renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
  },
  { field: 'poolconfig', headerName: 'Pool Configuration', width: colWidths.poolconfig, maxWidth: 400, renderCell: renderJSONPretty, hide:!colVisibilityModel.poolconfig, filterable: false, sortable: false },
  { field: 'priceconfig', headerName: 'Price Configuration', width: colWidths.priceconfig, maxWidth: 400, renderCell: renderJSONPretty, hide:!colVisibilityModel.priceconfig, filterable: false, sortable: false },
  { field: 'demandconfig', headerName: 'DR Configuration', width: colWidths.demandconfig, maxWidth: 400, renderCell: renderJSONPretty, hide:!colVisibilityModel.demandconfig, filterable: false, sortable: false },
  {
    field: 'count', headerName: 'Total Devices', width: colWidths.count, maxWidth: 400, hide:!colVisibilityModel.count, renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: 'hashing', headerName: 'Miners Hashing', width: colWidths.hashing, maxWidth: 400,  hide:!colVisibilityModel.hashing, renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: 'aggrTHs', headerName: 'Aggr THs', width: colWidths.aggrTHs, maxWidth: 400, hide:!colVisibilityModel.aggrTHs, renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: 'aggrJTHs', headerName: 'Aggr J/THs', width: colWidths.aggrJTHs, maxWidth: 400, hide:!colVisibilityModel.aggrJTHs, renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
  },
  {
    field: 'aggrPower', headerName: 'Aggr Power', width: colWidths.aggrPower, maxWidth: 400, hide:!colVisibilityModel.aggrPower, renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
  },

];

React.useEffect(() => {
  const storedColWidths = localStorage.getItem('ViewColWidths');
  if (storedColWidths) {
    setColWidths(JSON.parse(storedColWidths));
  }
}, []);

React.useEffect(() => {
  const storedColVisibility = localStorage.getItem('ViewColVisibility');
  if (storedColVisibility) {
    setColVisibilityModel(JSON.parse(storedColVisibility));
  }
}, []);


React.useEffect(() => {
  // console.log('colVisibilityModel', colVisibilityModel)
  localStorage.setItem('ViewColVisibility', JSON.stringify(colVisibilityModel));
}, [colVisibilityModel]);


React.useEffect(() => {
  const storedSortModel = localStorage.getItem('ViewSortModel');
  if (storedSortModel) {
    setSortModel(JSON.parse(storedSortModel));
  }
}, []);

React.useEffect(() => {
  localStorage.setItem('ViewColWidths', JSON.stringify(colWidths));
}, [colWidths]);

const handleColumnVisibilityChange = (newModel) => {
  setColVisibilityModel(newModel);
};

const handleSortModelChange = (newSortModel) => {
  setSortModel(newSortModel);
  localStorage.setItem('ViewSortModel', JSON.stringify(newSortModel));
};

function handleColumnWidthChange(width) {
  if (width.colDef.field==='dgname') {
    setColWidths({...colWidths, dgname: width.colDef.width})
  } else if (width.colDef.field==='poolconfig') {
    setColWidths({...colWidths, poolconfig: width.colDef.width})
  } else if (width.colDef.field==='priceconfig') {
    setColWidths({...colWidths, priceconfig: width.colDef.width})
  } else if (width.colDef.field==='demandconfig') {
    setColWidths({...colWidths, demandconfig: width.colDef.width})
  } else if (width.colDef.field==='count') {
    setColWidths({...colWidths, count: width.colDef.width})
  } else if (width.colDef.field==='hashing') {
    setColWidths({...colWidths, hashing: width.colDef.width})
  } else if (width.colDef.field==='aggrTHs') {
    setColWidths({...colWidths, aggrTHs: width.colDef.width})
  } else if (width.colDef.field==='aggrJTHs') {
    setColWidths({...colWidths, aggrJTHs: width.colDef.width})
  } else if (width.colDef.field==='aggrPower') {
    setColWidths({...colWidths, aggrPower: width.colDef.width})
  }
}

  const displayHash = (hash) => {
    const n = Number(hash)
    if (isNaN(n)) {
      return 0
    } else {
      return n.toFixed(2)
    }
  }

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  // Function to handle interval changes
  const handleInterval = (val) => {
    // console.log('set interval', val)
    localStorage.setItem('refreshInterval', val);
    setTimegap(val);
  };

  const { getAccessTokenSilently } = useAuth0();

  const [paginationModel, setPaginationModel] = React.useState({page: 0, pageSize: 50})

  const GetDGView = async () => {
    let r = {
      count: 0,
      docs: []
    }

   try {
      const token = await getAccessTokenSilently();

      const response = await fetch(
        env.APIPath.dgview,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );
      const data = await response.json();
      if (data.status === "ok") {
        validUser = 0
        setSubmitMsg('ok')
        setSuccessModalOpen(true);
        data.dgview.forEach((el) => {
          // console.log('element', el)
          const doc = {
            org_name: data.org_id ?? '',
          }
          if (el.dgname) {
            if (el.dgname === 'Teraflux Group') {
              doc.dgname = 'Default Group'
            } else {
              doc.dgname = el.dgname
            }
          }
          if (el.tuneConfig && Object.keys(el.tuneConfig).length !== 0) {
            doc.tuningconfig = el.tuneConfig
          }
          if (el.priceConfig && Object.keys(el.priceConfig).length !== 0) {
            doc.priceconfig = el.priceConfig
            doc.priceconfig.Name = el.priceConfigName
          }
          if (el.demandResponseConfig && Object.keys(el.demandResponseConfig).length !== 0) {
            doc.demandconfig = el.demandResponseConfig
            doc.demandconfig.Name = el.demandResponseConfigName
          }
          if (el.config && el.config.ztp && el.config.ztp.poolCfg) {
            doc.poolconfig = el.config.ztp.poolCfg
          }
          doc.count = 0
          if (el.count) {
            doc.count = el.count
          }
          if (el.totalHashingDevicesLastHour) {
            doc.hashing = el.totalHashingDevicesLastHour
          }
          if (el.aggregatedTHsLastHour) {
            doc.aggrTHs = displayHash(el.aggregatedTHsLastHour)
          }
          if (el.aggregatedJperTHsLastHour) {
            doc.aggrJTHs = displayHash(el.aggregatedJperTHsLastHour)
          }
          if (el.aggregatedPowerConsumptionLastHour) {
            doc.aggrPower = displayHash(el.aggregatedPowerConsumptionLastHour)
          }
          r.docs.push(doc)
        });
        r.count = data.count
      } else {
        if (data.errorCode === 1007) {
          validUser = 1
          setSubmitMsg('nouser')
          setSuccessModalOpen(true);
        } else if (data.errorCode === 1009) {
          validUser = 1
          setSubmitMsg('noauth')
          setSuccessModalOpen(true);
        } else {
          validUser = 1
          setSubmitMsg('fetchfailed')
          setSuccessModalOpen(true);
        }
        // console.log(data);
      }
    } catch (error) {
      validUser = 1
      setSubmitMsg('something')
      setSuccessModalOpen(true);
      console.log(error.message);
    }
    return r
  };

  // Effect to retrieve the interval value from localStorage when the component mounts
  React.useEffect(() => {
    // Retrieve the interval value from localStorage
    const savedInterval = localStorage.getItem('refreshInterval');
    // Use the savedInterval value if it exists, otherwise, use the default interval
    const initialInterval = savedInterval ? parseInt(savedInterval) : DEFAULT_INTERVAL;
    // Set the initial interval in state
    // console.log('retrieved value ', initialInterval)
    setTimegap(initialInterval);
  }, []);

  React.useEffect(() => {
    let active = true;

    const fn = async () => {
      setLoading(true);
      const { docs: newRows, count: count1 } = await GetDGView();

      if (!active) {
        setLoading(false);
        return;
      }

      setRows(newRows);
      setLoading(false);
      setCount(count1);

      const nowDate = new Date();
      setLastRefresh(nowDate.toLocaleString());
      setTimeout(() => {
        fn()
      }, timegap * 1000);
      localStorage.setItem('refreshInterval', timegap);
    }
    fn()
    return () => {
      active = false;
    }
  }, [timegap]);


  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);


  if (validUser === 0) {
    return (
      <PageContainer title="View" description="this is the miner group details page">
      <Box sx={{
        '& .rowGreen': {
          bgcolor: (theme) =>
            getBackgroundColor('#FFA500', theme.palette.mode),
        },
      }}>
        <Typography
            variant="h4"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center", color: "#007bff" }}
            >
            Miner Group Details
        </Typography>
        <br />
        <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 4, ml:2 }}>
          <Typography sx={{mr:3, mt:-2}}>Refresh Interval</Typography>
          <RefreshInterval name={`Last Refresh: ${lastRefresh}`} value={timegap} callback={handleInterval} />
        </Stack>
        <Box
          sx={{
            fontSize: '12px',
            fontStyle: 'italic',
            textAlign: 'left',
            color: 'gray',
            mr: 1,
          }}>
          <IconButton color="primary" size="small">
          <InfoOutlinedIcon fontSize="small" />
          </IconButton>
          <Typography variant="caption" sx={{ fontStyle: 'italic', fontSize: 12 }}>
          Miner Hashing, Aggr THs, J/THs and Power values are calculated for the last one hour
          </Typography>
        </Box>
        <br />

        <DataGrid
          autoHeight
          getRowId={(row) => row.dgname}
          rows={rows}
          columns={columns}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 60}
          pagination
          paginationMode="client"
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          rowSelectionModel={selectionModel}
          loading={loading}
          disableRowSelectionOnClick
          getRowClassName={(params) => {
            if (params.row.bgGreen) {
              return 'rowGreen';
            } else {
              return '';
            }
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[25, 50]}
          sx={{mr:5, ml:2, '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '6px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '9px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },}}
          onColumnWidthChange={handleColumnWidthChange}
          onColumnResize={(params) => handleColumnWidthChange(params)}
          columnVisibilityModel={colVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => handleColumnVisibilityChange(newModel)}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
        />
        <Footer sub={true}/>
      </Box >
      </PageContainer>
    );
  } else if (validUser !== -1 && validUser !== 0) {
    return (
      <PageContainer title="View" description="this is the miner group details page">
      <div>
        <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg} />
      </div>
      </PageContainer>
    );
  } else {
    return  <PageContainer title="View" description="this is the miner group details page"><PageLoading /></PageContainer>
  }
}
