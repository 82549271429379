import Footer from "../../components/footer";
import React, { useState, useEffect, useRef } from "react";
import "./dgstyles.css";
import { TabPanel, a11yProps } from "../../components/tab";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Autocomplete from "@mui/material/Autocomplete";
import { displayTS, DeviceDialog } from './device-dialog'
import {
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  FormControl,
  MenuItem,
  Select,
  Dialog,
  OutlinedInput,
  DialogActions,
  DialogContentText,
  Chip,
  useTheme,
  DialogContent
} from "@mui/material";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import * as api from "../../api/api";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { InputLabel, Input } from "@mui/material";
import PaperWrap from "../../components/paper-wrap";
import { FaTimes } from "react-icons/fa";
import { gridDensityTotalHeaderHeightSelector } from "@mui/x-data-grid";
import PageLoading from "../../components/pageloading";

let validUser = -1

const SuccessModal = ({ isOpen, onClose, Status }) => {
  console.log('status is', Status)
  let h4Msg = ''
  let pMsg = ''
  switch (Status) {
    case 'ok':
      break;
    case 'nouser':
      h4Msg = "Authentication Failed!"
      pMsg = "User is not part of the Organization. Please contact your Administrator"
      break;
    case 'noauth':
        h4Msg = "Authorization Failed!"
        pMsg = "User doesn't have permission to view this page"
        break;
    case 'fetchfailed':
      h4Msg = "Fetch Failed!"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
    default:
      h4Msg = "Unknown Error"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
  }
  if (Status !== 'ok' && Status !== 'something') {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose} >
        <h4 className="popup-title">{h4Msg}</h4>
        <p className="success-message">{pMsg}</p>
        <div className="button-container">
          <button onClick={onClose}>Close</button>
        </div>
      </Popup>
    );
  }
};


export const GroupMgmtYYY = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const { user: user0, getAccessTokenSilently } = useAuth0();
  const [showPopup, setShowPopup] = useState(false);
  const [value, setValue] = React.useState(0);
  const [globalDGName, setGlobalDGName] = useState("");

  const [minerGroups, setMinerGroups] = useState([]);

  const [openCreateGroupConfirmation, setCreateGroupConfirmation] = React.useState(false);
  const [confMessage, setConfMessage] = React.useState('');
  const [confDeleteSuccessMessage, setDeleteSuccessConfMessage] = React.useState('');
  const [confDeleteMoveMessage, setDeleteMoveConfMessage] = React.useState('');
  const [confDeleteFailureMessage, setDeleteFailureConfMessage] = React.useState('');
  const [confDeleteNotFoundMessage, setDeleteNotFoundConfMessage] = React.useState('');
  const [selectedDGs, setSelectedDGs] = React.useState([]);
  const [openDeleteConfirm, setDeleteConfirm] = React.useState('' ? true : false);
  const [inputDGValue, setInputDGValue] = useState('');
  const [isUniqueValue, setIsUniqueValue] = useState(true);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [submitMsg, setSubmitMsg] = useState('');

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const checkIfUnique = () => {
    setIsUniqueValue(!minerGroups.includes(inputDGValue));
  };

  useEffect(() => {
    checkIfUnique();
  }, [inputDGValue, minerGroups]);


  const handleCreateDGInputChange = (event) => {
    setInputDGValue(event.target.value);
  }

  const handleCreateConfMessage = () => {
    setCreateGroupConfirmation(true);
  };

  const handleCloseCreateConfMessage = () => {
    setCreateGroupConfirmation(false);
  };

  const handleOkCreateDG = async () => {
    console.log(inputDGValue);
    const normalizedinputDGValue = inputDGValue.replace(/\s+/g, ' ');
    const lowerCaseInput = normalizedinputDGValue.toLowerCase();
    if (lowerCaseInput === 'teraflux group' || lowerCaseInput === 'default group') {
      setCreateGroupConfirmation(true);
      if (lowerCaseInput === 'teraflux group') {
        setConfMessage("This is an internally used group. Please choose a different name");
      } else {
        setConfMessage("Default group name already exists");
      }
      setInputDGValue('');
      return;
    }
    if (normalizedinputDGValue.trim() !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = normalizedinputDGValue;
      const resp = await api.AddDG(token, dgdata);
      if (resp === undefined) {
        setCreateGroupConfirmation(true);
        setConfMessage("Failed to create group");
      } else {
        console.log("creating group ", JSON.stringify(resp));
        if (resp.status === "error") {
          setCreateGroupConfirmation(true);
          setConfMessage(resp.msg);
        } else {
          setCreateGroupConfirmation(true);
          setConfMessage(`Successfully created group: ${dgdata.dgname}`);
        }
      }
      await fetchData();
    }
    setGlobalDGName('');
    setShowPopup(false);
    setInputDGValue('');
  };

  const handleCancelCreateDG = (event, reason) => {
    if (reason === 'clickaway') {
      setInputDGValue('');
      return;
    }
    setInputDGValue('');
    setShowPopup(false);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  useEffect(() => {
    const runFn = async () => {
      const token = await getAccessTokenSilently();
      const isAllowed = await api.IsOperationAllowed(token);
      if (isAllowed === undefined) {
        validUser = 1
        setSubmitMsg('something')
        setSuccessModalOpen(true);
        return
      } else if (isAllowed.status === 'error') {
        if (isAllowed.errorCode === 1007) {
          validUser = 1
          setSubmitMsg('nouser')
          setSuccessModalOpen(true);
        } else if (isAllowed.errorCode === 1009) {
          validUser = 1
          setSubmitMsg('noauth')
          setSuccessModalOpen(true);
        } else {
          validUser = 1
          setSubmitMsg('fetchfailed')
          setSuccessModalOpen(true);
        }
        console.log("Operation Allowed", isAllowed);
        return
      } else {
        validUser = 0
        setSubmitMsg('ok')
        setSuccessModalOpen(true);
      }
      const deviceGroups = await api.GetAllDeviceGroups(token);
      if (deviceGroups === undefined) {
      } else {
        var minerGroupList = [];
        for (let i = 0; i < deviceGroups.length; i++) {
          minerGroupList.push(deviceGroups[i].DGName);
        }
        minerGroupList.sort();
        setMinerGroups(minerGroupList);
      }
    };
    runFn();
  }, [getAccessTokenSilently]);

  /*
   * Delete Group Names
   */
  const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        let allDGNames = await api.GetDGNames(token);
        if (allDGNames.status === 'error') {
          console.error('Error fetching group names');
        } else {
          const allDGNamesList = Object.keys(allDGNames);
          allDGNamesList.sort();
          setMinerGroups(allDGNamesList);
        }
      } catch (error){
        console.error('Error fetching DGNames:', error);
      }
  };

  useEffect(() => {
    // console.log('calling fetchdata', inputDGValue, 'global ', globalDGName);
    if (inputDGValue === '') {
      return;
    }
    if (globalDGName === inputDGValue) {
      return;
    }
    setGlobalDGName(inputDGValue);
    fetchData();
    checkIfUnique();
  }, [getAccessTokenSilently, inputDGValue, minerGroups, globalDGName]); // check if input value is unique when inputValue or existingData changes


  //DELETE HERE
  const [openDeleteConfMessage, setDeleteConfMessage] = React.useState(false);

  const handleOpenConfMessage = () => {
    setDeleteConfMessage(true);
  };

  const handleCloseConfMessage = () => {
    setDeleteConfMessage(false);
  };
  const handleDeleteDG = async () => {
    setDeleteConfirm(false);
    setDeleteSuccessConfMessage('')
    setDeleteMoveConfMessage('')
    setDeleteFailureConfMessage('')
    setDeleteNotFoundConfMessage('')
    try {
      const token = await getAccessTokenSilently();
        const dgData = { };
        dgData.dgname = selectedDGs;
        const response = await api.DeleteDG(token,dgData );
        if (response === undefined || response === null) {
            setDeleteConfMessage(true);
            setDeleteFailureConfMessage(`Failed to delete group(s) ${dgData.dgname}`);
        } else {
          console.log("DeleteDG response", response.status);
          if (response.status === "error" || response.status === "ok") {
            if (response.msg.success.length > 0) {
              if (response.msg.success.length === 1) {
                setDeleteSuccessConfMessage("Successfully deleted group: " + response.msg.success);
              } else {
                setDeleteSuccessConfMessage("Successfully deleted groups: " + response.msg.success);
              }
            }
            if (response.msg.moveMiners.length > 0) {
              if (response.msg.moveMiners.length === 1) {
                setDeleteMoveConfMessage("Failed to delete non empty group: " + response.msg.moveMiners);
              } else {
                setDeleteMoveConfMessage("Failed to delete non empty groups: " + response.msg.moveMiners);
              }
            }
            if (response.msg.failure.length > 0) {
              if (response.msg.failure.length === 1) {
                setDeleteFailureConfMessage("Failed to delete group: " + response.msg.failure);
              } else {
                setDeleteFailureConfMessage("Failed to delete groups: " + response.msg.failure);
              }
            }
            if (response.msg.notFound.length > 0) {
              if (response.msg.notFound.length === 1) {
                setDeleteNotFoundConfMessage("Group not found: " + response.msg.notFound);
              } else {
                setDeleteNotFoundConfMessage("Groups not found: " + response.msg.notFound);
              }
            }
            await fetchData();
            await fetchData();
            setSelectedDGs([]);
            setDeleteConfMessage(true);
          } else {
            console.error(`Error in deleting group(s) ${dgData.dgname}`, JSON.stringify(response.msg));
            setDeleteConfMessage(true);
            setSelectedDGs([]);
            if (dgData.dgname.length === 1) {
              setDeleteFailureConfMessage(`Error in deleting group ${dgData.dgname}`);
            } else {
              setDeleteFailureConfMessage(`Error in deleting groups ${dgData.dgname}`);
            }
          }
        }
    } catch (error) {
      setDeleteFailureConfMessage(`Error in deleting one or more groups`);
      setDeleteConfMessage(true);
      setSelectedDGs([]);
      console.error('Error deleting groups:', error);
    }
  };

  const handleClickDeleteOpen = () => {
    setDeleteConfirm(true);
  };
  // const handleClickDeleteClose = () => {
  //   setDeleteConfirm(false);
  // };

  if (validUser === 0) {
      return (
        <div>
          <Typography
            variant="h4"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center", color: "#007bff" }}
          >
            Manage Groups
          </Typography>
          <br />
          <Box sx={{ borderBottom: 1, borderColor: "divider", }}>
            <Tabs value={value} onChange={handleChange}  aria-label="Profile">
              <Tab
                sx={{ fontFamily: "Arial, sans-serif", textTransform: "none", width:'300px' }}
                label="Create Group"
                {...a11yProps(0)}
              />
              <Tab
                sx={{ fontFamily: "Arial, sans-serif", textTransform: "none", width:'300px'}}
                label="Delete Group"
                {...a11yProps(0)}
              />
            </Tabs>
          </Box>
          {/* Create Group */}
          <TabPanel value={value} index={0}>
              <Box>
              <TextField
                id="outlined-required"
                label="Enter Group Name"
                helperText={
                  !isUniqueValue ?
                  inputDGValue === 'Teraflux Group' ? 'This is an internally used group. Please choose a different name' : 'Group name already exists!' :
                  ''
                }
                sx={{width: 600}}
                onChange={handleCreateDGInputChange}
                value={inputDGValue}
                inputProps={{
                  pattern: '^[a-zA-Z0-9- ]*$',
                  title: 'Only alphanumeric characters, spaces are allowed',
                  maxLength: 512,
                }}
              />
              </Box>
              <br/>
              <Box sx={{ display: 'flex', mb: 5}}>
                <Button onClick={handleOkCreateDG}
                disabled={!isUniqueValue || !inputDGValue.match(/^[a-zA-Z0-9- ]*$/)}
                type="submit" variant="contained" sx={{ marginRight: '10px' }}>
                  Ok
                </Button>
                <Button onClick={handleCancelCreateDG} type="submit" variant="contained" >Cancel</Button>
                <Dialog
                  open={openCreateGroupConfirmation}
                  onClose={handleCloseCreateConfMessage}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {confMessage}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={handleCloseCreateConfMessage} type="submit" variant="contained">
                    ok
                  </Button>
                  </DialogActions>
                </Dialog>
              </Box>
          </TabPanel>
          {/*Delete Group Tab*/}
          <TabPanel value={value} index={1}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  // options={deviceGroupsNames.filter(group => group !== 'Teraflux Group')} //deviceGroupsNames list of all DGs
                  options={minerGroups} //deviceGroupsNames list of all DGs
                  value={selectedDGs || []}
                  onChange={(event, value) =>{
                    // console.log('selected DGs:', value);
                    setSelectedDGs(value);
                  }}
                  disableCloseOnSelect
                  getOptionLabel={(option) =>
                    option === 'Teraflux Group' ? 'Default Group' : option
                  }
                  getOptionDisabled={(option) => option === 'Teraflux Group'}
                  renderGroup={(props, option, {selected}) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                        />
                        {option}
                    </li>
                  )}
                  style={{width: 500}}
                  renderInput={(params) => (
                    <TextField {...params} label="Select group(s) from the list"  />
                  )}
                />
                <Box sx={{ marginLeft: '10px' }} />
                <Button onClick={handleClickDeleteOpen}
                disabled={selectedDGs.length ===0}
                type="submit" variant="contained"
                >
                  Delete
                </Button>
                <Dialog open={openDeleteConfirm} >
                  <DialogContent>
                    <DialogContentText>
                    {`Are you sure you want to delete?`}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDeleteDG} variant="contained" color="error">
                      Confirm
                    </Button>
                    <Button onClick={ ()=> setDeleteConfirm(false)} variant="contained">
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                {/*CONFIRMATION MESSAGE*/}
                <Dialog
                open={openDeleteConfMessage}
                onClose={handleCloseConfMessage}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <p>{confDeleteSuccessMessage}</p>
                <p>{confDeleteMoveMessage}</p>
                <p>{confDeleteFailureMessage}</p>
                <p>{confDeleteNotFoundMessage}</p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseConfMessage} type="submit" variant="contained">
                  ok
                </Button>
              </DialogActions>
                </Dialog>
              </Box>
          </TabPanel>
          <Footer />
        </div>
      );
  } else if (validUser !== -1 && validUser !== 0) {
    return (
      <div>
        <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg} />
      </div>
    );
  } else {
    return <PageLoading />;
  }
};

export default GroupMgmtYYY;
