import React from "react";
import { Box, Stack, Typography } from '@mui/material';
import PoolConfig from "./pool-config";

export const MinerConfig = ({ cfg }) => {

  console.log('miner config ...')

  return (
    <Box>
      <Stack direction="row">
        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }} >Miner Pool Config</Typography>
      </Stack>
      <Box>
        <PoolConfig PoolIndex={1} />
        <PoolConfig PoolIndex={2} />
        <PoolConfig PoolIndex={3} />
      </Box>
    </Box>
  );
};

export default MinerConfig;
