import React, {useState, useEffect} from "react";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Command from "./command";
import PaperWrap from "../components/paper-wrap";
import Footer from '../components/footer';
import Autocomplete from '@mui/material/Autocomplete';
import * as api from '../api/api'
import Modal from 'react-modal';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import CloseIcon from '@mui/icons-material/Close';
import { FaTimes } from 'react-icons/fa';
import '../device/dgstyles.css';
import Button from '@mui/material/Button';
import {
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Chip from '@mui/material/Chip';
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import * as constants from '../constants';
import PageLoading from "../components/pageloading";

const DVFS_GROUP_NAME = 'dvfsGroupName';
const DVFS_GROUP_LIST = 'dvfsGroupList';
const DVFS_UPDATED_GROUPS = 'dvfsUpdatedGroups';
const DVFS_APPLY_GROUPS = 'dvfsApplyGroups';
const DVFS_SELECT_ALL = 'dvfsSelectAll';


const SuccessModal = ({ isOpen, onClose, Status, ManualTuneMsg }) => {

  let h4Msg = ''
  let pMsg = ''
  switch (Status) {
    case 'ok':
      h4Msg = "Success Message"
      pMsg = "Successfully submitted the Tune Configuration"
      break;
    case 'hashrate':
      h4Msg = "Failure Message"
      pMsg = "Empty Desired HashRate field"
      break;
    case 'powerconstraint':
      h4Msg = "Failure Message"
      pMsg = "Empty Power Constraint fields"
       break;
    case 'energyprice':
      h4Msg = "Failure Message"
      pMsg = "Empty Electricity Cost field"
      break;
    case 'rampupdown':
      h4Msg = "Failure Message"
      pMsg = "Empty Ramp Up/Down Time in Seconds field"
      break;
    case 'invalidrange':
      h4Msg = "Failure Message"
      pMsg = "Please fill up with valid range values"
      break;
    case 'hrrange':
      h4Msg = "Failure Message"
      pMsg = "Can't tune because desired hashrate is in 5% range of running hashrate"
      break;
    case 'unknown':
      h4Msg = "Failure Message"
      pMsg = "Failed to send tuning command"
      break;
    case 'error':
      h4Msg = "Failure Message"
      //pMsg = "Failed to save the Tune Configuration"
      pMsg =  ManualTuneMsg
      break;
    case 'minergroup':
        h4Msg = "Failure Message"
        pMsg = "Invalid Miner Group"
        break;
    case 'minergroups':
        h4Msg = "Failure Message"
        pMsg = "Empty Group List"
        break;
    case 'manualtuneunknown':
      h4Msg = "Failure Operation"
      pMsg = "Failed to call the Tune Operation"
      break;
    case 'manualtuneok':
      h4Msg = "Success Message"
      pMsg =  ManualTuneMsg
      break;
    case 'manualtuneerror':
      h4Msg = "Failure Message"
      pMsg =  ManualTuneMsg
      break;
    case 'fetchfailed':
      h4Msg = "Fetch Failed!"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
    case 'nouser':
        h4Msg = "Authentication Failed!"
        pMsg = "User is not part of the Organization. Please contact your Administrator"
      break;
    case 'noauth':
        h4Msg = "Authorization Failed!"
        pMsg = "User doesn't have permission to view this page"
        break;
    default:
      h4Msg = "Unknown Error"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
  }
  if (Status !== 'ok') {
  return (
    <Popup className="popup1-content" open={isOpen} onClose={onClose}>
      <h4 className="popup-title">{h4Msg}</h4>
      <div className="gap"></div>
      {typeof pMsg === 'object' ? (
        <div className="success-message-container">
          {Object.keys(pMsg).map((group, index) => (
            <div key={index} className="group-message">
              <span className="group-name">{group}:</span>
              <span
                className="group-content"
                dangerouslySetInnerHTML={{
                  __html: pMsg[group].replace(
                    /([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12})/gi,
                    '<span class="job-id">$1</span>'
                  ),
                }}
              ></span>
            </div>
          ))}
        </div>
      ) : (
        <p className="success-message">{pMsg}</p>
      )}
      <div className="button-container">
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
  );
  }
};

let validUser = -1

export const DVFSConfig = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [inputDesiredHashRate, setinputDesiredHashRate] = useState('');
  const [currentDesiredHashRate, setcurrentDesiredHashRate] = useState('');
  const [inputRampUpDown, setinputRampUpDown] = useState('');
  const [TuneOperationMode, setTuneOperationMode] = useState(false);
  const [PowerOperationMode, setPowerOperationMode] = useState(false);
  const [submitMsg, setSubmitMsg] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [powerConstraintInputMax, setPowerConstraintMax] = useState('');
  const [powerConstraintInputMin, setPowerConstraintMin] = useState('');
  const [btcDifficultyURL, setbtcDifficultyURL] = useState('https://api.blockchain.info/charts/difficulty?format=json')
  const [btcURL, setbtcURL] = useState('https://api.blockchain.info/ticker')
  const [inputEnergyPrice, setinputEnergyPrice] = useState('');
  const [manualTuneMsg, setmanualTuneMsg]=useState('');
  // const [minerGroup, setMinerGroup] = useState(localStorage.getItem(DVFS_GROUP_NAME) || '');
  // const [minerGroups, setMinerGroups] = useState(
  //   JSON.parse(localStorage.getItem(DVFS_GROUP_LIST)) || []
  // );
  // const [updatedGroups, setUpdatedGroups]=useState(JSON.parse(localStorage.getItem(DVFS_UPDATED_GROUPS)) || []);
  // const [applyGroups, setApplyGroups] = useState(JSON.parse(localStorage.getItem(DVFS_APPLY_GROUPS)) || []);
  // const storedSelectAll = localStorage.getItem(DVFS_SELECT_ALL);
  // const initialSelectAll = storedSelectAll === 'true';
  // const [selectAll, setSelectAll] = useState(initialSelectAll || false);

  const [minerGroup, setMinerGroup] = useState('Teraflux Group');
  const [minerGroups, setMinerGroups] = useState([]);
  const [updatedGroups, setUpdatedGroups]=useState([]);
  const [applyGroups, setApplyGroups] = useState([]);
  const [selectAll, setSelectAll] = useState(false);


  const handleSelectAll = (event) => {
    const allOptions = updatedGroups;
    const selectedOptions = event.target.checked ? allOptions : [];
    console.log('handleSelectAll', selectedOptions)
    setApplyGroups(selectedOptions);
    localStorage.setItem(DVFS_APPLY_GROUPS, JSON.stringify(selectedOptions));
    setSelectAll(event.target.checked);
    localStorage.setItem(DVFS_SELECT_ALL, event.target.checked.toString());
  };

  const applyVal = (value) => {
    console.log('applyVal', value, value.length )
    setApplyGroups(value);
    localStorage.setItem(DVFS_APPLY_GROUPS, JSON.stringify(value));
    setSelectAll(value.length === updatedGroups.length);
    localStorage.setItem(DVFS_SELECT_ALL, (Array.isArray(value) ? value.length === updatedGroups.length : false).toString());
  };

  const handleMinerGroup = async(value) => {
    setMinerGroup(value);
    localStorage.setItem(DVFS_GROUP_NAME, value);
    const updatedGroupList = minerGroups.filter(group => group !== value);
    // console.log('Selected miner group', value, applyGroups, updatedGroupList)
    setUpdatedGroups(updatedGroupList);
    localStorage.setItem(DVFS_UPDATED_GROUPS, JSON.stringify(updatedGroupList));
    if (applyGroups.length > 0) {
      const newApplyGroups =  applyGroups.filter(group => group !== value);
      setApplyGroups(newApplyGroups)
      localStorage.setItem(DVFS_APPLY_GROUPS, JSON.stringify(newApplyGroups));
      setSelectAll(newApplyGroups.length === updatedGroups.length);
      localStorage.setItem(DVFS_SELECT_ALL, (Array.isArray(newApplyGroups) ? newApplyGroups.length === updatedGroups.length : false).toString());
    }
  }

  const { user: user0, getAccessTokenSilently } = useAuth0();

  const [isAutoOn, setIsAutoOn] = useState(false);
  const [autoTuneInterval, setAutoTuneInterval] = useState('');

  const [showBanner, setShowBanner] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [ElectrictyPriceFilled, setElectrictyPriceFilled] = useState(false);
  const [isElectrictyPriceEdited, setIsElectrictyPriceEdited] = useState(false);

  const [DesiredHashRateFilled, setDesiredHashRateFilled] = useState(false);
  const [isDesiredHashRateEdited, setIsDesiredHashRateEdited] = useState(false);
  const [error, setError] = React.useState({
    error: false,
    helperText: "",
  })

  const [PMaxFilled, setPMaxFilled] = useState(false);
  const [isPMaxEdited, setIsPMaxEdited] = useState(false);
  const [PMaxerror, setPMaxError] = React.useState({
    error: false,
    helperText: "",
  })

  const [PMinFilled, setPMinFilled] = useState(false);
  const [isPMinEdited, setIsPMinEdited] = useState(false);
  const [PMinerror, setPMinError] = React.useState({
    error: false,
    helperText: "",
  })

  const handleConfirmation = () => {
    submitHandler();
    setIsLoading(true);
    // Close the popup after the user confirms
    setShowPopup(false);
  };

  const handleCancelConfirmation = () => {
    setShowPopup(false);
  };

  const handleinputEnergyPriceChange = (event) => {
    const value = event.target.value;
    if (value >= 0) {
      setinputEnergyPrice(value);
      setElectrictyPriceFilled(value.trim() !== '');
      setIsElectrictyPriceEdited(value !== inputEnergyPrice);
    }
  };

  const handlePowerConstraintMin =  (event) => {
    const value = event.target.value;
    if (value >= 0) {
      let fvalue = parseFloat(value)
      if (fvalue > constants.MAXPOWER || fvalue < constants.MINPOWER) {
        setPMinError({ error: true, helperText: "" });
        setPMaxFilled(false);
      } else {
        setPMinError({ error: false, helperText: "" });
        setPMinFilled(value.trim() !== '');
        setPMaxFilled(powerConstraintInputMax !== '');
      }
      setPowerConstraintMin(value);
      setIsPMinEdited(value !== powerConstraintInputMin);
    }
  };

  const handlePowerConstraintMax =  (event) => {
    const value = event.target.value;
    if (value >= 0) {
      let fvalue = parseFloat(value)
      if (fvalue > constants.MAXPOWER || fvalue < constants.MINPOWER) {
        setPMaxError({ error: true, helperText: "" });
        setPMinFilled(false);
      } else {
        setPMaxError({ error: false, helperText: "" });
        setPMaxFilled(value.trim() !== '');
        setPMinFilled(powerConstraintInputMin !== '');
      }
      setPowerConstraintMax(value);
      setIsPMaxEdited(value !== powerConstraintInputMax);
    }
  };

  const handleTuneOperationMode = () => {
    setTuneOperationMode(!TuneOperationMode);
  }

  const handlePowerOperationMode = () => {
    setPowerOperationMode(!PowerOperationMode);
    setShowBanner(!PowerOperationMode);
  }

  const handleBannerClose = () => {
    setShowBanner(false);
  };

  const handleinputDesiredHashRate = (event) => {
    const value = event.target.value;
    if (value >= 0) {
      let fvalue = parseFloat(value)
      if (fvalue < constants.MINTHS || fvalue > constants.MAXTHS) {
        setError({ error: true, helperText: "" });
      } else {
        setError({ error: false, helperText: "" });
        setDesiredHashRateFilled(value.trim() !== '');
      }
      setinputDesiredHashRate(value);
      setIsDesiredHashRateEdited(value !== inputDesiredHashRate);
    }
  };

  const handleinputRampUpDown = (event) => {
    const value = event.target.value;
    if (value >= 0) {
      setinputRampUpDown(value);
    }
  }

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };


  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);


  const submitHandler = async() => {

    const token = await getAccessTokenSilently();
    const info = {}
    info.config = {}

    let btcdata = {}


    setIsLoading(true);
    console.log(typeof inputEnergyPrice)
    console.log(typeof inputDesiredHashRate)
    console.log(typeof inputRampUpDown)
    console.log(typeof minerGroup)
    console.log(typeof powerConstraintInputMin)
    console.log(typeof powerConstraintInputMax)

    if (!TuneOperationMode && !PowerOperationMode) {
      if (inputDesiredHashRate === '') {
        setSubmitMsg("hashrate");
        setSuccessModalOpen(true);
        setIsLoading(false);
        return
      }
      if (inputEnergyPrice === '') {
        setSubmitMsg("hashrate");
        setSuccessModalOpen(true);
        setIsLoading(false);
        return
      }
    } else {
      if (inputEnergyPrice === '') {
        setSubmitMsg("energyprice");
        setSuccessModalOpen(true);
        setIsLoading(false);
        return
      }
    }
    if (PowerOperationMode) {
      if (powerConstraintInputMin === '' || powerConstraintInputMax === '') {
        setSubmitMsg("powerconstraint");
        setSuccessModalOpen(true);
        setIsLoading(false);
        return
      } else {
        const minpc = parseInt(powerConstraintInputMin)
        const maxpc = parseInt(powerConstraintInputMax)
        if (isNaN(minpc) || isNaN(maxpc)) {
          setSubmitMsg("powerconstraint");
          setSuccessModalOpen(true);
          setIsLoading(false);
          return
        } else {
          if (minpc < constants.MINPOWER  || maxpc < constants.MINPOWER || minpc > constants.MAXPOWER || maxpc > constants.MAXPOWER || minpc > maxpc) {
            setSubmitMsg("invalidrange");
            setSuccessModalOpen(true);
            setIsLoading(false);
            return
          } else {
            info.config.PowerConstraintMin = minpc
            info.config.PowerConstraintMax = maxpc
          }
        }
      }
    } else {
      const minpc = parseInt(powerConstraintInputMin)
      const maxpc = parseInt(powerConstraintInputMax)
      if (isNaN(minpc)) {
        info.config.PowerConstraintMin = -1
      } else {
        info.config.PowerConstraintMin = minpc
      }
      if (isNaN(maxpc)) {
        info.config.PowerConstraintMax = -1
      } else {
        info.config.PowerConstraintMax = maxpc
      }
    }
    if (minerGroup === '') {
      setSubmitMsg("minergroup");
      setSuccessModalOpen(true);
      setIsLoading(false);
      return
    }
    /*
     * The following is not for September 2023 release
     */
    /*
      if (inputRampUpDown === '') {
        setSubmitMsg("rampupdown");
        setSuccessModalOpen(true);
        setIsLoading(false);
        return
      }
    */
      info.dgName = minerGroup
      if (isNaN(parseFloat(inputEnergyPrice))) {
        info.config.energyPrice = 0.0
      } else {
        info.config.energyPrice = parseFloat(inputEnergyPrice);
      }
      info.config.TargetHashRate = 0.0
      if (isNaN(parseFloat(inputDesiredHashRate)) === false) {
        info.config.TargetHashRate = parseFloat(inputDesiredHashRate);
      }
      // if (!TuneOperationMode && isNaN(parseFloat(inputDesiredHashRate)) === false) {
      //   info.config.TargetHashRate = parseFloat(inputDesiredHashRate);
      //   if (!PowerOperationMode) {
      //       info.config.TargetHashRate = parseFloat(inputDesiredHashRate);
      //   } else {
      //     info.config.TargetHashRate = 0.0;
      //   }
      // } else {
      //   info.config.TargetHashRate = 0.0
      // }
      info.config.TuneOperationMode = TuneOperationMode
      info.config.PowerOperationMode = PowerOperationMode
      if (PowerOperationMode) {
        info.config.TuneOperationMode = false
      }
      if (isNaN(parseFloat(inputRampUpDown))) {
        info.config.RampUpDownTimeInSeconds = 10 //setting to default
      } else {
        info.config.RampUpDownTimeInSeconds = parseInt(inputRampUpDown);
      }
      if ((!TuneOperationMode && !PowerOperationMode && (isNaN(info.config.TargetHashRate)  || info.config.TargetHashRate <constants.MINTHS || info.config.TargetHashRate > constants.MAXTHS)) ||
          isNaN(info.config.RampUpDownTimeInSeconds) || info.config.RampUpDownTimeInSeconds <10 || info.config.RampUpDownTimeInSeconds > 900) {
          setSubmitMsg("invalidrange");
          setSuccessModalOpen(true);
      } else {
        // default AutoTune Interval is 5 minutes for QA. In Prod, this gets set to 60 mins
        info.config.AutoTuneInterval = 3600
        info.config.btcDifficultyURL = btcDifficultyURL
        info.config.btcURL = btcURL
        /*
         * Check whether calculated HashRate is <> 3% of { DesiredHashRate or OptimalHashRate }
         * If yes, update the job status with the right message and exit
         * REVISIT!!!! Discuss with PLM
         * Discussed with Sheldon. For now, Allowing User option and not impose any limit
         * Therefore, commenting the following code
         */
        /*
        if (!TuneOperationMode && parseFloat(currentDesiredHashRate) > 0) {
            const lowerBound = currentDesiredHashRate * 0.97; // 3% below current hash rate
            const upperBound = currentDesiredHashRate * 1.03; // 3% above current hash rate
            if (info.config.TargetHashRate >= lowerBound && info.config.TargetHashRate <= upperBound) {
              setSubmitMsg("hrrange");
              setSuccessModalOpen(true);
              setIsLoading(false);
              return
            }
        }
        */
        const postConfig = await api.PostDGConfig(token, info);
        //console.log(postConfig)
        if (postConfig === undefined || !postConfig.hasOwnProperty('status')) {
          setSubmitMsg("unknown");
          setSuccessModalOpen(true);
        } else if (postConfig.status === 'ok') {
            /*
             * Once the Tune Configuration is successfully saved to the DB,
             * and if the Tune Operation Mode is 'Manual', set the Desired
             * Hash Rate to the Miners of the Group
             */
            if (!TuneOperationMode || PowerOperationMode) {
              console.log("Send Manual Tune Operation")
              let tunedata = {}
              tunedata.dgname = info.dgName
              const tuneResp = await api.PostManualTune(token, tunedata);
              if (tuneResp === undefined || !tuneResp.hasOwnProperty('status')) {
                setSubmitMsg("manualtuneunknown");
              } else if (tuneResp.status === 'ok') {
                setSubmitMsg("manualtuneok")
                setmanualTuneMsg(tuneResp.msg)
              } else if (tuneResp.status === 'error') {
                setSubmitMsg("manualtuneerror")
                setmanualTuneMsg(tuneResp.msg)
              } else {
                setSubmitMsg("manualtuneunknown")
              }
            } else { // AutoTune
              console.log("Send Auto Tune Operation")
              let tunedata = {}
              tunedata.dgname = info.dgName
              const tuneResp = await api.PostAutoTune(token, tunedata);
              if (tuneResp === undefined || !tuneResp.hasOwnProperty('status')) {
                setSubmitMsg("manualtuneunknown");
              } else if (tuneResp.status === 'ok') {
                setSubmitMsg("manualtuneok")
                setmanualTuneMsg(tuneResp.msg)
              } else if (tuneResp.status === 'error') {
                setSubmitMsg("manualtuneerror")
                setmanualTuneMsg(tuneResp.msg)
              } else {
                setSubmitMsg("manualtuneunknown")
              }
            }
            setSuccessModalOpen(true);
        } else {
          setSubmitMsg("error");
          setmanualTuneMsg(postConfig.msg)
          setSuccessModalOpen(true);
        }
      }
    setIsLoading(false);
  }

  const submitMultiDGHandler = async(dglist) => {

    const token = await getAccessTokenSilently();
    const info = {}
    info.config = {}

    let btcdata = {}


    setIsLoading1(true);
    console.log(typeof inputEnergyPrice)
    console.log(typeof inputDesiredHashRate)
    console.log(typeof inputRampUpDown)
    console.log(typeof minerGroup)
    console.log(typeof powerConstraintInputMin)
    console.log(typeof powerConstraintInputMax)

    if (!TuneOperationMode && !PowerOperationMode) {
      if (inputDesiredHashRate === '') {
        setSubmitMsg("hashrate");
        setSuccessModalOpen(true);
        setIsLoading1(false);
        return
      }
      if (inputEnergyPrice === '') {
        setSubmitMsg("hashrate");
        setSuccessModalOpen(true);
        setIsLoading1(false);
        return
      }
    } else {
      if (inputEnergyPrice === '') {
        setSubmitMsg("energyprice");
        setSuccessModalOpen(true);
        setIsLoading1(false);
        return
      }
    }
    if (PowerOperationMode) {
      if (powerConstraintInputMin === '' || powerConstraintInputMax === '') {
        setSubmitMsg("powerconstraint");
        setSuccessModalOpen(true);
        setIsLoading1(false);
        return
      } else {
        const minpc = parseInt(powerConstraintInputMin)
        const maxpc = parseInt(powerConstraintInputMax)
        if (isNaN(minpc) || isNaN(maxpc)) {
          setSubmitMsg("powerconstraint");
          setSuccessModalOpen(true);
          setIsLoading1(false);
          return
        } else {
          if (minpc < constants.MINPOWER || maxpc < constants.MINPOWER || minpc > constants.MAXPOWER || maxpc > constants.MAXPOWER || minpc > maxpc) {
            setSubmitMsg("invalidrange");
            setSuccessModalOpen(true);
            setIsLoading1(false);
            return
          } else {
            info.config.PowerConstraintMin = minpc
            info.config.PowerConstraintMax = maxpc
          }
        }
      }
    } else {
      const minpc = parseInt(powerConstraintInputMin)
      const maxpc = parseInt(powerConstraintInputMax)
      if (isNaN(minpc)) {
        info.config.PowerConstraintMin = -1
      } else {
        info.config.PowerConstraintMin = minpc
      }
      if (isNaN(maxpc)) {
        info.config.PowerConstraintMax = -1
      } else {
        info.config.PowerConstraintMax = maxpc
      }
    }
    if (dglist.length === 0) {
      setSubmitMsg("minergroups");
      setSuccessModalOpen(true);
      setIsLoading1(false);
      return
    }
    /*
     * The following is not for September 2023 release
     */
    /*
      if (inputRampUpDown === '') {
        setSubmitMsg("rampupdown");
        setSuccessModalOpen(true);
        setIsLoading1(false);
        return
      }
    */
      info.dglist = dglist
      if (isNaN(parseFloat(inputEnergyPrice))) {
        info.config.energyPrice = 0.0
      } else {
        info.config.energyPrice = parseFloat(inputEnergyPrice);
      }
      info.config.TargetHashRate = 0.0
      if (isNaN(parseFloat(inputDesiredHashRate)) === false) {
        info.config.TargetHashRate = parseFloat(inputDesiredHashRate);
      }
      // if (!TuneOperationMode && isNaN(parseFloat(inputDesiredHashRate)) === false) {
      //   info.config.TargetHashRate = parseFloat(inputDesiredHashRate);
      //   if (!PowerOperationMode) {
      //       info.config.TargetHashRate = parseFloat(inputDesiredHashRate);
      //   } else {
      //     info.config.TargetHashRate = 0.0;
      //   }
      // } else {
      //   info.config.TargetHashRate = 0.0
      // }
      info.config.TuneOperationMode = TuneOperationMode
      info.config.PowerOperationMode = PowerOperationMode
      if (PowerOperationMode) {
        info.config.TuneOperationMode = false
      }
      if (isNaN(parseFloat(inputRampUpDown))) {
        info.config.RampUpDownTimeInSeconds = 10 //setting to default
      } else {
        info.config.RampUpDownTimeInSeconds = parseInt(inputRampUpDown);
      }
      if ((!TuneOperationMode && !PowerOperationMode && (isNaN(info.config.TargetHashRate)  || info.config.TargetHashRate <constants.MINTHS || info.config.TargetHashRate > constants.MAXTHS)) ||
          isNaN(info.config.RampUpDownTimeInSeconds) || info.config.RampUpDownTimeInSeconds <10 || info.config.RampUpDownTimeInSeconds > 900) {
          setSubmitMsg("invalidrange");
          setSuccessModalOpen(true);
      } else {
        // default AutoTune Interval is 5 minutes for QA. In Prod, this gets set to 60 mins
        info.config.AutoTuneInterval = 3600
        info.config.btcDifficultyURL = btcDifficultyURL
        info.config.btcURL = btcURL
        /*
         * Check whether calculated HashRate is <> 3% of { DesiredHashRate or OptimalHashRate }
         * If yes, update the job status with the right message and exit
         * REVISIT!!!! Discuss with PLM
         * Discussed with Sheldon. For now, Allowing User option and not impose any limit
         * Therefore, commenting the following code
         */
        /*
        if (!TuneOperationMode && parseFloat(currentDesiredHashRate) > 0) {
            const lowerBound = currentDesiredHashRate * 0.97; // 3% below current hash rate
            const upperBound = currentDesiredHashRate * 1.03; // 3% above current hash rate
            if (info.config.TargetHashRate >= lowerBound && info.config.TargetHashRate <= upperBound) {
              setSubmitMsg("hrrange");
              setSuccessModalOpen(true);
              setIsLoading1(false);
              return
            }
        }
        */
        const postConfig = await api.PostMultiDGConfig(token, info);
        //console.log(postConfig)
        if (postConfig === undefined || !postConfig.hasOwnProperty('status')) {
          setSubmitMsg("unknown");
          setSuccessModalOpen(true);
        } else if (postConfig.status === 'ok') {
            /*
             * Once the Tune Configuration is successfully saved to the DB,
             * and if the Tune Operation Mode is 'Manual', set the Desired
             * Hash Rate to the Miners of the Group
             */
            const tuneResponses = [];
            const allMessages = {};

            for (const dgname of info.dglist) {
              let tunedata = {}
              tunedata.dgname = dgname
              if (!TuneOperationMode || PowerOperationMode) {
                console.log("Send Manual Tune Operation");
                const tuneResp = await api.PostManualTune(token, tunedata);
                tuneResponses.push({ dgname, ...tuneResp });
              } else {
                console.log("Send Auto Tune Operation");
                const tuneResp = await api.PostAutoTune(token, tunedata);
                tuneResponses.push({ dgname, ...tuneResp });
              }
            }
            let b_fail = false
            for (const response of tuneResponses) {
              if (response.status === 'ok') {
                const match = response.msg.match(/job ([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12})/i);
                const jobId = match ? match[1] : 'N/A';
                allMessages[response.dgname] = `Please track job id ${jobId} in the Jobs tab`;
              } else if (response.status === 'error') {
                allMessages[response.dgname] = response.msg;
                b_fail = true
              } else {
                allMessages[response.dgname] = "Unknown Error";
                b_fail = true
              }
            }
            // const newMessage = JSON.stringify(allMessages, null, 2);
            setmanualTuneMsg(allMessages);
            if (b_fail === false) {
              setSubmitMsg("manualtuneok");
            } else {
              setSubmitMsg("manualtuneerror");
            }
            setSuccessModalOpen(true);
        } else {
          setSubmitMsg("error");
          setmanualTuneMsg(postConfig.msg)
          setSuccessModalOpen(true);
        }
      }
      setIsLoading1(false);
  }

  const clearHandler = async() => {
    setinputDesiredHashRate('')
    setTuneOperationMode(false)
    setPowerOperationMode(false)
    setPowerConstraintMin('')
    setPowerConstraintMax('')
    setinputRampUpDown('')
    setinputEnergyPrice('')
  }

  useEffect(() => {
    const runFn = async () => {
        const token = await getAccessTokenSilently();
        const isAllowed = await api.IsOperationAllowed(token);
        if (isAllowed === undefined) {
          validUser = 1
          setSubmitMsg('something')
          setSuccessModalOpen(true);
          return
        } else if (isAllowed.status === 'error') {
          if (isAllowed.errorCode === 1007) {
            validUser = 1
            setSubmitMsg('nouser')
            setSuccessModalOpen(true);
          } else if (isAllowed.errorCode === 1009) {
            validUser = 1
            setSubmitMsg('noauth')
            setSuccessModalOpen(true);
          } else {
            validUser = 1
            setSubmitMsg('fetchfailed')
            setSuccessModalOpen(true);
          }
          console.log("Operation Allowed", isAllowed);
          return
        } else {
          validUser = 0
          setSubmitMsg('ok')
          setSuccessModalOpen(true);
        }
        const deviceGroups = await api.GetDGNames(token);
        if (deviceGroups.status === 'error') {
            if (deviceGroups.errorCode === 1007) {
                validUser = 1
                setSubmitMsg('nouser')
                setSuccessModalOpen(true);
            } else {
                validUser = 1
                setSubmitMsg('fetchfailed')
                setSuccessModalOpen(true);
            }
        } else {
          const groupList = Object.keys(deviceGroups)
          var minerGroupList = [];
          for (let i=0; i < groupList.length; i++) {
              minerGroupList.push(groupList[i]);
          }
          setMinerGroups(minerGroupList)
          localStorage.setItem(DVFS_GROUP_LIST, JSON.stringify(minerGroupList));
          validUser = 0
      }
    }
    runFn();
}, [getAccessTokenSilently]);

  useEffect(() => {
    const runFn = async () => {
      if (minerGroup === '') {
        return
      }
      const token = await getAccessTokenSilently();
      const isAllowed = await api.IsOperationAllowed(token);
      if (isAllowed === undefined) {
        validUser = 1
        setSubmitMsg('something')
        setSuccessModalOpen(true);
        return
      } else if (isAllowed.status === 'error') {
        if (isAllowed.errorCode === 1007) {
          validUser = 1
          setSubmitMsg('nouser')
          setSuccessModalOpen(true);
        } else if (isAllowed.errorCode === 1009) {
          validUser = 1
          setSubmitMsg('noauth')
          setSuccessModalOpen(true);
        } else {
          validUser = 1
          setSubmitMsg('fetchfailed')
          setSuccessModalOpen(true);
        }
        console.log("Operation Allowed", isAllowed);
        return
      } else {
        validUser = 0
        setSubmitMsg('ok')
        setSuccessModalOpen(true);
      }
      const info = {}
      info.dgName = minerGroup
      setinputEnergyPrice('')
      setinputDesiredHashRate('')
      setcurrentDesiredHashRate('')
      setinputRampUpDown('')
      setTuneOperationMode(false)
      setPowerOperationMode(false)
      setPowerConstraintMin('')
      setPowerConstraintMax('')
      let op = 'getdginfo'
      const deviceGroupInfo = await api.GetDGInfo(token, info, op);
      //  console.log(JSON.stringify(deviceGroupInfo))
      if (deviceGroupInfo === undefined || deviceGroupInfo === null) {
        validUser = 1
        setSubmitMsg("something");
        setSuccessModalOpen(true);
      } else if (deviceGroupInfo.status === 'error') {
        validUser = 1
        if (deviceGroupInfo.errorCode === 1007) {
          setSubmitMsg("nouser");
        } else if (deviceGroupInfo.errorCode === 1017) {
          setSubmitMsg("minergroup");
          setMinerGroup('');
        }
        setSuccessModalOpen(true);
      }  else if (!deviceGroupInfo.hasOwnProperty('devicegroup')) {
        validUser = 0
      } else {
        validUser = 0
        if (deviceGroupInfo.devicegroup.hasOwnProperty('energyPrice') && deviceGroupInfo.devicegroup.energyPrice !== undefined &&
            deviceGroupInfo.devicegroup.energyPrice !== null) {
              setinputEnergyPrice(String((deviceGroupInfo.devicegroup.energyPrice).toFixed(3)));
        }
        if (deviceGroupInfo.devicegroup.hasOwnProperty('TargetHashRate') && deviceGroupInfo.devicegroup.TargetHashRate !== undefined &&
            deviceGroupInfo.devicegroup.TargetHashRate !== null) {
              setinputDesiredHashRate(String((deviceGroupInfo.devicegroup.TargetHashRate).toFixed(2)));
              setcurrentDesiredHashRate(String((deviceGroupInfo.devicegroup.TargetHashRate).toFixed(2)));
              // if (deviceGroupInfo.devicegroup.hasOwnProperty('TuneOperationMode') && deviceGroupInfo.devicegroup.TuneOperationMode !== undefined &&
              //     deviceGroupInfo.devicegroup.TuneOperationMode !== null &&
              //     !deviceGroupInfo.devicegroup.TuneOperationMode &&
              //     deviceGroupInfo.devicegroup.hasOwnProperty('PowerOperationMode') &&
              //     !deviceGroupInfo.devicegroup.PowerOperationMode) {
              //     setinputDesiredHashRate(String((deviceGroupInfo.devicegroup.TargetHashRate).toFixed(2)));
              //     setcurrentDesiredHashRate(String((deviceGroupInfo.devicegroup.TargetHashRate).toFixed(2)));
              // }
        }
        if (deviceGroupInfo.devicegroup.hasOwnProperty('RampUpDownTimeInSeconds') && deviceGroupInfo.devicegroup.RampUpDownTimeInSeconds !== undefined &&
           deviceGroupInfo.devicegroup.RampUpDownTimeInSeconds !== null)
            setinputRampUpDown(String(deviceGroupInfo.devicegroup.RampUpDownTimeInSeconds))

        if (deviceGroupInfo.devicegroup.hasOwnProperty('TuneOperationMode') && deviceGroupInfo.devicegroup.TuneOperationMode !== undefined &&
            deviceGroupInfo.devicegroup.TuneOperationMode !== null)
            setTuneOperationMode(deviceGroupInfo.devicegroup.TuneOperationMode);

        if (deviceGroupInfo.devicegroup.hasOwnProperty('PowerOperationMode') && deviceGroupInfo.devicegroup.PowerOperationMode !== undefined &&
            deviceGroupInfo.devicegroup.PowerOperationMode !== null) {
            setPowerOperationMode(deviceGroupInfo.devicegroup.PowerOperationMode);
            setShowBanner(deviceGroupInfo.devicegroup.PowerOperationMode);
        }

        if (deviceGroupInfo.devicegroup.hasOwnProperty('PowerConstraintMin') && deviceGroupInfo.devicegroup.hasOwnProperty('PowerConstraintMax') &&
            deviceGroupInfo.devicegroup.PowerConstraintMin !== undefined && deviceGroupInfo.devicegroup.PowerConstraintMin !== null &&
            deviceGroupInfo.devicegroup.PowerConstraintMax !== undefined && deviceGroupInfo.devicegroup.PowerConstraintMax !== null &&
            deviceGroupInfo.devicegroup.PowerConstraintMin !== -1 && deviceGroupInfo.devicegroup.PowerConstraintMin !== -1) {
            setPowerConstraintMin(deviceGroupInfo.devicegroup.PowerConstraintMin);
            setPowerConstraintMax(deviceGroupInfo.devicegroup.PowerConstraintMax);
        }
      }
    }
    runFn();
  }, [minerGroup]);

  const applyTuneConfig = async () => {
    console.log('applyTuneConfig', applyGroups)
    await submitMultiDGHandler(applyGroups)
    setIsLoading1(false);
    setShowPopup(false);
    return
  }

  const labelTHSValue = `Range ${constants.MINTHS} - ${constants.MAXTHS} THs`;
  const labelMinPowerValue = `Min ${constants.MINPOWER} Watts`;
  const labelMaxPowerValue = `Max ${constants.MAXPOWER} Watts`;


  if (validUser === 0) {
    return (
      <Box>
        {/* {showBanner && (
          <Box sx={{ mt: 2, backgroundColor: 'white', color: 'gray', padding: '5px', display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" style={{ color: 'gray', fontWeight: 'bolder'}} className="blinking-text">Note: </Typography>
          <Typography variant="body1" style={{ marginLeft: '10px' }}>Miner performance may differ from tuning parameters due to Power Constraints</Typography>
          <IconButton size="small" color="inherit" onClick={handleBannerClose} sx={{ ml: 'auto' }}>
          <CloseIcon />
          </IconButton>
          </Box>
        )} */}
        <br/>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px', textAlign: 'left' }}>
            <span style={{ marginRight: '10px' }}>Load Tune Configuration From:</span>
            <Autocomplete
              disablePortal
              onChange={(event, value) => handleMinerGroup(value)}
              sx = {{
              width: '200px',
              }}
              disableClearable
              id="mode"
              value = {minerGroup}
              options={minerGroups}
              getOptionLabel={(option) =>
                option === 'Teraflux Group' ? 'Default Group' : option
              }
              renderOption={(props, option, { selected }) => (
              <li {...props}>
                    {option === 'Teraflux Group' ? 'Default Group' : option}
              </li>
              )}
              getOptionSelected={(option, value) => option === value}
              renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Miner Group" aria-label="Select mode" />
              )}
            />
        </div>

        {minerGroup && (
          <Box>
        <Box sx={{ mt: 2 }}>
          <PaperWrap>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
            <Typography variant="h7" component="div" sx={{ mr: '5px', paddingRight: '10px'}}>
              Power Constraint per Miner
            </Typography>
            <Typography variant="h7" component="div" sx={{ mr: '5px' }}>
              ON
            </Typography>
              <label className={`radio-button ${PowerOperationMode ? 'active' : ''}`}>
                <input
                  type="radio"
                  value="true"
                  name="PowerOperation"
                  checked={PowerOperationMode}
                  onChange={handlePowerOperationMode}
                />
                <div className="radio-button-indicator"></div>
              </label>
              <Typography variant="h7" component="div" sx={{ ml: '10px', mr: '5px' }}>
                OFF
              </Typography>
              <label className={`radio-button ${!PowerOperationMode ? 'active' : ''}`}>
                <input
                  type="radio"
                  value="false"
                  name="PowerOperation"
                  checked={!PowerOperationMode}
                  onChange={handlePowerOperationMode}
                />
                <div className="radio-button-indicator"></div>
              </label>
          </Box>
          {PowerOperationMode && (
            <Box sx={{ mt: 2 }}>
              <br/>
              <Typography variant="caption" sx={{ color: 'black', fontFamily: 'Arial', fontSize: '12px' }}>
              Power Constraints should be Off, to allow AutoTune mode. AutoTune mode sets the HashRate (optimal) for maximum profitability, every hour
              </Typography>
            </Box>
          )}
        </PaperWrap>
        </Box>
        {(PowerOperationMode) ?
          <Box sx={{ mt: 2 }}>
          <PaperWrap>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h7" component="div" sx={{ mt: '20px', width: '150px' }}>Minimum Power</Typography>
              <TextField
                type="text"
                value={powerConstraintInputMin}
                onChange={handlePowerConstraintMin}
                sx={{ ml: '5px', mt: '10px', backgroundColor: '#f5f5f5' }}
                label={labelMinPowerValue}
                error={PMinerror.error}
                helperText={PMinerror.helperText}
                margin="dense"
              />
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h7" component="div" sx={{ mt: '20px' , width: '150px'}}>Maximum Power</Typography>
              <TextField
                type="text"
                value={powerConstraintInputMax}
                onChange={handlePowerConstraintMax}
                sx={{ ml: '5px', mt: '10px', backgroundColor: '#f5f5f5' }}
                label={labelMaxPowerValue}
                error={PMaxerror.error}
                helperText={PMaxerror.helperText}
                margin="dense"
              />
            </Box>
          </PaperWrap>
          </Box> :
        <Box sx={{ mt: 2 }}>
          <PaperWrap>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
            <Typography variant="h7" component="div" sx={{ mr: '5px', paddingRight: '10px' }}>
                Mode of Tune
              </Typography>
              <Typography variant="h7" component="div" sx={{ mr: '5px' }}>
                Auto
              </Typography>
              <label className={`radio-button ${TuneOperationMode ? 'active' : ''}`}>
                <input
                  type="radio"
                  value="true"
                  name="TuneOperation"
                  checked={TuneOperationMode}
                  onChange={handleTuneOperationMode}
                  disabled={PowerOperationMode}
                />
                <div className="radio-button-indicator"></div>
              </label>
              <Typography variant="h7" component="div" sx={{ ml: '10px', mr: '5px' }}>
                Manual
              </Typography>
              <label className={`radio-button ${!TuneOperationMode ? 'active' : ''}`}>
                <input
                  type="radio"
                  value="false"
                  name="TuneOperation"
                  checked={!TuneOperationMode}
                  onChange={handleTuneOperationMode}
                />
                <div className="radio-button-indicator"></div>
              </label>
          </Box>
          </PaperWrap>
        </Box>}
        {(TuneOperationMode) ?
          <Box sx={{ mt: 2}}>
            <PaperWrap>
            <Box sx={{ display: 'flex'}}>
            <Typography variant="h7" component="div" sx={{mt: '20px', width: '180px'}} >Electricity Cost per kWh</Typography>
            <TextField
                type="text"
                value={inputEnergyPrice}
                label="Amount in USD"
                onChange={handleinputEnergyPriceChange}
                sx={{
                  mt: '10px',
                  ml: '5px',
                  backgroundColor: '#f5f5f5',
                }}
              />
            </Box>
            <Box sx={{ display: 'flex'}}>
            <Typography variant="h7" component="div" sx={{mt: '20px', width: '135px'}} >BTC Information</Typography>
                <Typography
                  variant="caption" // Choose the appropriate typography variant based on your use case
                  sx={{
                    mt: '10px',
                    ml: '5px',
                    width: 275,
                    padding: '8px',
                  }}
                >
                BTC Price Retrieved From https://api.blockchain.info/ticker
                </Typography>
                <Typography
                  variant="caption" // Choose the appropriate typography variant based on your use case
                  sx={{
                    mt: '10px',
                    ml: '5px',
                    width: 275,
                    padding: '8px',
                  }}
                >
                Block Subsidy Retrieved From https://blockchain.info/q/bcperblock
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    mt: '10px',
                    ml: '5px',
                    width: 350,
                    padding: '8px',
                  }}
                >
                Block Network Difficulty Retrieved From https://api.blockchain.info/charts/difficulty?format=json
                </Typography>
            </Box>
            </PaperWrap>
          </Box>
        :
          <Box sx={{ mt: 2}}>
            {/* REVISIT!!! Added Energy Price to calculate Cost, Revenue and Profit */}
            <PaperWrap>
            <Box sx={{ display: 'flex'}}>
            <Typography variant="h7" component="div" sx={{mt: '20px', width: '180px'}} >Electricity Cost per kWh</Typography>
            <TextField
                type="text"
                value={inputEnergyPrice}
                label="Amount in USD"
                onChange={handleinputEnergyPriceChange}
                sx={{
                  mt: '10px',
                  ml: '5px',
                  backgroundColor: '#f5f5f5',
                }}
              />
            </Box>
            {!PowerOperationMode && (
              <Box sx={{ display: 'flex'}}>
                <Typography variant="h7" component="div" sx={{mt: '20px' , width: '260px'}} >Desired Hashrate per Miner (TH/s)</Typography>
                <TextField
                value={inputDesiredHashRate}
                onChange={handleinputDesiredHashRate}
                sx = {{ml: '5px', mt: '10px', backgroundColor: '#f5f5f5' }}
                label={labelTHSValue}
                error={error.error}
                helperText={error.helperText}
                margin="dense"
                />
              </Box>)}
              <Box sx={{ display: 'flex'}}>
            <Typography variant="h7" component="div" sx={{mt: '20px', width: '135px'}} >BTC Information</Typography>
                <Typography
                  variant="caption" // Choose the appropriate typography variant based on your use case
                  sx={{
                    mt: '10px',
                    ml: '5px',
                    width: 275,
                    padding: '8px',
                  }}
                >
                BTC Price Retrieved From https://api.blockchain.info/ticker
                </Typography>
                <Typography
                  variant="caption" // Choose the appropriate typography variant based on your use case
                  sx={{
                    mt: '10px',
                    ml: '5px',
                    width: 275,
                    padding: '8px',
                  }}
                >
                Block Subsidy Retrieved From https://blockchain.info/q/bcperblock
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    mt: '10px',
                    ml: '5px',
                    width: 350,
                    padding: '8px',
                  }}
                >
                Block Network Difficulty Retrieved From https://api.blockchain.info/charts/difficulty?format=json
                </Typography>
            </Box>
            </PaperWrap>
          </Box>
        }
      {/* The following is not for September 2023 release */}
        {/* <Box sx={{ mt: 2 }}>
          <PaperWrap>
          <Box sx={{ display: 'flex'}}>
          <Typography variant="h7" component="div" sx={{mt: '20px', width: '200px'}} >RampUp/Down Time (sec) </Typography>
          <TextField value={inputRampUpDown} onChange={handleinputRampUpDown} sx = {{ml: '5px', mt: '10px', backgroundColor: '#f5f5f5' }} label="Range 10 - 900 Seconds"/>
          </Box>
          </PaperWrap>
        </Box> */}
        <Box sx={{ display: 'flex', mb: 5}}>
        <Box sx={{mr: 5}}>
        <Button onClick={clearHandler} type="submit" variant="contained" sx={{ mr: 3 }}>
          Clear
        </Button>
        </Box>
          {/* <button onClick={submitHandler} disabled={isLoading} className="submitButton">
              {isLoading ? 'In Progress...' : TuneOperationMode === false ? 'Save & Tune' : 'Save & Tune'}
          </button> */}
          <Button
              onClick={() => {
                /*
                 * Show the confirmation dialog only if TuneOperationMode is false
                 */
                // if (TuneOperationMode === false) {
                //   setShowPopup(true);
                // } else {
                //   submitHandler();
                //   setIsLoading(true);
                // }
                setShowPopup(true);
              }}
              disabled={isLoading ||
                        (((isElectrictyPriceEdited || inputEnergyPrice.trim() === '') && !ElectrictyPriceFilled) ||
                        ((!PowerOperationMode && (isDesiredHashRateEdited || inputDesiredHashRate.trim() === '')) &&
                          !DesiredHashRateFilled && !TuneOperationMode) ||
                        (((isPMaxEdited || powerConstraintInputMax === '') &&
                          !PMaxFilled && PowerOperationMode) ||
                        ((isPMinEdited || powerConstraintInputMin === '') &&
                          !PMinFilled && PowerOperationMode)))
                      }
              type="submit"
              variant="contained"
          >
              {isLoading ? 'In Progress...' : (TuneOperationMode === false) || (PowerOperationMode === true) ? 'Save & Tune' : 'Save & Tune'}
          </Button>
          {/* Confirmation dialog in case of Manual Tune */}
          {showPopup && (
              <div className="popup">
                <div className="popup-content">
                  <div className="close-icon" onClick={() => setShowPopup(false)}>
                  <FaTimes />
                  </div>
                  <p>Are you sure you want to proceed with Save & Tune?</p>
                  <div className="button-container">
                    <button onClick={handleCancelConfirmation}>No</button>
                    <button onClick={handleConfirmation}>Yes</button>
                  </div>
                </div>
              </div>
          )}
        <div>
          <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg} ManualTuneMsg={manualTuneMsg}/>
        </div>
        </Box>
        {updatedGroups.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Apply Tune Configuration to Group(s):</Typography>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginLeft: 8 }}
                  checked={selectAll}
                  onChange={(event) => handleSelectAll(event)}
                />
                <span>Check this box to select all</span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                value={applyGroups}
                onChange={(event, value) => applyVal(value)}
                sx={{
                  width: 400,
                }}
                disableCloseOnSelect
                id="combo-box-demo3"
                options={updatedGroups}
                getOptionLabel={(option) =>
                  option === 'Teraflux Group' ? 'Default Group' : option
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option === 'Teraflux Group' ? 'Default Group' : option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    aria-label="Select mode"
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        fontSize: "12px",
                      },
                    }}
                  />
                )}
                // renderTags={() => null}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option === 'Teraflux Group' ? 'Default Group' : option} {...getTagProps({ index })} />
                  ))
                }
                defaultValue={[]}
              />
            </Grid>
            {applyGroups.length > 0 && (
              <Grid item xs={12}>
                <Button onClick={applyTuneConfig} variant="contained" disabled={false} sx={{ mr: 0 }}>
                {isLoading1 ? 'In Progress...' : 'Apply'}</Button>
              </Grid>
            )}
            {applyGroups.length <= 0 && (
              <Grid item xs={12}>
                <Button onClick={applyTuneConfig} variant="contained" disabled={true} sx={{ mr: 0 }}>Apply</Button>
              </Grid>
            )}
            </Grid>
          )}
        </Box>
    )}
        <Footer />
      </Box>
    );
  } else if (validUser !== -1 && validUser !== 0) {
    return(
      <div>
      <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg}/>
      </div>);
  } else {
    return <PageLoading />;
  }
};

export default DVFSConfig;