import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import EditUser from "./editUser";
import * as api from '../api/api'
import Footer from '../components/footer';
import CloseIcon from '@mui/icons-material/Close';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import PageLoading from "../components/pageloading";
import PageContainer from "../components/PageContainer";

const SuccessModal = ({ isOpen, onClose, Status }) => {
  console.log('status is', Status)
  let h4Msg = ''
  let pMsg = ''
  switch (Status) {
    case 'ok':
      break;
    case 'fetchfailed':
      h4Msg = "Fetch Failed!"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
    case 'nouser':
        h4Msg = "Authentication Failed!"
        pMsg = "User is not part of the Organization. Please contact your Administrator"
      break;
    default:
      h4Msg = "Unknown Error"
      pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
      break;
  }
  if (Status !== 'ok') {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose} >
          <h4 className="popup-title">{h4Msg}</h4>
          <p className="success-message">{pMsg}</p>
          <div className="button-container">
            <button onClick={onClose}>Close</button>
          </div>
      </Popup>
    );
  }
};


let validUser = -1

const showUser = (user0, usera) => {
  // console.log(user0)
  // console.log(usera)

  return (
    <PageContainer title="User Profile" description="this is the user profile page">
    <div>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell align="right">Name:</TableCell>
            <TableCell align="left">{usera.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Email:</TableCell>
            <TableCell align="left">{user0.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Email Verified:</TableCell>
            <TableCell align="left">{user0.email_verified ? "Yes" : "No"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Role:</TableCell>
            <TableCell align="left">{usera.role}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Address:</TableCell>
            <TableCell align="left">{usera.address}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">Phone:</TableCell>
            <TableCell align="left">{usera.phone}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
    </PageContainer>
  );

}

export var UserA = {};

export const UserProfile = () => {
  const { user: user0, getAccessTokenSilently } = useAuth0();
  const [submitMsg, setSubmitMsg] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);


  const [edit, setEdit] = useState(false);
  const { picture, email } = user0;
  //console.log(user0);

  const [usera, setUsera] = useState({
    name: "",
    phone: "",
    address: "",
    org_id: "",
  });

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  useEffect(() => {
    const fn = async () => {
      const token = await getAccessTokenSilently();
      await api.GetUser(token, (data) => {
        if (data.status === 'error') {
          if (data.errorCode === 1007) {
            validUser = 1
            setSubmitMsg("nouser");
            setSuccessModalOpen(true);
          } else {
            validUser = 1
            setSubmitMsg("fetchfailed");
            setSuccessModalOpen(true);
          }
        } else {
          setSubmitMsg("ok");
          setSuccessModalOpen(true);
          validUser = 0
          console.log('in user', { data: data })
          setUsera(data)
          UserA = data
        }
      })
    }
    fn()

  }, [edit]);
  if (validUser === 0) {
    return (
      <PageContainer title="User Profile" description="this is the user profile page">
      <div>
        <div>
          <Toolbar>
            <div>
              <img
                src={picture}
                alt="Profile"
              />
            </div>
            <Typography variant="h4" component="div" sx={{ padding: 2, flexGrow: 1 }}>
              {email}
            </Typography>
            {edit
              ? <Button onClick={() => setEdit(false)} id="backBtn" variant="outlined">
                Back
              </Button>
              : <Button onClick={() => setEdit(true)} id="editBtn" variant="outlined">
                Edit
              </Button>
            }
          </Toolbar>
        </div>
        {edit
          ? <EditUser usera={usera} setEdit={setEdit} />
          : showUser(user0, usera)
        }
      <Footer sub={true}/>
      </div >
      </PageContainer>
    );
  } else if (validUser !== -1 && validUser !== 0) {
    return(
      <PageContainer title="User Profile" description="this is the user profile page">
      <div>
      <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg} />
      </div>
      </PageContainer>
    )
  } else {
    return <PageContainer title="User Profile" description="this is the user profile page"><PageLoading /></PageContainer>
  }
}

export default UserProfile;
