import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import PaperWrap from '../components/paper-wrap';
import Loading from "../components/loading";
import { TabPanel, a11yProps } from '../components/tab';
import ZTPConfig from "./ztp-config";
import MinerConfig from "./miner-config";
import StatsConfig from "./stats-config";
import ReviewConfig from './review-config';
import * as cfg from './cfg';
import * as api from '../api/api';
import Footer from '../components/footer';

export const Config = () => {
    console.log('config ...')

    const { getAccessTokenSilently } = useAuth0();

    const [value, setValue] = React.useState(0);
    const [ready, setReady] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        const fn = async () => {
            const token = await getAccessTokenSilently();
            await api.GetOrg(token, (data) => {
                if (data.config) {
                    const cfg0 = data.config
                    console.log({ cfg0: cfg0 })
                    cfg.loadCfg(cfg0)
                }
            })
            setReady(true)
        }
        fn()
    }, []);

    return (
        <Box flex={1}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="config">
                    <Tab label="ZTP" {...a11yProps(0)} />
                    <Tab label="Miner" {...a11yProps(1)} />
                    <Tab label="Stats" {...a11yProps(2)} />
                    <Tab label="Review" {...a11yProps(3)} />
                </Tabs>
            </Box>
            {
                ready ? <Box>
                    <TabPanel value={value} index={0}>
                        <PaperWrap><ZTPConfig /></PaperWrap>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <PaperWrap><MinerConfig /></PaperWrap>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <PaperWrap><StatsConfig /></PaperWrap>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <PaperWrap><ReviewConfig /></PaperWrap>
                    </TabPanel> </Box>
                    : ''
            }
            <Footer />
        </Box>
    );
}

export default withAuthenticationRequired(Config, {
    onRedirecting: () => <Loading />,
});
