import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import { env } from "./env.js"

const Auth0ProviderWithHistory = ({ children }) => {
    const history = useHistory();
    const domain = env.REACT_APP_AUTH0_DOMAIN;
    const clientId = env.REACT_APP_AUTH0_CLIENT_ID;
    const audience = env.REACT_APP_AUDIENCE;

    const onRedirectCallback = (appState) => {
        history.push(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            audience={audience}
            useMfa={true}
            advancedOptions={{
                defaultScope: 'openid profile email',
                auth0Client: {
                    factors: {
                        otp: {
                            provider: 'auth0'
                        }
                    }
                }
            }}
        >
            {children}
        </Auth0Provider>
    )

}

export default Auth0ProviderWithHistory;
