function isEmpty(obj) {
    if (!obj)
        return true;

    return Object.keys(obj).length === 0;
}

const goodTime = (ts) => {
    if (isEmpty(ts))
        return ""

    return (new Date(ts)).toISOString().replace("T", " ").split(".")[0]
}

const dateBetweenRange = (dtStr, startDate = new Date('2022-10-03T00:00:00.000Z'), endDate = Date.now()) => {
    if (dtStr.trim() === "") {
        return false
    }

    const isoDate = new Date(dtStr);
    // console.log({
    //     startDate: startDate,
    //     isoDate: isoDate,
    //     endDate: endDate
    // })

    if (isoDate < startDate) {
        return false
    }
    if (isoDate > endDate) {
        return false
    }

    return true
}

const isPrivacyAgreed = (privacyObj, name) => {
    if (isEmpty(privacyObj)) {
        return false
    }

    const nameObj = privacyObj[name]
    // console.log({ nameObj: nameObj })
    if (isEmpty(nameObj)) {
        return false
    }

    const agreedAt = nameObj['agreedAt']
    // console.log({ agreeObj: agreeObj })
    const valid = dateBetweenRange(JSON.stringify(agreedAt))

    return valid
}

export { isEmpty, goodTime, dateBetweenRange, isPrivacyAgreed }