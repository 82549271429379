import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

export default function PaperWrap(props) {
    const { children } = props;

    return (
        <Box
            sx={{
                '& > :not(style)': {
                    m: 1,
                    padding: 5,
                },
            }}
        >
            <Paper elevation={3}>
                {children}
            </Paper>
        </Box>
    );
}
