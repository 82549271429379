import React, {useState, useEffect, useCallback} from "react";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Command from "../config/command";
import PaperWrap from "../components/paper-wrap";
import Footer from '../components/footer';
import Autocomplete from '@mui/material/Autocomplete';
import * as api from '../api/api'
import Modal from 'react-modal';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import CloseIcon from '@mui/icons-material/Close';
import { FaTimes } from 'react-icons/fa';
import '../device/dgstyles.css';
import Button from '@mui/material/Button';
import {
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
} from "@mui/material";
import Chip from '@mui/material/Chip';
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDropzone } from 'react-dropzone';
import PageLoading from "../components/pageloading";
import PageContainer from "../components/PageContainer";


const SuccessModal = ({ isOpen, onClose, Status, resultMsg }) => {
  // console.log('status is', Status, Status, resultMsg)
  let h4Msg = ''
  let pMsg = ''
  switch (Status) {
    case 'ok':
        h4Msg = "Success Message"
        pMsg = resultMsg
        break;
    case 'importok':
        h4Msg = "Success Message"
        pMsg = resultMsg
        break;
    case 'configok':
          h4Msg = "Success Message"
          pMsg = resultMsg
          break;
    case 'getok':
        h4Msg = "Success Message"
        pMsg = resultMsg
        break;
    case 'importunknown':
        h4Msg = "Import Handler Failed!"
        pMsg = "Unable to import the devices"
        break;
    case 'configunknown':
        h4Msg = "Config Handler Failed!"
        pMsg = "Unable to update the config"
    case 'fetchfailed':
        h4Msg = "Fetch Failed!"
        pMsg = "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine"
        break;
    case 'nouser':
        h4Msg = "Authentication Failed!"
        pMsg = "User is not part of the Organization. Please contact your Administrator"
        break;
    case 'unauth':
        h4Msg = "Authentication Failed!"
        pMsg = "User is not authorized to perform this operation"
        validUser = 0
        break;
    case 'geterror':
        h4Msg = "Failed!"
        if (resultMsg === '' || resultMsg === undefined || resultMsg === null) {
          pMsg = "Response from the server is not as expected."
        } else {
          pMsg =  resultMsg
        }
        validUser = 0
        break;
    case 'error':
        h4Msg = "Failed!"
        if (resultMsg === '' || resultMsg === undefined || resultMsg === null) {
          pMsg = "Response from the server is not as expected."
        } else {
          pMsg =  resultMsg
        }
        validUser = 0
        break;
    case 'importerror':
        h4Msg = "Failed!"
        if (resultMsg === '' || resultMsg === undefined || resultMsg === null) {
          pMsg = "Response from the server is not as expected."
        } else {
          pMsg =  resultMsg
        }
        validUser = 0
        break;
    case 'configerror':
        h4Msg = "Failed!"
        if (resultMsg === '' || resultMsg === undefined || resultMsg === null) {
          pMsg = "Response from the server is not as expected."
        } else {
          pMsg =  resultMsg
        }
        validUser = 0
        break;
    default:
        h4Msg = "Unknown Error"
        pMsg = "Unable to fetch the data. Try refreshing the page"
        break;
  }
  if (Status !== '') {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose}>
      <h4 className="popup-title">{h4Msg}</h4>
      <div className="gap"></div>
      {typeof pMsg === 'object' ? (
      <div className="success-message-container" style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(pMsg, null, 2)}</pre>
        <a
            href={`data:application/json;charset=utf-8,${encodeURIComponent(JSON.stringify(pMsg, null, 2))}`}
            download="output.json"
        >
          Download JSON
        </a>
      </div>
      ) : (
        <p className="success-message">{pMsg}</p>
      )}
      <div className="button-container">
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
    );
  }
};

let validUser = -1

const TECHSUPPORT_ORG_ID = 'techsupport_org_id';

export const Techsupport = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [submitMsg, setSubmitMsg] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const { user: user0, getAccessTokenSilently } = useAuth0();
  const [showBanner, setShowBanner] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [inputOrgID, setOrgID] = useState(localStorage.getItem(TECHSUPPORT_ORG_ID) || '');
  const [inputOrgName, setOrgName] = useState('');
  const [inputSuperAdminName, setSuperAdminName] = useState('');
  const [inputDesiredAlertInterval, setDesiredAlertInterval] = useState('');
  const [inputSuperAdminEmail, setSuperAdminEmail] = useState('');
  const [inputCurrentSuperAdminEmail, setCurrentSuperAdminEmail] = useState('');
  const [OverwriteSuperAdmin, setOverwriteSuperAdmin] = useState(false);
  const [onboardMsg, setOnboardMsg] = useState('');
  const [dgChassisList, setDGChassisList] = useState([]);
  const [placeholder, setPlaceholder] = useState('Select Miners from the Organization');
  const [openTechsupportConfirmation, setOpenTechsupportConfirmation] = React.useState(false);
  const [confMessage, setConfMessage] = React.useState('');
  const [confMessage1, setConfMessage1] = React.useState('');
  const [jobMessage, setJobMessage] = React.useState([]);
  const [getTSfail, setGetTSfail] = React.useState(false);

  const [error, setError] = React.useState({
    error: false,
    helperText: "",
  })

  const [isLoading, setIsLoading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [isDeregister, setIsDeregistering] = useState(false);
  const [isRemoveDevices, setIsRemoveDevices] = useState(false);
  const [isRemoveOrg, setIsRemoveOrg] = useState(false);
  const [isUpdateOrgConfig, setIsUpdateOrgConfig] = useState(false);
  const [dglen, setDGDeviceLen] = React.useState(0);
  const [dgDeviceList1, setDGDevices1] = useState([]);
  const [dgDeviceList, setDGDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [tsDevices, setTSDevices] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [generateTS, setGenerateTS] = useState(false);
  const [jobTS, setJobTS] = useState(false);
  const [org, setOrg] = useState({
    org_id: "",
    name: "",
    member: [],
    owner: "",
  });


  const [fileContent, setFileContent] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const [ztpfileContent, setZTPFileContent] = useState(null);
  const [ztpsearchQuery, setZTPSearchQuery] = useState('');

// Assuming fileContent.data is an array of objects
const filteredData = fileContent && fileContent.data
  ? fileContent.data.filter((item) =>
      JSON.stringify(item).toLowerCase().includes(searchQuery.toLowerCase())
    )
  : [];

  const ztpfilteredData = ztpfileContent && ztpfileContent.data
  ? ztpfileContent.data.filter((item) =>
      JSON.stringify(item).toLowerCase().includes(ztpsearchQuery.toLowerCase())
    )
  : [];


  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };


  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    // Read the content of the file
    const reader = new FileReader();
    reader.onload = () => {
      const content = reader.result;

      // Parse the JSON content
      try {
        const jsonData = JSON.parse(content);
        // Check if jsonData is an object
        if (jsonData && typeof jsonData === 'object') {
          setFileContent({
            isValid: true,
            data: Array.isArray(jsonData) ? jsonData : [jsonData],
          });
        } else {
          throw new Error('Invalid JSON format. Please upload a valid JSON file.');
        }
      } catch (error) {
        setFileContent({
          isValid: false,
          errorMessage: 'Invalid JSON format. Please upload a valid JSON file.',
        });
      }
    };
    reader.readAsText(file);
  }, []);

  const onDropConfig = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    // Read the content of the file
    const reader = new FileReader();
    reader.onload = () => {
      const content = reader.result;

      // Parse the JSON content
      try {
        const jsonData = JSON.parse(content);
        if (jsonData && typeof jsonData === 'object') {
          setZTPFileContent({
            isValid: true,
            data: Array.isArray(jsonData) ? jsonData : [jsonData],
          });
        } else {
          throw new Error('Invalid JSON format. Please upload a valid JSON file.');
        }
      } catch (error) {
        setZTPFileContent({
          isValid: false,
          errorMessage: 'Invalid JSON format. Please upload a valid JSON file.',
        });
      }
    };
    reader.readAsText(file);
  }, []);

  const {
    getRootProps: getRootPropsConfig,
    getInputProps: getInputPropsConfig,
    isDragActive: isDragActiveConfig,
  } = useDropzone({ onDrop: onDropConfig });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });


  useEffect(() => {
    const fn = async () => {
      const token = await getAccessTokenSilently();
      await api.GetUserTechsupport(token, (data) => {
        if (data.status === 'error') {
          if (data.errorCode === 1007) {
            validUser = 1
            setSubmitMsg("nouser");
            setSuccessModalOpen(true);
          } else {
            validUser = 1
            setSubmitMsg("nouser");
            setSuccessModalOpen(true);
          }
        } else {
          setSuccessModalOpen(true);
          validUser = 0
        }
      })
    }
    fn()
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const runFn = async () => {
      console.log('Second useEffect')
      setSubmitMsg('')
      return
    }
    runFn();
  }, [inputOrgID]);

  const clearHandler = async() => {
    setOnboardMsg('')
    setOrgID('')
    setOrgName('')
    setSuperAdminName('')
    setDesiredAlertInterval('')
    setSuperAdminEmail('')
    setCurrentSuperAdminEmail('')
    setFileContent(null)
    setZTPFileContent(null)
    setSelectedDevices([]);
    setDGDevices([]);
    setDGDevices1([]);
    setDGChassisList({})
    setDGDeviceLen(0);
    setTSDevices([]);
    setSelectAll(false);
  }

  const GetHandler = async() => {
    const token = await getAccessTokenSilently();
    const info = {}
    info.org_id = inputOrgID
    info.superadmin_email = inputSuperAdminEmail
    info.method = 'fetch'
    const fetchOnboard = await api.PostOnboard(token, info);
    if (fetchOnboard === undefined || !fetchOnboard.hasOwnProperty('status') || fetchOnboard.status === 'error') {
      console.log("Failed to fetch the org id details")
      setSubmitMsg("geterror");
      setOnboardMsg(fetchOnboard.msg)
      setSuccessModalOpen(true);
      setOrgID('')
      setOrgName('')
      setSuperAdminName('')
      setSuperAdminEmail('')
      setCurrentSuperAdminEmail('')
    } else {
      const deviceGroupInfo = await api.GetOrgDevices(token, info);
      // console.log("Miner Org Device list ", JSON.stringify(deviceGroupInfo));
      if (deviceGroupInfo === undefined || deviceGroupInfo === null ||
        (deviceGroupInfo.hasOwnProperty('status') && deviceGroupInfo.status === 'error')) {
        console.log('deviceGroupInfo is undefined or null');
        setSelectedDevices([]);
        setTSDevices([]);
        setDGDevices([]);
        setDGDevices1([]);
        setDGChassisList({})
        setDGDeviceLen(0);
      } else if (deviceGroupInfo.hasOwnProperty('status') && deviceGroupInfo.status === 'ok') {
        if (
          deviceGroupInfo.devicelist &&
          deviceGroupInfo.devicelist.length > 0
        ) {
          setDGDeviceLen(deviceGroupInfo.devicelist.length);
          setDGDevices(deviceGroupInfo.devicelist);
          setDGDevices1(deviceGroupInfo.devicelist);
          setDGChassisList(deviceGroupInfo.chassislist);
          setTSDevices(deviceGroupInfo.devicelist);
          setSelectedDevices([]);
        } else {
          setSelectedDevices([]);
          setTSDevices([]);
          setDGDevices([]);
          setDGDevices1([]);
          setDGChassisList({})
          setDGDeviceLen(0);
        }
      }
      setSubmitMsg("getok");
      setOnboardMsg("Successfully fetched the organization details")
      setSuccessModalOpen(true);
      setOrgID(fetchOnboard.org_id)
      setOrgName(fetchOnboard.org_name)
      setSuperAdminName(fetchOnboard.name)
      setSuperAdminEmail(fetchOnboard.email)
      setTSDevices([]);
    }
    setShowPopup(false);
  }

  const SaveCSDesiredAlertInterval = async() => {
    const token = await getAccessTokenSilently();
    const info = {}
    info.desiredAlertInterval = parseInt(inputDesiredAlertInterval)
    if (isNaN(info.desiredAlertInterval)) {
      setSubmitMsg("geterror");
      setOnboardMsg("Interval should be a number")
      setSuccessModalOpen(true);
      setDesiredAlertInterval('')
      return
    }
    const resp = await api.PostDesiredAlertIntervalCSTeam(token, info);
    if (resp === undefined || !resp.hasOwnProperty('status') || resp.status === 'error') {
      console.log("Failed to update")
      setSubmitMsg("geterror");
      setOnboardMsg(resp.msg)
      setSuccessModalOpen(true);
      setDesiredAlertInterval('')
    } else {
      setSubmitMsg("getok");
      setOnboardMsg("Successfully saved the interval")
      setSuccessModalOpen(true);
    }
    setShowPopup(false);
  }

  const handleCancelConfirmation = () => {
    setShowPopup(false);
  };
  const handleConfirmation = () => {
    submitHandler();
    setIsLoading(false);
    setShowPopup(false);
  };

  const handleImportDevices = async (org_id, jsonData) => {
    console.log('Org ID:', org_id);
    console.log('Imported Devices:', jsonData)
    setIsImporting(true);

    if (org_id === undefined || org_id === null || org_id.trim() === '') {
      console.log('Org ID is empty')
      setSubmitMsg("importerror");
      setOnboardMsg("Org ID is empty")
      setSuccessModalOpen(true);
      setIsImporting(false);
      return
    }
    if (jsonData === undefined || jsonData === null || jsonData.length === 0) {
      console.log('Uploaded JSON file either empty or invalid')
      setSubmitMsg("importerror");
      setOnboardMsg("Uploaded JSON file either empty or invalid")
      setSuccessModalOpen(true);
      setIsImporting(false);
      return
    }
    const areKeysPresent = jsonData.every(obj => 'SerialNo' in obj && 'TPMPubKey' in obj && 'ChassisSerialNo' in obj );

    if (!areKeysPresent) {
      console.log('Uploaded JSON file does not have SerialNo or TPMPubKey')
      setSubmitMsg("importerror");
      setOnboardMsg("Imported Devices does not have keys matching SerialNo or TPMPubKey or ChassisSerialNo. Please check and upload again")
      setSuccessModalOpen(true);
      setIsImporting(false);
      return
    }

    const token = await getAccessTokenSilently();
    const info = {}
    info.org_id = org_id
    info.importDevices = jsonData

    const postImportDevices = await api.PostImportDevices(token, info);
    if (postImportDevices === undefined || !postImportDevices.hasOwnProperty('status')) {
      setSubmitMsg("importunknown");
    } else if (postImportDevices.status === 'ok') {
        setSubmitMsg("importok")
        setOnboardMsg(postImportDevices)
        setSuccessModalOpen(true);
    } else {
        setSubmitMsg("importerror");
        setOnboardMsg(postImportDevices.msg)
        setSuccessModalOpen(true);
    }
    setIsImporting(false);
  }

  const handleDeregisterDevices = async (org_id, jsonData) => {
    console.log('Org ID:', org_id);
    console.log('Imported Devices:', jsonData)
    setIsDeregistering(true);

    if (org_id === undefined || org_id === null || org_id.trim() === '') {
      console.log('Org ID is empty')
      setSubmitMsg("importerror");
      setOnboardMsg("Org ID is empty")
      setSuccessModalOpen(true);
      setIsDeregistering(false);
      return
    }
    if (jsonData === undefined || jsonData === null || jsonData.length === 0) {
      console.log('Uploaded JSON file either empty or invalid')
      setSubmitMsg("importerror");
      setOnboardMsg("Uploaded JSON file either empty or invalid")
      setSuccessModalOpen(true);
      setIsDeregistering(false);
      return
    }
    const areKeysPresent = jsonData.every(obj => 'SerialNo' in obj && 'TPMPubKey' in obj);

    if (!areKeysPresent) {
      console.log('Uploaded JSON file does not have SerialNo or TPMPubKey')
      setSubmitMsg("importerror");
      setOnboardMsg("Imported Devices does not have keys matching SerialNo or TPMPubKey. Please check and upload again")
      setSuccessModalOpen(true);
      setIsDeregistering(false);
      return
    }

    const token = await getAccessTokenSilently();
    const info = {}
    info.org_id = org_id
    info.deregisterDevices = jsonData

    const postImportDevices = await api.PostDeregisterDevices(token, info);
    if (postImportDevices === undefined || !postImportDevices.hasOwnProperty('status')) {
      setSubmitMsg("importunknown");
    } else if (postImportDevices.status === 'ok') {
        setSubmitMsg("importok")
        setOnboardMsg(postImportDevices)
        setSuccessModalOpen(true);
    } else {
        setSubmitMsg("importerror");
        setOnboardMsg(postImportDevices.msg)
        setSuccessModalOpen(true);
    }
    setIsDeregistering(false);
  }

  const handleRemoveDevices = async (org_id, jsonData) => {
    console.log('Org ID:', org_id);
    console.log('Imported Devices:', jsonData)
    setIsRemoveDevices(true);

    if (org_id === undefined || org_id === null || org_id.trim() === '') {
      console.log('Org ID is empty')
      setSubmitMsg("importerror");
      setOnboardMsg("Org ID is empty")
      setSuccessModalOpen(true);
      setIsRemoveDevices(false);
      return
    }
    if (jsonData === undefined || jsonData === null || jsonData.length === 0) {
      console.log('Uploaded JSON file either empty or invalid')
      setSubmitMsg("importerror");
      setOnboardMsg("Uploaded JSON file either empty or invalid")
      setSuccessModalOpen(true);
      setIsRemoveDevices(false);
      return
    }
    const areKeysPresent = jsonData.every(obj => 'SerialNo' in obj && 'TPMPubKey' in obj);

    if (!areKeysPresent) {
      console.log('Uploaded JSON file does not have SerialNo or TPMPubKey')
      setSubmitMsg("importerror");
      setOnboardMsg("Imported Devices does not have keys matching SerialNo or TPMPubKey. Please check and upload again")
      setSuccessModalOpen(true);
      setIsRemoveDevices(false);
      return
    }

    const token = await getAccessTokenSilently();
    const info = {}
    info.org_id = org_id; //not used
    info.removeDevices = jsonData

    const postImportDevices = await api.PostRemoveDevices(token, info);
    if (postImportDevices === undefined || !postImportDevices.hasOwnProperty('status')) {
      setSubmitMsg("importunknown");
    } else if (postImportDevices.status === 'ok') {
        setSubmitMsg("importok")
        setOnboardMsg(postImportDevices)
        setSuccessModalOpen(true);
    } else {
        setSubmitMsg("importerror");
        setOnboardMsg(postImportDevices.msg)
        setSuccessModalOpen(true);
    }
    setIsRemoveDevices(false);
  }

  const handleRemoveOrg = async (org_id) => {
    console.log('Org ID:', org_id);
    setIsRemoveOrg(true);

    if (org_id === undefined || org_id === null || org_id.trim() === '') {
      console.log('Org ID is empty')
      setSubmitMsg("importerror");
      setOnboardMsg("Org ID is empty")
      setSuccessModalOpen(true);
      setIsRemoveOrg(false);
      return
    }

    const token = await getAccessTokenSilently();
    const info = {}
    info.org_id = org_id;

    const postOrg = await api.PostRemoveOrgTechsupport(token, info);
    if (postOrg === undefined || !postOrg.hasOwnProperty('status')) {
      setSubmitMsg("importunknown");
    } else if (postOrg.status === 'ok') {
        setSubmitMsg("importok")
        setOnboardMsg("Org removed successfully")
        setSuccessModalOpen(true);
    } else {
        setSubmitMsg("importerror");
        setOnboardMsg(postOrg.msg)
        setSuccessModalOpen(true);
    }
    setIsRemoveDevices(false);
  }


  const handleConfig = async (org_id, jsonData) => {
    console.log('Org ID:', org_id);
    console.log('Config:', jsonData)
    setIsUpdateOrgConfig(true);

    if (org_id === undefined || org_id === null || org_id.trim() === '') {
      console.log('Org ID is empty')
      setSubmitMsg("configerror");
      setOnboardMsg("Org ID is empty")
      setSuccessModalOpen(true);
      setIsUpdateOrgConfig(false);
      return
    }
    if (jsonData === undefined || jsonData === null || jsonData.length === 0) {
      console.log('Uploaded JSON file either empty or invalid')
      setSubmitMsg("configerror");
      setOnboardMsg("Uploaded JSON file either empty or invalid")
      setSuccessModalOpen(true);
      setIsUpdateOrgConfig(false);
      return
    }
    const token = await getAccessTokenSilently();
    const info = {}
    if (!jsonData[0].hasOwnProperty('config')) {
      console.log('No Config object found in the uploaded JSON file')
      setSubmitMsg("configerror");
      setOnboardMsg("No Config object found in the uploaded JSON file")
      setSuccessModalOpen(true);
      setIsUpdateOrgConfig(false);
      return
    }
    if (jsonData[0].config.hasOwnProperty('ztp') || jsonData[0].config.hasOwnProperty('test')) {
        info.org_id = org_id
        info.config = jsonData[0].config
        const postOrgTechsupport = await api.PostOrgTechsupport(token, info);
        if (postOrgTechsupport === undefined || !postOrgTechsupport.hasOwnProperty('status')) {
          setSubmitMsg("configunknown");
        } else if (postOrgTechsupport.status === 'ok') {
            setSubmitMsg("configok")
            setOnboardMsg("Org Config updated successfully")
            setSuccessModalOpen(true);
        } else {
            setSubmitMsg("configerror");
            setOnboardMsg(postOrgTechsupport.msg)
            setSuccessModalOpen(true);
        }
        setIsUpdateOrgConfig(false);
    } else {
      console.log('No ztp object found in the uploaded JSON file')
      setSubmitMsg("configerror");
      setOnboardMsg("No ztp object found in the uploaded JSON file")
      setSuccessModalOpen(true);
      setIsUpdateOrgConfig(false);
      return
    }
  }

  const handleConfigForAllOrgs = async (jsonData) => {
    console.log('Config : ', jsonData)
    setIsUpdateOrgConfig(true);

    if (jsonData === undefined || jsonData === null || jsonData.length === 0) {
      console.log('Uploaded JSON file either empty or invalid')
      setSubmitMsg("configerror");
      setOnboardMsg("Uploaded JSON file either empty or invalid")
      setSuccessModalOpen(true);
      setIsUpdateOrgConfig(false);
      return
    }
    const token = await getAccessTokenSilently();
    const info = {}
    if (!jsonData[0].hasOwnProperty('config')) {
      console.log('No Config object found in the uploaded JSON file')
      setSubmitMsg("configerror");
      setOnboardMsg("No Config object found in the uploaded JSON file")
      setSuccessModalOpen(true);
      setIsUpdateOrgConfig(false);
      return
    }
    if (jsonData[0].config.hasOwnProperty('ztp') || jsonData[0].config.hasOwnProperty('test')) {
      info.org_id = "allOrgs"
      info.config = jsonData[0].config
      const postOrgTechsupport = await api.PostOrgTechsupport(token, info);
      if (postOrgTechsupport === undefined || !postOrgTechsupport.hasOwnProperty('status')) {
        setSubmitMsg("configunknown");
      } else if (postOrgTechsupport.status === 'ok') {
        setSubmitMsg("configok")
        setOnboardMsg("Org Config updated successfully")
        setSuccessModalOpen(true);
      } else {
        setSubmitMsg("configerror");
        setOnboardMsg(postOrgTechsupport.msg)
        setSuccessModalOpen(true);
      }
      setIsUpdateOrgConfig(false);
    } else {
      console.log('No ztp object found in the uploaded JSON file')
      setSubmitMsg("configerror");
      setOnboardMsg("No ztp object found in the uploaded JSON file")
      setSuccessModalOpen(true);
      setIsUpdateOrgConfig(false);
      return
    }
  }

  const submitHandler = async() => {

    const token = await getAccessTokenSilently();
    const info = {}

    setIsLoading(true);

    info.method = 'post'
    info.org_id = inputOrgID
    info.org_name = inputOrgName.trim()
    info.superadmin_name = inputSuperAdminName.trim()
    info.superadmin_email = inputSuperAdminEmail
    info.overwrite = OverwriteSuperAdmin
    info.current_email = inputSuperAdminEmail
    if (OverwriteSuperAdmin) {
      info.current_email = inputCurrentSuperAdminEmail
    }
    const postOnboard = await api.PostOnboard(token, info);
    if (postOnboard === undefined || !postOnboard.hasOwnProperty('status')) {
      setSubmitMsg("unknown");
    } else if (postOnboard.status === 'ok') {
        setOnboardMsg("Successfully onboarded the organization")
        setSubmitMsg("ok")
    } else {
        setSubmitMsg("error");
        setOnboardMsg(postOnboard.msg)
    }
    setSuccessModalOpen(true);
    setIsLoading(false);
  }

  const handleOrgID =  (event) => {
    const value = event.target.value.trim();
    setOrgID(value);
    localStorage.setItem(TECHSUPPORT_ORG_ID, value);
  };
  const handleOrgName =  (event) => {
    const value = event.target.value;
    setOrgName(value);

  };
  const handleSuperAdminName =  (event) => {
    const value = event.target.value;
    setSuperAdminName(value);

  };
  const handleDesiredAlertInterval =  (event) => {
    const value = event.target.value;
    if (/^[0-9]*$/.test(value)) {
      setDesiredAlertInterval(value);
    } else {
      setDesiredAlertInterval('');
    }
  };
  const handleSuperAdminEmail =  (event) => {
    const value = event.target.value.trim();
    setSuperAdminEmail(value);
  };

  const handleCurrentSuperAdminEmail =  (event) => {
    const value = event.target.value.trim();
    setCurrentSuperAdminEmail(value);
  };

  const handleOverwriteSuperAdmin = () => {
    setOverwriteSuperAdmin(!OverwriteSuperAdmin);
  }


  const selectTSCheckVal = (value) => {
    console.log('selectTSCheckVal', value, value.length )
    setTSDevices(value);
    setSelectAll(value.length === dgDeviceList.length);
    if (value.length === dgDeviceList.length) {
      setPlaceholder('');
    } else if (value.length === 0) {
      setPlaceholder(`Select Miners from the Organization`);
    } else {
      setPlaceholder('');
    }
  };

  const handleSelectAll = (event) => {
    const allOptions = dgDeviceList;
    const selectedOptions = event.target.checked ? allOptions : [];
    setTSDevices(selectedOptions);
    setSelectAll(event.target.checked);
  };

  const handleCloseTechsupportConfMessage = () => {
    setOpenTechsupportConfirmation(false);
    setJobMessage([]);
    setConfMessage('');
    setConfMessage1('');
    setGenerateTS(false);
    setJobTS(false);
    setGetTSfail(false);
  };

  const handleGenerateTechsupport = async () => {
    console.log(inputOrgID);
    setGenerateTS(true)
    setTSDevices(tsDevices);
    console.log('handleGenerateTechsupport::', tsDevices);
    if (inputOrgID !== "") {
      const token = await getAccessTokenSilently();
      let request = {};
      request.serials = tsDevices;
      const resp = await api.GenerateTechsupport(token, request);
      if (resp === undefined || resp === null ) {
        setOpenTechsupportConfirmation(true);
        setConfMessage(`Error in generating techsupport for ${inputOrgID}`);
        console.log(`Error in generating techsupport for ${inputOrgID}`)
      } else if (resp.status === "ok") {
        console.log(`Succeeded in generating techsupport for ${inputOrgID}`)
        setOpenTechsupportConfirmation(true);
        let msg = 'Succeeded in generating techsupport for ' + inputOrgID + ' . ' + resp.msg;
        setConfMessage(msg);
      } else {
        setOpenTechsupportConfirmation(true);
        let msg = 'Failed to generate techsupport for ' + inputOrgID + ' . ' + resp.msg;
        setConfMessage(msg);
        console.log(
          `Failed to generate techsupport for ${inputOrgID}`, resp.msg
        );
      }
    } else {
      setOpenTechsupportConfirmation(true);
      setConfMessage(`Please select organization id to generate techsupport`);
    }
    setSelectedDevices([]);
  };

  const handleTSJobStatus = async () => {
    console.log(inputOrgID);
    setTSDevices(tsDevices);
    setJobTS(true)
    console.log('handleTSJobStatus::', tsDevices);
    if (inputOrgID !== "") {
      const token = await getAccessTokenSilently();
      let request = {};
      request.serials = tsDevices;
      request.org_id = inputOrgID;
      const resp = await api.GetTSJobStatus(token, request);
      console.log('handleTSJobStatus::', JSON.stringify(resp))
      if (resp === undefined || resp === null ) {
        setOpenTechsupportConfirmation(true);
        setConfMessage1(`Error in getting techsupport job status for ${inputOrgID}`);
        console.log(`Error in getting techsupport job status for ${inputOrgID}`)
        setGetTSfail(true);
      } else if (resp.status === "ok") {
        console.log(`Succeeded in getting techsupport job statusfor ${inputOrgID}`)
        let msg = resp.msg;
        if (msg.length === 0) {
          msg = 'No techsupport job status found for selected miners';
          setConfMessage1(msg);
          setGetTSfail(true);
        } else {
          setJobMessage(msg);
          setGetTSfail(false);
        }
        setOpenTechsupportConfirmation(true);
      } else {
        setOpenTechsupportConfirmation(true);
        let msg = 'Failed to get techsupport job status for ' + inputOrgID + ' . ' + resp.msg;
        setConfMessage1(msg);
        setGetTSfail(true);
        console.log(
          `Failed to get techsupport job status for ${inputOrgID}`, resp.msg
        );
      }
    } else {
      setOpenTechsupportConfirmation(true);
      setGetTSfail(true);
      setConfMessage1(`Please select organization id to get techsupport job status`);
    }
    setSelectedDevices([]);
  };

  const displayTS = (updatedAt) => {
    const isoDate = updatedAt
    const localDate = new Date(isoDate);
    const nowDate = Date.now();
    const diffTime = Math.abs(nowDate - localDate); // diffTime in milliseconds
    return localDate.toLocaleString()
}

  if (validUser === 0) {
    return (
      <PageContainer title="TechSupport" description="this is the techsupport page">
      <Box>
        <Typography
          variant="h4"
          component="div"
          sx={{ flexGrow: 1, textAlign: "center", color: "#007bff" }}
        >
          Auradine Support Team Operations Center
        </Typography>
        <br/><br/>
        <Box>
          <Box sx={{ mt: 2 }}>
          <PaperWrap>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h7" component="div" sx={{ mt: '20px', width: '150px' }}>Organization ID</Typography>
              <TextField
                type="text"
                value={inputOrgID}
                onChange={handleOrgID}
                sx={{ ml: '5px', mt: '10px', width: '300px', backgroundColor: '#f5f5f5' }}
                label="Enter organization ID"
                margin="dense"
              />
            </Box>
            <Typography variant="caption" sx={{ color: 'black', fontFamily: 'Arial', fontSize: '12px' }}>
              If you know the Org ID value, enter and click on the Get button down below to fetch the organization details
              </Typography>
            </PaperWrap>
            <PaperWrap>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h7" component="div" sx={{ mt: '20px' , width: '150px'}}>Organization Name</Typography>
              <TextField
                type="text"
                value={inputOrgName}
                onChange={handleOrgName}
                sx={{ ml: '5px', mt: '10px', width: '300px', backgroundColor: '#f5f5f5' }}
                label="Enter organization name"
                margin="dense"
              />
            </Box>
            </PaperWrap>
            <PaperWrap>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h7" component="div" sx={{ mt: '20px' , width: '150px'}}>Super Admin Name</Typography>
              <TextField
                type="text"
                value={inputSuperAdminName}
                onChange={handleSuperAdminName}
                sx={{ ml: '5px', mt: '10px', width: '300px', backgroundColor: '#f5f5f5' }}
                label="Enter super admin name"
                margin="dense"
              />
            </Box>
            </PaperWrap>
            <PaperWrap>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h7" component="div" sx={{ mt: '20px' , width: '150px'}}>Super Admin Email</Typography>
              <TextField
                type="text"
                value={inputSuperAdminEmail}
                onChange={handleSuperAdminEmail}
                sx={{ ml: '5px', mt: '10px', width: '300px', backgroundColor: '#f5f5f5' }}
                label="Enter super admin email address"
                margin="dense"
              />
            </Box>
          </PaperWrap>
          </Box>
          <Box sx={{ mt: 2 }}>
          <PaperWrap>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
            <Typography variant="h7" component="div" sx={{ mr: '5px', paddingRight: '10px'}}>
              Replace Super Admin
            </Typography>
            <Typography variant="h7" component="div" sx={{ mr: '5px' }}>
              Yes
            </Typography>
              <label className={`radio-button ${OverwriteSuperAdmin ? 'active' : ''}`}>
                <input
                  type="radio"
                  value="true"
                  name="OverwriteSuperAdmin"
                  checked={OverwriteSuperAdmin}
                  onChange={handleOverwriteSuperAdmin}
                />
                <div className="radio-button-indicator"></div>
              </label>
              <Typography variant="h7" component="div" sx={{ ml: '10px', mr: '5px' }}>
                No
              </Typography>
              <label className={`radio-button ${!OverwriteSuperAdmin ? 'active' : ''}`}>
                <input
                  type="radio"
                  value="false"
                  name="OverwriteSuperAdmin"
                  checked={!OverwriteSuperAdmin}
                  onChange={handleOverwriteSuperAdmin}
                />
                <div className="radio-button-indicator"></div>
              </label>
          </Box>
          {OverwriteSuperAdmin && (
            <Box sx={{ mt: 2 }}>
              <br/>
              <Typography variant="caption" sx={{ color: 'black', fontFamily: 'Arial', fontSize: '12px' }}>
              This will overwrite the existing Super Admin details. Proceed with caution.
              </Typography>
              <Box sx={{ display: 'flex' }}>
              <Typography variant="h7" component="div" sx={{ mt: '20px' , width: '150px'}}>Current SuperAdmin Email</Typography>
              <TextField
                type="text"
                value={inputCurrentSuperAdminEmail}
                onChange={handleCurrentSuperAdminEmail}
                sx={{ ml: '5px', mt: '10px', width: '300px', backgroundColor: '#f5f5f5' }}
                label="Enter current super admin email"
                margin="dense"
              />
            </Box>
            </Box>
          )}
        </PaperWrap>
        </Box>
        <br/>
        <Box sx={{ display: 'flex', mb: 5}}>
          <Box sx={{mr: 5, ml:1}}>
          <Button onClick={clearHandler} type="submit" variant="contained">
            Clear
          </Button>
          </Box>
          <Box sx={{mr: 5}}>
          <Button onClick={GetHandler} type="submit" variant="contained">
            Get
          </Button>
          </Box>
          <Button
              onClick={() => {
                setShowPopup(true);
              }}
              disabled={isLoading ||
                ((inputOrgID !== undefined && inputOrgID !== null && inputOrgID.trim() === '') ||
                 !inputOrgID.match(/^[a-zA-Z0-9 ]*$/) ||
                 (inputOrgName !== undefined && inputOrgName !== null && inputOrgName.trim() === '') ||
                 !inputOrgName.match(/^[a-zA-Z0-9 ]*$/) ||  // Allowing spaces in OrgName
                 (inputSuperAdminName !== undefined && inputSuperAdminName !== null && inputSuperAdminName.trim() === '') ||
                 !inputSuperAdminName.match(/^[a-zA-Z0-9 ]*$/) ||  // Allowing spaces in SuperAdminName
                 (inputSuperAdminEmail !== undefined && inputSuperAdminEmail !== null && inputSuperAdminEmail.trim() === '') ||
                 !inputSuperAdminEmail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/) ||
                 (OverwriteSuperAdmin &&
                  ((inputCurrentSuperAdminEmail !== undefined && inputCurrentSuperAdminEmail !== null && inputCurrentSuperAdminEmail.trim() === '') ||
                   !inputCurrentSuperAdminEmail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/))))
              }
              type="submit"
              variant="contained"
          >
              {isLoading ? 'In Progress...' : 'Onboard'}
          </Button>
            {showPopup && (
                <div className="popup" style={{ zIndex: 1000 }}>
                  <div className="popup-content">
                    <div className="close-icon" onClick={() => setShowPopup(false)}>
                    <FaTimes />
                    </div>
                    <p>Do you want to onboard?</p>
                    <div className="button-container">
                      <button onClick={handleCancelConfirmation}>No</button>
                      <button onClick={handleConfirmation}>Yes</button>
                    </div>
                  </div>
                </div>
            )}
          <div>
            <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg} resultMsg={onboardMsg}/>
          </div>
        </Box>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Left side */}
          <div className="file-uploader-container" style={{ marginLeft: '5px' }}>
            <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
              <input {...getInputProps()} />
              <Button variant="contained" color="primary" component="button">
                {isDragActive ? 'Drop ImportDevice JSON file here' : 'Click or drop ImportDevice JSON file to upload'}
              </Button>
            </div>
            {fileContent && fileContent.isValid && (
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <Popup trigger={<Button variant="contained" color="primary" sx={{ marginRight: '10px' }}>View File Content</Button>} modal>
                  <div className="file-content-popup">
                    <div>
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                    {filteredData.length > 0 ? (
                      <div>
                        <h4>Filtered File Content:</h4>
                        <pre>{JSON.stringify(filteredData, null, 2)}</pre>
                      </div>
                    ) : (
                      <p className="error-message">No matching results</p>
                    )}
                  </div>
                </Popup>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleImportDevices(inputOrgID, fileContent.data)}
                  disabled={isImporting}
                  sx={{ marginRight: '10px' }}
                >
                  Import Devices
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleDeregisterDevices(inputOrgID, fileContent.data)}
                  disabled={isDeregister}
                  sx={{ marginRight: '10px' }}
                >
                  Deregister Devices
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleRemoveDevices(inputOrgID, fileContent.data)}
                  disabled={isRemoveDevices}
                  sx={{ marginRight: '10px' }}
                >
                  Delete Devices
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleRemoveOrg(inputOrgID)}
                  disabled={isRemoveDevices}
                >
                  Remove Org
                </Button>
              </div>
            )}
            {fileContent && !fileContent.isValid && (
              <p className="error-message">{fileContent.errorMessage}</p>
            )}
          </div>
          {/* Right side */}
          <div className="file-uploader-container" >
            <div {...getRootPropsConfig()} className={`dropzone ${isDragActiveConfig ? 'active' : ''}`}>
              <input {...getInputPropsConfig()} />
              <Button variant="contained" color="primary" component="button">
                {isDragActive ? 'Drop Config JSON file here' : 'Click or drop Config JSON file to upload'}
              </Button>
            </div>
            <br />
            {ztpfileContent && ztpfileContent.isValid && (
              <>
                <Popup trigger={<Button variant="contained" color="primary" sx={{ marginRight: '7px' }} >View Config</Button>} modal>
                  <div className="file-content-popup">
                    <div>
                      <input
                        type="text"
                        placeholder="Search..."
                        value={ztpsearchQuery}
                        onChange={(e) => setZTPSearchQuery(e.target.value)}
                      />
                    </div>
                    {ztpfilteredData.length > 0 ? (
                      <div>
                        <h4>Filtered Config Content:</h4>
                        <pre>{JSON.stringify(ztpfilteredData, null, 2)}</pre>
                      </div>
                    ) : (
                      <p className="error-message">No matching results</p>
                    )}
                  </div>
                </Popup>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleConfig(inputOrgID, ztpfileContent.data)}
                  disabled={isUpdateOrgConfig}
                  sx={{ marginRight: '7px' }}
                >
                  Update Org Cfg
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleConfigForAllOrgs(ztpfileContent.data)}
                  disabled={isUpdateOrgConfig}
                  >
                  Update AllOrg's Cfg
                </Button>
              </>
            )}
            {ztpfileContent && !ztpfileContent.isValid && (
              <p className="error-message">{ztpfileContent.errorMessage}</p>
            )}
          </div>
          </div>
          <div>
          {dgDeviceList1.length > 0 && inputOrgID !== '' && (
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px', textAlign: 'left' }}>
                <span style={{ width: '240px', marginRight: '10px' }}>Miners from Source Organization</span>
                <Autocomplete
                    multiple
                    value={tsDevices || []}
                    onChange={(event, value) => selectTSCheckVal(value)}
                    sx={{
                      width: '500px',
                    }}
                    disableCloseOnSelect
                    id="combo-box-demo3"
                    options={dgDeviceList1}
                    getOptionLabel={(option) => {
                      const chassisSerialNo = dgChassisList[option];
                      return chassisSerialNo ? `${option} - ${chassisSerialNo}` : option
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}
                        style={{
                          whiteSpace: 'normal',
                        }}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {dgChassisList[option] ? `${option} - ${dgChassisList[option]}` : option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        aria-label="Select mode"
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        placeholder={placeholder}
                      />
                    )}
                    renderTags={(value, getTagProps) => (
                      <>
                        {value.map((option, index) => (
                          <Chip
                            label={dgChassisList[option] ? `${option} - ${dgChassisList[option]}` : option}
                            {...getTagProps({ index })}
                          />
                        ))}
                      </>
                    )}
                    defaultValue={[]}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginLeft: 8 }}
                    checked={selectAll}
                    onChange={(event) => handleSelectAll(event)}
                  />
                  <span>Select All</span>
                </div>
            </div>
          )}
          <br/>
          {dgDeviceList1.length > 0 && inputOrgID !== '' && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
              <br/>
              <Button onClick={handleGenerateTechsupport} disabled={inputOrgID === '' || tsDevices.length <= 0}
                  type="submit" variant="contained" sx={{ marginRight: '30px' }}>
                    Generate TS
              </Button>
              {generateTS && (
              <Dialog
                  open={openTechsupportConfirmation}
                  onClose={handleCloseTechsupportConfMessage}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {confMessage}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={handleCloseTechsupportConfMessage} type="submit" variant="contained">
                    ok
                  </Button>
                  </DialogActions>
              </Dialog>
              )}
              <Button onClick={handleTSJobStatus} disabled={inputOrgID === '' || tsDevices.length <= 0}
                  type="submit" variant="contained" sx={{ marginRight: '30px' }}>
                    Get TS Job Status
              </Button>
              {jobTS && (
              <Dialog
                open={openTechsupportConfirmation}
                onClose={handleCloseTechsupportConfMessage}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
              {getTSfail === false && jobMessage.length > 0 && (
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {jobMessage.map((item, index) => (
                      <div key={index}>
                          <p>
                            <strong>SerialNo:</strong> {item.SerialNo},{' '}
                            <strong>JobID:</strong> {item.JobID},{' '}
                            <strong>JobStatus:</strong> {item.JobStatus},{' '}
                            <strong>updatedAt:</strong> {displayTS(item.updatedAt)}
                          </p>
                          <br/>
                      </div>
                    ))}
                  </DialogContentText>
                </DialogContent>
              )}
              {getTSfail === true && (
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {confMessage1}
                  </DialogContentText>
                </DialogContent>
              )}
                <DialogActions>
                  <Button onClick={handleCloseTechsupportConfMessage} type="submit" variant="contained">
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              )}
            </Box>
          )}
          </div>
          <Box>
          <PaperWrap>
          <Box sx={{ display: 'flex' }}>
          <Typography variant="h7" component="div" sx={{mt: '10px', width: '330px'}}>Desired interval between the alert notifications</Typography>
              <TextField
                type="text"
                value={inputDesiredAlertInterval}
                onChange={handleDesiredAlertInterval}
                label="In minutes. 0 to turn off alerts"
                sx={{ ml: '5px', mt: '1px', width: '230px', mr: '15px', backgroundColor: '#f5f5f5' }}
                margin="dense"
              />
            <Button onClick={SaveCSDesiredAlertInterval} type="submit" variant="contained">
              Save
            </Button>
          </Box>
          </PaperWrap>
          </Box>
        <Footer />
      </Box>
      </PageContainer>
    );
  } else if (validUser !== -1 && validUser !== 0) {
    return(
      <div>
      <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} Status={submitMsg}/>
      </div>);
  } else {
    return (
      <PageContainer title="TechSupport" description="this is the techsupport page">
        <PageLoading />;
      </PageContainer>
    )
  }
};

export default Techsupport;