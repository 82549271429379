import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const IntervalSelect = ({ name, value, callback }) => {
    const handleChange = (event) => {
        if (callback) {
            callback(event.target.value)
        }
    };

    return (
        <Box sx={{ minWidth: 120, mt: 2, ml: 2 }}>
            <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="simple-select-label">{name}</InputLabel>
                <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={value}
                    label={name}
                    onChange={handleChange}
                >
                    <MenuItem value={60}>60 seconds</MenuItem>
                    <MenuItem value={300}>5 minutes</MenuItem>
                    <MenuItem value={3600}>1 hour</MenuItem>
                    <MenuItem value={86400}>1 day (24 hours)</MenuItem>
                    <MenuItem value={259200}>3 days</MenuItem>
                    <MenuItem value={432000}>5 days</MenuItem>
                    <MenuItem value={604800}>1 week (7 days)</MenuItem>
                    <MenuItem value={2592000}>1 month (30 days)</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}