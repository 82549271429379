import React from 'react';



import { Typography, Box } from '@mui/material';
import img1 from '../assets/logo.png';

const LogoIcon = () => {

  return (
    <Box>
      <img
              src={img1}
              alt="bg"
              style={{
                width: '100%',
                maxWidth: '812px',
              }}
            />
    </Box>
  );
};

export default LogoIcon;
