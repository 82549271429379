import { sub, subMinutes, startOfDay, startOfYear } from "date-fns";
import { toDate, formatInTimeZone } from "date-fns-tz";

const calculateTimeToMidnight = () => {
  const currentDate = new Date();
  const startOfTheDay = startOfDay(currentDate);
  const currentDateTimeMinusOneMinute = subMinutes(currentDate, 1);
  const utcTimeEndString = formatInTimeZone(
    currentDateTimeMinusOneMinute,
    "UTC",
    "yyyy-MM-dd HH:mm:ss.SSS"
  );
  const utcTimeStartString = formatInTimeZone(
    startOfTheDay,
    "UTC",
    "yyyy-MM-dd HH:mm:ss.SSS"
  );

  return {
    utcTimeStartString,
    utcTimeEndString,
  };
};

const calculateTimeForLastSevenDays = () => {
  const currentDate = new Date();
  const currentDateTimeMinusOneMinute = subMinutes(currentDate, 1);
  const dateMinusSevenDays = sub(currentDateTimeMinusOneMinute, {
    days: 7,
  });

  const utcTimeEndString = formatInTimeZone(
    currentDateTimeMinusOneMinute,
    "UTC",
    "yyyy-MM-dd HH:mm:ss.SSS"
  );
  const utcTimeStartString = formatInTimeZone(
    dateMinusSevenDays,
    "UTC",
    "yyyy-MM-dd HH:mm:ss.SSS"
  );

  return {
    utcTimeStartString,
    utcTimeEndString,
  };
};

const calculateTimeForLastThirtyDays = () => {
  const currentDate = new Date();

  const currentDateTimeMinusOneMinute = subMinutes(currentDate, 1);

  const dateMinusThirtyDays = sub(currentDateTimeMinusOneMinute, {
    days: 30,
  });

  const utcTimeEndString = formatInTimeZone(
    currentDateTimeMinusOneMinute,
    "UTC",
    "yyyy-MM-dd HH:mm:ss.SSS"
  );
  const utcTimeStartString = formatInTimeZone(
    dateMinusThirtyDays,
    "UTC",
    "yyyy-MM-dd HH:mm:ss.SSS"
  );

  return {
    utcTimeStartString,
    utcTimeEndString,
  };
};

const calculateTimeForLastSixMonths = () => {
  const currentDate = new Date();
  const currentDateTimeMinusOneMinute = subMinutes(currentDate, 1);
  const dateMinusSixMonths = sub(currentDateTimeMinusOneMinute, { months: 6 });
  const utcTimeEndString = formatInTimeZone(
    currentDateTimeMinusOneMinute,
    "UTC",
    "yyyy-MM-dd HH:mm:ss.SSS"
  );
  const utcTimeStartString = formatInTimeZone(
    dateMinusSixMonths,
    "UTC",
    "yyyy-MM-dd HH:mm:ss.SSS"
  );
  return {
    utcTimeStartString,
    utcTimeEndString,
  };
};

const calculateTimeForYTD = () => {
  const currentDate = new Date();
  const currentDateTimeMinusOneMinute = subMinutes(currentDate, 1);

  const firstDay = startOfYear(currentDate);

  const utcTimeEndString = formatInTimeZone(
    currentDateTimeMinusOneMinute,
    "UTC",
    "yyyy-MM-dd HH:mm:ss.SSS"
  );
  const utcTimeStartString = formatInTimeZone(
    firstDay,
    "UTC",
    "yyyy-MM-dd HH:mm:ss.SSS"
  );

  return {
    utcTimeStartString,
    utcTimeEndString,
  };
};

export {
  calculateTimeToMidnight,
  calculateTimeForLastSevenDays,
  calculateTimeForLastThirtyDays,
  calculateTimeForLastSixMonths,
  calculateTimeForYTD,
};
