import {
  eachDayOfInterval,
  eachHourOfInterval,
  eachMinuteOfInterval,
  eachMonthOfInterval,
  isValid,
  parseISO,
  format,
  roundToNearestMinutes,
} from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

export function createHistMap(start_time, end_time, binsize) {
  // Create empty histogram
  const start = parseISO(format(start_time, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"));
  const end = parseISO(format(end_time, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"));

  let timeSeries;
  if (binsize === "hour") {
    timeSeries = eachHourOfInterval({
      start: start,
      end: end,
    });
  } else if (binsize === "day") {
    timeSeries = eachDayOfInterval({
      start: start,
      end: end,
    });
  } else if (binsize === "month") {
    timeSeries = eachMonthOfInterval({
      start: start,
      end: end,
    });
  }
  const histMap = {};
  timeSeries?.forEach((time) => {
    // eslint-disable-next-line no-undef
    const dateString = format(time, "yyyy-MM-dd HH:mm:ss");
    histMap[dateString] = 0;
  });
  return histMap;
}

export function validateTimeStamps(data) {
  const start = parseISO(data.start);
  const end = parseISO(data.end);
  return isValid(start) && isValid(end);
}

export function validateBinsize(data) {
  if (!new Set(["1h", "1d", "1min"]).has(data.binsize)) {
    console.error('Invalid bin size: "%s"', data.binsize);
    throw new Error(('Invalid bin size: "%s"', data.binsize));
  }
}

export function convertTimestamp(timestamp, binsize) {
  let date;
  if (!isValid(timestamp)) return;

  if (isValid(timestamp)) {
    date = parseISO(timestamp.toISOString());
  }
  if (date !== undefined) {
    if (binsize === "day") {
      date.setHours(0, 0, 0, 0);
    }
    if (binsize === "month") {
      date.setDate(1);
      date.setHours(0, 0, 0, 0);
    }
    if (binsize === "hour") {
      date.setMinutes(0, 0, 0);
    }
  }

  return date !== undefined ? format(date, "yyyy-MM-dd HH:mm:ss") : "";
}

export const generateDataForGraphs = (startTime, endTime, binsize, data) => {
  let keysWithHistMap = [];
  if (data && Array.isArray(data.msg) && data.msg.length > 0) {
    const KeysToBeMapped = Object.keys(data.msg[0]).filter(
      (key) => key !== "time_col"
    );
    keysWithHistMap = KeysToBeMapped.map((key) => {
      return {
        [key]: createHistMap(startTime, endTime, binsize),
      };
    });

    // we extract each item and set the data from the response

    data.msg.forEach((item) => {
      // we iterate over each item pick the keys. Iterate over the keys and look for that key in the list of keysWithHistMap
      // if the key is found we pick the time_col and perform date conversion. We try to find the same date in the histmap
      //if date is found we update the value of the key in the histmap
      Object.keys(item).forEach((key) => {
        const isoTimeStamp = item?.time_col
          ?.replace(" ", "T") // Replace the space with "T"
          .replace(/(\.\d{3})\d+$/, "$1") // Keep only the first 3 digits after the decimal point
          .concat("Z");
        const parsedDate = parseISO(isoTimeStamp);
        const time = convertTimestamp(parsedDate, binsize);
        keysWithHistMap.forEach((histMap) => {
          const key = Object.keys(histMap)[0];
          if (key in item) {
            if (histMap[key][time] !== undefined) {
              histMap[key][time] = item[key];
            }
          }
        });
      });
    });
  }

  return keysWithHistMap;
};

const createGraphData = (lowData, mediumData, highData) => {
  if (!lowData || !mediumData || !highData) return;
  const data = [
    {
      name: "Minimum",
      data: [...lowData],

      sum: 173,
      color: "#FFD7F6",
      type: "spline",
      visible: true,
    },
    {
      name: "Average",
      data: [...mediumData],

      sum: 40,
      color: "#B6C4FF",
      type: "spline",
      visible: true,
    },
    {
      name: "Maximum",
      data: [...highData],
      sum: 39,
      color: "#2D55C9",
      type: "spline",
      visible: true,
    },
  ];
  return data;
};

const provideGraphConfig = (data, xAxisValues) => {
  if (!data || !xAxisValues) return;
  return {
    chart: {
      type: "spline",
      height: 180,
      spacingLeft: 24,
      spacingRight: 24,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      spline: {
        lineWidth: 2,
        lineCap: "round",
        softThreshold: true,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },

    xAxis: {
      lineColor: "#e0e0e0",
      categories: [...xAxisValues],
      tickWidth: 2,
      tickmarkPlacement: "on",
      align: "center", // Align the labels to the center of the tick
      tickColor: "#e0e0e0",
      labels: {
        style: {
          fontFamily: "roboto",
          fontWeight: 400,
          fontSize: "0.8rem",
          color: "#44474E",
        },
      },
    },
    yAxis: {
      startOnTick: true,
      endOnTick: true,
      opposite: false,

      gridLineColor: "#E0E0E0",
      title: {
        text: "",
      },
      min: 0,
      labels: {
        format: "{value:.0f}",
        style: {
          fontFamily: "roboto",
          fontWeight: 400,
          fontSize: "0.8rem",
          color: "#44474E",
        },
      },
    },
    series: [...data],
    legend: {
      enabled: true,
      align: "left",
    },
  };
};

export const generateConfigForGraphs = (data, binSize) => {
  if (!data) return;

  const calculateAverages = (data) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return;
    }

    const numArrays = data.length;
    const numElements = data[0].length;

    const sums = data.reduce((acc, array) => {
      return acc.map((sum, index) => sum + array[index]);
    }, new Array(numElements).fill(0));

    return sums.map((sum) => parseFloat((sum / numArrays).toFixed(2)));
  };

  const hashRateData = data.filter((hashrate) =>
    Object.keys(hashrate)[0].includes("hash")
  );
  const hashRateMin = hashRateData.filter((hashrate) =>
    Object.keys(hashrate)[0].includes("min")
  );
  const hashRateMax = hashRateData.filter((hashrate) =>
    Object.keys(hashrate)[0].includes("max")
  );
  const hashRateAvg = hashRateData.filter((hashrate) =>
    Object.keys(hashrate)[0].includes("avg")
  );

  const xAxisValues =
    hashRateMin[0] && Object.keys(Object.values(hashRateMin[0])[0]);

  const modifiedXAxisValues =
    Array.isArray(xAxisValues) &&
    xAxisValues.map((time) => {
      let utcTimeString = time;
      return binSize === "hour"
        ? format(parseISO(utcTimeString), "hh:mm a")
        : binSize === "day"
        ? format(parseISO(utcTimeString), "MM/dd/yy")
        : format(parseISO(utcTimeString), "MMM''yy");
    });

  const hashRateLowData =
    hashRateMin[0] && Object.values(Object.values(hashRateMin[0])[0]);
  const hashRateAvgData =
    hashRateAvg[0] && Object.values(Object.values(hashRateAvg[0])[0]);
  const hashRateMaxData =
    hashRateMax[0] && Object.values(Object.values(hashRateMax[0])[0]);

  const hashRateGraphData = createGraphData(
    hashRateLowData,
    hashRateAvgData,
    hashRateMaxData
  );
  const hashRateGraphConfig = provideGraphConfig(
    hashRateGraphData,
    modifiedXAxisValues
  );

  //extract power related info and create graph data
  const powerData = data.filter((power) =>
    Object.keys(power)[0].includes("power")
  );
  const powerMin = powerData.filter((power) =>
    Object.keys(power)[0].includes("min")
  );
  const powerMax = powerData.filter((power) =>
    Object.keys(power)[0].includes("max")
  );
  const powerAvg = powerData.filter((power) =>
    Object.keys(power)[0].includes("avg")
  );
  const powerLowData =
    powerMin[0] && Object.values(Object.values(powerMin[0])[0]);
  const powerAvgData =
    powerAvg[0] && Object.values(Object.values(powerAvg[0])[0]);
  const powerMaxData =
    powerMax[0] && Object.values(Object.values(powerMax[0])[0]);

  const powerGraphData = createGraphData(
    powerLowData,
    powerAvgData,
    powerMaxData
  );
  const powerGraphConfig = provideGraphConfig(
    powerGraphData,
    modifiedXAxisValues
  );

  //extract temperature related info and create graph data
  const temperatureData = data.filter((temperature) =>
    Object.keys(temperature)[0].includes("temp")
  );
  const temperatureMin = temperatureData.filter((temperature) =>
    Object.keys(temperature)[0].includes("min")
  );
  const temperatureMax = temperatureData.filter((temperature) =>
    Object.keys(temperature)[0].includes("max")
  );
  const temperatureAvg = temperatureData.filter((temperature) =>
    Object.keys(temperature)[0].includes("avg")
  );

  const tempuratureLowData =
    Array.isArray(temperatureMin) && temperatureMin.length > 0
      ? temperatureMin.map((tempuratureData) => {
          const [key, tempuratureValues] = Object.entries(tempuratureData)[0];
          const tempurature = tempuratureValues
            ? Object.values(tempuratureValues)
            : [];
          return tempurature.map((temp) => Number(temp));
        })
      : [];
  const tempuratureLowAvgData = calculateAverages(tempuratureLowData);

  const tempuratureAvgData =
    Array.isArray(temperatureAvg) && temperatureAvg.length > 0
      ? temperatureAvg.map((tempuratureData) => {
          const [key, tempuratureValues] = Object.entries(tempuratureData)[0];
          const tempurature = tempuratureValues
            ? Object.values(tempuratureValues)
            : [];
          return tempurature.map((temp) => Number(temp));
        })
      : [];
  const tempuratureAvgAvgData = calculateAverages(tempuratureAvgData);

  const tempuratureMaxData =
    Array.isArray(temperatureMax) && temperatureMax.length > 0
      ? temperatureMax.map((tempuratureData) => {
          const [key, tempuratureValues] = Object.entries(tempuratureData)[0];
          const tempurature = tempuratureValues
            ? Object.values(tempuratureValues)
            : [];
          return tempurature.map((temp) => Number(temp));
        })
      : [];
  const tempuratureMaxAvgData = calculateAverages(tempuratureMaxData);

  const tempuratureGraphData = createGraphData(
    tempuratureLowAvgData,
    tempuratureAvgAvgData,
    tempuratureMaxAvgData
  );
  const tempuratureGraphConfig = provideGraphConfig(
    tempuratureGraphData,
    modifiedXAxisValues
  );

  //extract uptime related info and create graph data
  const uptimeData = data.filter((uptime) =>
    Object.keys(uptime)[0].includes("uptime")
  );
  const uptimeMin = uptimeData.filter((uptime) =>
    Object.keys(uptime)[0].includes("min")
  );
  const uptimeMax = uptimeData.filter((uptime) =>
    Object.keys(uptime)[0].includes("max")
  );
  const uptimeAvg = uptimeData.filter((uptime) =>
    Object.keys(uptime)[0].includes("avg")
  );
  const uptimeLowData =
    uptimeMin[0] && Object.values(Object.values(uptimeMin[0])[0]);
  const uptimeAvgData =
    uptimeAvg[0] && Object.values(Object.values(uptimeAvg[0])[0]);
  const uptimeMaxData =
    uptimeMax[0] && Object.values(Object.values(uptimeMax[0])[0]);

  const uptimeLowDataInMins =
    uptimeLowData &&
    uptimeLowData.map((uptime) => roundToNearestMinutes(uptime)?.getMinutes());
  const uptimeAvgDataInMins =
    uptimeLowData &&
    uptimeAvgData.map((uptime) => roundToNearestMinutes(uptime)?.getMinutes());
  const uptimeMaxDataInMins =
    uptimeLowData &&
    uptimeMaxData.map((uptime) => roundToNearestMinutes(uptime)?.getMinutes());

  const uptimeGraphData = createGraphData(
    uptimeLowDataInMins,
    uptimeAvgDataInMins,
    uptimeMaxDataInMins
  );
  const uptimeGraphConfig = provideGraphConfig(
    uptimeGraphData,
    modifiedXAxisValues
  );

  return {
    hashRateGraphConfig,
    powerGraphConfig,
    tempuratureGraphConfig,
    uptimeGraphConfig,
  };
};
