import React from "react";
import { Box, Stack, Typography } from '@mui/material';
import Command from "./command";
import Footer from '../components/footer';

export const StatsConfig = () => {

  console.log('stats config ...')

  return (
    <Box>
      <Stack direction="row">
        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }} >Stats Config</Typography>
      </Stack>
      <Box sx={{ mt: 2 }}>
        <Command label="Summary" name="summary" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Command label="Pools" name="pools" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Command label="Devs" name="devs" />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Command label="Version" name="version" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Command label="Config" name="config" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Command label="Coin" name="coin" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Command label="Devdetails" name="devdetails" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Command label="Stats" name="stats" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Command label="LCD" name="lcd" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Command label="Fan" name="fan" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Command label="Temperature" name="temperature" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Command label="Mode" name="mode" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Command label="PSU" name="psu" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Command label="LED" name="led" />
      </Box>
      <Footer />
    </Box>
  );
};

export default StatsConfig;
