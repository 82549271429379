import Footer from "../components/footer";
import React, { useState, useEffect, useRef } from "react";
import "./dgstyles.css";
import { TabPanel, a11yProps } from "../components/tab";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import * as api from "../api/api";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { InputLabel, Input } from "@mui/material";
import PaperWrap from "../components/paper-wrap";
import { FaTimes } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { gridDensityTotalHeaderHeightSelector } from "@mui/x-data-grid";

export const DeviceGroup = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const { user: user0, getAccessTokenSilently } = useAuth0();

  const [showPopup, setShowPopup] = useState(false);
  const [deviceGroupName, setDeviceGroupName] = useState("");
  const [value, setValue] = React.useState(0);
  const [dlen, setDeviceLen] = React.useState(0);
  const [dglen, setDGDeviceLen] = React.useState(0);

  const [val2, setVal2] = useState([]);
  const [val3, setVal3] = useState([]);
  const [val4, setVal4] = useState([]);

  const [deviceList, setDeviceList] = useState([]);
  const [dgDeviceList1, setDGDevices1] = useState([]);

  const [selectedDevices, setSelectedDevices] = useState([]);
  const [addedDevices, setAddedDevices] = useState([]);

  const [deletedDevices, setDeletedDevices] = useState([]);
  const [movedDevices, setMovedDevices] = useState([]);

  const [dgDeviceList, setDGDevices] = useState([]);

  const [hashInput, setHashInput] = useState("");
  const [powerInput, setPowerInput] = useState("");
  const [rampInput, setRampInput] = useState("");
  const [isAutoOn, setIsAutoOn] = useState(false);
  const [devicesexist, setDevicesExist] = useState(false);
  const [usehrexist, setUseHRExists] = useState(false);
  const [istgthrexist, setisThrExists] = useState(false);
  const [autoexist, setAutoOnExists] = useState(false);
  const [isHashRateOn, setisHashRateOn] = useState(false);
  const [isTargetHashRate, setisTargetHashRateOn] = useState(false);
  const [OptHashRate, setOptHashRate] = useState("");
  const [minerGroup, setMinerGroup] = useState("");
  const [minerGroups, setMinerGroups] = useState([]);
  const [targetGroup, setTargetGroup] = useState("");
  const [takenDevices, setTakenDevices] = useState([]);

  const handleCreateGroup = () => {
    setShowPopup(true);
  };

  const handleClearCreateDG = () => {
    setDeviceGroupName("");
  };

  const handleInputChange = (e) => {
    setDeviceGroupName(e.target.value);
  };

  const handleOkCreateDG = async () => {
    console.log(deviceGroupName);
    if (deviceGroupName.trim() !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = deviceGroupName;
      const resp = await api.AddDG(token, dgdata);
      if (resp === undefined) {
        console.log("failed to create group");
      } else {
        console.log("succeeded in creating group ", JSON.stringify(resp));
      }
      if (!minerGroups.includes(deviceGroupName)) {
        minerGroups.push(deviceGroupName);
      }
      minerGroups.sort();
      setMinerGroups(minerGroups);
    }
    setShowPopup(false);
  };

  // const handleDGMember = async () => {
  //   let dlist1 = selectedDevices;
  //   let dlist2 = addedDevices;

  //   let combinedList = [...dlist1, ...dlist2];

  //   // Create a Set from the combined list to remove duplicates
  //   let uniqueList = [...new Set(combinedList)];

  //   uniqueList = uniqueList.filter((item) => item !== "Select All");
  //   console.log("Selected Miner list to send to BE", uniqueList);
  //   console.log("Selected Miner Group Name", minerGroup);
  //   if (minerGroup !== "") {
  //     const token = await getAccessTokenSilently();
  //     let dgdata = {};
  //     dgdata.dgname = minerGroup;
  //     dgdata.devices = uniqueList;
  //     const resp = await api.AddDGMember(token, dgdata);
  //     if (resp === undefined) {
  //       console.log("failed to add miners to group");
  //     } else {
  //       console.log(
  //         "succeeded in adding miners to group ",
  //         JSON.stringify(resp)
  //       );
  //       await handleMinerGroup(minerGroup);
  //     }
  //   }
  //   console.log(selectedDevices);
  // };

  const handleDGMember = async () => {
    let dlist1 = selectedDevices;
    let dlist2 = addedDevices;

    let combinedList = [...dlist1, ...dlist2];

    // Create a Set from the combined list to remove duplicates
    let uniqueList = [...new Set(combinedList)];
    let filteredListt = dgDeviceList.filter(
      (device) => device !== "Select All"
    );
    uniqueList = uniqueList.filter((item) => item !== "Select All");
    console.log("Selected Miner list to send to BE", uniqueList);
    console.log("Selected Miner Group Name", minerGroup);

    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = minerGroup;
      dgdata.devices = filteredListt;
      const resp = await api.DeleteDGMember(token, dgdata);
      if (resp === undefined) {
        console.log("failed to delete miners from group");
      } else {
        console.log(
          "succeeded in deleting miners from group ",
          JSON.stringify(resp)
        );
        await handleMinerGroup(minerGroup);
      }
    }

    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = minerGroup;
      dgdata.devices = uniqueList;
      const resp = await api.AddDGMember(token, dgdata);
      if (resp === undefined) {
        console.log(`failed to add ${uniqueList} miners to group`);
      } else {
        console.log(
          `succeeded in adding ${uniqueList} miners to group`,
          JSON.stringify(resp)
        );
        await handleMinerGroup(minerGroup);
      }
    }
    console.log(selectedDevices);
  };

  const handleDGdeleteMember = async () => {
    let dlist = deletedDevices;

    dlist = dlist.filter((item) => item !== "Select All");
    console.log("Selected Miner list to send to BE", dlist);
    console.log("Selected Miner Group Name", minerGroup);
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = minerGroup;
      dgdata.devices = dlist;
      const resp = await api.DeleteDGMember(token, dgdata);
      if (resp === undefined) {
        console.log("failed to delete miners from group");
      } else {
        console.log(
          "succeeded in deleting miners from group ",
          JSON.stringify(resp)
        );
        await handleMinerGroup(minerGroup);
      }
    }
  };

  const handleClearDGMoveMember = () => {
    setMovedDevices([]);
    setTargetGroup("");
    console.log("canceled");
  };

  const handleDGMoveMember = async () => {
    await handleMinerGroup(minerGroup);
    console.log("moved now");
    console.log(minerGroup);
    console.log(targetGroup);
    setMovedDevices(movedDevices.filter((device) => device !== "Select All"));
    console.log(movedDevices);

    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.sourcegroup = minerGroup;
      dgdata.destinationgroup = targetGroup;
      dgdata.devices = movedDevices;
      const resp = await api.MoveDGMember(token, dgdata);
      if (resp === undefined) {
        console.log(
          `failed to move ${movedDevices} miners from ${minerGroup} to ${targetGroup}`
        );
      } else {
        console.log(
          `succeeded in moving ${movedDevices} miners from ${minerGroup} to ${targetGroup}`,
          JSON.stringify(resp)
        );
        await handleMinerGroup(minerGroup);
      }
    }

    setTargetGroup("");
  };

  const handleClearDGdeleteMember = () => {
    setDeletedDevices([]);
    console.log("clear the selected miner list", deletedDevices);
  };

  const handleClearDGMember = () => {
    setSelectedDevices([]);
    console.log("clear the selected miner list", selectedDevices);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setAddedDevices([]);
    setDeletedDevices([]);
    setMovedDevices([]);
  };

  const handleIsAutoOn = () => {
    setIsAutoOn(!isAutoOn);
  };
  const handleisHashRateOn = () => {
    setisHashRateOn(!isHashRateOn);
  };

  const handleHashInput = (event) => {
    setHashInput(event.target.value);
  };
  const handlePowerInput = (event) => {
    setPowerInput(event.target.value);
  };
  const handleRampInput = (event) => {
    setRampInput(event.target.value);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const runFn = async () => {
      const token = await getAccessTokenSilently();
      const deviceGroups = await api.GetAllDeviceGroups(token);
      if (deviceGroups === undefined) {
      } else {
        var minerGroupList = [];
        for (let i = 0; i < deviceGroups.length; i++) {
          minerGroupList.push(deviceGroups[i].DGName);
        }
        minerGroupList.sort();
        setMinerGroups(minerGroupList);
      }
    };
    runFn();
  }, [getAccessTokenSilently]);

  const handleMinerGroup = async (value) => {
    setMinerGroup(value);
    const token = await getAccessTokenSilently();
    const info = {};
    info.dgName = value;
    let op = 'getdginfo'
    const deviceGroupInfo = await api.GetDGInfo(token, info, op);
    console.log("Miner Group Info ", deviceGroupInfo);
    if (deviceGroupInfo === undefined || !deviceGroupInfo.devicegroup) {
      setDevicesExist(false);
      setSelectedDevices([]);
      setAddedDevices([]);
      setDeletedDevices([]);
      setMovedDevices([]);
      setDGDevices([]);
      setDGDevices1([]);
      setHashInput("");
      setPowerInput("");
      setRampInput("");
      setAutoOnExists(false);
      setUseHRExists(false);
      setOptHashRate("");
      setisThrExists(false);
      setDGDeviceLen(0);
    } else {
      if (
        deviceGroupInfo.devicegroup.Devices &&
        deviceGroupInfo.devicegroup.Devices.length > 0
      ) {
        setDevicesExist(true);
        setDGDeviceLen(deviceGroupInfo.devicegroup.Devices.length);
        setSelectedDevices(
          deviceGroupInfo.devicegroup.Devices.filter(
            (devices) => devices !== "Select All"
          )
        );
        setAddedDevices(deviceGroupInfo.devicegroup.Devices);
        setDeletedDevices(deviceGroupInfo.devicegroup.Devices);
        setMovedDevices(deviceGroupInfo.devicegroup.Devices);
        setDGDevices(deviceGroupInfo.devicegroup.Devices);
        let dl = deviceGroupInfo.devicegroup.Devices;
        dl.unshift("Select All");
        setDGDevices1(dl);
      } else {
        setDevicesExist(false);
        setSelectedDevices([]);
        setAddedDevices([]);
        setDeletedDevices([]);
        setMovedDevices([]);
        setDGDevices([]);
        setDGDevices1([]);
        setDGDeviceLen(0);
      }
      if (deviceGroupInfo.devicegroup.TargetHashRate) {
        setHashInput(deviceGroupInfo.devicegroup.TargetHashRate);
      } else {
        setHashInput("");
      }
      if (deviceGroupInfo.devicegroup.TargetPower) {
        setPowerInput(deviceGroupInfo.devicegroup.TargetPower);
      } else {
        setPowerInput("");
      }

      if (deviceGroupInfo.devicegroup.RampUpDownTimeInSeconds) {
        setRampInput(deviceGroupInfo.devicegroup.RampUpDownTimeInSeconds);
      } else {
        setRampInput("");
      }

      if (
        deviceGroupInfo.devicegroup.hasOwnProperty("automaticTempProtection")
      ) {
        setAutoOnExists(true);
        setIsAutoOn(deviceGroupInfo.devicegroup.automaticTempProtection);
      } else {
        setAutoOnExists(true);
        setIsAutoOn(false);
      }

      if (deviceGroupInfo.devicegroup.hasOwnProperty("useHashRate")) {
        setisHashRateOn(deviceGroupInfo.devicegroup.useHashRate);
        setUseHRExists(true);
      } else {
        setisHashRateOn(true);
        setUseHRExists(true);
      }

      if (deviceGroupInfo.devicegroup.OptimalHashRate) {
        setOptHashRate(deviceGroupInfo.devicegroup.OptimalHashRate);
      } else {
        setOptHashRate("");
      }
      if (deviceGroupInfo.devicegroup.hasOwnProperty("isTargetHashRate")) {
        setisTargetHashRateOn(deviceGroupInfo.devicegroup.isTargetHashRate);
        setisThrExists(true);
      } else {
        setisTargetHashRateOn(true);
        setisThrExists(true);
      }
    }
    setAddedDevices([]);
    setDeletedDevices([]);
    setMovedDevices([]);
  };

  useEffect(() => {
    const runFn = async () => {
      const token = await getAccessTokenSilently();
      var user;
      await api.GetUser(token, (data) => {
        user = data;
      });
      const devices = await api.GetAllDevices(token);
      if (devices === undefined) {
        setDeviceLen(0);
        setDeviceList([]);
      } else {
        var deviceArray = [];
        for (let i = 0; i < devices.length; i++) {
          deviceArray.push(devices[i].SerialNo);
        }
        let dlen = deviceArray.length;
        setDeviceLen(dlen);
        deviceArray.unshift("Select All");
        setDeviceList(deviceArray);
      }
    };
    runFn();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const runFunc = async () => {
      const token = await getAccessTokenSilently();
      const takenList = [];
      for (const i of minerGroups) {
        const info = {};
        info.dgName = i;
        let op = 'getdginfo'
        const deviceGroupInfo = await api.GetDGInfo(token, info, op);
        let taken = deviceGroupInfo.devicegroup.Devices;
        takenList.push(...taken);
      }
      setTakenDevices(takenList);
    };
    runFunc();
  }, [getAccessTokenSilently, deletedDevices, addedDevices, minerGroups]);

  const checkVal2 = async (value) => {
    if (value.includes("Select All")) {
      setVal2(value);
      setSelectedDevices(deviceList);
      console.log("selected miners 1", value);
    } else {
      if (val2.includes("Select All")) {
        setVal2([]);
        setSelectedDevices([]);
        console.log("selected miners 2");
      } else {
        setVal2(value);
        setSelectedDevices(value);
        console.log("selected miners 3", value);
      }
    }
  };

  const checkVal3 = async (value) => {
    if (value.includes("Select All")) {
      setVal3(value);
      setDeletedDevices(dgDeviceList1);
      console.log("selected miners 4", value);
    } else {
      if (val3.includes("Select All")) {
        setVal3([]);
        setDeletedDevices([]);
        console.log("selected miners 5");
      } else {
        setVal3(value);
        setDeletedDevices(value);
        console.log("selected miners 6", value);
      }
    }
  };

  const checkVal4 = async (value) => {
    if (value.includes("Select All")) {
      setVal4(value);
      setMovedDevices(deviceList);
      console.log("selected miners 7", value);
    } else {
      if (val4.includes("Select All")) {
        setVal4([]);
        setMovedDevices([]);
        console.log("selected miners 8");
      } else {
        setVal4(value);
        setMovedDevices(value);
        console.log("selected miners 9", value);
      }
    }
  };

  const filteredDeviceList =
    dgDeviceList && dgDeviceList.length > 0
      ? dgDeviceList.filter(
          (device) =>
            device.toLowerCase().includes(searchTerm.toLowerCase()) &&
            device !== "Select All" &&
            device !== "device"
        )
      : [];

  const handleDeleteDG = async (item) => {
    const updatedMinerGroups = minerGroups.filter((group) => group !== item);
    const wasSelected = minerGroup === item;

    const token = await getAccessTokenSilently();
    let dgdata = {};
    dgdata.dgname = item;
    const resp = await api.DeleteDG(token, dgdata);
    if (resp === undefined) {
      console.log("failed to delete group");
    } else {
      console.log("succeeded in deleting group ", JSON.stringify(resp));
    }
    updatedMinerGroups.sort();
    setMinerGroups(updatedMinerGroups);
    if (wasSelected) {
      setMinerGroup("");
    }

    setDevicesExist(false);
    setSelectedDevices([]);
    setAddedDevices([]);
    setDeletedDevices([]);
    setMovedDevices([]);
    setDGDevices([]);
    setDGDevices1([]);
    setHashInput("");
    setPowerInput("");
    setRampInput("");
    setAutoOnExists(false);
    setUseHRExists(false);
    setOptHashRate("");
    setisThrExists(false);
    setDGDeviceLen(0);
  };

  return (
    <div>
      <Typography
        variant="h5"
        component="div"
        sx={{ flexGrow: 1, textAlign: "center", color: "#007bff" }}
      >
        Miner Group Management
      </Typography>
      <br></br>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div style={{ marginRight: "20px", textAlign: "left" }}>
          <Autocomplete
            disablePortal
            value={minerGroup}
            onChange={(event, value) => handleMinerGroup(value)}
            sx={{ width: "200px" }}
            disableClearable
            id="mode"
            options={minerGroups}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {option}
                {selected && (
                  <IconButton
                    onClick={() => handleDeleteDG(option)}
                    style={{
                      color: "red",
                      marginLeft: "auto",
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Select Miner Group"
                aria-label="Select mode"
                inputProps={{
                  ...params.inputProps,
                  style: {
                    fontSize: "12px",
                  },
                }}
              />
            )}
          />
        </div>
        <div className="app">
          <button
            onClick={handleCreateGroup}
            style={{ marginLeft: "auto", marginRight: "10px" }}
          >
            + Create Miner Group
          </button>

          {showPopup && (
            <div className="popup">
              <div className="popup-content">
                <div className="close-icon" onClick={() => setShowPopup(false)}>
                  <FaTimes />
                </div>
                <br />
                <h5 style={{ fontWeight: "normal" }}>Create Miner Group</h5>
                <br></br>
                <input
                  type="text"
                  value={deviceGroupName}
                  onChange={handleInputChange}
                  placeholder="Enter miner group name"
                />
                <div className="button-container">
                  <button onClick={handleOkCreateDG}>OK</button>
                  <button onClick={handleClearCreateDG}>Clear</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Stack>
      <br />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="Profile">
          <Tab
            sx={{ fontFamily: "Arial, sans-serif", textTransform: "none" }}
            label="Attach Miners"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ fontFamily: "Arial, sans-serif", textTransform: "none" }}
            label="Detach Miners"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ fontFamily: "Arial, sans-serif", textTransform: "none" }}
            label="Move Miners"
            {...a11yProps(1)}
          />
          <Tab
            sx={{ fontFamily: "Arial, sans-serif", textTransform: "none" }}
            label="Information"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 2 }}>
          <Typography>Select Miners to Attach:</Typography>
          <Typography component="div" sx={{ ml: 3 }} />
          <Autocomplete
            multiple
            value={selectedDevices}
            onChange={(event, value) => checkVal2(value)}
            //value={val2}
            sx={{
              width: 400,
            }}
            disableCloseOnSelect
            id="combo-box-demo3"
            options={
              minerGroup
                ? deviceList.filter(
                    (device) =>
                      dgDeviceList.includes(device) ||
                      !takenDevices.includes(device)
                  )
                : []
            }
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder={
                  dglen === 1 && minerGroup
                    ? `Select Miners (Org has ${dlen} and Group has 1 miner)`
                    : dglen !== 1 && minerGroup
                    ? `Select Miners (Org has ${dlen} and Group has ${dglen} miners)`
                    : `Select Miners (Org has ${dlen} miners)`
                }
                aria-label="Select mode"
                inputProps={{
                  ...params.inputProps,
                  style: {
                    fontSize: "12px",
                  },
                }}
              />
            )}
            renderTags={() => null}
            defaultValue={[]}
          />
        </Stack>
        <div className="button-container">
          <button onClick={handleDGMember}>Attach</button>
          <button onClick={handleClearDGMember}>Clear</button>
        </div>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 2 }}>
          <Typography>Select Miners to Detach:</Typography>
          <Typography component="div" sx={{ ml: 3 }} />
          <Autocomplete
            multiple
            value={deletedDevices}
            onChange={(event, value) => checkVal3(value)}
            //value={val2}
            sx={{
              width: 400,
            }}
            disableCloseOnSelect
            id="combo-box-demo3"
            options={dgDeviceList1}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder={
                  dglen === 1 && minerGroup
                    ? "Select Miners (Found 1 miner in your group)"
                    : dglen !== 1 && minerGroup
                    ? `Select Miners (Found ${dglen} miners in your group)`
                    : "Select Miners"
                }
                aria-label="Select mode"
                inputProps={{
                  ...params.inputProps,
                  style: {
                    fontSize: "12px",
                  },
                }}
              />
            )}
            renderTags={() => null}
            defaultValue={[]}
          />
        </Stack>

        <div className="button-container">
          <button onClick={handleDGdeleteMember}>Detach</button>
          <button onClick={handleClearDGdeleteMember}>Clear</button>
        </div>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          alignItems="center"
          justifyContent="flex-start"
          xs={8}
          sx={{ mt: 2, mb: 2 }}
        >
          <Grid
            item
            xs={3.5}
            display="flex"
            align-items="center"
            justify-content="center"
          >
            <Typography>Select Miners to Move:</Typography>
          </Grid>
          <Grid item xs>
            <Autocomplete
              multiple
              value={movedDevices}
              onChange={(event, value) => checkVal4(value)}
              sx={{
                width: 400,
              }}
              disableCloseOnSelect
              id="combo-box-demo3"
              options={dgDeviceList1}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder={
                    dglen === 1 && minerGroup
                      ? `Select Miners (Found 1 miner in your group)`
                      : dglen !== 1 && minerGroup
                      ? `Select Miners (Found ${dglen} miners in your group)`
                      : "Select Miners"
                  }
                  aria-label="Select mode"
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      fontSize: "12px",
                    },
                  }}
                />
              )}
              renderTags={() => null}
              defaultValue={[]}
            />
          </Grid>
          <Grid
            item
            xs={3.5}
            display="flex"
            align-items="center"
            justify-content="center"
          >
            <Typography>Select Target Miner Group:</Typography>
          </Grid>
          <Grid item xs>
            <Autocomplete
              value={targetGroup}
              onChange={(event, value) => setTargetGroup(value)}
              sx={{
                width: 400,
              }}
              disableCloseOnSelect
              id="combo-box-demo3"
              options={
                minerGroup
                  ? minerGroups.filter((group) => group !== minerGroup)
                  : []
              }
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder={`Select Target Group`}
                  aria-label="Select mode"
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      fontSize: "12px",
                    },
                  }}
                />
              )}
              renderTags={() => null}
              defaultValue={[]}
            />
          </Grid>
        </Grid>

        <div className="button-container">
          <button onClick={handleDGMoveMember}>Move</button>
          <button onClick={handleClearDGMoveMember}>Clear</button>
        </div>
      </TabPanel>

      <TabPanel value={value} index={3}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell align="right">Optimal hashrate(TH/s)</TableCell>
              <TableCell align="left">
                <TextField value={OptHashRate} size="small" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right">Target hashrate(TH/s)</TableCell>
              <TableCell align="left">
                <TextField value={hashInput} size="small" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right">Target power(W)</TableCell>
              <TableCell align="left">
                <TextField value={powerInput} size="small" />
              </TableCell>
            </TableRow>
            {usehrexist && (
              <TableRow>
                <TableCell align="right">DVFS tuning using</TableCell>
                <TableCell align="left">
                  <TextField
                    value={isHashRateOn ? "HashRate" : "Power"}
                    size="small"
                  />
                </TableCell>
              </TableRow>
            )}
            {istgthrexist && (
              <TableRow>
                <TableCell align="right">Apply hashrate from</TableCell>
                <TableCell align="left">
                  <TextField
                    value={isTargetHashRate ? "Target THs" : "Optimal THs"}
                    size="small"
                  />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell align="right">
                Power/THs ramp up/down in seconds{" "}
              </TableCell>
              <TableCell align="left">
                <TextField value={rampInput} size="small" />
              </TableCell>
            </TableRow>
            {autoexist && (
              <TableRow>
                <TableCell align="right">
                  Automatic temperature protection
                </TableCell>
                <TableCell align="left">
                  <TextField value={isAutoOn ? "True" : "False"} size="small" />
                </TableCell>
              </TableRow>
            )}
            {devicesexist && (
              <TableRow>
                <TableCell align="right">Attached miners </TableCell>
                <TableCell align="left">
                  <TextField
                    value={searchTerm}
                    onChange={handleSearch}
                    size="small"
                    placeholder="Search Miners"
                  />
                  <div
                    style={{
                      maxHeight: "150px",
                      overflowY: "auto",
                      border: "1px solid #ccc",
                      padding: "4px",
                      marginTop: "4px",
                    }}
                  >
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      {filteredDeviceList.map((device, index) => (
                        <li key={index} style={{ margin: "4px 0" }}>
                          {device}
                        </li>
                      ))}
                    </ul>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TabPanel>
      <Footer />
    </div>
  );
};

export default DeviceGroup;