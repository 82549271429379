export const apiBodyConstructorForDashboard = (
  startTime,
  endTime,
  binSize,
  authToken,
  dgname
) => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // You may need to include the authorization token if required by your API
      Authorization: `Bearer ${authToken}`,
    },
    body: {
      startTime: `${startTime}`,
      endTime: `${endTime}`,
      dgname: `${dgname}`,
      binSize: `${binSize}`,
    },
  };
};
