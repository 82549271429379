import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import Modal from '@mui/material/Modal';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Toolbar } from "@mui/material";
import { goodTime } from "../components/util";
import { UserA } from "../user/user";
import { CSVLink, CSVDownload } from "react-csv";
import * as api from '../api/api'
import PageLoading from "../components/pageloading";
import Footer from '../components/footer';
import SHA256 from 'crypto-js/sha256';
import PageContainer from "../components/PageContainer";

export const ApiKey = () => {
  const [issue, setIssue] = useState(false);
  const [state, setState] = useState(false);
  const [open, setOpen] = useState(false);
  const [downloadKey, setDownloadKey] = useState("");
  const[modalDescription, setModalDescription] = useState();
  const handleClose = () => {
    setState(false);
    setOpen(false);
  }

  const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

  const { user: user0, getAccessTokenSilently } = useAuth0();

  const [org, setOrg] = useState({
    org_id: "",
    name: "",
    member: [],
    owner: "",
  });

  const [usera, setUsera] = useState({
    name: "",
    phone: "",
    address: "",
    org_id: "",
    api_key: "",
    abv: "",
    key_added: "",
  });
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const fn = async () => {
      const token = await getAccessTokenSilently();
      api.GetOrg(token, (data) => { setOrg(data) })
      await api.GetUser(token, (data) => {
          setUsera(data)
          setDisplay(true)
      })
    }
    fn()
  }, [issue, open]);

  const generateKey = async() => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for(let i = 0; i < 64; i++) {
    result += characters.charAt(Math.floor(Math.random()*characters.length));
    }
    return btoa(result);
  }

  const callAPIKeyHandler = async(op, key) => {
      const token = await getAccessTokenSilently();
      if (op === 'generate') {
        var abv_key = key.substring(0,6);
        var api_key = SHA256(key).toString();
        const resp = await api.PostAPIKey(token, { api_key: api_key, abv: abv_key, key_added: Date.now() });
        return resp;
      } else {
        const resp = await api.PostAPIKey(token, { email: key.email, api_key: "", abv: "", key_added: Date.now() });
        return resp;
      }
  }

  const addKey = async() => {
    if ((usera.abv === undefined) || (usera.abv === "") || (usera.abv === null)) {
      const key = await generateKey();
      const resp = await callAPIKeyHandler('generate', key);
      if (resp === null || resp === undefined || resp.status === 'error') {
        setModalDescription("API Key generation failed. Please try again");
        setIssue(false)
        setState(false)
        setDownloadKey("")
      } else {
        setModalDescription("API Key is successfully generated. Please save this key:\n" + key);
        setDownloadKey(key);
        setIssue(true)
        setState(true)
      }
    } else {
      setModalDescription("API Key already issued to" + usera.name + " . Please Revoke to generate a new API Key");
      setIssue(false)
      setState(false)
      setDownloadKey("")
    }
    setOpen(true)
  }

  const revokeKey = async (item) => {
      if (item === null || item.abv === undefined ||  item.abv === null || item.abv === "") {
        setModalDescription("API Key was either revoked already or doesn't exist  " +  item.name);
        setIssue(false)
        setState(false)
      } else {
        const resp = await callAPIKeyHandler('revoke', item);
        if (resp === null || resp === undefined || resp.status === 'error') {
          setModalDescription("API Key revocation failed. Please try again");
        } else {
          setModalDescription("API Key revoked for " + item.name);
          setIssue(true)
          setState(true)
        }
      }
    setOpen(true)
  }
  if (display === true) {
  return (
    <PageContainer title="API Keys" description="this is the API Keys page">
    <Box>
      {(usera.role === 'superadmin' || usera.role === 'pooladmin' || usera.role === 'admin') && (
        <Box>
        <Button onClick={() => addKey()} variant="contained" sx={{mb:2}}>Generate Api Key</Button>
        </Box>
      )}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalDescription}
          </Typography>
          {state && usera.abv && <CSVLink data={downloadKey} filename={"my-file.csv"}>Download to CSV</CSVLink>}
        </Box>
      </Modal>
      <br/>
      <Table aria-label="Users">
        <TableHead>
          <TableRow>
            <TableCell>User Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>API Key</TableCell>
            <TableCell>Operation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (org.user) && (org.user.length) > 0 ? org.user.map((item, index) => (
              item.abv ?
              (<TableRow key={index}>
                <TableCell>
                  {item.name}
                </TableCell>
                <TableCell>
                  {item.email}
                </TableCell>
                <TableCell>
                  {item.abv + "..."}
                </TableCell>
                <TableCell>
                {(usera.abv === item.abv || usera.role === 'superadmin') && <Button size="small" onClick={() => revokeKey(item)} variant="text" sx={{ height: '2px', textTransform: 'none'}}>Revoke API Key</Button>}
                </TableCell>
              </TableRow>) :
              (<TableRow key={index}>
                <TableCell>
                  {item.name}
                </TableCell>
                <TableCell>
                  {item.email}
                </TableCell>
                <TableCell>
                    <span style={{ color: 'darkorange' }}>{"Not Generated"}</span>
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>)
            )) : <TableRow />
          }
        </TableBody>
      </Table>
      <Typography style={{ marginTop: 15, color: 'blue', fontStyle: 'italic', fontSize: 13}} ><strong>Note: </strong>Super admin can revoke any user's API key</Typography>
      <Footer sub={true}/>
    </Box>
    </PageContainer>
  );
  } else {
    return (
      <PageContainer title="API Keys" description="this is the API Keys page"><PageLoading /></PageContainer>
    )
  }
}

export default withAuthenticationRequired(ApiKey, {
    onRedirecting: () => <PageLoading />,
});