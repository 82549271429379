import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Box, Button } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark, prism } from 'react-syntax-highlighter/dist/esm/styles/prism';

const displayTS = (updatedAt) => {
    const isoDate = updatedAt
    const localDate = new Date(isoDate);
    const nowDate = Date.now();
    const diffTime = Math.abs(nowDate - localDate); // diffTime in milliseconds

    // console.log({ utcDate: utcDate, localDate: localDate, nowDate: nowDate, diffTime: diffTime })

    return { diffTime: diffTime, timeStr: localDate.toLocaleString() }
}

function DeviceDialog({ name, text, code, validUser }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let buttonStyle = "text";
    let ts = "Click Here";
    if (name === "priceconfig") {
        ts = code.Name
    } else if (name === "demandconfig") {
        ts = code.Name
    }
    const lowerCaseString = name.toLowerCase();
    const capitalizedString = lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
    return (
        <Box>
            <Button variant={buttonStyle} onClick={handleClickOpen}>
                {ts}
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>{capitalizedString}</DialogTitle>
                <DialogContent>
                    <SyntaxHighlighter language="json" style={prism}>
                        {text}
                    </SyntaxHighlighter>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>OK</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}


export { displayTS, DeviceDialog }