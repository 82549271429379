/*
 **************
 * This file exists both under customer.app/src for FluxVision UI
 * and in the customer.lib/src for Back-end AWS Lambda functions
 * Please make sure to keep these two files consistent. The reason
 * to keep them separate is to give flexibility for developers to
 * test with different values
 **************
 */

/*
 * Set Lower Values for FluxVision UI
 */
export const MINPOWER = 1000
export const MINTHS = 30

/*
 * Source: FluxOS - conf/at1x/chassisconfig.json file and taking the
 * max soft limit for the Power
 */
export const AT1500_MAXTHS = 185
export const AT1500_MAXPOWER = 4000

export const AI2500_MAXTHS = 300
export const AI2500_MAXPOWER = 7350

export const AD2500_MAXTHS = 300
export const AD2500_MAXPOWER = 7350

/*
 * Source: FluxOS - conf/at28x/chassisconfig.json file and taking the
 * max soft limit for the Power
 */
export const AT2880_MAXTHS = 350
export const AT2880_MAXPOWER = 4000

export const AT2860_MAXTHS = 350
export const AT2860_MAXPOWER = 7350

export const AI3680_MAXTHS = 390
export const AI3680_MAXPOWER = 7350

/*
 * Source: FluxOS - conf/ad3x/chassisconfig.json file and taking the
 * max soft limit for the Power
 */
export const AD3500_MAXTHS = 600
export const AD3500_MAXPOWER = 12000

export const AD3520_MAXTHS = 300
export const AD3520_MAXPOWER = 4000

export const AD3540_MAXTHS = 600
export const AD3540_MAXPOWER = 8000

export const AD3560_MAXTHS = 900
export const AD3560_MAXPOWER = 12000

/*
 * NOTE:
 * Set the max THs and Power for FluxVision UI
 * Use the highest supported model values here.
 * ===========
 * NOT SUPPORTING AD3X models in FluxVision UI Yet
 * ===========
 */
export const MAXTHS = AI3680_MAXTHS
export const MAXPOWER = AI3680_MAXPOWER

/*
 * Max THs and Power for Test models. These are not real models
 * Devices like test0000, test0001, testXXXX would use this model number
 */
export const  AR10_MAXTHS   = 135
export const  AR10_MAXPOWER = 3500

/*
 * Max THs and Power for old AT1500 that has space b/w AT and 1500
 * We should remove these systems from Test environment database
 * For testing purpose, defining these constants.
 * Once these old systems gets upgraded with latest firmware, the following
 * constants will never be used
 */
export const  ATSPACE1500_MAXTHS   = 150
export const  ATSPACE1500_MAXPOWER = 4000

/*
 * The following constants will be used in deviceRuntimeDB.js
 * if the input mode is not matching with any values.
 * No usecase to hit this but if system is report with no model information
 * for some reason, use these values to recognize the scenario
 */
export const  NOMODEL_MAXTHS   = 145
export const  NOMODEL_MAXPOWER = 3750

/*
 * These values should never be used by customer.lib/src/db/connectionDB.js, in case,
 * If the device is not found in the in DeviceRunTime tabel and Device table
 * set to lower values for that device while sending THS command to device
 * over websocket
 */
export const NODEVICE_FOUND_MAXTHS = 170
export const NODEVICE_FOUND_MAXPOWER = 4500

/*
 * The following will be used to calculate the efficiency curve.
 * Change the following value to match the number of samples to
 * MaxTHs of miner.
 * For example, for 170MaxTHs, used 100 samples
 * for 300MaxThs, using 175 samples for better curve
 */
export const FLUXVISION_EFFICIENCY_CURVE_SAMPLES = 175

/*
 * Number of Devices per batch used while sending the THs/Power command
 * to the devices in customer.lib/src/db/connectionDB.js
 */
export const DEVICES_BATCH_SIZE = 1000

/*
 * Auradine Product Family List. Add Ettan to the list in the future
 */
export const PRODUCT_FAMILY = [
    "AT1500",
    "AI2500",
    "AD2500",
    "AT2880",
    "AT2860",
    "AI3680",
    "AD3500",
    "AD3520",
    "AD3540",
    "AD3560"
]
export const PRODUCT_FAMILY_MAXPOWER = {
        "AT1500" : AT1500_MAXPOWER,
        "AI2500" : AI2500_MAXPOWER,
        "AD2500" : AD2500_MAXPOWER,
        "AT2880" : AT2880_MAXPOWER,
        "AT2860" : AT2860_MAXPOWER,
        "AI3680" : AI3680_MAXPOWER,
        "AD3500" : AD3500_MAXPOWER,
        "AD3520" : AD3520_MAXPOWER,
        "AD3540" : AD3540_MAXPOWER,
        "AD3560" : AD3560_MAXPOWER
}

/*
 * Power Curtail Max Schedule Events per Day
 */
export const MAX_SCHEDULE_EVENTS = 15

/*
 * BTC blockreward and Pool fee
 * Define if any other constants are needed for BTC
 */

/*
 * 6.25 is current BTC Blockreward, subject to change.
 * Transaction fee is 0.78: source https://mempool.space/api/v1/mining/blocks/fees/1w
 * as of 01/12/2024
 */
export const BTC_BLOCKREWARD_TRANSACTION_FEES = 7.03
export const BTC_MINING_POOL_FEE = 0.02

export const ERCOT_PRICE_ZONES = ["HB_HOUSTON", "HB_NORTH", "HB_PAN", "HB_SOUTH", "HB_WEST", "LZ_AEN", "LZ_CPS", "LZ_HOUSTON", "LZ_LCRA", "LZ_NORTH", "LZ_RAYBN", "LZ_SOUTH", "LZ_WEST"]
export const ERCOT_PRICE_LZ_ZONES = ["LZ_AEN", "LZ_CPS", "LZ_HOUSTON", "LZ_LCRA", "LZ_NORTH", "LZ_RAYBN", "LZ_SOUTH", "LZ_WEST"]
export const ERCOT_PRICE_HB_ZONES = ["HB_HOUSTON", "HB_NORTH", "HB_PAN", "HB_SOUTH", "HB_WEST"]

export const ERCOT_ZONE_BATCH_SIZE = 7
export const ERCOT_PRICE_ZONE_TYPES = ["LZ", "HU", "BITCOIN"]

export const VOLTUS_API_URL = "https://api.voltus.co/2022-04-15/"
export const VOLTUS_SANDBOX_API_URL = "https://sandbox.voltus.co/2022-04-15/"
export const CPOWER_SANDBOX_API_URL = "https://link-stg.cpowercorp.com/vlinkservice.svc"
export const CPOWER_XML_NS = "http://schemas.datacontract.org/2004/07/VLinkService.Services.vLinkService"
export const CPOWER_XML_NS_I = "http://www.w3.org/2001/XMLSchema-instance"

export const AT1_chipLayout = [
    [0, 130, 3, 133, 6, 136, 9, 139, 12, 142, 15],
    [1, 129, 4, 132, 7, 135, 10, 138, 13, 141, 16],
    [2, 128, 5, 131, 8, 134, 11, 137, 14, 140, 17],
    [158, 32, 155, 29, 152, 26, 149, 23, 146, 20, 143],
    [159, 31, 156, 28, 153, 25, 150, 22, 147, 19, 144],
    [160, 30, 157, 27, 154, 24, 151, 21, 148, 18, 145],
    [33, 163, 36, 166, 39, 169, 42, 172, 45, 175, 48],
    [34, 162, 37, 165, 40, 168, 43, 171, 46, 174, 49],
    [35, 161, 38, 164, 41, 167, 44, 170, 47, 173, 50],
    [191, 65, 188, 62, 185, 59, 182, 56, 179, 53, 176],
    [192, 64, 189, 61, 186, 58, 183, 55, 180, 52, 177],
    [193, 63, 190, 60, 187, 57, 184, 54, 181, 51, 178]
  ];

  export const AI25_chipLayout = [
    [15, 16, 17, 143, 144, 145, 48, 49, 50, 176, 177, 178],
    [142, 141, 140, 20, 19, 18, 175, 174, 173, 53, 52, 51],
    [12, 13, 14, 146, 147, 148, 45, 46, 47, 179, 180, 181],
    [139, 138, 137, 23, 22, 21, 172, 171, 170, 56, 55, 54],
    [9, 10, 11, 149, 150, 151, 42, 43, 44, 182, 183, 184],
    [136, 135, 134, 26, 25, 24, 169, 168, 167, 59, 58, 57],
    [6, 7, 8, 152, 153, 154, 39, 40, 41, 185, 186, 187],
    [133, 132, 131, 29, 28, 27, 166, 165, 164, 62, 61, 60],
    [3, 4, 5, 155, 156, 157, 36, 37, 38, 188, 189, 190],
    [130, 129, 128, 32, 31, 30, 163, 162, 161, 65, 64, 63],
    [0, 1, 2, 158, 159, 160, 33, 34, 35, 191, 192, 193]
  ];

  export const AT28_chipLayout = [
    [-1, 0, 130, 3, 133, 6, 136, 9, 139, 12, 142, 15],
    [-1, 1, 129, 4, 132, 7, 135, 10, 138, 13, 141, 16],
    [-1, 2, 128, 5, 131, 8, 134, 11, 137, 14, 140, 17],
    [35, 158, 32, 155, 29, 152, 26, 149, 23, 146, 20, 143],
    [34, 159, 31, 156, 28, 153, 25, 150, 22, 147, 19, 144],
    [33, 160, 30, 157, 27, 154, 24, 151, 21, 148, 18, 145],
    [163, 36, 166, 39, 169, 42, 172, 45, 175, 48, 178, 51],
    [162, 37, 165, 40, 168, 43, 171, 46, 174, 49, 177, 52],
    [161, 38, 164, 41, 167, 44, 170, 47, 173, 50, 176, 53],
    [-1, 194, 68, 191, 65, 188, 62, 185, 59, 182, 56, 179],
    [-1, 195, 67, 192, 64, 189, 61, 186, 58, 183, 55, 180],
    [-1, 196, 66, 193, 63, 190, 60, 187, 57, 184, 54, 181]
  ]

  export const AI36_chipLayout = [
    [15, 16, 17, 143, 144, 145, 51, 52, 53, 179, 180, 181],
    [142, 141, 140, 20, 19, 18, 178, 177, 176, 56, 55, 54],
    [12, 13, 14, 146, 147, 148, 48, 49, 50, 182, 183, 184],
    [139, 138, 137, 23, 22, 21, 175, 174, 173, 59, 58, 57],
    [9, 10, 11, 149, 150, 151, 45, 46, 47, 185, 186, 187],
    [136, 135, 134, 26, 25, 24, 172, 171, 170, 62, 61, 60],
    [6, 7, 8, 152, 153, 154, 42, 43, 44, 188, 189, 190],
    [133, 132, 131, 29, 28, 27, 169, 168, 167, 65, 64, 63],
    [3, 4, 5, 155, 156, 157, 39, 40, 41, 191, 192, 193],
    [130, 129, 128, 32, 31, 30, 166, 165, 164, 68, 67, 66],
    [0, 1, 2, 158, 159, 160, 36, 37, 38, 194, 195, 196],
    [-1, -1, -1, 35, 34, 33, 163, 162, 161, -1, -1, -1]
  ]

  export const AD_chipLayout = [
    [526, 525, 524, 302, 301, 300, 226, 225, 224, 2, 1, 0],
    [396, 397, 398, 428, 429, 430, 96, 97, 98, 128, 129, 130],
    [523, 522, 521, 305, 304, 303, 223, 222, 221, 5, 4, 3],
    [393, 394, 395, 431, 432, 433, 93, 94, 95, 131, 132, 133],
    [520, 519, 518, 308, 307, 306, 220, 219, 218, 8, 7, 6],
    [390, 391, 392, 434, 435, 436, 90, 91, 92, 134, 135, 136],
    [517, 516, 515, 311, 310, 309, 217, 216, 215, 11, 10, 9],
    [387, 388, 389, 437, 438, 439, 87, 88, 89, 137, 138, 139],
    [514, 513, 512, 314, 313, 312, 214, 213, 212, 14, 13, 12],
    [384, 385, 386, 440, 441, 442, 84, 85, 86, 140, 141, 142],
    [511, 510, 509, 317, 316, 315, 211, 210, 209, 17, 16, 15],
    [381, 382, 383, 443, 444, 445, 81, 82, 83, 143, 144, 145],
    [508, 507, 506, 320, 319, 318, 208, 207, 206, 20, 19, 18],
    [378, 379, 380, 446, 447, 448, 78, 79, 80, 146, 147, 148],
    [505, 504, 503, 323, 322, 321, 205, 204, 203, 23, 22, 21],
    [375, 376, 377, 449, 450, 451, 75, 76, 77, 149, 150, 151],
    [502, 501, 500, 326, 325, 324, 202, 201, 200, 26, 25, 24],
    [372, 373, 374, 452, 453, 454, 72, 73, 74, 152, 153, 154],
    [499, 498, 497, 329, 328, 327, 199, 198, 197, 29, 28, 27],
    [369, 370, 371, 455, 456, 457, 69, 70, 71, 155, 156, 157],
    [496, 495, 494, 332, 331, 330, 196, 195, 194, 32, 31, 30],
    [366, 367, 368, 458, 459, 460, 66, 67, 68, 158, 159, 160],
    [493, 492, 491, 335, 334, 333, 193, 192, 191, 35, 34, 33],
    [363, 364, 365, 461, 462, 463, 63, 64, 65, 161, 162, 163],
    [490, 489, 488, 338, 337, 336, 190, 189, 188, 38, 37, 36],
    [360, 361, 362, 464, 465, 466, 60, 61, 62, 164, 165, 166],
    [487, 486, 485, 341, 340, 339, 187, 186, 185, 41, 40, 39],
    [357, 358, 359, 467, 468, 469, 57, 58, 59, 167, 168, 169],
    [484, 483, 482, 344, 343, 342, 184, 183, 182, 44, 43, 42],
    [354, 355, 356, 470, 471, 472, 54, 55, 56, 170, 171, 172],
    [481, 480, 479, 347, 346, 345, 181, 180, 179, 47, 46, 45],
    [351, 352, 353, 473, 474, 475, 51, 52, 53, 173, 174, 175],
    [478, 477, 476, 350, 349, 348, 178, 177, 176, 50, 49, 48]
  ];

/*
 * Default AppCfgDefault values that contains ztp configuration
 */
export const AppCfgDefault = {
  "ztp": {
      "enabled": true,
      "interval": 60
  },
  "ws": {
      "enabled": true,
      "interval": 60
  },
  "summary": {
      "enabled": true,
      "interval": 60
  },
  "mode": {
      "enabled": true,
      "interval": 60
  },
  "led": {
      "enabled": true,
      "interval": 60
  },
  "ipreport": {
      "enabled": true,
      "interval": 120
  },
  "gettimedate": {
      "enabled": true,
      "interval": 120
  },
  "telemetry": {
      "enabled": true,
      "interval": 180
  },
  "pools": {
      "enabled": true,
      "interval": 300
  },
  "stats": {
      "enabled": true,
      "interval": 300
  },
  "lcd": {
      "enabled": true,
      "interval": 300
  },
  "fan": {
      "enabled": true,
      "interval": 300
  },
  "temperature": {
      "enabled": true,
      "interval": 300
  },
  "psu": {
      "enabled": true,
      "interval": 300
  },
  "frequency": {
      "enabled": true,
      "interval": 300
  },
  "voltage": {
      "enabled": true,
      "interval": 300
  },
  "accept-remote-tuning": {
      "enabled": true,
      "interval": 300
  },
  "version": {
      "enabled": true,
      "interval": 3600
  },
  "config": {
      "enabled": true,
      "interval": 3600
  },
  "devs": {
      "enabled": true,
      "interval": 3600
  },
  "coin": {
      "enabled": true,
      "interval": 3600
  },
  "devdetails": {
      "enabled": true,
      "interval": 3600
  },
  "poolCfg": [
    {
      "url": "",
      "user": "",
      "pass": ""
    },
    {
      "url": "",
      "user": "",
      "pass": ""
    },
    {
      "url": "",
      "user": "",
      "pass": ""
    }
  ]
}

export const TechSupportAlertMailingList = ['rkim@auradine.com', 'rkurma@auradine.com', 'shishir@auradine.com']
export const TechSupportAlertMailingListForNonProd = ['rkim@auradine.com', 'rkurma@auradine.com']
