import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  useMediaQuery,
  List,
  Typography,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoIcon from "./LogoIcon";
import { OldMenuitems, NewMenuitems } from "./Menuitems";
import LoginButton from "./login-button";
import LogoutButton from "./logout-button";
import Scrollbar from "./Scrollbar";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import logo from "../assets/logo.png";
import { env } from "../env";
import "reactjs-popup/dist/index.css";
import * as api from "../api/api";

const NavBar = ({
  isMobileSidebarOpen,
  onSidebarClose,
  isSidebarOpen,
  toggleSidebar,
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const SidebarWidth = 265;
  const [sidebarOpen, setSidebarOpen] = React.useState(isSidebarOpen);
  const [open, setOpen] = React.useState(true);
  const [techSupport, setTechSupport] = React.useState(false);
  const [readonly, setReadOnly] = React.useState(false);
  const { pathname } = useLocation();
  const pathDirect = pathname;
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf("/"));
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const toggleSidebarFunc = () => {
    setSidebarOpen(!sidebarOpen);
    toggleSidebar();
  };
  const handleClick = (index) => {
    if (open === index) {
      setOpen((prevopen) => !prevopen);
    } else {
      setOpen(index);
    }
  };

  useEffect(() => {
    const fn = async () => {
      const token = await getAccessTokenSilently();
      await api.GetUserTechsupport(token, (data) => {
        if (data.status === "error") {
          setTechSupport(false);
        } else {
          setTechSupport(true);
        }
      });
    };
    fn();
    const fn1 = async () => {
      const token = await getAccessTokenSilently();
      const isAllowed = await api.IsOperationAllowed(token);
      if (isAllowed === undefined) {
        return;
      } else if (isAllowed.status === "error") {
        if (isAllowed.errorCode === 1007) {
          setReadOnly(true);
        } else if (isAllowed.errorCode === 1009) {
          setReadOnly(true);
        }
        return;
      }
    };
    fn1();
  }, [getAccessTokenSilently]);

  const NewSidebarContent = (
    <Scrollbar style={{ height: "calc(100vh - 5px)" }}>
      <Box sx={{ p: 2 }}>
        <Box display="flex">
          <LogoIcon />
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleSidebarFunc}
            size="large"
            sx={{
              ml: "5px",
              display: {
                lg: "flex",
                xs: "none",
              },
            }}
          >
            <FeatherIcon icon={"x"} />
          </IconButton>
        </Box>
        <Box>
          <List>
            {NewMenuitems.map((item, index) => {
              if (
                (readonly && item.title === "Configuration") ||
                (readonly && item.title === "Power Management")
              ) {
                return;
              }
              // {/********SubHeader**********/}
              if (item.subheader) {
                return (
                  <li key={item.subheader}>
                    <Typography
                      variant="subtitle2"
                      fontWeight="500"
                      sx={{ my: 2, mt: 4, opacity: "0.4" }}
                    >
                      {item.subheader}
                    </Typography>
                  </li>
                );
                // {/********If Sub Menu**********/}
                /* eslint no-else-return: "off" */
              } else if (item.children) {
                return (
                  <React.Fragment key={item.title}>
                    <ListItem
                      button
                      component="li"
                      onClick={() => handleClick(index)}
                      selected={pathWithoutLastPart === item.href}
                      sx={{
                        mb: 1,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ...(pathWithoutLastPart === item.href && {
                            color: "white",
                          }),
                        }}
                      ></ListItemIcon>
                      <ListItemText>{item.title}</ListItemText>
                      {index === open || pathWithoutLastPart === item.href ? (
                        <FeatherIcon icon="chevron-down" size="16" />
                      ) : (
                        <FeatherIcon icon="chevron-right" size="16" />
                      )}
                    </ListItem>
                    <Collapse in={index === open} timeout="auto" unmountOnExit>
                      <List component="li" disablePadding>
                        {item.children.map((child) => {
                          return (
                            <ListItem
                              key={child.title}
                              button
                              component={NavLink}
                              to={child.href}
                              onClick={onSidebarClose}
                              selected={pathDirect === child.href}
                              sx={{
                                mb: 1,

                                ...(pathDirect === child.href && {
                                  color: "white",
                                  backgroundColor: (theme) =>
                                    `${theme.palette.primary.main}!important`,
                                }),
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  svg: { width: "14px", marginLeft: "3px" },
                                  ...(pathDirect === child.href && {
                                    color: "primary.main",
                                  }),
                                }}
                              ></ListItemIcon>
                              <ListItemText sx={{ ml: 5 }}>
                                {child.title}
                              </ListItemText>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
                // {/********If Sub No Menu**********/}
              } else {
                if (
                  item.title === "FluxGPT™" ||
                  item.title === "Power Management"
                ) {
                  return (
                    <List component="li" disablePadding key={item.title}>
                      <ListItem
                        onClick={() => handleClick(index)}
                        button
                        component={NavLink}
                        to={item.href}
                        selected={pathDirect === item.href}
                        sx={{
                          mb: 1,
                          ...(pathDirect === item.href && {
                            color: "white",
                            backgroundColor: (theme) =>
                              `${theme.palette.primary.main}!important`,
                          }),
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ...(pathDirect === item.href && { color: "white" }),
                          }}
                        ></ListItemIcon>
                        <ListItemText onClick={onSidebarClose}>
                          {item.title}
                        </ListItemText>
                        <span className="new-label">BETA</span>
                      </ListItem>
                    </List>
                  );
                } else {
                  if (item.title === "TechSupport") {
                    return;
                  }
                  return (
                    <List component="li" disablePadding key={item.title}>
                      <ListItem
                        onClick={() => handleClick(index)}
                        button
                        component={NavLink}
                        to={item.href}
                        selected={pathDirect === item.href}
                        sx={{
                          mb: 1,
                          ...(pathDirect === item.href && {
                            color: "white",
                            backgroundColor: (theme) =>
                              `${theme.palette.primary.main}!important`,
                          }),
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ...(pathDirect === item.href && { color: "white" }),
                          }}
                        ></ListItemIcon>
                        <ListItemText onClick={onSidebarClose}>
                          {item.title}
                        </ListItemText>
                      </ListItem>
                    </List>
                  );
                }
              }
            })}
          </List>
        </Box>
      </Box>
    </Scrollbar>
  );

  const NewSidebarContentTechsupport = (
    <Scrollbar style={{ height: "calc(100vh - 5px)" }}>
      <Box sx={{ p: 2 }}>
        <Box display="flex">
          <LogoIcon />
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleSidebarFunc}
            size="large"
            sx={{
              ml: "5px",
              display: {
                lg: "flex",
                xs: "none",
              },
            }}
          >
            <FeatherIcon icon={"x"} />
          </IconButton>
        </Box>

        <Box>
          <List>
            {NewMenuitems.map((item, index) => {
              if (
                (readonly && item.title === "Configuration") ||
                (readonly && item.title === "Power Management")
              ) {
                return;
              }
              // {/********SubHeader**********/}
              if (item.subheader) {
                return (
                  <li key={item.subheader}>
                    <Typography
                      variant="subtitle2"
                      fontWeight="500"
                      sx={{ my: 2, mt: 4, opacity: "0.4" }}
                    >
                      {item.subheader}
                    </Typography>
                  </li>
                );
                // {/********If Sub Menu**********/}
                /* eslint no-else-return: "off" */
              } else if (item.children) {
                return (
                  <React.Fragment key={item.title}>
                    <ListItem
                      button
                      component="li"
                      onClick={() => handleClick(index)}
                      selected={pathWithoutLastPart === item.href}
                      sx={{
                        mb: 1,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ...(pathWithoutLastPart === item.href && {
                            color: "white",
                          }),
                        }}
                      ></ListItemIcon>
                      <ListItemText>{item.title}</ListItemText>
                      {index === open || pathWithoutLastPart === item.href ? (
                        <FeatherIcon icon="chevron-down" size="16" />
                      ) : (
                        <FeatherIcon icon="chevron-right" size="16" />
                      )}
                    </ListItem>
                    <Collapse in={index === open} timeout="auto" unmountOnExit>
                      <List component="li" disablePadding>
                        {item.children.map((child) => {
                          return (
                            <ListItem
                              key={child.title}
                              button
                              component={NavLink}
                              to={child.href}
                              onClick={onSidebarClose}
                              selected={pathDirect === child.href}
                              sx={{
                                mb: 1,

                                ...(pathDirect === child.href && {
                                  color: "white",
                                  backgroundColor: (theme) =>
                                    `${theme.palette.primary.main}!important`,
                                }),
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  svg: { width: "14px", marginLeft: "3px" },
                                  ...(pathDirect === child.href && {
                                    color: "primary.main",
                                  }),
                                }}
                              ></ListItemIcon>
                              <ListItemText sx={{ ml: 5 }}>
                                {child.title}
                              </ListItemText>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
                // {/********If Sub No Menu**********/}
              } else {
                if (
                  item.title === "FluxGPT™" ||
                  item.title === "Power Management"
                ) {
                  return (
                    <List component="li" disablePadding key={item.title}>
                      <ListItem
                        onClick={() => handleClick(index)}
                        button
                        component={NavLink}
                        to={item.href}
                        selected={pathDirect === item.href}
                        sx={{
                          mb: 1,
                          ...(pathDirect === item.href && {
                            color: "white",
                            backgroundColor: (theme) =>
                              `${theme.palette.primary.main}!important`,
                          }),
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ...(pathDirect === item.href && { color: "white" }),
                          }}
                        ></ListItemIcon>
                        <ListItemText onClick={onSidebarClose}>
                          {item.title}
                        </ListItemText>
                        <span className="new-label">BETA</span>
                      </ListItem>
                    </List>
                  );
                } else {
                  return (
                    <List component="li" disablePadding key={item.title}>
                      <ListItem
                        onClick={() => handleClick(index)}
                        button
                        component={NavLink}
                        to={item.href}
                        selected={pathDirect === item.href}
                        sx={{
                          mb: 1,
                          ...(pathDirect === item.href && {
                            color: "white",
                            backgroundColor: (theme) =>
                              `${theme.palette.primary.main}!important`,
                          }),
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ...(pathDirect === item.href && { color: "white" }),
                          }}
                        ></ListItemIcon>
                        <ListItemText onClick={onSidebarClose}>
                          {item.title}
                        </ListItemText>
                      </ListItem>
                    </List>
                  );
                }
              }
            })}
          </List>
        </Box>
      </Box>
    </Scrollbar>
  );

  if (isAuthenticated && lgUp) {
    // console.log('Flux vision', env.REACT_FLUX_VISION, techSupport)
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Drawer
          anchor="left"
          open={sidebarOpen}
          variant="persistent"
          PaperProps={{
            sx: {
              width: SidebarWidth,
              border: "0 !important",
              boxShadow: "0px 7px 30px 0px rgb(113 122 131 / 11%)",
            },
          }}
        >
          {techSupport ? NewSidebarContentTechsupport : NewSidebarContent}
        </Drawer>
        <Toolbar>
          <div>
            {!sidebarOpen && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleSidebarFunc}
                size="large"
                sx={{
                  display: {
                    lg: "flex",
                    xs: "none",
                  },
                  ml: isSidebarOpen ? "280px!important" : "",
                }}
              >
                <FeatherIcon icon={"menu"} />
              </IconButton>
            )}
          </div>
          <Typography
            variant="h1"
            component="div"
            sx={{ mt: "60px", flexGrow: 1 }}
          />
          {isAuthenticated ? <LogoutButton /> : <LoginButton />}
        </Toolbar>
      </Box>
    );
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Drawer
        anchor="left"
        open={isMobileSidebarOpen}
        onClose={onSidebarClose}
        PaperProps={{
          sx: {
            width: SidebarWidth,
            border: "0 !important",
          },
        }}
        variant="temporary"
      >
        {techSupport ? NewSidebarContentTechsupport : NewSidebarContent}
      </Drawer>
      <Toolbar>
        <div></div>
        <Typography
          variant="h1"
          component="div"
          sx={{ mt: "60px", flexGrow: 1 }}
        />
        {isAuthenticated ? <LogoutButton /> : <LoginButton />}
      </Toolbar>
    </Box>
  );
};

NavBar.propTypes = {
  isMobileSidebarOpen: PropTypes.bool,
  onSidebarClose: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
};

export default NavBar;
