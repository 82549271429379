import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useRef } from "react";
import { Paper, Grid, Box, Table, TableBody, TableRow, TableCell, Toolbar, Typography, Button, Select, MenuItem, Tabs, Tab } from '@mui/material';
import { UserA } from "../user/user";
import TextField from '@mui/material/TextField';
import * as api from '../api/api'
import Loading from "../components/loading";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Footer from '../components/footer';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { useTheme } from '@mui/material/styles';
import Plotly from 'plotly.js-dist';
import Tooltip from '@mui/material/Tooltip';
import PageContainer from '../components/PageContainer';
import InfoBlock from './InfoBlock';
import { CSVLink } from 'react-csv';
import PaperWrap from "../components/paper-wrap";
import { TabPanel, a11yProps } from '../components/tab';
import Modal from 'react-modal';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


const SuccessModal = ({ isOpen, onClose }) => {
  return (
    <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <div>
        <h4>Success Message</h4>
        <p className="success-message">Profit and HashRate has been successfully calculated</p>
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
  );
};

const FailModal = ({ isOpen, onClose }) => {
  return (
    <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <div>
        <h4>Failure Message</h4>
        <p className="success-message">Failed to calculate Profit and HashRate</p>
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
  );
};

const FailMinerGroupModal = ({ isOpen, onClose }) => {
  return (
    <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <div>
        <h4>Failure Message</h4>
        <p className="success-message">Please select Miner Group</p>
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
  );
};

const FailAutoTuneModal = ({ isOpen, onClose }) => {
  return (
    <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <div>
        <h4>Failure Message</h4>
        <p className="success-message">Failed to Tune the Miners</p>
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
  );
};
const FailAutoTuneModalAPI = ({ isOpen, onClose, msg }) => {
  return (
    <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <div>
        <h4>Failure Message</h4>
        <p className="success-message">{msg}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
  );
};

const FailCustomerProfileModal = ({ isOpen, onClose }) => {
  return (
    <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <div>
        <h4>Failure Message</h4>
        <p className="success-message">Please fill in Customer Profile data in case of manual</p>
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
  );
};

const FailMarketProfileModal = ({ isOpen, onClose }) => {
  return (
    <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <div>
        <h4>Failure Message</h4>
        <p className="success-message">Please fill in Market Profile data in case of manual</p>
        <button onClick={onClose}>Close</button>
      </div>
    </Popup>
  );
};

// const SuccessAutoModal = ({ isOpen, autotune, onClose }) => {
//     console.log(autotune, typeof autotune)
//     return (
//       <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
//         <div>
//           <h4>AutoTune Response</h4>
//           {
//           Object.keys(autotune).map((key, index) => ( 
//             <p key={index}> {key} - {autotune[key].result.msg}</p> 
//           ))
//           }
//           <button onClick={onClose}>Close</button>
//         </div>
//       </Popup>
//     );
// };

const SuccessAutoModal = ({ isOpen, autotune, onClose, msg }) => {
    console.log(autotune, typeof autotune)
    return (
      <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <div>
          <h4>Success Message</h4>
          <p className="success-message">{msg}</p>
          <button onClick={onClose}>Close</button>
        </div>
      </Popup>
    );
};

export const EnergyTuning = () => {
    const [showChart, setShowChart] = useState(false);

    const [submitMsg, setMsg] = useState('');
    const [firstOption, setfirstOption] = useState('');
    const [firstmanualInput, setFirstManualInput] = useState('');
    const [minerGroup, setMinerGroup]=useState('');
    const [minerGroups, setMinerGroups]=useState([]);
    const { user: user0, getAccessTokenSilently } = useAuth0();
    const [value, setValue] = useState(0);
    const [btcData, setBtcData] = useState('');
    const [btcDGname, setBtcDGname] = useState('');
    const [dgInfo, setDgInfo] = useState('');
    const [bitcoinPrice, setBitcoinPrice] = useState('')
    const [EnergyPrice, setEnergyPrice] = useState('')
    const [blockReward, setBlockReward] = useState('')
    const [btcDiff, setBtcDiff] = useState('')
    const [currentThs, setCurrentThs] = useState('')
    const [currentEff, setCurrentEff] = useState('')
    const [currentRev, setCurrentRev] = useState('')
    const [aggCurrentRev, setAggCurrentRev] = useState('')
    const [currentElec, setCurrentElec] = useState('')
    const [aggCurrentElec, setAggCurrentElec] = useState('')
    const [currentPCost, setCurrentPCost] = useState('')
    const [currentMProfit, setCurrentMProfit] = useState('')
    const [aggCurrentMProfit, setAggCurrentMProfit] = useState('')
    const [optimalThs, setOptimalThs] = useState('')
    const [optimalEff, setOptimalEff] = useState('')
    const [optimalRev, setOptimalRev] = useState('')
    const [aggOptimalRev, setAggOptimalRev] = useState('')
    const [optimalElec, setOptimalElec] = useState('')
    const [aggOptimalElec, setAggOptimalElec] = useState('')
    const [optimalPCost, setOptimalPCost] = useState('')
    const [optimalMProfit, setOptimalMProfit] = useState('')
    const [aggOptimalMProfit, setAggOptimalMProfit] = useState('')
    const [btcSubmit, setBtcSubmit] = useState(0)

    const [autotuneSubmit, setAutoTuneSubmit] = useState(0)

    const [autotune, setTune] = useState({})

    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [successAutoModalOpen, setSuccessAutoModalOpen] = useState(false);


    const handleTabChange = (event, newValue) => {
      if (isLoading) {
        event.preventDefault();
      } else {
        setValue(newValue);
      }
    };

    const handleMinerGroup = async(value) => {
      setMinerGroup(value)
      const token = await getAccessTokenSilently();
      const info = {}
      info.dgName = value
      const dgInfoCurrent = await api.GetDGInfo(token, info);

      if (dgInfoCurrent === undefined) {
      }
      else {
        console.log('Response from BE', dgInfoCurrent)
        console.log("set use opt or not ", !dgInfoCurrent.devicegroup.isTargetHashRate)
        setButtonOn3(!dgInfoCurrent.devicegroup.isTargetHashRate);

        setDgInfo(dgInfoCurrent)
        if (dgInfoCurrent.devicegroup.bitcoinPrice)
          setBitcoinPrice(dgInfoCurrent.devicegroup.bitcoinPrice)
        else setBitcoinPrice(0)
        if (dgInfoCurrent.devicegroup.energyPrice)
          setEnergyPrice(dgInfoCurrent.devicegroup.energyPrice)
        else setEnergyPrice(0)
        if (dgInfoCurrent.devicegroup.bitcoinPrice)
          setBlockReward(dgInfoCurrent.devicegroup.blockreward)
        else setBlockReward(0)
        if (dgInfoCurrent.devicegroup.btcDifficulty)
          setBtcDiff((dgInfoCurrent.devicegroup.btcDifficulty/1000000000000).toFixed(2))
        else setBtcDiff(0)

        if (btcData !== '' && btcData !== undefined && btcDGname === minerGroup) {
          console.log('Picking from btcData', JSON.stringify(btcData))
          if (btcData.maxRate)
            setOptimalThs(btcData.maxRate)
          else setOptimalThs(0)
          if (btcData.currentmaxRate)
            setCurrentThs(btcData.currentmaxRate)
          else setCurrentThs(0)
          if (btcData.currentEfficiency)
            setCurrentEff(btcData.currentEfficiency)
          else setCurrentEff(0)
          if (btcData.currentmaxRevenue)
            setCurrentRev(btcData.currentmaxRevenue.toFixed(2))
          else setCurrentRev(0)
          if (btcData.currentElectricityCost)
            setCurrentElec(btcData.currentElectricityCost.toFixed(2))
          else setCurrentElec(0)
          if (btcData.currentmaxProfit)
            setCurrentMProfit(btcData.currentmaxProfit.toFixed(2))
          else setCurrentMProfit(0)
        } else {
          console.log('Picking from dginfo')
          if (dgInfoCurrent.devicegroup.maxRate)
            setOptimalThs(dgInfoCurrent.devicegroup.maxRate)
          else setOptimalThs(0)
          if (dgInfoCurrent.devicegroup.currentmaxRate)
            setCurrentThs(dgInfoCurrent.devicegroup.currentmaxRate)
          else setCurrentThs(0)
          if (dgInfoCurrent.devicegroup.currentEfficiency)
            setCurrentEff(dgInfoCurrent.devicegroup.currentEfficiency)
          else setCurrentEff(0)
          if (dgInfoCurrent.devicegroup.currentmaxRevenue)
            setCurrentRev(dgInfoCurrent.devicegroup.currentmaxRevenue.toFixed(2))
          else setCurrentRev(0)
          if (dgInfoCurrent.devicegroup.currentElectricityCost)
            setCurrentElec(dgInfoCurrent.devicegroup.currentElectricityCost.toFixed(2))
          else setCurrentElec(0)
          if (dgInfoCurrent.devicegroup.currentmaxProfit)
            setCurrentMProfit(dgInfoCurrent.devicegroup.currentmaxProfit.toFixed(2))
          else setCurrentMProfit(0)
        }
        if (dgInfoCurrent.devicegroup.bestEfficiency)
          setOptimalEff(dgInfoCurrent.devicegroup.bestEfficiency.toFixed(2))
        else setOptimalEff(0)
        if (dgInfoCurrent.devicegroup.maxRevenue)
          setOptimalRev(dgInfoCurrent.devicegroup.maxRevenue.toFixed(2))
        else setOptimalRev(0)
        if (btcData !== '' && btcData !== undefined && btcDGname === minerGroup) {
          if (btcData.aggrmaxRevenue)
            setAggOptimalRev(btcData.aggrmaxRevenue.toFixed(2))
          else setAggOptimalRev(0)
          if (btcData.currentaggrmaxRevenue)
            setAggCurrentRev(btcData.currentaggrmaxRevenue.toFixed(2))
          else setAggCurrentRev(0)
        } else {
          if (dgInfoCurrent.devicegroup.aggrmaxRevenue)
            setAggOptimalRev(dgInfoCurrent.devicegroup.aggrmaxRevenue.toFixed(2))
          else setAggOptimalRev(0)
          if (dgInfoCurrent.devicegroup.currentaggrmaxRevenue)
            setAggCurrentRev(dgInfoCurrent.devicegroup.currentaggrmaxRevenue.toFixed(2))
          else setAggCurrentRev(0)
        }
        if (dgInfoCurrent.devicegroup.electricityCost)
          setOptimalElec(dgInfoCurrent.devicegroup.electricityCost.toFixed(2))
        else setOptimalElec(0)
        if (btcData !== '' && btcData !== undefined && btcDGname === minerGroup) {
          if (btcData.aggrelectricityCost)
            setAggOptimalElec(btcData.aggrelectricityCost.toFixed(2))
          else setAggOptimalElec(0)
          if (btcData.currentaggrElectricityCost)
            setAggCurrentElec(btcData.currentaggrElectricityCost.toFixed(2))
          else setAggCurrentElec(0)
        } else {
          if (dgInfoCurrent.devicegroup.aggrelectricityCost)
            setAggOptimalElec(dgInfoCurrent.devicegroup.aggrelectricityCost.toFixed(2))
          else setAggOptimalElec(0)
          if (dgInfoCurrent.devicegroup.currentaggrElectricityCost)
            setAggCurrentElec(dgInfoCurrent.devicegroup.currentaggrElectricityCost.toFixed(2))
          else setAggCurrentElec(0)
        }
        if (dgInfoCurrent.devicegroup.estimatedPoolFee)
         setOptimalPCost(dgInfoCurrent.devicegroup.estimatedPoolFee.toFixed(2))
        else setOptimalPCost(0)
        if (dgInfoCurrent.devicegroup.estimatedPoolFee)
         setCurrentPCost(dgInfoCurrent.devicegroup.estimatedPoolFee.toFixed(2))
        else setCurrentPCost(0)
        if (dgInfoCurrent.devicegroup.maxProfit)
         setOptimalMProfit(dgInfoCurrent.devicegroup.maxProfit.toFixed(2))
        else setOptimalMProfit(0)
        if (btcData !== '' && btcData !== undefined && btcDGname === minerGroup) {
          if (btcData.aggrmaxProfit)
            setAggOptimalMProfit(btcData.aggrmaxProfit.toFixed(2))
          else setAggOptimalMProfit(0)
          if (btcData.currentaggrmaxProfit)
            setAggCurrentMProfit(btcData.currentaggrmaxProfit.toFixed(2))
          else setAggCurrentMProfit(0)
        } else {
          if (dgInfoCurrent.devicegroup.aggrmaxProfit)
            setAggOptimalMProfit(dgInfoCurrent.devicegroup.aggrmaxProfit.toFixed(2))
          else setAggOptimalMProfit(0)
          if (dgInfoCurrent.devicegroup.currentaggrmaxProfit)
            setAggCurrentMProfit(dgInfoCurrent.devicegroup.currentaggrmaxProfit.toFixed(2))
          else setAggCurrentMProfit(0)
        }
      }
    }
  
    const handleFirstManualInputChange = (event) => {
      const value = event.target.value;
      if (value >= 0) {
        setFirstManualInput(value);
      }
    };

    const [secondOption, setsecondOption] = useState('');
    const [secondmanualInput, setSecondManualInput] = useState('');

    const handlesecondOptionChange = (event) => {
      setsecondOption(event.target.value);
      setSecondManualInput('');
    };
  
    const handleSecondManualInputChange = (event) => {
      const value = event.target.value;
      if (value >= 0) {
        setSecondManualInput(value);
      }
    };

    const [thirdOption, setthirdOption] = useState('');
    const [thirdmanualInput, setThirdManualInput] = useState('');

    const handlethirdOptionChange = (event) => {
      setthirdOption(event.target.value);
      setThirdManualInput('');
    };
  
    const handleThirdManualInputChange = (event) => {
      const value = event.target.value;
      if (value >= 0) {
        setThirdManualInput(value);
      }
    };  

    const [fourthOption, setfourthOption] = useState('');
    const [fourthmanualInput, setFourthManualInput] = useState('');

    const handlefourthOptionChange = (event) => {
      setfourthOption(event.target.value);
      setFourthManualInput('');
    };
  
    const handleFourthManualInputChange = (event) => {
      const value = event.target.value;
      if (value >= 0) {
        setFourthManualInput(value);
      }
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isTabEnabled, setIsTabEnabled] = useState(true);

    const handleBTCsubmit = async () => {
      console.log("YES")
      setIsLoading(true);
      setIsTabEnabled(false);

      const token = await getAccessTokenSilently();
      const btcdata = {}

      if (minerGroup === '') {
          setBtcSubmit(1);
          setSuccessModalOpen(true);
      } else if (!isButtonOn && (firstmanualInput.trim() === '')) {
          setBtcSubmit(2);
          setSuccessModalOpen(true);
      } else if (!isButtonOn2 && (secondmanualInput.trim() === '' || thirdmanualInput.trim() === '' || fourthmanualInput.trim() === '')) {
          setBtcSubmit(3);
          setSuccessModalOpen(true);
      } else {
          if (firstmanualInput !== '' && !isButtonOn)
            btcdata.energyPricekWh = parseFloat(firstmanualInput.trim())
          if (secondmanualInput !== '' && !isButtonOn2)
            btcdata.BTCPrice = parseFloat(secondmanualInput.trim())
          if (thirdmanualInput !== '' && !isButtonOn2)
            btcdata.blockreward = parseFloat(thirdmanualInput.trim())
          if (fourthmanualInput !== '' && !isButtonOn2) {
            btcdata.btcDifficulty = parseFloat(fourthmanualInput) * 1000000000000
            console.log('actual btc difficulty', btcdata.btcDifficulty)
          }
          console.log('btc data ', JSON.stringify(btcdata))

          btcdata.dgname = minerGroup
          const btcInfo = await api.PostBTC(token, btcdata);
          if (btcInfo !== undefined || btcInfo !== null) {
            setBtcData(btcInfo)
            setBtcDGname(minerGroup)
            if (btcInfo && btcInfo.status === "ok") {
              setBtcSubmit(100)
              setSuccessModalOpen(true);
            } else {
              setBtcSubmit(4)
              setSuccessModalOpen(true);
            }
          } else {
            setBtcSubmit(4)
            setSuccessModalOpen(true);
          }
      }
      setIsLoading(false);
      setIsTabEnabled(true);
    }

    const handleCloseSuccessModal = () => {
      // Close the success modal
      setSuccessModalOpen(false);
      setTune({})
    };

    const handleAutoCloseSuccessModal = () => {
      // Close the success modal
      setSuccessAutoModalOpen(false);
      setTune({})
    };

    const handleTune = async () => {
      setIsLoading(true);
      setIsTabEnabled(false);
      const token = await getAccessTokenSilently();
      if (minerGroup !== '') {
        const tunedata = {}
        tunedata.dgname = minerGroup
        const tuneResp = await api.PostManualTune(token, tunedata);
        console.log('Tune Response from BE', tuneResp)
        if (tuneResp) {
            setSuccessAutoModalOpen(true);
            setTune(tuneResp)
            setAutoTuneSubmit(100)
            setMsg(tuneResp.msg)
            if (tuneResp.status && tuneResp.status === 'error') {
              setAutoTuneSubmit(3)
              setTune({})
              setMsg(tuneResp.msg)
            }
        } else {
            setAutoTuneSubmit(2)
            setTune({})
            setSuccessAutoModalOpen(true);
        }
      } else {
        setAutoTuneSubmit(1)
        setTune({})
        setSuccessAutoModalOpen(true);

      }
      setIsLoading(false);
      setIsTabEnabled(true);
    }


    const [fifthOption, setfifthOption] = useState('');
    const [fifthmanualInput, setFifthManualInput] = useState('');

    const handlefifthOptionChange = (event) => {
      setfifthOption(event.target.value);
      setFifthManualInput('');
    };
  
    const handleFifthManualInputChange = (event) => {
      const value = event.target.value;
      if (value >= 0 && (value <= 1000000000)) {
        setFifthManualInput(value);
      }
    };


    const [sixthOption, setsixthOption] = useState('');
    const [sixthmanualInput, setSixthManualInput] = useState('');

    const handlesixthOptionChange = (event) => {
      setsixthOption(event.target.value);
      setSixthManualInput('');
    };
  
    const handleSixthManualInputChange = (event) => {
      setSixthManualInput(event.target.value);
    };

    useEffect(() => {
      const runFn = async () => {
        const token = await getAccessTokenSilently();
        const deviceGroups = await api.GetAllDeviceGroups(token);
        console.log(deviceGroups)
        if (deviceGroups === undefined) {
          setMinerGroups([])
        }
        else {
          var minerGroupList = [];
          for (let i=0; i < deviceGroups.length; i++) {
            minerGroupList.push(deviceGroups[i].DGName);
          }
          minerGroupList=['Teraflux Group']
          setMinerGroups(minerGroupList)
        }
      }
      runFn();
    }, []);

    useEffect(() => {
      const runFn = async () => {
        const token = await getAccessTokenSilently();
        const info = {}
        if (minerGroup !== '') {
          info.dgName = minerGroup
          const dgInfoCurrent = await api.GetDGInfo(token, info);
          if (dgInfoCurrent === undefined) {
          }
          else {
            console.log('Response from BE', dgInfoCurrent)
            setDgInfo(dgInfoCurrent)
            if (dgInfoCurrent.devicegroup.bitcoinPrice)
              setBitcoinPrice(dgInfoCurrent.devicegroup.bitcoinPrice)
            else setBitcoinPrice(0)
            if (dgInfoCurrent.devicegroup.energyPrice)
              setEnergyPrice(dgInfoCurrent.devicegroup.energyPrice)
            else setEnergyPrice(0)
            if (dgInfoCurrent.devicegroup.bitcoinPrice)
              setBlockReward(dgInfoCurrent.devicegroup.blockreward)
            else setBlockReward(0)
            if (dgInfoCurrent.devicegroup.btcDifficulty)
              setBtcDiff((dgInfoCurrent.devicegroup.btcDifficulty/1000000000000).toFixed(2))
            else setBtcDiff(0)

            if (btcData !== '' && btcData !== undefined && btcDGname === minerGroup) {
              console.log('Picking from btcData', JSON.stringify(btcData))
              if (btcData.maxRate)
                setOptimalThs(btcData.maxRate)
              else setOptimalThs(0)
              if (btcData.currentmaxRate)
                setCurrentThs(btcData.currentmaxRate)
              else setCurrentThs(0)
              if (btcData.currentEfficiency)
                setCurrentEff(btcData.currentEfficiency)
              else setCurrentEff(0)
              if (btcData.currentmaxRevenue)
                setCurrentRev(btcData.currentmaxRevenue.toFixed(2))
              else setCurrentRev(0)
              if (btcData.currentElectricityCost)
                setCurrentElec(btcData.currentElectricityCost.toFixed(2))
              else setCurrentElec(0)
              if (btcData.currentmaxProfit)
                setCurrentMProfit(btcData.currentmaxProfit.toFixed(2))
              else setCurrentMProfit(0)
            } else {
              console.log('Picking from dginfo')
              if (dgInfoCurrent.devicegroup.maxRate)
                setOptimalThs(dgInfoCurrent.devicegroup.maxRate)
              else setOptimalThs(0)
              if (dgInfoCurrent.devicegroup.currentmaxRate)
                setCurrentThs(dgInfoCurrent.devicegroup.currentmaxRate)
              else setCurrentThs(0)
              if (dgInfoCurrent.devicegroup.currentEfficiency)
                setCurrentEff(dgInfoCurrent.devicegroup.currentEfficiency)
              else setCurrentEff(0)
              if (dgInfoCurrent.devicegroup.currentmaxRevenue)
                setCurrentRev(dgInfoCurrent.devicegroup.currentmaxRevenue.toFixed(2))
              else setCurrentRev(0)
              if (dgInfoCurrent.devicegroup.currentElectricityCost)
                setCurrentElec(dgInfoCurrent.devicegroup.currentElectricityCost.toFixed(2))
              else setCurrentElec(0)
              if (dgInfoCurrent.devicegroup.currentmaxProfit)
                setCurrentMProfit(dgInfoCurrent.devicegroup.currentmaxProfit.toFixed(2))
              else setCurrentMProfit(0)
            }
            if (dgInfoCurrent.devicegroup.bestEfficiency)
              setOptimalEff(dgInfoCurrent.devicegroup.bestEfficiency.toFixed(2))
            else setOptimalEff(0)
            if (dgInfoCurrent.devicegroup.maxRevenue)
              setOptimalRev(dgInfoCurrent.devicegroup.maxRevenue.toFixed(2))
            else setOptimalRev(0)
            if (btcData !== '' && btcData !== undefined && btcDGname === minerGroup) {
              if (btcData.aggrmaxRevenue)
                setAggOptimalRev(btcData.aggrmaxRevenue.toFixed(2))
              else setAggOptimalRev(0)
              if (btcData.currentaggrmaxRevenue)
                setAggCurrentRev(btcData.currentaggrmaxRevenue.toFixed(2))
              else setAggCurrentRev(0)
            } else {
              if (dgInfoCurrent.devicegroup.aggrmaxRevenue)
                setAggOptimalRev(dgInfoCurrent.devicegroup.aggrmaxRevenue.toFixed(2))
              else setAggOptimalRev(0)
              if (dgInfoCurrent.devicegroup.currentaggrmaxRevenue)
                setAggCurrentRev(dgInfoCurrent.devicegroup.currentaggrmaxRevenue.toFixed(2))
              else setAggCurrentRev(0)
            }
            if (dgInfoCurrent.devicegroup.electricityCost)
              setOptimalElec(dgInfoCurrent.devicegroup.electricityCost.toFixed(2))
            else setOptimalElec(0)
            if (btcData !== '' && btcData !== undefined && btcDGname === minerGroup) {
              if (btcData.aggrelectricityCost)
                setAggOptimalElec(btcData.aggrelectricityCost.toFixed(2))
              else setAggOptimalElec(0)
              if (btcData.currentaggrElectricityCost)
                setAggCurrentElec(btcData.currentaggrElectricityCost.toFixed(2))
              else setAggCurrentElec(0)
            } else {
              if (dgInfoCurrent.devicegroup.aggrelectricityCost)
                setAggOptimalElec(dgInfoCurrent.devicegroup.aggrelectricityCost.toFixed(2))
              else setAggOptimalElec(0)
              if (dgInfoCurrent.devicegroup.currentaggrElectricityCost)
                setAggCurrentElec(dgInfoCurrent.devicegroup.currentaggrElectricityCost.toFixed(2))
              else setAggCurrentElec(0)
            }
            if (dgInfoCurrent.devicegroup.estimatedPoolFee)
             setOptimalPCost(dgInfoCurrent.devicegroup.estimatedPoolFee.toFixed(2))
            else setOptimalPCost(0)
            if (dgInfoCurrent.devicegroup.estimatedPoolFee)
             setCurrentPCost(dgInfoCurrent.devicegroup.estimatedPoolFee.toFixed(2))
            else setCurrentPCost(0)
            if (dgInfoCurrent.devicegroup.maxProfit)
             setOptimalMProfit(dgInfoCurrent.devicegroup.maxProfit.toFixed(2))
            else setOptimalMProfit(0)
            if (btcData !== '' && btcData !== undefined && btcDGname === minerGroup) {
              if (btcData.aggrmaxProfit)
                setAggOptimalMProfit(btcData.aggrmaxProfit.toFixed(2))
              else setAggOptimalMProfit(0)
              if (btcData.currentaggrmaxProfit)
                setAggCurrentMProfit(btcData.currentaggrmaxProfit.toFixed(2))
              else setAggCurrentMProfit(0)
            } else {
              if (dgInfoCurrent.devicegroup.aggrmaxProfit)
                setAggOptimalMProfit(dgInfoCurrent.devicegroup.aggrmaxProfit.toFixed(2))
              else setAggOptimalMProfit(0)
              if (dgInfoCurrent.devicegroup.currentaggrmaxProfit)
                setAggCurrentMProfit(dgInfoCurrent.devicegroup.currentaggrmaxProfit.toFixed(2))
              else setAggCurrentMProfit(0)
            }
          }
        }
      }
      runFn();
    }, [btcData, minerGroup]);
    
    const chartRef = useRef(null);

    const chartRef1 = useRef(null);

    const [deviceVal, setDeviceVal] = useState([]);
    const [deviceList, setDeviceList]=useState([]);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const [isButtonOn, setButtonOn] = useState(false);
    const [isButtonOn2, setButtonOn2] = useState(false);
    const [isButtonOn3, setButtonOn3] = useState(false);
    const [isButtonOn4, setButtonOn4] = useState(false);
    const [isButtonOn5, setButtonOn5] = useState(false);
    const [isButtonOn6, setButtonOn6] = useState(false);
    const [isButtonOn7, setButtonOn7] = useState(false);

    const [inputValue, setInputValue] = useState('');


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = () => {
    setButtonOn(!isButtonOn);
    if (!isButtonOn) {
      setInputValue('');
    }
  };

  const handleButtonClick2 = () => {
    setButtonOn2(!isButtonOn2);
  };

  const handleButtonClick3 = async () => {
    setButtonOn3(!isButtonOn3);
    if (minerGroup !== '') {
      console.log("Handle the toggle to use optimal ths or not", isButtonOn3)
      const token = await getAccessTokenSilently();
      const req = {}
      req.dgname = minerGroup
      req.isTargetHashRate = isButtonOn3
      api.PostDGisTgtHR(token, req);
    }
  };


  const [showInput, setShowInput] = useState(false);
  const [temperature, setTemperature] = useState('');

  const handleGridClick = () => {
    setShowInput(true);
  };

  const handleTemperatureChange = (event) => {
    setTemperature(event.target.value);
  };

  const handleSubmit2 = (event) => {
    event.preventDefault();
    console.log('Third Option:', thirdOption);
    console.log('Third Manual Input:', thirdmanualInput);
  }

  const handleSubmit3 = (event) => {
    event.preventDefault();
    console.log('Fourth Option:', fourthOption);
    console.log('Fourth Manual Input:', fourthmanualInput);
  }

  const handleShowChart = (event) => {
    event.preventDefault();
    setShowChart(!showChart);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform actions with the selected options and inputs
    console.log('First Option:', firstOption);
    console.log('Second Option:', secondOption);
    console.log('First Manual Input:', firstmanualInput);
    console.log('Second Manual Input:', secondmanualInput);
    console.log('Third Manual Input:', thirdmanualInput);
    console.log('Fourth Manual Input:', fourthmanualInput);
    console.log('Fifth manual input', fifthmanualInput)
  };

  const renderChart = () => {
    console.log('render chart buttonOn6 ', isButtonOn6, minerGroup)
    if (dgInfo !== '') console.log(JSON.stringify(dgInfo))
    if (isButtonOn6 === true) {
      if (dgInfo !== '' && dgInfo.devicegroup.aggrChartProfitData) {
      // if (dgInfo !== '' && dgInfo.devicegroup.aggrChartProfitData && 
      //     dgInfo.devicegroup.aggrChartProfitData[0].x && dgInfo.devicegroup.aggrChartProfitData[0].y &&
      //     dgInfo.devicegroup.OptimalHashRate && dgInfo.devicegroup.TargetHashRate ) {
        console.log('in renderChart button6 is true and dginfo exists')
        // let x=[]
        // let y=[]
        // if (dgInfo.devicegroup.aggrChartProfitData) {
        //   let x = dgInfo.devicegroup.aggrChartProfitData[0].x
        //   let y = dgInfo.devicegroup.aggrChartProfitData[0].y
        // }
        // let mr1 = 0
        // let mr2 = 0
        // if (dgInfo.devicegroup.OptimalHashRate) mr1 = dgInfo.devicegroup.OptimalHashRate
        // if (dgInfo.devicegroup.TargetHashRate) mr2 = dgInfo.devicegroup.TargetHashRate
        let profitData=[
          {
            "x": dgInfo.devicegroup.aggrChartProfitData[0].x,
            "y": dgInfo.devicegroup.aggrChartProfitData[0].y,
            // "x": x,
            // "y": y,
            "type": "scatter",
            "name": "Profit per day",
            "hovertemplate": 'Hash: %{x:.2f} TH/s<extra></extra>' + '<br>Profit: %{y:$.2f}',
          }
        ]
        let maxRate1 = 0;
        let maxRate2 = 0;
        if (btcData !== '' && btcData !== undefined && btcDGname === minerGroup) {
          maxRate1 = btcData.maxRate
          maxRate2 = btcData.currentmaxRate
        } else {
          maxRate1 = dgInfo.devicegroup.OptimalHashRate
          maxRate2 = dgInfo.devicegroup.TargetHashRate
          // maxRate1 = mr1
          // maxRate2 = mr2
        }
        
        const titleText = ''
        const layout = {
          title: {
            text: titleText,
            font: { size: 16 },
            x: 0, //Set the x-position of the title in the center
            y: 15000, //Set the y-position of the title slightly above the chart
            xref: 'paper',
            yref: 'paper',
          },
          xaxis: { title: 'Hash Rate (TH/s)' },
          yaxis: { title: 'Profit Per Group ' },
          shapes: [
            {
              type: 'line',
              x0: maxRate1,
              x1: maxRate1,
              y0: 0,
              y1: 1,
              //y0: Math.min(...profitData.map((data) => data.y)) - 10,
              //y1: Math.max(...profitData.map((data) => data.y)) + 10,
              xref: 'x',
              yref: 'paper',
              line: { color: 'red', width: 2, dash: 'dash' },
            },
            {
              type: 'line',
              x0: maxRate2,
              x1: maxRate2,
              //y0: Math.min(...profitData.map((data) => data.y)) - 10,
              //y1: Math.max(...profitData.map((data) => data.y)) + 10,
              y0: 0,
              y1: 1,
              xref: 'x',
              yref: 'paper',
              line: { color: 'green', width: 2, dash: 'dash' },
            },
          ],
        };
        if (chartRef.current) {
          Plotly.newPlot(chartRef.current, profitData, layout);
        }
      }
    } else {
      // if (dgInfo !== '' && dgInfo.devicegroup.ChartProfitData && 
      //     dgInfo.devicegroup.ChartProfitData[0].x && dgInfo.devicegroup.ChartProfitData[0].y &&
      //     dgInfo.devicegroup.OptimalHashRate && dgInfo.devicegroup.TargetHashRate) {
        if (dgInfo !== '' && dgInfo.devicegroup.ChartProfitData) {
        console.log('in renderChart button6 is false and dginfo exists')
        // let x=[]
        // let y=[]
        // if (dgInfo.devicegroup.ChartProfitData) {
        //   let x = dgInfo.devicegroup.ChartProfitData[0].x
        //   let y = dgInfo.devicegroup.ChartProfitData[0].y
        // }
        // let mr1 = 0
        // let mr2 = 0
        // if (dgInfo.devicegroup.OptimalHashRate) mr1 = dgInfo.devicegroup.OptimalHashRate
        // if (dgInfo.devicegroup.TargetHashRate) mr2 = dgInfo.devicegroup.TargetHashRate
        let profitData=[
          {
            "x": dgInfo.devicegroup.ChartProfitData[0].x,
            "y": dgInfo.devicegroup.ChartProfitData[0].y,
            // "x": x,
            // "y": y,
            "type": "scatter",
            "name": "Profit per day",
            "hovertemplate": 'Hash: %{x:.2f} TH/s<extra></extra>' + '<br>Profit: %{y:$.2f}',
          }
        ]
        let maxRate1 = 0;
        let maxRate2 = 0;
        if (btcData !== '' && btcData !== undefined && btcDGname === minerGroup) {
          console.log('btcData exists for chart')
          maxRate1 = btcData.maxRate
          maxRate2 = btcData.currentmaxRate
        } else {
          console.log('dginfo data exists for chart')
          maxRate1 = dgInfo.devicegroup.OptimalHashRate
          maxRate2 = dgInfo.devicegroup.TargetHashRate
          // maxRate1 = mr1
          // maxRate2 = mr2
        }

        const titleText = ''
        const layout = {
          title: {
            text: titleText,
            font: { size: 16 },
            x: 0.5, //Set the x-position of the title in the center
            y: 15, //Set the y-position of the title slightly above the chart
            xref: 'paper',
            yref: 'paper',
          },
          xaxis: { title: 'Hash Rate (TH/s) Per Miner' },
          yaxis: { title: 'Profit Per Miner ' },
          shapes: [
            {
              type: 'line',
              x0: maxRate1,
              x1: maxRate1,
              y0: 0,
              y1: 1,
              //y0: Math.min(...profitData.map((data) => data.y)) - 10,
              //y1: Math.max(...profitData.map((data) => data.y)) + 10,
              xref: 'x',
              yref: 'paper',
              line: { color: 'red', width: 2, dash: 'dash' },
            },
            {
              type: 'line',
              x0: maxRate2,
              x1: maxRate2,
              //y0: Math.min(...profitData.map((data) => data.y)) - 10,
              //y1: Math.max(...profitData.map((data) => data.y)) + 10,
              y0: 0,
              y1: 1,
              xref: 'x',
              yref: 'paper',
              line: { color: 'green', width: 2, dash: 'dash' },
            },
          ],
        };
        if (chartRef.current) {
          Plotly.newPlot(chartRef.current, profitData, layout);
        }
      }
    }
    //console.log('render chart done')
  };
      
  useEffect(() => {
    renderChart(isButtonOn6);
  }, [isButtonOn6, value]);

  useEffect(() => {
    return () => {
      if (chartRef.current) {
        console.log('purge the chart')
        Plotly.purge(chartRef.current);
      }
    };
  }, [isButtonOn6]);

  
  

const HistoricChart = ({devicegroupInfo, buttonInfo}) => {
  
  const [selectedDuration, setSelectedDuration] = useState('1w');
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    var devicegroupInf = devicegroupInfo
    let historicData = []
    if (devicegroupInf !== '' && (devicegroupInf !== 'undefined') && (devicegroupInf !== null) 
        && devicegroupInf.devicegroup.PerGroupHistoryData && devicegroupInf.devicegroup.PerMinerHistoryData) {
      if (buttonInfo) {
        historicData = devicegroupInf.devicegroup.PerGroupHistoryData
        historicData.sort((a, b) => new Date(a.date) - new Date(b.date));
      } else {
        historicData = devicegroupInf.devicegroup.PerMinerHistoryData
        historicData.sort((a, b) => new Date(a.date) - new Date(b.date));
      }
      const filteredData = historicData.filter((item) => {
        const currentDate = new Date(item.date);
        const endDate = new Date();
        const startDate = new Date();
        switch (selectedDuration) {
          case '1d':
            startDate.setDate(endDate.getDate() - 1);
            break;
          case '1w':
            startDate.setDate(endDate.getDate() - 7);
            break;
          case '1m':
            startDate.setMonth(endDate.getMonth() - 1);
            break;
          case '1yr':
            startDate.setFullYear(endDate.getFullYear() - 1);
            break;
          default:
            break;
        }
        return currentDate >= startDate && currentDate <= endDate;
      });
      setSelectedData(filteredData);
    }
  }, [selectedDuration]);


  useEffect(() => {

    const dates = selectedData.map((item) => item.date);
    const hashrates = selectedData.map((item) => item.hashrate);
    const profits = selectedData.map((item) => item.profit);

    const desiredValues = [];
    const minValue = 100;
    const maxValue = 10000;
    const increment = 100;

    
    for (let value = minValue; value <= maxValue; value += increment) {
      desiredValues.push(value);
    }
    
    const validProfits = profits.filter((value) => typeof value === 'number' && !isNaN(value));
    const maxProfitrange = validProfits.length > 0 ? Math.max(...validProfits.filter((value) => desiredValues.includes(value))) : 0;
        
    const profitTrace = {
      x: dates,
      y: profits,
      type: 'scatter',
      name: 'Profit in USD',
      mode: 'lines+markers', // Display a smooth curve with markers
      yaxis: 'y1', // Assign the trace to the left y-axis
      line: { shape: 'spline', color: 'blue' }, // Set the line shape to spline
    };
    
    const hashrateTrace = {
      x: dates,
      y: hashrates,
      type: 'scatter',
      name: 'TeraHashrate',
      mode:'lines+markers',
      yaxis: 'y2', // Assign the trace to a new right y-axis
      line: { shape: 'spline', color: 'green', dash:'dot' },
    };
  
    const data = [profitTrace, hashrateTrace];
    
    const chartLayout = {
      title: 'Historic Data for Profit and Hashrate',
      xaxis: { title: 'Date' },
      yaxis: {
        title: 'Profit in USD',
        range: [0, maxProfitrange],
        side: 'left', // Position the Profit axis on the left side
        showgrid: false,
      },
      yaxis2: {
        title: 'TeraHashrate',
        range: [60, 165],
        overlaying: 'y',
        side: 'right',
        showgrid: false,
      },
    };
    console.log('plot historic data')
    Plotly.newPlot('chart-container', data, chartLayout);
  }, [selectedData]);

 
  return (
    <div>
      <br/><br/>
      <div className="duration-buttons">
        <button
          onClick={() => setSelectedDuration('1d')}
          className={selectedDuration === '1d' ? 'active' : ''}
        >
          1D
        </button>
        <button
          onClick={() => setSelectedDuration('1w')}
          className={selectedDuration === '1w' ? 'active' : ''}
        >
          1W
        </button>
        <button
          onClick={() => setSelectedDuration('1m')}
          className={selectedDuration === '1m' ? 'active' : ''}
        >
          1M
        </button>
        <button
          onClick={() => setSelectedDuration('1yr')}
          className={selectedDuration === '1yr' ? 'active' : ''}
        >
          1YR
        </button>
      </div>
      <div id="chart-container" className="chart-container" style={{ width: 1500,}} />
    </div>
  );
  
};


const exportToCSV = (minerGroup) => {
  // Extract the data from the historicData array
  var csvData = []
  //console.log("in the exporttoCSV function... ", isButtonOn6)
  if (isButtonOn6) {
    if ((dgInfo !== '') && (dgInfo.devicegroup) && (dgInfo.devicegroup.PerGroupHistoryData) && 
        (dgInfo.devicegroup.PerGroupHistoryData.length>0)) {
      csvData = dgInfo.devicegroup.PerGroupHistoryData.map((item) => ({
        MinerGroup: minerGroup,
        Date: item.date,
        TuningMode: item.TuningMode,
        TotalMiners: item.TotalDevices,

        Profit: item.profit,

        Hashrate: item.hashrate,
        Efficiency:item.bestEfficiency,

        BTCPriceinUSD:item.bitcoinPrice,
        BlockReward:item.blockreward,
        BTCDifficulty:item.btcDifficulty,

        EnergyPrice:item.energyPrice,
        estimatedPoolFee:item.estimatedPoolFee,
        ElectricityCost:item.electricityCost,
        Revenue:item.maxRevenue,
      }));
      csvData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
    }
  } else {
    if ((dgInfo !== '') && (dgInfo.devicegroup) && (dgInfo.devicegroup.PerMinerHistoryData) && 
        (dgInfo.devicegroup.PerMinerHistoryData.length>0)) {
      csvData = dgInfo.devicegroup.PerMinerHistoryData.map((item) => ({
        MinerGroup: minerGroup,
        Date: item.date,
        TuningMode: item.TuningMode,
        TotalMiners: item.TotalDevices,

        Profit: item.profit,

        Hashrate: item.hashrate,
        Efficiency:item.bestEfficiency,

        BTCPriceinUSD:item.bitcoinPrice,
        BlockReward:item.blockreward,
        BTCDifficulty:item.btcDifficulty,


        EnergyPrice:item.energyPrice,
        estimatedPoolFee:item.estimatedPoolFee,
        ElectricityCost:item.electricityCost,
        Revenue:item.maxRevenue,
      }));
      csvData.sort((a, b) => new Date(a.Date) - new Date(b.Date));

    }
    //console.log('done with exportToCSV')
  }


  // Define the CSV headers
  const headers = [
    { label: 'Miner Group', key: 'MinerGroup'},
    { label: 'Date', key: 'Date' },
    { label: 'Tuning Mode', key: 'TuningMode' },
    { label: 'TotalMiners', key: 'TotalMiners' },
    { label: 'Profit', key: 'Profit' },
    { label: 'Hashrate', key: 'Hashrate' },
    { label: 'Efficiency', key: 'Efficiency' },
    { label: 'BTCPriceinUSD', key: 'BTCPriceinUSD' },
    { label: 'BlockReward', key: 'BlockReward' },
    { label: 'BTCDifficulty', key: 'BTCDifficulty' },
    { label: 'EnergyPrice', key: 'EnergyPrice' },
    { label: 'estimatedPoolFee', key: 'estimatedPoolFee' },
    { label: 'ElectricityCost', key: 'ElectricityCost' },
    { label: 'Revenue', key: 'Revenue' },
  ];

  if (dgInfo !== '' && (dgInfo.devicegroup)) {
    return (
        <CSVLink
          data={csvData}
          headers={headers}
          filename="historic_data.csv"
          className="csv-link"
        >
          Export to CSV
      </CSVLink>
    );
  }

};

  const handleButtonClick4 = () => {
    setButtonOn4(!isButtonOn4);
  };

  const handleButtonClick5 = () => {
    setButtonOn5(!isButtonOn5);
  };

  const handleButtonClick6 = () => {
    setButtonOn6(!isButtonOn6);
  };

  const handleButtonClick7 = () => {
    setButtonOn7(!isButtonOn7);
  };

  const [selectedOption1, setSelectedOption1] = useState('');

  const handleOptionChange1 = (event) => {
    setSelectedOption1(event.target.value);
  };

  const SideArrowIcon = () => {
    return (
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 20 20">
              <path d="M0 0L10 5L0 10Z" fill="#333" />
          </svg>
    );
  };
  const DownArrowIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 20 20">
        <path d="M0 0L10 15L20 0H0Z" fill="blue" />
        </svg>
    )
  }
  const PlusIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20">
        <rect x="4" y="8" width="12" height="4" fill="#333" />
        <rect x="8" y="4" width="4" height="12" fill="#333" />
      </svg>
    )
  }

  const NegativeIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20">
        <rect x="4" y="8" width="12" height="4" fill="#333" />
      </svg>
    );
  }
  const EqualIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20">
        <rect x="2" y="8" width="16" height="4" fill="green" />
        <rect x="2" y="13" width="16" height="4" fill="green" />
      </svg>
    );
    
  }

return (
    <PageContainer title="Mock DVFS Page" description="this is a Dashboard for the stats of the miner">
      <div style={{ marginRight: '20px', textAlign: 'left' }}>
               <Autocomplete
            disablePortal
            onChange={(event, value) => handleMinerGroup(value)}
            sx = {{
         
              width: '200px',
            }}
            disableClearable
            id="mode"
            value = {minerGroup}
            options={minerGroups}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} size="small" placeholder="Select Miner Group" aria-label="Select mode" />
            )}

          />
      </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleTabChange} aria-label="Profile">
          <Tab sx = {{textTransform: "none"}} label="Input Parameters" {...a11yProps(0)} />
          <Tab sx = {{textTransform: "none"}} label="Profit Vs Hashrate Chart" {...a11yProps(1)} />
          <Tab sx = {{textTransform: "none"}} label="History" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} disabled={!isTabEnabled}>
      
      <br/>
      <PaperWrap>
        <Box sx={{ display: 'flex', height: 40}}>
        <span className="toggle-text">Customer Profile</span>
        {/* <label className={`toggle-button ${isButtonOn ? 'active' : ''}`}>
          <input
            type="checkbox"
            checked={isButtonOn}
            onChange={handleButtonClick}
          />
          <div className="slider"></div>
          <span className="slider-label">{isButtonOn ? 'Auto' : 'Manual'}</span>
        </label> */}
        {/* {isButtonOn && 
          <TextField
            type="text"
            label = "kWh assumed cost is $0.03"
            value={'URL of Energy Provider'}
            sx={{
              mt: '-5px',
              ml: '5px',
              width: 275,
              color: 'green',
              fontSize: '14px',
              backgroundColor: '#f5f5f5',
            }}
          />
        } */}
        {!isButtonOn && 
          <TextField
            type="text"
            label="Enter kWh Cost in USD"
            value={firstmanualInput}
            onChange={handleFirstManualInputChange}
            sx={{
              mt: '-5px',
              ml: '5px',
              color: 'blue',
              fontSize: '14px',
              backgroundColor: '#f5f5f5',
            }}
          />
        }
        </Box>
        </PaperWrap>
        <br/>
        <PaperWrap>
        <Box sx={{ display: 'flex',}}>
        <span className="toggle-text">Market Profile</span>
        <label className={`toggle-button ${isButtonOn2 ? 'active' : ''}`}>
          <input
            type="checkbox"
            checked={isButtonOn2}
            onChange={handleButtonClick2}
          />
          <div className="slider"></div>
          <span className="slider-label">{isButtonOn2 ? 'Auto' : 'Manual'}</span>
        </label>
        {!isButtonOn2 && 
          <TextField
            type="text"
            // label = "BTC Price in USD(0-9999999)"
            label = "BTC Price in USD"
            value={secondmanualInput}
            onChange={handleSecondManualInputChange}
            sx={{
              mt: '-5px',
              ml: '5px',
              color: 'green',
              fontSize: '14px',
              backgroundColor: '#f5f5f5',
            }}
          />
        }
        {isButtonOn2 && 
          <TextField
            type="text"
            label = "BTC Price Retrieved From"
            value={'https://api.blockchain.info/ticker'}
            sx={{
              mt: '-5px',
              ml: '5px',
              width: 275,
              color: 'green',
              fontSize: '14px',
              backgroundColor: '#f5f5f5',
            }}
          />
        }
        {!isButtonOn2 && 
          <TextField
            type="text"
            label = "Block Reward"
            value={thirdmanualInput}
            onChange={handleThirdManualInputChange}
            sx={{
              mt: '-5px',
              ml: '5px',
              color: 'green',
              fontSize: '14px',
              backgroundColor: '#f5f5f5',
            }}
          />
        }
        {isButtonOn2 && 
          <TextField
            type="text"
            label = "Block Reward Retrieved From"
            value={'https://api.blockchain.info'}
            sx={{
              mt: '-5px',
              ml: '5px',
              width: 275,
              color: 'green',
              fontSize: '14px',
              backgroundColor: '#f5f5f5',
            }}
          />
        }
        {!isButtonOn2 && 
          <TextField
            type="text"
            label = "BTC Difficulty (in Trillions)"
            value={fourthmanualInput}
            onChange={handleFourthManualInputChange}
            sx={{
              mt: '-5px',
              ml: '5px',
              color: 'green',
              fontSize: '14px',
              backgroundColor: '#f5f5f5',
            }}
          />
        }
        {isButtonOn2 && 
          <TextField
            type="text"
            label = "BTC Difficulty Retrieved From"
            value={'https://api.blockchain.info/charts/difficulty?format=json'}
            sx={{
              mt: '-5px',
              ml: '5px',
              width: 450,
              color: 'green',
              fontSize: '14px',
              backgroundColor: '#f5f5f5',
            }}
          />
        }
        </Box>
        </PaperWrap>
    <br/>
    {/* <button  onClick={handleBTCsubmit} type="submit" className="submitButton">
      Submit
    </button> */}
    <button onClick={handleBTCsubmit} disabled={isLoading} className="submitButton">
      {isLoading ? 'Inprogress...' : 'Submit'}
    </button>
    <br /><br />
    <div>
      {btcSubmit === 1 && (
        <FailMinerGroupModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} />
      )}
      {btcSubmit === 2 && (
        <FailCustomerProfileModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} />
      )}
      {btcSubmit === 3 && (
        <FailMarketProfileModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} />
      )}
      {btcSubmit === 100 && (
        <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} />
      )}
      {btcSubmit === 4 && (
        <FailModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} />
      )}
    </div>
    </TabPanel>
    <TabPanel value={value} index={1} disabled={!isTabEnabled}>
      {minerGroup !== '' && (
          <div className="toggle-container">
              <span className="toggle-text1">Miner Group Name: {minerGroup}</span>
           </div>
      )}
      <Grid container spacing={1} sx={{ }}>
            <Grid item>
              <InfoBlock title = {"BTC Price"} info = {bitcoinPrice || 0.0} color = {'#cfb53b'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>
            <Grid item>
              <InfoBlock title = {"Block Reward"} info = {blockReward || 0.0} color = {'purple'} unit = {'BTC'} orientation = {"right"} fontsize={'5px'}/>
            </Grid>
            <Grid item>
              <InfoBlock title = {"BTC Difficulty"} info = {btcDiff || 0} color = {'magenta'} unit = {'T'} orientation = {"right"} fontsize={'5px'}/>
            </Grid>
            <Grid item>
              <InfoBlock title = {"Energy Price"} info = {EnergyPrice || 0} color = {'green'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>      
      </Grid>
      <br/>
      <div className="toggle-container-left">
        <Typography variant="h7" component="div" sx={{ mr: '5px' }}>
        <span className="toggle-text1">Miner </span>
        </Typography>
        <label className={`radio-button ${isButtonOn6 ? 'active' : ''}`}>
          <input
            type="radio"
            value="true"
            name="hashRateOption"
            checked={isButtonOn6}
            onChange={handleButtonClick6}
          />
          <div className="radio-button-indicator"></div>
        </label>
        <Typography variant="h7" component="div" sx={{ ml: '10px', mr: '5px' }}>
        <span className="toggle-text1">Miner Group </span>
        </Typography>
        <label className={`radio-button ${!isButtonOn6 ? 'active' : ''}`}>
          <input
            type="radio"
            value="false"
            name="hashRateOption"
            checked={!isButtonOn6}
            onChange={handleButtonClick6}
          />
          <div className="radio-button-indicator"></div>
        </label>
      </div>
      <br/>

      <div style={{ display: 'flex', 'alignitems': 'flex-start', width: 1300}}>
      {isButtonOn6 === true && (
       <div id="chartContainer" ref={chartRef} style={{ width: 1500,}}/>
      )}
      {isButtonOn6 === false && (
       <div id="chartContainer" ref={chartRef} style={{ width: 1500,}}/>
      )}
      {isButtonOn6 === false && (
        <Grid container spacing={1} sx={{ }}>
            <Grid item>
              <Typography variant="h8" sx={{ mt: -8}}>Running Configuration</Typography>
              <InfoBlock title = {"Running THs and J/TH"} info = {(currentThs ? currentThs : 0.0)+ " and " + (currentEff ? currentEff:0) } color = {'blue'} unit = {''} orientation = {"left"}/>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><DownArrowIcon /></Box>
              <InfoBlock title = {"Revenue"} info = {currentRev || 0.0} color = {'darkorchid'} unit = {'$'} orientation = {"left"} fontsize={'5px'} />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><NegativeIcon/></Box>
              <InfoBlock title = {"Pool and Electricity Cost"} info = {'2%' + ' BTC and $' + currentElec} color = {'red'} unit = {''} orientation = {"left"} fontsize={'5px'}/>
              <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><EqualIcon/></Box>
              </Grid>
              <InfoBlock title = {"Max Profit"} info = {currentMProfit || 0.0} color = {'blue'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>
            <Grid item>
              <Typography variant="h8" sx = {{mt: -8}}>Optimal Configuration</Typography>
              <InfoBlock title = {"Optimal THs and J/TH"} info = {(optimalThs ? optimalThs : 0.0)+ " and " + (optimalEff ?optimalEff:0) } color = {'blue'} unit = {''} orientation = {"left"}/>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><DownArrowIcon /></Box>
              <InfoBlock title="Revenue" info={optimalRev || 0.0} color="darkorchid" unit="$" orientation="left" fontsize="5px"/>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><NegativeIcon/></Box>
              <InfoBlock title = {"Pool and Electricity Cost"} info = {'2%' + ' BTC and $' + optimalElec} color = {'red'} unit = {''} orientation = {"left"} fontsize={'5px'}/>
              <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><EqualIcon/></Box>
              </Grid>
              <InfoBlock title = {"Max Profit"} info = {optimalMProfit || 0.0} color = {'blue'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>

        </Grid>
      )}
      {isButtonOn6 === true && (
        <Grid container spacing={1} sx={{ mt: '0' }}>
            <Grid item>
            <Typography variant="h8" sx={{ mt: -8}}>Running Configuration</Typography>
              <InfoBlock title = {"Running THs and J/TH"} info = {(currentThs ? currentThs : 0.0)+ " and " + (currentEff ? currentEff:0) } color = {'blue'} unit = {''} orientation = {"left"}/>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><DownArrowIcon /></Box>
              <InfoBlock title = {"Revenue"} info = {aggCurrentRev || 0.0} color = {'darkorchid'} unit = {'$'} orientation = {"left"} fontsize={'5px'} />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><NegativeIcon/></Box>
              <InfoBlock title = {"Pool and Electricity Cost"} info = {'2%' + ' BTC and $' + aggCurrentElec} color = {'red'} unit = {''} orientation = {"left"} fontsize={'5px'}/>
              <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><EqualIcon/></Box>
              </Grid>
              <InfoBlock title = {"Max Profit"} info = {aggCurrentMProfit || 0.0} color = {'blue'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>
            <Grid item>
              <Typography variant="h8" sx = {{mt: -8}}>Optimal Configuration</Typography>
              <InfoBlock title = {"Optimal THs and J/TH"} info = {(optimalThs ? optimalThs : 0.0)+ " and " + (optimalEff ? optimalEff:0) } color = {'blue'} unit = {''} orientation = {"left"}/>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><DownArrowIcon /></Box>
              <InfoBlock title = {"Revenue"} info = {aggOptimalRev || 0.0} color = {'darkorchid'} unit = {'$'} orientation = {"left"} fontsize={'5px'} />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><NegativeIcon/></Box>
              <InfoBlock title = {"Pool and Electricity Cost"} info = {'2%' + ' BTC and $' + aggOptimalElec} color = {'red'} unit = {''} orientation = {"left"} fontsize={'5px'}/>
              <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}><EqualIcon/></Box>
              </Grid>
              <InfoBlock title = {"Max Profit"} info = {aggOptimalMProfit || 0} color = {'blue'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>
        </Grid>
      )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'left' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="220" height="20" >
            <text x="10" y="15" fill="green" fontSize="12">
              ----
            <tspan fill='black' fontSize="10"> Target THs</tspan>
          </text>
        </svg>
        </div>
        <div style={{ display: 'flex', justifyContent: 'left' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="220" height="20">
            <text x="10" y="15" fill="red" fontSize="12">
              ----
            <tspan fill='black' fontSize="10"> Optimal THs</tspan>
          </text>
        </svg>
        </div>
        <br/>
        <PaperWrap>
          <Box sx={{ display: 'flex', height: 40}}>
            <span className="toggle-text">Use Optimal THs to Tune</span>
            <label className={`toggle-button ${isButtonOn3 ? 'active' : ''}`}>
              <input
                type="checkbox"
                checked={isButtonOn3}
                onChange={handleButtonClick3}
              />
              <div className="slider"></div>
              <span className="slider-label">{isButtonOn3 ? 'Yes' : 'No'}</span>
            </label>
          </Box>
        </PaperWrap>
        {/* <PaperWrap>
          <Box sx={{ display: 'flex', height: 40}}>
          <span className="toggle-text">Power Consumption Constraint</span>
            <TextField
                type="number"
                label = "Watt range 0-1000000000 (1gW)"
                value={fifthmanualInput}
                onChange={handleFifthManualInputChange}
                sx={{
                  mt: '-5px',
                  ml: '5px',
                  color: 'green',
                  fontSize: '14px',
                  backgroundColor: '#f5f5f5',
                  width: '300px',
                }}
              />
          </Box>
        </PaperWrap> */}
        <br/>
        <button onClick={handleTune} disabled={isLoading} className="submitButton">
            {isLoading ? 'Inprogress...' : 'Tune'}
        </button>
        <br /><br />
        <div>
        {autotuneSubmit === 1 && (
        <FailMinerGroupModal isOpen={successAutoModalOpen} onClose={handleAutoCloseSuccessModal} />
        )}
        {autotuneSubmit === 2 && (
          <FailAutoTuneModal isOpen={successAutoModalOpen} onClose={handleAutoCloseSuccessModal} />
        )}
         {autotuneSubmit === 3 && (
          <FailAutoTuneModalAPI isOpen={successAutoModalOpen} onClose={handleAutoCloseSuccessModal} msg={submitMsg}/>
        )}
        {autotuneSubmit === 100 && (
          <SuccessAutoModal isOpen={successAutoModalOpen} autotune={autotune} onClose={handleAutoCloseSuccessModal} msg={submitMsg}/>
        )}
        </div>
    </TabPanel>
    <br/>
    <div style={{ textAlign: 'center' }}>
    <br/>
    <TabPanel value={value} index={2} disabled={!isTabEnabled}>
       <br/>
       {minerGroup !== '' && (
          <div className="toggle-container">
              <span className="toggle-text1">Miner Group Name: {minerGroup}</span>
           </div>
      )}
       <div className="toggle-container">
        <span className="toggle-text">Per Miner</span>
        <label className={`toggle-button ${isButtonOn6 ? 'active' : ''}`}>
          <input
            type="checkbox"
            checked={isButtonOn6}
            onChange={handleButtonClick6}
          />
          <div className="slider"></div>
        </label>
         <span className="toggle-text">Per Group</span>
      </div>
        <div>
          <div><HistoricChart devicegroupInfo = {dgInfo} buttonInfo = {isButtonOn6} /></div>
        </div>
        <br/>
        <div>
        <div>{exportToCSV(minerGroup)}</div>
        </div>
    </TabPanel>
      </div>
      </PageContainer>
  ) ;
};

export default withAuthenticationRequired(EnergyTuning, {
  onRedirecting: () => <Loading />,
});
