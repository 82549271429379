import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import { Stack, Box, Typography } from '@mui/material';
import { env } from '../env';
import { RefreshInterval } from '../components/refresh-interval';
import { displayTS, DeviceDialog } from '../device/device-dialog'
import { darken, lighten } from '@mui/material/styles';
import Footer from '../components/footer';

const defaultRefresh = 30

const renderJSONPretty = (params) => {
    //console.log(params)
    if (params.value) {
        if (params.value.updatedAt) {
        } else {
            return ""
        }

        const text = JSON.stringify(params.value, null, 4)

        return <DeviceDialog name={params.field} updatedAt={params.value.updatedAt} text={text} code={params.value} />
    }
    return ""
}

const columns = [
    { field: 'serial', headerName: 'Serial No.', minWidth: 120, flex: 1 },
    { field: 'mac', headerName: 'MAC address', minWidth: 140, flex: 1 },
    { field: 'ip', headerName: 'IP address', minWidth: 140, flex: 1 },
    { field: 'status', headerName: 'Status', minWidth: 300, flex: 1 },
    { field: 'mode', headerName: 'Mode', minWidth: 160, flex: 1 },
    { field: 'model', headerName: 'Miner Model', minWidth: 100, flex: 1 },
    { field: 'firmware', headerName: 'Firmware', minWidth: 180, flex: 1 },
    { field: 'hostname', headerName: 'Hostname', minWidth: 100, flex: 1 },
    { field: 'accepted', headerName: 'Accepted', minWidth: 100, flex: 1 },
    { field: 'rejected', headerName: 'Rejected', minWidth: 100, flex: 1 },
    { field: 'mhs5s', headerName: 'MHS 5s', minWidth: 100, flex: 1 },
    { field: 'mhs1m', headerName: 'MHS 1m', minWidth: 100, flex: 1 },
    { field: 'mhs5m', headerName: 'MHS 5m', minWidth: 100, flex: 1 },
    { field: 'mhs15m', headerName: 'MHS 15m', minWidth: 100, flex: 1 },
    { field: 'mhsav', headerName: 'MHS av', minWidth: 100, flex: 1 },
    { field: 'elapsed', headerName: 'Elapsed', minWidth: 100, flex: 1 },
    { field: 'updatedAt', headerName: 'Updated', minWidth: 180, flex: 1 },
    { field: 'temp', headerName: 'Temperature', minWidth: 160, flex: 1 },
    { field: 'fan', headerName: 'Fan Speed', minWidth: 160, flex: 1 },
    { field: 'psu', headerName: 'PSU info', minWidth: 160, flex: 1 },
    { field: 'pool1', headerName: 'Pool 1', minWidth: 400, flex: 1 }, // 'resizable: true' needs datagrid pro
    { field: 'worker1', headerName: 'Worker 1', minWidth: 400, flex: 1 },
    { field: 'pool2', headerName: 'Pool 2', minWidth: 400, flex: 1 },
    { field: 'worker2', headerName: 'Worker 2', minWidth: 400, flex: 1 },
    { field: 'pool3', headerName: 'Pool 3', minWidth: 400, flex: 1 },
    { field: 'worker3', headerName: 'Worker 3', minWidth: 400, flex: 1 },
    { field: 'ipreport', headerName: 'IP Report', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: false },
    { field: 'summary', headerName: 'Summary', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: false },
    { field: 'pools', headerName: 'Pools', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: true },
    { field: 'devs', headerName: 'Devs', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: true },
    { field: 'version', headerName: 'Version', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: true },
    { field: 'config', headerName: 'Config', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: true },
    { field: 'coin', headerName: 'Coin', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: true },
    { field: 'devdetails', headerName: 'Dev Details', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: true },
    { field: 'stats', headerName: 'Stats', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: true },
    { field: 'lcd', headerName: 'LCD', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: true },
    { field: 'fanJSON', headerName: 'fanJSON', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: true },
    { field: 'tempJSON', headerName: 'tempJSON', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: true },
    { field: 'modeJSON', headerName: 'modeJSON', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: true },
    { field: 'psuJSON', headerName: 'psuJSON', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: true },
    { field: 'ledJSON', headerName: 'ledJSON', minWidth: 180, flex: 1, renderCell: renderJSONPretty, hide: true },
    { field: 'bgGreen', headerName: 'green', minWidth: 100, flex: 1, hide: true },
    { field: 'bgRed', headerName: 'red', minWidth: 100, flex: 1, hide: true },
];


export default function DeviceList() {
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(50);
    const [count, setCount] = React.useState(100);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [timegap, setTimegap] = React.useState(defaultRefresh);
    const [lastRefresh, setLastRefresh] = React.useState("");


    const handleInterval = (val) => {
        setTimegap(val)
    }

    const { getAccessTokenSilently } = useAuth0();

    const PostDevice = async (page) => {
        let r = {
            count: 0,
            docs: []
        }

        try {
            const token = await getAccessTokenSilently();

            const response = await fetch(
                env.APIPath.device,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        device: {
                            limit: pageSize,
                            skip: pageSize * page
                        }
                    })
                }
            );
            const data = await response.json();
            if (data.status === "ok") {
                //console.log(data);

                data.docs.forEach((el) => {
                    const doc = {
                        serial: el.SerialNo ?? '',
                    }
                    if (el.ipreport && (el.ipreport.IPReport === undefined || el.ipreport.IPReport === null)) {
                        doc.mac = el.ipreport.mac ?? ''
                        doc.ip = el.ipreport.ip ?? ''
                        doc.model = el.ipreport.model ?? ''
                        doc.hostname = el.ipreport.hostname ?? ''
                        doc.firmware = el.ipreport.version ?? ''
                        doc.ipreport = el.ipreport
                    } else if (el.ipreport && el.ipreport.IPReport) {
                        doc.mac = el.ipreport.IPReport[0].mac ?? ''
                        doc.ip = el.ipreport.IPReport[0].ip ?? ''
                        doc.model = el.ipreport.IPReport[0].model ?? ''
                        doc.hostname = el.ipreport.IPReport[0].hostname ?? ''
                        doc.firmware = el.ipreport.IPReport[0].version ?? ''
                        doc.ipreport = el.ipreport.IPReport[0]
                    }
                    if (el.summary && el.summary.SUMMARY) {
                        doc.accepted = el.summary.SUMMARY[0].Accepted
                        doc.rejected = el.summary.SUMMARY[0].Rejected
                        doc.mhs5s = el.summary.SUMMARY[0]['MHS 5s'].toFixed(2)
                        doc.mhs1m = el.summary.SUMMARY[0]['MHS 1m'].toFixed(2)
                        doc.mhs5m = el.summary.SUMMARY[0]['MHS 5m'].toFixed(2)
                        doc.mhs15m = el.summary.SUMMARY[0]['MHS 15m'].toFixed(2)
                        doc.mhsav = el.summary.SUMMARY[0]['MHS av'].toFixed(2)
                        doc.elapsed = el.summary.SUMMARY[0].Elapsed
                        doc.summary = el.summary
                    }
                    if (el.fan && el.fan.Fan) {
                        const fanSpeeds = [];
                        el.fan.Fan.forEach((el2) => {
                            fanSpeeds.push(el2.Speed.toString());
                        });
                        doc.fan = fanSpeeds.toString()
                        doc.fanJSON = el.fan
                    }
                    if (el.temperature && el.temperature.Temperature) {
                        const temp = [];
                        el.temperature.Temperature.forEach((board) => {
                            const boardTemp = [];
                            board.BoardTemp.forEach((el2) => {
                                boardTemp.push(el2.Temperature.toString());
                            });
                            temp.push(boardTemp.toString());
                        });
                        // console.log(temp);
                        doc.temp = temp.toString()
                        doc.tempJSON = el.temperature
                    }
                    if (el.mode && el.mode.Mode) {
                        const sleepStr = el.mode.Mode[0].Sleep === 'on' ? 'sleeping' : 'running';
                        doc.mode = `${el.mode.Mode[0].Mode}, ${sleepStr}`;
                        doc.modeJSON = el.mode
                    }
                    if (el.psu && el.psu.PSU && el.psu.PSU[0]) {
                        doc.psu = `${el.psu.PSU[0].Voltage}, ${el.psu.PSU[0].Current}`;
                        doc.psuJSON = el.psu
                    }
                    if (el.led && el.led.LED) {
                        doc.status = `${el.led.LED[0].Msg}`;
                        doc.ledJSON = el.led
                    }
                    if (el.version) {
                        doc.version = el.version
                    }
                    if (el.config) {
                        doc.config = el.config
                    }
                    if (el.devs) {
                        doc.devs = el.devs
                    }
                    if (el.pools) {
                        doc.pools = el.pools
                        if (el.pools.POOLS[0]) {
                            doc.pool1 = el.pools.POOLS[0]['URL'] ?? ''
                            doc.worker1 = el.pools.POOLS[0]['User'] ?? ''
                        }
                        if (el.pools.POOLS[1]) {
                            doc.pool2 = el.pools.POOLS[1]['URL'] ?? ''
                            doc.worker2 = el.pools.POOLS[1]['User'] ?? ''
                        }
                        if (el.pools.POOLS[2]) {
                            doc.pool3 = el.pools.POOLS[2]['URL'] ?? ''
                            doc.worker3 = el.pools.POOLS[2]['User'] ?? ''
                        }
                    }
                    if (el.coin) {
                        doc.coin = el.coin
                    }
                    if (el.devdetails) {
                        doc.devdetails = el.devdetails
                    }
                    if (el.stats) {
                        doc.stats = el.stats
                    }
                    if (el.lcd) {
                        doc.lcd = el.lcd
                    }
                    const { diffTime, timeStr } = displayTS(el.updatedAt)
                    const seconds = diffTime / 1000
                    if (seconds <= 300) {
                        doc.updatedAt = seconds + "s ago"
                        doc.bgGreen = true
                    } else {
                        doc.updatedAt = timeStr
                        doc.bgGreen = false
                    }
                    r.docs.push(doc)
                });
                r.count = data.count
            } else {
                console.log(data);
            }
        } catch (error) {
            console.log(error.message);
        }

        // return docs.slice(0, pageSize)
        return r
    };

    React.useEffect(() => {
        let active = true;

        const fn = async () => {
            setLoading(true);
            const { docs: newRows, count: count1 } = await PostDevice(page);

            if (!active) {
                return;
            }

            setRows(newRows);
            setLoading(false);
            setCount(count1);

            const nowDate = new Date();
            setLastRefresh(nowDate.toLocaleString());

            setTimeout(() => {
                fn()
            }, timegap * 1000);
        }
        fn()

        return () => {
            active = false;
        }
    }, [page, timegap, pageSize]);


    const getBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getHoverBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    return (
        <Box sx={{
            '& .rowGreen': {
                bgcolor: (theme) =>
                    getBackgroundColor(theme.palette.info.main, theme.palette.mode),
            },
        }}>
            <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 2 }}>
                <Typography>Last Refresh: {lastRefresh}</Typography>
                <RefreshInterval name="Refresh Interval" value={timegap} callback={handleInterval} />
                <Typography component="div" sx={{ ml: 1, flexGrow: 1 }} />
            </Stack>
            <DataGrid
                autoHeight
                getRowId={(row) => row.serial}
                rows={rows}
                columns={columns}
                getRowHeight={() => 'auto'}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                rowsPerPageOptions={[25, 50, 100]}
                rowCount={count}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                loading={loading}
                disableSelectionOnClick
                getRowClassName={(params) => {
                    if (params.row.bgGreen) {
                        // console.log(params.row)
                        return 'rowGreen'
                    } else {
                        return ''
                    }
                }}
            />
        <Footer />
        </Box >
    );
}
