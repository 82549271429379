import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import PropTypes from "prop-types";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";

import { Box, Grid } from "@mui/material";
import {
  BodySmall,
  BodyXSmall,
  H10TitleXSmall,
  H8TitleMedium,
} from "../../components/StyledComponents/Typography/Typography.tsx";
import IconButton from "@mui/material/IconButton";

const LineChart = (props) => {
  const {
    heading,
    subHeading,
    lineChartOptions,
    isExpanded,
    isExpansionRequired,
    chartIndex,
    setExpandedChartIndex,
    expandedChartIndex,
    isExpandedGraphsModeActivated,
  } = props;
  if (!lineChartOptions) return <> </>;

  const expansionHandler = (index) => {
    setExpandedChartIndex(index);
  };

  const minimizerHandler = () => {
    setExpandedChartIndex(-1);
  };

  if (isExpanded) {
    lineChartOptions.chart.height = 328;
  }
  if (
    expandedChartIndex !== -1 &&
    isExpandedGraphsModeActivated &&
    !isExpanded
  ) {
    lineChartOptions.chart.height = 97;
    lineChartOptions.legend.enabled = false;
    lineChartOptions.xAxis.labels.enabled = false;
    lineChartOptions.xAxis.tickWidth = 0;
  }

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        padding={
          expandedChartIndex !== -1 &&
          isExpandedGraphsModeActivated &&
          !isExpanded
            ? "0.313rem 0.75rem"
            : "0.675rem 1.5rem"
        }
      >
        <Box display="flex" flexDirection="column" alignItems="start" gap={1}>
          {expandedChartIndex !== -1 &&
          isExpandedGraphsModeActivated &&
          !isExpanded ? (
            <>
              <H8TitleMedium>{heading}</H8TitleMedium>
              <BodySmall>{subHeading}</BodySmall>
            </>
          ) : (
            <>
              <H8TitleMedium>{heading}</H8TitleMedium>
              <BodySmall>{subHeading}</BodySmall>
            </>
          )}
        </Box>
        {isExpansionRequired && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ maxHeight: "1.5rem", maxWidth: "1.5rem" }}
          >
            {!isExpanded ? (
              <IconButton onClick={() => expansionHandler(chartIndex)}>
                <OpenInFullOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton onClick={minimizerHandler}>
                <CloseFullscreenOutlinedIcon />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
      <HighchartsReact
        highcharts={Highcharts}
        options={lineChartOptions}
        containerProps={{ style: { height: "100%", width: "100%" } }}
      />
    </>
  );
};

LineChart.defaultProps = {
  isExpanded: false,
  isExpansionRequired: false,
};

LineChart.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  lineChartOptions: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool,
  isExpansionRequired: PropTypes.bool,
  chartIndex: PropTypes.number,
  isExpandedGraphsModeActivated: PropTypes.bool,
  expandedChartIndex: PropTypes.number,
};

export default React.memo(LineChart);
