import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import LoginButton from "./components/login-button";

import NavBar from "./components/nav-bar";
import Loading from "./components/loading";
import PrivateRoute from "./components/private-route";

import Home from "./home/home";
import UserProfile from "./user/user";
import OrgProfile from "./user/org";
import DeviceList from "./device/device-list";
import DeviceGroup from "./device/device-group";
import Config from "./config/config";
import ZTPConfig from "./config/ztp-config";
import MinerConfig from "./config/miner-config-setup";
import StatsConfig from "./config/stats-config";
import ReviewConfig from "./config/review-config";
import DVFSConfig from "./config/dvfs-config";
import PriceConfig from "./config/price-config";
import DemandConfig from "./config/demand-config";
import ApiKey from "./user/apikey";
import Job from "./job/job";
import MinerStatus from "./minerstatus/device-list";
import Techsupport from "./techsupport/techsupport";
import GroupMgmt from "./minergroup/groupmgmt/device-group-mgmt.js";
import GroupOperations from "./minergroup/group-operations/group-operations.js";

import GroupInfo from "./minergroup/groupinfo/device-group-info.js";
import Dashboard from "./dashboard/dashboard";
import DashboardV2 from "./dashboard/dashboardV2";
import FluxGPT from "./fluxgpt/fluxgpt";
import HomePage from "./home";

import Send from "./socket/send";
import DVFS from "./socket/dvfs";
import MockDVFS from "./socket/mockdvfs";
import Recieve from "./socket/recieve";
import EnergyTuning from "./socket/energyTuning";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

const PrivateDeviceList = withAuthenticationRequired(DeviceList, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateDeviceGroup = withAuthenticationRequired(DeviceGroup, {
  onRedirecting: () => {
    return <Loading />;
  },
});

const PrivateZTPConfig = withAuthenticationRequired(ZTPConfig, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateMinerConfig = withAuthenticationRequired(MinerConfig, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateStatsConfig = withAuthenticationRequired(StatsConfig, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateReviewConfig = withAuthenticationRequired(ReviewConfig, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateDVFSConfig = withAuthenticationRequired(DVFSConfig, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivatePriceConfig = withAuthenticationRequired(PriceConfig, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateDemandConfig = withAuthenticationRequired(DemandConfig, {
  onRedirecting: () => {
    return <Loading />;
  },
});

const PrivateUserProfile = withAuthenticationRequired(UserProfile, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateOrgProfile = withAuthenticationRequired(OrgProfile, {
  onRedirecting: () => {
    return <Loading />;
  },
});

const PrivateApiKey = withAuthenticationRequired(ApiKey, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateJob = withAuthenticationRequired(Job, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateMinerStatus = withAuthenticationRequired(MinerStatus, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateGroupMgmt = withAuthenticationRequired(GroupMgmt, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateGroupOperations = withAuthenticationRequired(GroupOperations, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateGroupInfo = withAuthenticationRequired(GroupInfo, {
  onRedirecting: () => {
    return <Loading />;
  },
});

const PrivateDashboard = withAuthenticationRequired(Dashboard, {
  onRedirecting: () => {
    return <Loading />;
  },
});

const PrivateHome = withAuthenticationRequired(HomePage, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateDashboardV2 = withAuthenticationRequired(DashboardV2, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateFluxGPT = withAuthenticationRequired(FluxGPT, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateTechsupport = withAuthenticationRequired(Techsupport, {
  onRedirecting: () => {
    return <Loading />;
  },
});

const PrivateSend = withAuthenticationRequired(Send, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateDVFS = withAuthenticationRequired(DVFS, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateMockDVFS = withAuthenticationRequired(MockDVFS, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateRecieve = withAuthenticationRequired(Recieve, {
  onRedirecting: () => {
    return <Loading />;
  },
});
const PrivateEnergyTuning = withAuthenticationRequired(EnergyTuning, {
  onRedirecting: () => {
    return <Loading />;
  },
});

const App = () => {
  const { isLoading } = useAuth0();
  const { isAuthenticated } = useAuth0();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <ThemeProvider theme={theme}>
      <div id="app">
        <NavBar
          isMobileSidebarOpen={isMobileSidebarOpen}
          isSidebardir={true}
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
        />
        <Box
          sx={{
            // marginY: 5,
            width: "auto",
            paddingLeft: isSidebarOpen ? "280px!important" : "",
          }}
        >
          <Switch>
            <Route path="/" exact component={PrivateHome} />
            <Route path="/home" component={PrivateHome} />
            <Route path="/device/devicelist" component={PrivateDeviceList} />
            <Route path="/device/devicegroup" component={PrivateDeviceGroup} />
            <Route path="/config/ztp" component={PrivateZTPConfig} />
            <Route path="/config/miner" component={PrivateMinerConfig} />
            <Route path="/config/stats" component={PrivateStatsConfig} />
            <Route path="/config/review" component={PrivateReviewConfig} />
            <Route path="/config/dvfs" component={PrivateDVFSConfig} />
            <Route path="/config/price" component={PrivatePriceConfig} />
            <Route path="/config/demand" component={PrivateDemandConfig} />
            <Route path="/profile/user" component={PrivateUserProfile} />
            <Route path="/profile/org" component={PrivateOrgProfile} />
            <Route path="/profile/apikey" component={PrivateApiKey} />
            <Route path="/job" component={PrivateJob} />
            <Route path="/minerstatus" component={PrivateMinerStatus} />
            <Route path="/techsupport" component={PrivateTechsupport} />
            <Route path="/minergroup/groupmgmt" component={PrivateGroupMgmt} />
            <Route
              path="/minergroup/group-operations"
              component={PrivateGroupOperations}
            />
            <Route path="/minergroup/groupinfo" component={PrivateGroupInfo} />
            <Route path="/dashboard" component={PrivateDashboard} />
            <Route path="/power-management" component={PrivateDashboardV2} />
            <Route path="/fluxgpt" component={PrivateFluxGPT} />
            <Route path="/socket/send" component={PrivateSend} />
            <Route path="/socket/dvfs" component={PrivateDVFS} />
            <Route path="/socket/recieve" component={PrivateRecieve} />
            <Route path="/socket/mockdvfs" component={PrivateMockDVFS} />
            <Route path="/socket/tuning" component={PrivateEnergyTuning} />
          </Switch>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default App;
