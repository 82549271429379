import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';

export const RefreshInterval = ({ name, value, callback }) => {
    const handleChange = (event) => {
        if (callback) {
            callback(event.target.value)
        }
    };

    return (
        <Box sx={{ minWidth: 120, mt: 0, ml: 2 }}>
            <TextField sx={{ minWidth: 270}}

                    labelId="simple-select-label"
                    id="simple-select"
                    value={value}
                    helperText={name}
                    onChange={handleChange}
                    select
                >
                    {/* <MenuItem value={5}>5 second</MenuItem>
                    <MenuItem value={10}>10 seconds</MenuItem> */}
                    <MenuItem value={30}>30 seconds</MenuItem>
                    <MenuItem value={60}>1 minute</MenuItem>
                    <MenuItem value={180}>3 minutes</MenuItem>
                    <MenuItem value={300}>5 minutes</MenuItem>

            </TextField>
        </Box>
    );
}

export const JobRefreshInterval = ({ name, value, callback }) => {
    const handleChange = (event) => {
        if (callback) {
            callback(event.target.value)
        }
    };

    return (
        <Box sx={{ minWidth: 120, mt: 2, ml: 2 }}>
            <TextField sx={{ minWidth: 270}}

                    labelId="simple-select-label"
                    id="simple-select"
                    value={value}
                    helperText={name}
                    onChange={handleChange}
                    select
                >
                    <MenuItem value={5}>5 seconds</MenuItem>
                    <MenuItem value={15}>15 seconds</MenuItem>
                    <MenuItem value={30}>30 seconds</MenuItem>
                    <MenuItem value={60}>1 minute</MenuItem>

            </TextField>
        </Box>
    );
}