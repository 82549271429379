import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect, useRef } from "react";
import { Paper, Grid, Box, Table, TableBody, TableRow, TableCell, Toolbar, Typography, Button, Select, MenuItem } from '@mui/material';
import { UserA } from "../user/user";
import TextField from '@mui/material/TextField';
import * as api from '../api/api'
import Loading from "../components/loading";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Footer from '../components/footer';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { useTheme } from '@mui/material/styles';
import Plotly from 'plotly.js-dist';
import Tooltip from '@mui/material/Tooltip';
import PageContainer from '../components/PageContainer';
import InfoBlock from './InfoBlock';
import { CSVLink } from 'react-csv';


  
export const MockDVFS = () => {
    const [showChart, setShowChart] = useState(false);

    const [firstOption, setfirstOption] = useState('');
    const [firstmanualInput, setFirstManualInput] = useState('');

    const handlefirstOptionChange = (event) => {
      setfirstOption(event.target.value);
      setFirstManualInput('');
    };
  
    const handleFirstManualInputChange = (event) => {
      setFirstManualInput(event.target.value);
    };

    const [secondOption, setsecondOption] = useState('');
    const [secondmanualInput, setSecondManualInput] = useState('');

    const handlesecondOptionChange = (event) => {
      setsecondOption(event.target.value);
      setSecondManualInput('');
    };
  
    const handleSecondManualInputChange = (event) => {
      setSecondManualInput(event.target.value);
    };

    const [thirdOption, setthirdOption] = useState('');
    const [thirdmanualInput, setThirdManualInput] = useState('');

    const handlethirdOptionChange = (event) => {
      setthirdOption(event.target.value);
      setThirdManualInput('');
    };
  
    const handleThirdManualInputChange = (event) => {
      setThirdManualInput(event.target.value);
    };  

    const [fourthOption, setfourthOption] = useState('');
    const [fourthmanualInput, setFourthManualInput] = useState('');

    const handlefourthOptionChange = (event) => {
      setfourthOption(event.target.value);
      setFourthManualInput('');
    };
  
    const handleFourthManualInputChange = (event) => {
      setFourthManualInput(event.target.value);
    };


    const [fifthOption, setfifthOption] = useState('');
    const [fifthmanualInput, setFifthManualInput] = useState('');

    const handlefifthOptionChange = (event) => {
      setfifthOption(event.target.value);
      setFifthManualInput('');
    };
  
    const handleFifthManualInputChange = (event) => {
      setFifthManualInput(event.target.value);
    };


    const [sixthOption, setsixthOption] = useState('');
    const [sixthmanualInput, setSixthManualInput] = useState('');

    const handlesixthOptionChange = (event) => {
      setsixthOption(event.target.value);
      setSixthManualInput('');
    };
  
    const handleSixthManualInputChange = (event) => {
      setSixthManualInput(event.target.value);
    };
    
    const chartRef = useRef(null);

    const chartRef1 = useRef(null);

    const [deviceVal, setDeviceVal] = useState([]);
    const [deviceList, setDeviceList]=useState([]);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const [isButtonOn, setButtonOn] = useState(false);
    const [isButtonOn2, setButtonOn2] = useState(false);
    const [isButtonOn3, setButtonOn3] = useState(false);
    const [isButtonOn4, setButtonOn4] = useState(false);
    const [isButtonOn5, setButtonOn5] = useState(false);
    const [isButtonOn6, setButtonOn6] = useState(false);
    const [isButtonOn7, setButtonOn7] = useState(false);

    const [inputValue, setInputValue] = useState('');


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = () => {
    setButtonOn(!isButtonOn);
    if (!isButtonOn) {
      setInputValue('');
    }
  };

  const handleButtonClick2 = () => {
    setButtonOn2(!isButtonOn2);
  };

  const handleButtonClick3 = () => {
    setButtonOn3(!isButtonOn3);
  };


  const [showInput, setShowInput] = useState(false);
  const [temperature, setTemperature] = useState('');

  const handleGridClick = () => {
    setShowInput(true);
  };

  const handleTemperatureChange = (event) => {
    setTemperature(event.target.value);
  };

    const checkDeviceVal = async(value) => {
      if (value.includes("select all")) {
        setDeviceVal(deviceList)
      } else {
        if (deviceVal.includes("select all")) {
          setDeviceVal([])
        } else {
          setDeviceVal(value)
        }
      }
    }

    const handleSubmit2 = (event) => {
      event.preventDefault();
      console.log('Third Option:', thirdOption);
      console.log('Third Manual Input:', thirdmanualInput);
    }

    const handleSubmit3 = (event) => {
      event.preventDefault();
      console.log('Fourth Option:', fourthOption);
      console.log('Fourth Manual Input:', fourthmanualInput);
    }

    const handleShowChart = (event) => {
      event.preventDefault();
      setShowChart(!showChart);
    }

    const handleSubmit = (event) => {
      event.preventDefault();
      // Perform actions with the selected options and inputs
      console.log('First Option:', firstOption);
      console.log('Second Option:', secondOption);
      console.log('First Manual Input:', firstmanualInput);
      console.log('Second Manual Input:', secondmanualInput);
      console.log('Third Manual Input:', thirdmanualInput);
      console.log('Fourth Manual Input:', fourthmanualInput);
      console.log('Fifth manual input', fifthmanualInput)
    };

    const renderChart = () => {
      console.log('sridhar ', isButtonOn6)
      if (isButtonOn6 === true) {
      let profitData=[
        {
          "x": [
            100, 101.01010101010101, 102.02020202020202, 103.03030303030303,
  104.04040404040404, 105.05050505050505, 106.06060606060606, 107.07070707070707,
  108.08080808080808,  109.0909090909091,  110.1010101010101, 111.11111111111111,
  112.12121212121212, 113.13131313131314, 114.14141414141415, 115.15151515151516,
  116.16161616161617, 117.17171717171718, 118.18181818181819,  119.1919191919192,
  120.20202020202021, 121.21212121212122, 122.22222222222223, 123.23232323232324,
  124.24242424242425, 125.25252525252526, 126.26262626262627, 127.27272727272728,
   128.2828282828283,  129.2929292929293,  130.3030303030303, 131.31313131313132,
  132.32323232323233, 133.33333333333334, 134.34343434343435, 135.35353535353536,
  136.36363636363637, 137.37373737373738,  138.3838383838384,  139.3939393939394,
  140.40404040404042, 141.41414141414143, 142.42424242424244, 143.43434343434345,
  144.44444444444446, 145.45454545454547, 146.46464646464648,  147.4747474747475,
   148.4848484848485,  149.4949494949495, 150.50505050505052, 151.51515151515153,
  152.52525252525254, 153.53535353535355, 154.54545454545456, 155.55555555555557,
  156.56565656565658,  157.5757575757576,  158.5858585858586,  159.5959595959596,
  160.60606060606062, 161.61616161616163, 162.62626262626264, 163.63636363636365,
  164.64646464646466, 165.65656565656568, 166.66666666666669,  167.6767676767677,
   168.6868686868687, 169.69696969696972, 170.70707070707073, 171.71717171717174,
  172.72727272727275, 173.73737373737376, 174.74747474747477, 175.75757575757578,
   176.7676767676768,  177.7777777777778,  178.7878787878788, 179.79797979797982,
  180.80808080808083, 181.81818181818184, 182.82828282828285, 183.83838383838386,
  184.84848484848487, 185.85858585858588,  186.8686868686869,  187.8787878787879,
   188.8888888888889, 189.89898989898992, 190.90909090909093, 191.91919191919195,
  192.92929292929296, 193.93939393939397, 194.94949494949498,   195.959595959596,
    196.969696969697,   197.979797979798, 198.98989898989902,                200,
          ],
          "y": [
            6072.136661891323,  6133.466024919766,   6194.79535209447,  6256.124641706494,
   6317.453891896994,  6378.783100657215,  6440.112265828498,  6501.441385102275,
  6562.7704560200755,  6624.099475973516,  6685.428442204307,  6746.757351804261,
    6808.08620171527,   6869.41498872933, 6930.7437094885245,  6992.072360485028,
   7053.400938061116,  7114.729438409148,  7176.057857571586,  7237.386191440977,
  7298.7144357599645,  7360.042586121286,   7421.37063796777,  7482.698586592338,
   7544.026427138006,  7605.354154597882,  7666.681763815168,  7728.009249483159,
   7789.336606145243,  7850.663828194902,  7911.990909875705, 7973.3178452813245,
   8034.644628355516,  8095.971252892135,  8157.297712535126,  8218.624000778535,
   8279.950110966482,  8341.276036293202,   8402.60176980301,  8463.927304390318,
   8525.252632799631,  8586.577747625544,  8647.902641312749,  8709.227306156034,
   8770.551734300268,  8831.875917740428,  8893.199848321572,  8954.523517738859,
   9015.846917537536,  9077.170039112947,  9138.492873710526,  9199.815412425802,
   9261.137646204395,  9322.459565842022,  9383.781161984487,  9445.102425127696,
   9506.423345617637,    9567.7439136504,  9629.064119272161,  9690.383952379198,
   9751.703402717874,  9813.022459884647,  9874.341113326069,   9935.65935233879,
   9996.977166069544,  10058.29454351516, 10119.611473522567, 10180.927944788782,
  10242.243945860913, 10303.559465136164, 10364.874490861834,  10426.18901113531,
  10487.503013904074, 10548.816486965705,  10610.12941796787,  10671.44179440833,
  10732.753603634943, 10794.064832845655, 10855.375469088507, 10916.685499261634,
   10977.99491011326, 11039.303688241711, 11100.611820095399, 11161.919291972828,
  11223.226090022597, 11284.532200243402, 11345.837608484027, 11407.142300443349,
  11468.446261670346, 11529.749477564077, 11591.051933373697, 11652.353614198466,
  11713.654504987722, 11774.954590540903, 11836.253855507543,  11897.55228438726,
  11958.849861529772, 12020.146571134892, 12081.442397252518, 12142.737323782647
          ],
          "type": "scatter",
          "name": "Profit per day",
          "hovertemplate": 'Hash: %{x:.2f} TH/s<extra></extra>' + '<br>Profit: %{y:$.2f}',
        }
      ]
      let maxRate1 = 200
      let maxRate2 = 180
      let maxProfit1 = 7.29
      let joules1 = 29
      let txFee1 = 0.7
      let subsidy1 = 6.25
      let poolFee1 = 0.02
      let btcDifficulty1 = 48005534313579
      let ElectricityCost1 =  4.0368
      let maxRevenue1 = 11.33
      let btcprice = 27384.83
      const titleText = ''
      const layout = {
        title: {
          text: titleText,
          font: { size: 16 },
          x: 0, //Set the x-position of the title in the center
          y: 15000, //Set the y-position of the title slightly above the chart
          xref: 'paper',
          yref: 'paper',
        },
        xaxis: { title: 'Hash Rate (TH/s)' },
        yaxis: { title: 'Profit Per Group ' },
        shapes: [
          {
            type: 'line',
            x0: maxRate1,
            x1: maxRate1,
            y0: 0,
            y1: 1,
            //y0: Math.min(...profitData.map((data) => data.y)) - 10,
            //y1: Math.max(...profitData.map((data) => data.y)) + 10,
            xref: 'x',
            yref: 'paper',
            line: { color: 'red', width: 2, dash: 'dash' },
          },
          {
            type: 'line',
            x0: maxRate2,
            x1: maxRate2,
            //y0: Math.min(...profitData.map((data) => data.y)) - 10,
            //y1: Math.max(...profitData.map((data) => data.y)) + 10,
            y0: 0,
            y1: 1,
            xref: 'x',
            yref: 'paper',
            line: { color: 'green', width: 2, dash: 'dash' },
          },
        ],
      };
      Plotly.newPlot(chartRef.current, profitData, layout);
    } else {
      let profitData=[
        {
          "x": [
            100,
            101.01010101010101,
            102.02020202020202,
            103.03030303030303,
            104.04040404040404,
            105.05050505050505,
            106.06060606060606,
            107.07070707070707,
            108.08080808080808,
            109.0909090909091,
            110.1010101010101,
            111.11111111111111,
            112.12121212121212,
            113.13131313131314,
            114.14141414141415,
            115.15151515151516,
            116.16161616161617,
            117.17171717171718,
            118.18181818181819,
            119.1919191919192,
            120.20202020202021,
            121.21212121212122,
            122.22222222222223,
            123.23232323232324,
            124.24242424242425,
            125.25252525252525,
            126.26262626262626,
            127.27272727272727,
            128.2828282828283,
            129.2929292929293,
            130.3030303030303,
            131.31313131313132,
            132.32323232323233,
            133.33333333333334,
            134.34343434343435,
            135.35353535353536,
            136.36363636363637,
            137.37373737373738,
            138.3838383838384,
            139.3939393939394,
            140.40404040404042,
            141.41414141414143,
            142.42424242424244,
            143.43434343434345,
            144.44444444444446,
            145.45454545454547,
            146.46464646464648,
            147.4747474747475,
            148.4848484848485,
            149.4949494949495,
            150.5050505050505,
            151.5151515151515,
            152.5252525252525,
            153.53535353535352,
            154.54545454545453,
            155.55555555555554,
            156.56565656565655,
            157.57575757575756,
            158.58585858585857,
            159.59595959595958,
            160.6060606060606,
            161.6161616161616,
            162.62626262626262,
            163.63636363636363,
            164.64646464646464,
            165.65656565656565,
            166.66666666666666,
            167.67676767676767,
            168.68686868686868,
            169.6969696969697,
            170.7070707070707,
            171.7171717171717,
            172.72727272727272,
            173.73737373737373,
            174.74747474747474,
            175.75757575757575,
            176.76767676767676,
            177.77777777777777,
            178.78787878787878,
            179.7979797979798,
            180.8080808080808,
            181.8181818181818,
            182.82828282828282,
            183.83838383838383,
            184.84848484848484,
            185.85858585858585,
            186.86868686868686,
            187.87878787878788,
            188.88888888888889,
            189.8989898989899,
            190.9090909090909,
            191.91919191919192,
            192.92929292929293,
            193.93939393939394,
            194.94949494949495,
            195.95959595959596,
            196.96969696969697,
            197.97979797979798,
            198.989898989899,
            200
          ],
          "y": [
            2.5409187841967826,
            2.553207810417633,
            2.5654072022873713,
            2.577512687458464,
            2.5895196188160314,
            2.601422974477887,
            2.613217357794502,
            2.6248969973490244,
            2.636455746957277,
            2.647887085667749,
            2.6591841177616065,
            2.670339572752682,
            2.681345805387485,
            2.6921947956452,
            2.702878148737674,
            2.713387095109428,
            2.7237124904376637,
            2.73384481563225,
            2.7437741768357204,
            2.753490305423294,
            2.762982558002844,
            2.772239916414941,
            2.7812509877327996,
            2.7900040042623244,
            2.798486823542092,
            2.8066869283433435,
            2.81459142666999,
            2.8221870517586236,
            2.8294601620785063,
            2.8363967413315656,
            2.842982398452407,
            2.8492023676083065,
            2.8550415081992115,
            2.8604843048577475,
            2.865514867449197,
            2.8701169310715304,
            2.8742738560553827,
            2.877968627964057,
            2.8811838575935376,
            2.8839017809724776,
            2.886104259362197,
            2.8877727792566947,
            2.8888884523826395,
            2.889432015699361,
            2.889383831398888,
            2.888723886905897,
            2.8874317948777346,
            2.885486793204441,
            2.8828677450087117,
            2.8795531386459245,
            2.8755210877041115,
            2.8707493310039958,
            2.8652152325989615,
            2.8588957817750753,
            2.8517675930510613,
            2.8438069061783278,
            2.834989586140951,
            2.8252911231556794,
            2.8146866326719255,
            2.8031508553717934,
            2.790658157170034,
            2.7771825292140964,
            2.7626975878840785,
            2.7471765747927606,
            2.7305923567856016,
            2.712917425940722,
            2.6941238995689147,
            2.674183520213651,
            2.653067655651073,
            2.6307472988899736,
            2.6071930681718634,
            2.582375206970889,
            2.5562635839938768,
            2.5288276931803235,
            2.500036653702395,
            2.469859209964959,
            2.4382637316055114,
            2.405218213494239,
            2.370690275734015,
            2.3346471636603567,
            2.2970557478414797,
            2.257882524078262,
            2.217093613404222,
            2.1746547620856305,
            2.1305313416213334,
            2.0846883487429224,
            2.0370904054146113,
            1.987701758833337,
            1.9364862814286425,
            1.8834074708628101,
            1.828428450030751,
            1.7715119670600696,
            1.712620395311033,
            1.651715733376557,
            1.5887596050822737,
            1.5237132594864757,
            1.4565375708801103,
            1.3871930387867906,
            1.3156397879628337,
            1.241837568397207
          ],
          "type": "scatter",
          "name": "Profit per day",
          "hovertemplate": 'Hash: %{x:.2f} TH/s<extra></extra>' + '<br>Profit: %{y:$.2f}',
        }
      ]
      let maxRate1 = 145
      let maxRate2 = 130
      let maxProfit1 = 7.29
      let joules1 = 29
      let txFee1 = 0.7
      let subsidy1 = 6.25
      let poolFee1 = 0.02
      let btcDifficulty1 = 48005534313579
      let ElectricityCost1 =  4.0368
      let maxRevenue1 = 11.33
      let btcprice = 27384.83
      const titleText = ''
      const layout = {
        title: {
          text: titleText,
          font: { size: 16 },
          x: 0.5, //Set the x-position of the title in the center
          y: 15, //Set the y-position of the title slightly above the chart
          xref: 'paper',
          yref: 'paper',
        },
        xaxis: { title: 'Hash Rate (TH/s) Per Machine' },
        yaxis: { title: 'Profit Per Machine ' },
        shapes: [
          {
            type: 'line',
            x0: maxRate1,
            x1: maxRate1,
            y0: 0,
            y1: 1,
            //y0: Math.min(...profitData.map((data) => data.y)) - 10,
            //y1: Math.max(...profitData.map((data) => data.y)) + 10,
            xref: 'x',
            yref: 'paper',
            line: { color: 'red', width: 2, dash: 'dash' },
          },
          {
            type: 'line',
            x0: maxRate2,
            x1: maxRate2,
            //y0: Math.min(...profitData.map((data) => data.y)) - 10,
            //y1: Math.max(...profitData.map((data) => data.y)) + 10,
            y0: 0,
            y1: 1,
            xref: 'x',
            yref: 'paper',
            line: { color: 'green', width: 2, dash: 'dash' },
          },
        ],
      };
      Plotly.newPlot(chartRef.current, profitData, layout);
    }
  };
      
  useEffect(() => {
    renderChart(isButtonOn6);
  }, [isButtonOn6]);

  useEffect(() => {
    return () => {
      if (chartRef.current) {
        console.log('sridhar purging')
        Plotly.purge(chartRef.current);
      }
    };
  }, [isButtonOn6]);

  
  const historicData = [
    { date: '2023-04-16', profit: 13, hashrate: 150 },
    { date: '2023-04-17', profit: 9,  hashrate: 100 },
    { date: '2023-04-18', profit: 15, hashrate: 200 },
    { date: '2023-04-19', profit: 13, hashrate: 150 },
    { date: '2023-04-20', profit: 9,  hashrate: 100 },
    { date: '2023-04-21', profit: 15, hashrate: 200 },
    { date: '2023-04-22', profit: 13, hashrate: 150 },
    { date: '2023-04-23', profit: 9,  hashrate: 100 },
    { date: '2023-04-24', profit: 15, hashrate: 200 },
    { date: '2023-04-25', profit: 13, hashrate: 150 },
    { date: '2023-04-26', profit: 9,  hashrate: 100 },
    { date: '2023-04-27', profit: 15, hashrate: 200 },
    { date: '2023-04-28', profit: 11, hashrate: 160 },
    { date: '2023-04-29', profit: 12, hashrate: 170 },
    { date: '2023-04-30', profit: 11, hashrate: 160 },
    { date: '2023-05-01', profit: 10, hashrate: 150 },
    { date: '2023-05-02', profit: 12, hashrate: 160 },
    { date: '2023-05-03', profit: 8,  hashrate: 100 },
    { date: '2023-05-04', profit: 14, hashrate: 200 },
    { date: '2023-05-05', profit: 11, hashrate: 180 },
    { date: '2023-05-06', profit: 13, hashrate: 200 },
    { date: '2023-05-07', profit: 9,  hashrate: 110 },
    { date: '2023-05-08', profit: 15, hashrate: 140 },
    { date: '2023-05-09', profit: 7,  hashrate: 100 },
    { date: '2023-05-10', profit: 10, hashrate: 130 },
    { date: '2023-05-11', profit: 12, hashrate: 200 },
    { date: '2023-05-12', profit: 8,  hashrate: 145 },
    { date: '2023-05-13', profit: 14, hashrate: 190 },
    { date: '2023-05-14', profit: 11, hashrate: 160 },
    { date: '2023-05-15', profit: 13, hashrate: 150 },
    { date: '2023-05-16', profit: 9,  hashrate: 100 },
    { date: '2023-05-17', profit: 15, hashrate: 200 },
    { date: '2023-05-18', profit: 10, hashrate: 150 },
    { date: '2023-05-19', profit: 12, hashrate: 160 },
    { date: '2023-05-20', profit: 8,  hashrate: 100 },
    { date: '2023-05-21', profit: 11, hashrate: 160 },
    { date: '2023-05-22', profit: 11, hashrate: 180 },
    { date: '2023-05-23', profit: 13, hashrate: 200 },
    { date: '2023-05-24', profit: 9,  hashrate: 110 },
    { date: '2023-05-25', profit: 15, hashrate: 140 },
    { date: '2023-05-26', profit: 7,  hashrate: 100 },
    { date: '2023-05-27', profit: 10, hashrate: 130 },
    { date: '2023-05-28', profit: 12, hashrate: 200 },
    { date: '2023-05-29', profit: 8,  hashrate: 145 },
    { date: '2023-05-30', profit: 14, hashrate: 190 },
    { date: '2023-05-31', profit: 11, hashrate: 160 },
   
  ];

const HistoricChart = () => {
  
  const [selectedDuration, setSelectedDuration] = useState('1w');
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
  const filteredData = historicData.filter((item) => {
    const currentDate = new Date(item.date);
    const endDate = new Date();
    const startDate = new Date();
    switch (selectedDuration) {
      case '1d':
        startDate.setDate(endDate.getDate() - 1);
        break;
      case '1w':
        startDate.setDate(endDate.getDate() - 7);
        break;
      case '1m':
        startDate.setMonth(endDate.getMonth() - 1);
        break;
      case '1yr':
        startDate.setFullYear(endDate.getFullYear() - 1);
        break;
      default:
        break;
    }
    return currentDate >= startDate && currentDate <= endDate;
  });
  setSelectedData(filteredData);
  }, [selectedDuration]);


  useEffect(() => {
    const dates = selectedData.map((item) => item.date);
    const profits = selectedData.map((item) => item.profit);
    const hashrates = selectedData.map((item) => item.hashrate);

    const profitTrace = {
      x: dates,
      y: profits,
      type: 'scatter',
      name: 'Profit in USD',
      mode: 'lines+markers', // Display a smooth curve with markers
      yaxis: 'y1', // Assign the trace to the left y-axis
      line: { shape: 'spline', color: 'blue' }, // Set the line shape to spline
    };
    
    const hashrateTrace = {
      x: dates,
      y: hashrates,
      type: 'scatter',
      name: 'TeraHashrate',
      mode:'lines+markers',
      yaxis: 'y2', // Assign the trace to a new right y-axis
      line: { shape: 'spline', color: 'green', dash:'dot' },
    };
  
    const data = [profitTrace, hashrateTrace];
    
    const chartLayout = {
      title: 'Historic Data for Profit and Hashrate',
      xaxis: { title: 'Date' },
      yaxis: {
        title: 'Profit in USD',
        range: [0, 20],
        side: 'left', // Position the Profit axis on the left side
        showgrid: false,
      },
      yaxis2: {
        title: 'TeraHashrate',
        range: [100, 200],
        overlaying: 'y',
        side: 'right',
        showgrid: false,
      },
    };

    Plotly.newPlot('chart-container', data, chartLayout);
  }, [selectedData]);

 
  return (
    <div>
      <br/><br/>
      <div className="duration-buttons">
        <button
          onClick={() => setSelectedDuration('1d')}
          className={selectedDuration === '1d' ? 'active' : ''}
        >
          1D
        </button>
        <button
          onClick={() => setSelectedDuration('1w')}
          className={selectedDuration === '1w' ? 'active' : ''}
        >
          1W
        </button>
        <button
          onClick={() => setSelectedDuration('1m')}
          className={selectedDuration === '1m' ? 'active' : ''}
        >
          1M
        </button>
        <button
          onClick={() => setSelectedDuration('1yr')}
          className={selectedDuration === '1yr' ? 'active' : ''}
        >
          1YR
        </button>
      </div>
      <div id="chart-container" className="chart-container" style={{ width: 1500,}} />
    </div>
  );
  
};

const exportToCSV = () => {
  // Extract the data from the historicData array
  const csvData = historicData.map((item) => ({
    date: item.date,
    profit: item.profit,
    hashrate: item.hashrate,
  }));

  // Define the CSV headers
  const headers = [
    { label: 'Date', key: 'date' },
    { label: 'Profit', key: 'profit' },
    { label: 'Hashrate', key: 'hashrate' },
  ];

  return (
        <CSVLink
          data={csvData}
          headers={headers}
          filename="historic_data.csv"
          className="csv-link"
        >
          Export to CSV
    </CSVLink>
  );
};


  const handleButtonClick4 = () => {
    setButtonOn4(!isButtonOn4);
  };

  const handleButtonClick5 = () => {
    setButtonOn5(!isButtonOn5);
  };

  const handleButtonClick6 = () => {
    setButtonOn6(!isButtonOn6);
  };

  const handleButtonClick7 = () => {
    setButtonOn7(!isButtonOn7);
  };

  const [selectedOption1, setSelectedOption1] = useState('');

  const handleOptionChange1 = (event) => {
    setSelectedOption1(event.target.value);
  };

  const SideArrowIcon = () => {
    return (
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 20 20">
              <path d="M0 0L10 5L0 10Z" fill="#333" />
          </svg>
    );
  };
  const DownArrowIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 20 20">
        <path d="M0 0L10 15L20 0H0Z" fill="blue" />
        </svg>
    )
  }
  const PlusIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20">
        <rect x="4" y="8" width="12" height="4" fill="#333" />
        <rect x="8" y="4" width="4" height="12" fill="#333" />
      </svg>
    )
  }

  const NegativeIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20">
        <rect x="4" y="8" width="12" height="4" fill="#333" />
      </svg>
    );
  }
  const EqualIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20">
        <rect x="2" y="8" width="16" height="4" fill="green" />
        <rect x="2" y="13" width="16" height="4" fill="green" />
      </svg>
    );
    
  }

return (
    <PageContainer title="Mock DVFS Page" description="this is a Dashboard for the stats of the miner">
      <div style={{ marginRight: '20px', textAlign: 'left' }}>
               <select
                  value={selectedOption1}
                  onChange={handleOptionChange1}
                  className="dropdown-select" // Apply the CSS class
                >
                <option value="">Select Miner Group</option>
                <option value="item1">Fortune Group</option>
                <option value="item2">Marathon Group</option>
              </select>
      </div>
       <div className="toggle-container">
        <span className="toggle-text">Customer Profile</span>
        <label className={`toggle-button ${isButtonOn ? 'active' : ''}`}>
          <input
            type="checkbox"
            checked={isButtonOn}
            onChange={handleButtonClick}
          />
          <div className="slider"></div>
          <span className="slider-label">{isButtonOn ? 'Auto' : 'Manual'}</span>
        </label>
        <span className="toggle-text">Market Profile</span>
        <label className={`toggle-button ${isButtonOn2 ? 'active' : ''}`}>
          <input
            type="checkbox"
            checked={isButtonOn2}
            onChange={handleButtonClick2}
          />
          <div className="slider"></div>
          <span className="slider-label">{isButtonOn2 ? 'Auto' : 'Manual'}</span>
        </label>
      </div>
    <br/>
      <div style={{ textAlign: 'center' }}>
        <form onSubmit={handleSubmit}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {isButtonOn === false && (
            <div style={{ marginRight: '20px' }}>
              <TextField
                type="text"
                label="Enter kWh Cost in USD"
                value={firstmanualInput}
                onChange={handleFirstManualInputChange}
                style={{
                  color: 'blue',
                  fontSize: '14px',
                  backgroundColor: '#f5f5f5',
                }}
              />
              </div>
            )}
            
            {isButtonOn2 == false  && (
              <div style={{ marginRight: '20px' }}>
                <TextField
                  type="text"
                  label = "Enter BTC Price in USD"
                  value={secondmanualInput}
                  onChange={handleSecondManualInputChange}
                  style={{
                    color: 'green',
                    fontSize: '14px',
                    backgroundColor: '#f5f5f5',
                  }}
                />
              </div>
            )}
            {isButtonOn2 == false  && (
              <div style={{ marginRight: '20px' }}>
                <TextField
                  type="text"
                  label = "Enter Transaction Fee"
                  value={fourthmanualInput}
                  onChange={handleFourthManualInputChange}
                  style={{
                    color: 'green',
                    fontSize: '14px',
                    backgroundColor: '#f5f5f5',
                  }}
                />
              </div>
            )}
            {((isButtonOn2 == false) || (isButtonOn == false))  && (
              <div style={{ marginRight: '20px' }}>
              <button type="submit" className="submitButton">
                Submit
              </button>
              </div>
            )}
          </div>
        </form>
       <br/>
       <div className="toggle-container">
        <span className="toggle-text">Per Group</span>
        <label className={`toggle-button ${isButtonOn6 ? 'active' : ''}`}>
          <input
            type="checkbox"
            checked={isButtonOn6}
            onChange={handleButtonClick6}
          />
          <div className="slider"></div>
          <span className="slider-label">{isButtonOn6 ? 'ON' : 'OFF'}</span>
        </label>
      </div>
       <br/>
       <div style={{ display: 'flex', 'align-items': 'flex-start'}}>
      {isButtonOn6 === true && (
       <div id="chartContainer" ref={chartRef} style={{ width: 2500,}}/>
      )}
      {isButtonOn6 === false && (
       <div id="chartContainer" ref={chartRef} style={{ width: 2500,}}/>
      )}
      {isButtonOn6 === false && (
        <Grid container spacing={1} sx={{ mt: '0' }}>
            <Grid item>
              <InfoBlock title = {"Optimal THs and J/TH"} info = {'145 and 29'} color = {'blue'} unit = {''} orientation = {"left"}/>
            </Grid>
            <Grid item>
              <InfoBlock title = {"BTC Price"} info = {27384.83} color = {'#cfb53b'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
              <InfoBlock title = {"Block Reward"} info = {'6.25+0.7'} color = {'purple'} unit = {'BTC'} orientation = {"right"} fontsize={'5px'}/>
              <InfoBlock title = {"BTC Difficulty"} info = {48.01} color = {'magenta'} unit = {'T'} orientation = {"right"} fontsize={'5px'}/>
              <DownArrowIcon />
              <InfoBlock title = {"Revenue"} info = {11.33} color = {'darkorchid'} unit = {'$'} orientation = {"left"} fontsize={'5px'} />
              <NegativeIcon/>
              <InfoBlock title = {"Electricity and Pool Cost"} info = {'2% BTC and $4.0368'} color = {'red'} unit = {''} orientation = {"left"} fontsize={'5px'}/>
              <Grid item>
                <EqualIcon/>
              </Grid>
              <InfoBlock title = {"Max Profit"} info = {7.29} color = {'blue'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>
        </Grid>
      )}
      {isButtonOn6 === true && (
        <Grid container spacing={1} sx={{ mt: '0' }}>
            <Grid item>
              <InfoBlock title = {"Optimal THs and J/TH"} info = {'145 and 29'} color = {'blue'} unit = {''} orientation = {"left"}/>
            </Grid>
            <Grid item>
              <InfoBlock title = {"BTC Price"} info = {27384.83} color = {'#cfb53b'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
              <InfoBlock title = {"Block Reward"} info = {'6.25+0.7'} color = {'purple'} unit = {'BTC'} orientation = {"right"} fontsize={'5px'}/>
              <InfoBlock title = {"BTC Difficulty"} info = {48.01} color = {'magenta'} unit = {'T'} orientation = {"right"} fontsize={'5px'}/>
              <DownArrowIcon />
              <InfoBlock title = {"Revenue"} info = {11000} color = {'darkorchid'} unit = {'$'} orientation = {"left"} fontsize={'5px'} />
              <NegativeIcon/>
              <InfoBlock title = {"Electricity and Pool Cost"} info = {'2% BTC and $4000'} color = {'red'} unit = {''} orientation = {"left"} fontsize={'5px'}/>
              <Grid item>
                <EqualIcon/>
              </Grid>
              <InfoBlock title = {"Max Profit"} info = {7000} color = {'blue'} unit = {'$'} orientation = {"left"} fontsize={'5px'}/>
            </Grid>
        </Grid>
      )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'left' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="220" height="20" >
            <text x="10" y="15" fill="green" fontSize="12">
              ----
            <tspan fill='black' fontSize="10"> Max THs that was set</tspan>
          </text>
        </svg>
        </div>
        <div style={{ display: 'flex', justifyContent: 'left' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="220" height="20">
            <text x="10" y="15" fill="red" fontSize="12">
              ----
            <tspan fill='black' fontSize="10"> Optimal THs</tspan>
          </text>
        </svg>
        </div>
        <br/>
        <div className="toggle-container">
            <span className="toggle-text">Optimal Hashrate in THs</span>
            <label className={`toggle-button ${isButtonOn3 ? 'active' : ''}`}>
              <input
                type="checkbox"
                checked={isButtonOn3}
                onChange={handleButtonClick3}
              />
              <div className="slider"></div>
              <span className="slider-label">{isButtonOn3 ? 'Auto' : 'Manual'}</span>
            </label>
        </div>
        {isButtonOn3 == false && (
        <div>
          <br/><br/>
        </div>
        )}
        <br/>
        <div className="toggle-container">
        <span className="toggle-text1">Ambient Temperature(30°C - 40°C - 45°C)</span>
        <label className={`toggle-button ${isButtonOn4 ? 'active' : ''}`}>
          <input
            type="checkbox"
            checked={isButtonOn4}
            onChange={handleButtonClick4}
          />
          <div className="slider"></div>
          <span className="slider-label"></span>
        </label>
        <span className="toggle-text1">Power Consumption Constriant</span>
        <label className={`toggle-button ${isButtonOn5 ? 'active' : ''}`}>
          <input
            type="checkbox"
            checked={isButtonOn5}
            onChange={handleButtonClick5}
          />
          <div className="slider"></div>
          <span className="slider-label"></span>
        </label>
      </div>
    <br/>
    <form onSubmit={handleSubmit3}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {isButtonOn4 === true && (
            <div style={{ marginRight: '20px' }}>
              <TextField
                type="text"
                label="Max allowed temperature"
                value={fourthmanualInput}
                onChange={handleFourthManualInputChange}
                style={{
                  color: 'blue',
                  fontSize: '14px',
                  backgroundColor: '#f5f5f5',
                }}
              />
              </div>
            )}
            {isButtonOn5 == true  && (
              <div style={{ marginRight: '20px' }}>
                <TextField
                  type="text"
                  label ="Min-Max in Kilowatts"
                  value={fifthmanualInput}
                  onChange={handleFifthManualInputChange}
                  style={{
                    color: 'green',
                    fontSize: '14px',
                    backgroundColor: '#f5f5f5',
                  }}
                />
              </div>
            )}
            {((isButtonOn4 == true) || (isButtonOn5 == true))  && (
              <div style={{ marginRight: '20px' }}>
              <button type="submit" className="submitButton">
                Submit
              </button>
              </div>
            )}
          </div>
        </form>
       <br/><br/>
        <div>
          <button onClick={handleShowChart} className="submitButton">{showChart ? 'Hide History' : 'Show History'} </button>
          {showChart && <div><HistoricChart/></div>}
        </div>
        <br/>
        <div>
        <div>{showChart && exportToCSV()}</div>
        </div>
      </div>
      </PageContainer>
  ) ;
};

export default withAuthenticationRequired(MockDVFS, {
  onRedirecting: () => <Loading />,
});

