import React from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { IntervalSelect } from "../components/interval-select";
import { Stack, Typography } from "@mui/material";
import { isEmpty } from "../components/util";
import * as cfg from './cfg'

export const Command = ({ label, name, interval = true }) => {
    if (isEmpty(label)) {
        label = name
    }

    console.log('command ' + name + ' ...')
    let val = cfg.getCmd(name) ?? { enabled: true, interval: 3600 }
    // for ztp, getCmd will return just 'enabled'
    if (!val.interval) {
        val.interval = 3600
    }

    // console.log({ val: val })
    const [enabled, setEnabled] = React.useState(val.enabled);
    const [timegap, setTimegap] = React.useState(val.interval);

    const handleChange = (event) => {
        setEnabled(event.target.checked);
    };

    const handleInterval = (val) => {
        setTimegap(val)
    }

    React.useEffect(() => {
        cfg.setCmd(name, { enabled: enabled, interval: timegap })
    }, [enabled, timegap])

    return (
        <FormGroup>
            <Typography>{label}</Typography>
            <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                <FormControlLabel control={
                    <Checkbox checked={enabled} onChange={handleChange} />
                } label="Enabled" />
                {
                    interval ?
                        <IntervalSelect name="Interval" value={timegap} callback={handleInterval} />
                        : ""
                }
            </Stack>
        </FormGroup>
    );
}

export default Command;
