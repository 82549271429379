import { createTheme } from "@mui/material/styles";
import { teal, amber } from "@mui/material/colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const theme = createTheme({
  spacing: 4,
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          height: 40,
          // '&:hover': {
          //   backgroundColor: '#0086b3', // Change the background color on hover to teal
          // },
          // '&:active': {
          //   backgroundColor: 'teal', // Change the background color on active (when clicked) to teal
          // },
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        margin: "dense",
      },
    },
    // MuiSelect: {
    //   defaultProps: {
    //     IconComponent: KeyboardArrowDownIcon,
    //     MenuProps: {
    //       anchorOrigin: {
    //         vertical: "bottom",
    //         horizontal: "left", // Align menu to the left
    //       },
    //       transformOrigin: {
    //         vertical: "top",
    //         horizontal: "left", // Align menu to the left
    //       },
    //       PaperProps: {
    //         style: {
    //           boxShadow:
    //             "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
    //         },
    //       },
    //     },
    //   },
    //   styleOverrides: {
    //     root: {
    //       "&:focus": {
    //         backgroundColor: "rgba(29, 27, 32, 0.08)",
    //         outline: "none", // Remove browser outline
    //         borderColor: "unset", // Prevent border color change on focus
    //         boxShadow: "none", // Prevent box shadow on focus
    //       },
    //       "&:focus-visible": {
    //         // Override focus styles for clicks
    //         outline: "none", // Remove browser outline
    //         borderColor: "unset", // Prevent border color change on click
    //         boxShadow: "none", // Prevent box shadow on click
    //       },
    //       width: "12.5rem",
    //       height: "2rem",
    //       borderRadius: "0.5rem",
    //       filled: {
    //         backgroundColor: "#C5E7FF", // Background color on focus
    //       },
    //     },
    //     select: {
    //       minWidth: "12.5rem",
    //       height: "2rem",
    //       padding: "1.5rem 1rem 1rem 1rem",
    //     },
    //   },
    // },
    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: {
    //       height: "3.5rem",
    //       minWidth: "12.5rem",
    //       color: "#44474E",
    //       fontWeight: 400,
    //       "&:hover": {
    //         backgroundColor: "rgba(29, 27, 32, 0.08)", // Custom hover color
    //       },
    //     },
    //   },
    // },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFab: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiToolbar: {
      defaultProps: {
        variant: "dense",
      },
    },
  },
  palette: {
    main: "blue",
  },
  action: {
    hover: "teal",
  },
});

export default theme;
