import React from 'react';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import * as cfg from './cfg';

const PoolConfig = (props) => {

  console.log('pool config ...', props.PoolIndex, props.MinerGroupName)
  const PoolIndex = props.PoolIndex
  const MinerGroupName = props.MinerGroupName

  const [pool, setPool] = React.useState({
    url: '',
    user: '',
    pass: '',
  });

  const [poolHelp, setPoolHelp] = React.useState({
    url: { error: false, helperText: 'Empty' },
    user: { error: false, helperText: 'Empty' },
    pass: { error: false, helperText: 'Good' },
  });

  React.useEffect(() => {
    const fn = async () => {
      console.log('in get pool-config', MinerGroupName)
      const data = cfg.getPool(PoolIndex - 1, MinerGroupName);
      if (data) {
        setPool(data);
      }
    };
    fn();
  }, [PoolIndex, MinerGroupName]);

  React.useEffect(() => {
    const fn = async () => {
      cfg.setPool(PoolIndex - 1, pool, MinerGroupName);
      console.log('in set pool-config', pool, MinerGroupName);
    }
    fn()
  }, [pool, PoolIndex, MinerGroupName]);

  const handleChange = (event) => {
    const target = event.target;
    const { id } = target;

    if (id.endsWith('url') || id.endsWith('user')) {
      if (target.value) {
        console.log('%s is valid', target.value);
        setPool((prev) => ({
          ...prev,
          [id]: target.value,
        }));
        setPoolHelp((prev) => ({
          ...prev,
          [id]: {
            error: false,
            helperText: 'Good',
          },
        }));
      } else {
        console.log('%s is NOT valid', target.value);
        setPool((prev) => ({
          ...prev,
          [id]: target.value,
        }));
        setPoolHelp((prev) => ({
          ...prev,
          [id]: {
            error: false,
            helperText: 'Empty',
          },
        }));
      }
    }
    if (id === 'pass') {
      setPool((prev) => ({
        ...prev,
        [id]: target.value,
      }));
    }
  };

  return (
    <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
      <Typography>Pool {PoolIndex}:</Typography>
      <TextField
        id="url"
        label="URL"
        variant="outlined"
        value={pool.url}
        onChange={handleChange}
        error={poolHelp.url.error}
        helperText={poolHelp.url.helperText}
        sx={{ ml: 2 }}
        style={{ width: 400 }}
      />
      <TextField
        id="user"
        label="User"
        variant="outlined"
        value={pool.user}
        onChange={handleChange}
        error={poolHelp.user.error}
        helperText={poolHelp.user.helperText}
        sx={{ ml: 2 }}
        style={{ width: 400 }}
      />
      <TextField
        id="pass"
        label="Password"
        variant="outlined"
        value={pool.pass}
        onChange={handleChange}
        error={poolHelp.pass.error}
        helperText={poolHelp.pass.helperText}
        sx={{ ml: 2 }}
      />
    </Stack>
  );
};

export default PoolConfig;
