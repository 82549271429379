import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Box, Button, Stack, Typography } from '@mui/material';
import ReactJsonViewCompare from 'react-json-view-compare';
import * as cfg from './cfg'
import Footer from '../components/footer';

export const ReviewConfig = () => {
  const [oldCfg, setOldCfg] = React.useState({});
  const [newCfg, setNewCfg] = React.useState({});

  console.log('config review ...')

  const defaultHandler = () => {
    cfg.defaultCfg()
    setNewCfg(cfg.getCfg())
  }

  const revertHandler = () => {
    cfg.revertCfg()
    setNewCfg(cfg.getCfg())
  }

  const { getAccessTokenSilently } = useAuth0();
  const saveHandler = () => {
    const fn = async () => {
      const token = await getAccessTokenSilently();
      await cfg.saveCfg(token)

      setOldCfg(cfg.getCfg0())
      setNewCfg(cfg.getCfg())
    }
    fn()
  }

  React.useEffect(() => {
    setOldCfg(cfg.getCfg0())
    setNewCfg(cfg.getCfg())
  }, [])

  return (
    <Box>
      <Stack direction="row">
        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }} >Review</Typography>
        <Button onClick={defaultHandler} variant="outlined" sx={{ mr: 1 }}>Default</Button>
        <Button onClick={revertHandler} variant="outlined" sx={{ mr: 1 }}>Revert</Button>
        <Button onClick={saveHandler} variant="outlined">Save</Button>
      </Stack>
      <Box>
        <ReactJsonViewCompare oldData={oldCfg} newData={newCfg} />
      </Box>
      <Footer />
    </Box >
  );
};

export default ReviewConfig;
