import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div
      style={{
        position: 'absolute',
        top: 500,
        left: 0,
        right: 0,
        bottom: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2" component="div" sx={{ color: '#007bff', marginBottom: 20 }}>
          Welcome to FluxVision&trade;
        </Typography>
        <Button
          onClick={() => loginWithRedirect()}
          id="qsLoginBtn"
          variant="contained"
        >
          Log In
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
