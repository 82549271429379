import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { UserA } from "../user/user";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Toolbar } from "@mui/material";
import * as api from '../api/api'
import Loading from "../components/loading";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Footer from '../components/footer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Modal from 'react-modal';
import Popup from 'reactjs-popup';


const FailModal = ({ isOpen, onClose }) => {
  return (
    <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <div>
        <h4>Warning Message</h4>
        <p className="success-message">Please make sure Command(s) and Miner Group is selected</p>
        <button onClick={onClose} style={{ marginLeft: 0 }}>Close</button>
      </div>
    </Popup>
  );
};

const FailModalInvalidFile = ({ isOpen, onClose }) => {
  return (
    <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <div>
        <h4>Warning Message</h4>
        <p className="success-message">Invalid JSON File. Please fix it and Try again</p>
        <button onClick={onClose} style={{ marginLeft: 0 }}>Close</button>
      </div>
    </Popup>
  );
};

const FailModalBiggerFile = ({ isOpen, onClose }) => {
  return (
    <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <div>
        <h4>Warning Message</h4>
        <p className="success-message">Allowed JSON File Size is 1MB. Please Fix it and Try again</p>
        <button onClick={onClose} style={{ marginLeft: 0 }}>Close</button>
      </div>
    </Popup>
  );
};

const SuccessModal = ({ isOpen, onClose, msg}) => {
  return (
    <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <div>
        <h4>Success Message</h4>
        <p className="success-message">{msg}</p>
        <button onClick={onClose} style={{ marginLeft: 0 }}>Close</button>
      </div>
    </Popup>
  );
};

const FailModalError = ({ isOpen, onClose, msg }) => {
  return (
    <Popup open={isOpen} onClose={onClose} contentStyle={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <div>
        <h4>Error Message</h4>
        <p className="success-message">{msg}</p>
        <button onClick={onClose} style={{ marginLeft: 0 }}>Close</button>
      </div>
    </Popup>
  );
};

export const Send = () => {

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [val, setVal] = useState([]);
  const [val2, setVal2] = useState('');
  const [cmdList, setCmdList]=useState([]);
  const [minerGroupList, setMinerGroups]=useState([]);
  const [methodType, setMethodType]=useState("GET");
  const [cmdListFromFile, setJsonFileContent] = useState();
  const [errorData, setErrorData] = useState(null);
  const [fileName, setFileName] = useState("No File Selected");
  const [submitSuccess, setSubmitSuccess] = useState('');
  const [submitMsg, setMsg] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const methodTypes = ["GET", "POST"];

  const { user: user0, getAccessTokenSilently } = useAuth0();

  const getOpCommands = ["version", "summary", "stats", "ipreport", "temperature", "psu", "voltage", "mode", "fan", "led", "frequency"]
  const postOpCommands = ["addpool", "enablepool", "disablepool", "removepool", "updatepools", "updateztppools", "fan", "mode", "led"]

  useEffect(() => {
    const runFn = async () => {
      const token = await getAccessTokenSilently();
      const commandsList = await api.GetValidCommands(token);
      var user;
      await api.GetUser(token, (data) => {
        user = data;
      })
      if (commandsList === undefined) {
      }
      else {
        var commands = [];
        for (let i=0; i < commandsList.length; i++) {
          if (getOpCommands.includes(commandsList[i])) {
            commands.push(commandsList[i])
          }
        }
        commands.unshift("select all")
        setCmdList(commands)
      }
      const deviceGroups = await api.GetAllDeviceGroups(token);
      if (deviceGroups === undefined) {
      }
      else {
        var minerGroupList = [];
        for (let i=0; i < deviceGroups.length; i++) {
          minerGroupList.push(deviceGroups[i].DGName);
        }
        setMinerGroups(minerGroupList)
      }
    }
    runFn();
  }, []);

  const checkVal = async(value) => {
    if (value.includes("select all")) {
      setVal(cmdList)
    } else {
      if (val.includes("select all")) {
        setVal([])
      } else {
        setVal(value)
      }
    }
  }

  const checkVal2 = async(value) => {
    setVal2(value)
  }

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const onSubmit = async () => {
    let commands = []
    let commandList = [];

    if (methodType === "GET") {
      commands = Array.isArray(val) ? [...val] : [];
      const index = commands.indexOf("select all")
      if (index > -1) {
        commands.splice(index, 1);
      }
      for (let i = 0; i < commands.length ; i++) {
        commandList.push({"command" : commands[i]})
      }
    } else {
      const uniquecmdList = cmdListFromFile.filter((obj, index, self) =>
        index === self.findIndex((item) => item.command === obj.command)
      );
      for (let i = 0; i < uniquecmdList.length ; i++) {
        if (postOpCommands.includes(uniquecmdList[i].command)) {
          console.log(uniquecmdList[i])
          commandList.push(uniquecmdList[i])
        }
      }
    }

    var dgname = val2
   
    const token = await getAccessTokenSilently();
    const info = {}
    info.dgname = dgname;
    info.commands = commandList;
    info.lastTs = new Date();
    console.log(info)
    if (dgname !== '' && commandList.length > 0) {
      let result = await api.SendGroupCommands(token, info);
      if (result.status !== 'ok') {
        setSubmitSuccess("error");
        setSuccessModalOpen(true);
        setMsg(result.msg)
      } else {
        setSubmitSuccess("ok");
        setSuccessModalOpen(true);
        setMsg(result.msg)
      }
    } else {
      setSubmitSuccess("blank");
      setSuccessModalOpen(true);
    }
  }

  const readFileOnUpload = (uploadedFile) =>{
    const fileReader = new FileReader();
    fileReader.onloadend = ()=>{
      try {
        const fileSize = uploadedFile.size;
        if (fileSize < 1 * 1024 * 1024) {
          setJsonFileContent(JSON.parse(fileReader.result));
          setErrorData(null)
        } else {
          setSubmitSuccess("biggerfile");
          setSuccessModalOpen(true);
          setErrorData("biggerfile");
        }
      } catch(e){
          setSubmitSuccess("invalidfile");
          setSuccessModalOpen(true);
          setErrorData("invalidfile");
      }
    }
    if( uploadedFile !== undefined) {
      setFileName(uploadedFile.name)
      fileReader.readAsText(uploadedFile);
    }
}

  return (
    <Box>
      <Toolbar>
        <Typography variant="h4" component="div" sx={{ flexGrow: 1, marginLeft: '40%', whiteSpace: 'nowrap'}}>Send Commands to Miner Groups</Typography>
      </Toolbar>
      <Table aria-label="Users">
          <TableHead>
            <TableRow>
              <TableCell>Request Type</TableCell>
              <TableCell>Commands</TableCell>
              <TableCell>Miner Groups</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Autocomplete
                    disablePortal
                    onChange={(event, value) => setMethodType(value)}
                    sx = {{
                 
                      width: '100px',
                      mr: "5%",
                      ml : "5%",
                      mt: "1%"
                    }}
                    defaultValue={methodTypes[0]}
                    disableClearable
                    id="mode"
                    options={methodTypes}
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} size="small" placeholder="Select mode" aria-label="Select mode" />
                    )}

                  />
              </TableCell>
              <TableCell>
                {(methodType === "POST") ?
                  <Box display = 'flex'>
                      <label htmlFor="upload-photo"> 
                          <input
                            style={{ display: 'none' }}
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            onChange={(e)=>readFileOnUpload(e.target.files[0])}
                          />
                          {fileName + " "}<br></br>
                          <Button variant="contained" sx={{ whiteSpace: 'nowrap', textTransform: 'none' }} component="span">
                              Upload Commands JSON File
                          </Button>
                                  {errorData !== null && submitSuccess === "biggerfile" && (
                                    <FailModalBiggerFile isOpen={successModalOpen} onClose={handleCloseSuccessModal} />
                                  )}
                                  {errorData !== null && submitSuccess === "invalidfile" && (
                                    <FailModalInvalidFile isOpen={successModalOpen} onClose={handleCloseSuccessModal} />
                                  )}
                      </label>
                  </Box> :
                  /*
                   * Handle GET from here
                   */
                  <Autocomplete
                    multiple
                    onChange={(event, value) => checkVal(value)}
                    value={val}
                    sx = {{
                 
                      width: 500,
                      mt: "1%",
                      mr: "5%"
                      
                    }}
                    disableCloseOnSelect
                    id="combo-box-demo2"
                    options={cmdList}
                    getOptionLabel={(option) => option}

                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Commands"   />
                    )}
                    renderTags={() => null}
                  />
                }
              </TableCell>
              <TableCell>
                 <Autocomplete
                  // multiple
                  onChange={(event, value) => checkVal2(value)}
                  value={val2}
                  sx = {{
               
                    width: 500,
                    ml: "5%",
                    mr: "5%",
                    mt: "1%"
                    
                  }}
                  disableCloseOnSelect
                  id="group-select"
                  options={minerGroupList}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Miner Group"   />
                  )}
                  renderTags={() => null}
                />
              </TableCell>
            </TableRow>
          </TableBody>
      </Table>
      <Box display="flex" >
      <Button sx = {{mt: "1%", ml: "85%"}} variant='contained'  onClick={onSubmit}>Submit</Button>
      <div>
      {submitSuccess === "ok" && (
        <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} msg={submitMsg}/>
      )}
      {submitSuccess === "blank" && (
        <FailModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} />
      )}
      {submitSuccess === "error" && (
        <FailModalError isOpen={successModalOpen} onClose={handleCloseSuccessModal} msg={submitMsg} />
      )}
        </div>
    </Box>
    <Footer />
    </Box>
  );
}

export default withAuthenticationRequired(Send, {
  onRedirecting: () => <Loading />,
});