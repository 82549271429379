import { Box, Typography } from "@mui/material";
import React from "react";
import ErrorIcon from "../../../assets/auradine_error.svg";

type AuradineErrorStateProps = {
  message: string;
};

const AuradineErrorState: React.FC<AuradineErrorStateProps> = (
  props: AuradineErrorStateProps
): React.ReactElement => {
  const { message } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={"center"}
      alignItems={"center"}
      alignContent={"center"}
      gap={4}
      height={"100%"}
    >
      <img
        style={{ width: "20rem", height: "20rem" }}
        src={ErrorIcon}
        alt="empty basket"
      />
      <Typography style={{ fontSize: "1.375rem", fontWeight: 400 }}>
        {message}
      </Typography>
    </Box>
  );
};

export default AuradineErrorState;
