import React from 'react';
import {Box} from '@mui/material';
import './FractionalUnitDisplay.css';

const FractionalUnitDisplay = ({ num, den}) => {
  return (
    
    <div className="fractional-unit-display">
      <div className="numerator">{num}</div>
     {den &&  <div className="line"></div>}
    {den && <div className="denominator">{den}</div>}
    </div>
    
  );
};

export default FractionalUnitDisplay;